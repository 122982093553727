import { AxiosX } from '../../utils/axios'
import { ITeamUsers } from './interfaces/teamusers.interface'

export const teamUserService = {
    getTeamUserForTeam: async (teamId: number): Promise<ITeamUsers[]> => {
        const resp = await AxiosX.get<ITeamUsers[]>(
            `/v1/team/team-user/by-id/${teamId}`
        )

        return resp.data
    },

    create: async (data: ITeamUsers): Promise<ITeamUsers> => {
        const response = await AxiosX.post<ITeamUsers>(
            `v1/team/team-user/by-id`,
            data
        )

        return response.data
    },

    getUserTeams: async (userId: number): Promise<ITeamUsers[]> => {
        const data = await AxiosX.get<ITeamUsers[]>(
            `v1/team/team-user/by-user-id/${userId}`
        )

        return data.data
    },
}
