import React, { useEffect, useState } from 'react'
import './App.css'
import Layout from './components/Layout/Layout'
import { withRouter } from './utils/withRouter'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { Spin } from 'antd'

// @ts-ignore
function App(): JSX.Element {
    const { getAccessTokenSilently } = useAuth0()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const getUserMetadata = async (): Promise<void> => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: `https://x.supercharged.dev`,
                })
                localStorage.setItem('accessToken', accessToken)
                setLoading(true)
            } catch (e) {
                Sentry.captureException(e)
            }
        }

        getUserMetadata()
    }, [getAccessTokenSilently])

    return (
        <div>
            {loading && <Layout />}
            <div style={{ textAlign: 'center', paddingTop: '50vh' }}>
                {!loading && <Spin size="large" />}
            </div>
        </div>
    )
}

export default withAuthenticationRequired(withRouter(App))
