/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo } from 'react'
import { Col, Input, Popconfirm, Row, Select, Space } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { getEnumKeys } from '../../utils/enum'
import { CalculationRuleContext } from './new-calculation-rule'
import {
    CalColumnTypes,
    ArithmeticOperations,
} from '../../services/calculation-rule/interfaces/calculation-rule.interface'
import {
    calculationRuleHelper,
    ICalcColumnOption,
} from './calculation-rule.helper'

export interface ICalculationRuleArithmeticStep {
    column1?: number | string
    column1Type?: CalColumnTypes
    statusOfColumn1?: boolean
    column2?: number | string
    column2Type?: CalColumnTypes
    statusOfColumn2?: boolean
    operation: ArithmeticOperations
    textDecorationOfColumn1?: boolean
    textDecorationOfColumn2?: boolean
}

function CalculationRuleArithmeticStep({
    stepId,
    columnId,
    name,
    title,
}: {
    stepId: number
    columnId: number
    name: string
    title: string
}): JSX.Element {
    const calculationRuleValue = useContext(CalculationRuleContext)

    const [availableSelectOptions, setAvailableSelectOptions] = React.useState<
        ICalcColumnOption[]
    >([])

    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        if (!calculationRuleValue) {
            return
        }
        const tempAvailableColumns =
            calculationRuleHelper.filterAvailableColumns(
                calculationRuleValue,
                columnId,
                stepId
            )

        setAvailableSelectOptions([
            ...(tempAvailableColumns || []),
            {
                name: '__constant',
                id: -1,
                type: CalColumnTypes.constant,
                uniqueID: `-1-${CalColumnTypes.constant}`,
            },
        ])
    }, [
        calculationRuleValue,
        calculationRuleValue?.columns,
        calculationRuleValue?.dataset?.DatasetMetadata,
        columnId,
        stepId,
    ])

    function onChangeColumnValue(
        type: 'column1' | 'column2' | 'operation',
        columnOption?: ICalcColumnOption,
        arithmeticOperation?: ArithmeticOperations
    ): void {
        setLoading(true)
        const columnsUpdate = calculationRuleValue?.columns?.map((column) => ({
            ...column,
            steps:
                column.columnID !== columnId
                    ? column.steps
                    : column.steps?.map((item) => {
                          if (item.stepID !== stepId) {
                              return item
                          }
                          const arithmeticStepValue = {
                              ...item.arithmetic,
                          }

                          if (type === 'column1' && columnOption) {
                              arithmeticStepValue.column1 = columnOption.id
                              arithmeticStepValue.column1Type =
                                  columnOption.type
                              arithmeticStepValue.statusOfColumn1 = true
                          } else if (type === 'column2' && columnOption) {
                              arithmeticStepValue.column2 = columnOption.id
                              arithmeticStepValue.column2Type =
                                  columnOption.type
                              arithmeticStepValue.statusOfColumn2 = true
                          } else if (
                              type === 'operation' &&
                              arithmeticOperation
                          ) {
                              arithmeticStepValue.operation =
                                  arithmeticOperation
                          }

                          return {
                              ...item,
                              arithmetic: arithmeticStepValue,
                          }
                      }),
        }))

        // @ts-ignore
        calculationRuleValue?.setColumns(columnsUpdate || [])
        setLoading(false)
    }

    const currentStepDetails = useMemo(() => {
        return calculationRuleValue?.columns
            ?.find((column) => column.columnID === columnId)
            ?.steps?.find((step) => step.stepID === stepId)
    }, [calculationRuleValue, columnId, stepId])

    function getInputValueForCol1(): string | undefined {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.column1 === -1
        ) {
            return undefined
        }
        return String(
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.column1
        )
    }

    function getInputValueForCol2(): string | undefined {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.column2 === -1
        ) {
            return undefined
        }
        return String(
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.column2
        )
    }

    function getStatusCol1(): 'warning' | 'error' | undefined {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.column1 === undefined &&
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.statusOfColumn1 === false
        ) {
            return 'error'
        }
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.textDecorationOfColumn1 === false
        ) {
            return 'warning'
        }
        return undefined
    }

    function getStatusCol2(): 'warning' | 'error' | undefined {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.column2 === undefined &&
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.statusOfColumn2 === false
        ) {
            return 'error'
        }
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.textDecorationOfColumn2 === false
        ) {
            return 'warning'
        }
        return undefined
    }

    function getTextDecorationCol1(): 'line-through' | undefined {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.textDecorationOfColumn1 === false
        ) {
            return 'line-through'
        }
        return undefined
    }

    function getTextDecorationCol2(): 'line-through' | undefined {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .arithmetic?.textDecorationOfColumn2 === false
        ) {
            return 'line-through'
        }
        return undefined
    }

    return (
        <Row style={{ marginTop: '10px' }} gutter={[18, 16]}>
            <Space size="large">
                <Col>
                    <span
                        style={{
                            paddingLeft: '42px',
                        }}
                    >
                        <b>{name}</b>
                        <i> (arithmetic)</i>
                    </span>
                </Col>
                <Col>
                    <span
                        style={{
                            paddingRight: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Col 1
                    </span>
                    <Select
                        value={
                            availableSelectOptions.find((item) => {
                                let uniqueID
                                uniqueID = `${
                                    calculationRuleValue?.columns?.[
                                        columnId - 1
                                    ]?.steps?.[stepId - 1]?.arithmetic?.column1
                                }-${
                                    calculationRuleValue?.columns?.[
                                        columnId - 1
                                    ]?.steps?.[stepId - 1].arithmetic
                                        ?.column1Type
                                }`

                                if (
                                    calculationRuleValue?.columns?.[
                                        columnId - 1
                                    ]?.steps?.[stepId - 1].arithmetic
                                        ?.column1Type ===
                                    CalColumnTypes.constant
                                ) {
                                    uniqueID = `-1-constant`
                                }

                                if (uniqueID === item.uniqueID) {
                                    return true
                                }
                                return false
                            })?.uniqueID
                        }
                        status={getStatusCol1()}
                        style={{
                            minWidth: '160px',
                            textDecorationLine: getTextDecorationCol1(),
                        }}
                        loading={loading}
                        disabled={loading}
                        onChange={(item: string) => {
                            setLoading(true)
                            const selectedItem = availableSelectOptions.find(
                                (column) => column.uniqueID === item
                            )
                            if (selectedItem) {
                                onChangeColumnValue('column1', selectedItem)
                            }
                            setLoading(false)
                        }}
                    >
                        {availableSelectOptions
                            .filter((item) => {
                                return !(
                                    currentStepDetails?.arithmetic?.column2 &&
                                    currentStepDetails.arithmetic.column2 ===
                                        item.id &&
                                    currentStepDetails.arithmetic
                                        .column2Type === item.type
                                )
                            })
                            .map((column) => (
                                <Select.Option
                                    key={column.uniqueID}
                                    value={column.uniqueID}
                                >
                                    {column.name}
                                </Select.Option>
                            ))}
                    </Select>
                    {currentStepDetails?.arithmetic?.column1Type ===
                        CalColumnTypes.constant && (
                        <Input
                            value={getInputValueForCol1()}
                            type="number"
                            onChange={(e) => {
                                setLoading(true)
                                const selectedItem =
                                    availableSelectOptions.find(
                                        (column) =>
                                            column.uniqueID ===
                                            `-1-${CalColumnTypes.constant}`
                                    )
                                if (selectedItem) {
                                    onChangeColumnValue('column1', {
                                        ...selectedItem,
                                        id: e.target.value,
                                    })
                                }
                                setLoading(false)
                            }}
                            placeholder="constant value"
                            style={{ width: '80px' }}
                        />
                    )}
                </Col>
                <Col>
                    <span style={{ paddingRight: '10px', fontWeight: 'bold' }}>
                        Operation
                    </span>
                    <Select
                        // @ts-ignore
                        value={getEnumKeys(ArithmeticOperations).find(
                            (item) => {
                                const uniqueID = `${
                                    calculationRuleValue?.columns?.[
                                        columnId - 1
                                    ]?.steps?.[stepId - 1]?.arithmetic
                                        ?.operation
                                }`

                                if (uniqueID === item) {
                                    return true
                                }

                                return false
                            }
                        )}
                        style={{ minWidth: '160px' }}
                        loading={loading}
                        disabled={loading}
                        onChange={(item: ArithmeticOperations) => {
                            setLoading(true)
                            onChangeColumnValue('operation', undefined, item)
                            setLoading(false)
                        }}
                    >
                        {getEnumKeys(ArithmeticOperations).map((key) => (
                            <Select.Option key={key} value={key}>
                                {key}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col>
                    <span
                        style={{
                            paddingRight: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Col 2
                    </span>
                    <Select
                        value={
                            availableSelectOptions.find((item) => {
                                let uniqueID
                                uniqueID = `${
                                    calculationRuleValue?.columns?.[
                                        columnId - 1
                                    ]?.steps?.[stepId - 1]?.arithmetic?.column2
                                }-${
                                    calculationRuleValue?.columns?.[
                                        columnId - 1
                                    ]?.steps?.[stepId - 1].arithmetic
                                        ?.column2Type
                                }`

                                if (
                                    calculationRuleValue?.columns?.[
                                        columnId - 1
                                    ]?.steps?.[stepId - 1].arithmetic
                                        ?.column2Type ===
                                    CalColumnTypes.constant
                                ) {
                                    uniqueID = `-1-constant`
                                }

                                if (uniqueID === item.uniqueID) {
                                    return true
                                }

                                return false
                            })?.uniqueID
                        }
                        status={getStatusCol2()}
                        style={{
                            minWidth: '160px',
                            textDecorationLine: getTextDecorationCol2(),
                        }}
                        onChange={(item: string) => {
                            setLoading(true)
                            const selectedItem = availableSelectOptions.find(
                                (column) => column.uniqueID === item
                            )
                            if (selectedItem) {
                                onChangeColumnValue('column2', selectedItem)
                            }
                            setLoading(false)
                        }}
                        loading={loading}
                        disabled={loading}
                    >
                        {availableSelectOptions
                            .filter((item) => {
                                return !(
                                    currentStepDetails?.arithmetic?.column1 &&
                                    currentStepDetails.arithmetic.column1 ===
                                        item.id &&
                                    currentStepDetails.arithmetic
                                        .column1Type === item.type
                                )
                            })
                            .map((column) => (
                                <Select.Option
                                    key={column.uniqueID}
                                    value={column.uniqueID}
                                >
                                    {column.name}
                                </Select.Option>
                            ))}
                    </Select>
                    {currentStepDetails?.arithmetic?.column2Type ===
                        CalColumnTypes.constant && (
                        <Input
                            value={getInputValueForCol2()}
                            type="number"
                            onChange={(e) => {
                                setLoading(true)
                                const selectedItem =
                                    availableSelectOptions.find(
                                        (column) =>
                                            column.uniqueID ===
                                            `-1-${CalColumnTypes.constant}`
                                    )
                                if (selectedItem) {
                                    onChangeColumnValue('column2', {
                                        ...selectedItem,
                                        id: e.target.value,
                                    })
                                }
                                setLoading(false)
                            }}
                            placeholder="constant value"
                            style={{ width: '80px' }}
                        />
                    )}
                </Col>
                <Col>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        onMouseEnter={() => {
                            if (!calculationRuleValue) {
                                return
                            }
                            calculationRuleHelper.onMouseEnter(
                                calculationRuleValue,
                                columnId,
                                stepId
                            )
                        }}
                        onMouseLeave={() => {
                            if (!calculationRuleValue) {
                                return
                            }
                            calculationRuleHelper.onMouseLeave(
                                calculationRuleValue,
                                columnId,
                                stepId
                            )
                        }}
                    >
                        <Popconfirm
                            title={title}
                            onConfirm={() => {
                                if (!calculationRuleValue) {
                                    return
                                }
                                calculationRuleHelper.deleteStep(
                                    calculationRuleValue,
                                    columnId,
                                    stepId
                                )
                            }}
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                    </a>
                </Col>
            </Space>
        </Row>
    )
}

export default CalculationRuleArithmeticStep
