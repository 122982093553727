import { AxiosX } from '../../utils/axios'
import {
    IAllocationRuleGroup,
    IAllocationRuleGroupRequest,
} from './interfaces/allocation-rule-group.interface'

export const allocationRuleGroupService = {
    getAll: async (): Promise<IAllocationRuleGroup[]> => {
        const data = await AxiosX.get<IAllocationRuleGroup[]>(
            'v1/allocation-rule/group/all'
        )

        return data.data
    },

    create: async (
        data: IAllocationRuleGroupRequest
    ): Promise<IAllocationRuleGroup> => {
        const result = await AxiosX.post<IAllocationRuleGroup>(
            'v1/allocation-rule/group/create',
            data
        )

        return result.data
    },

    getConfig: async (
        ruleGroupID: number
    ): Promise<IAllocationRuleGroup['AllocationRuleGroupConfig']> => {
        const result = await AxiosX.get<
            IAllocationRuleGroup['AllocationRuleGroupConfig']
        >(`v1/allocation-rule/group/config/by-id/${ruleGroupID}`)

        return result.data
    },
}
