import * as React from 'react'
import {
    Button,
    Col,
    Input,
    notification,
    Row,
    Select,
    Space,
    Table,
    Typography,
    Upload,
} from 'antd'
import { RcFile } from 'antd/es/upload'
import * as XLSX from 'xlsx'
import { sourceDatasetService } from '../../../services/source-group/source-dataset.service'
import { handleAPIError } from '../../../utils/catch-error'
import { useEffect } from 'react'
import { routeMapMini } from '../../../constants/routeMap'
import { withRouter } from '../../../utils/withRouter'
import { sourceGroupService } from '../../../services/source-group/source-group.service'
import { ISourceGroup } from '../../../services/source-group/interfaces/source-group.interface'
import { ISourceDataset } from '../../../services/source-group/interfaces/source-dataset.interface'
import { FileUploadService } from '../../../services/file-upload/file-upload.service'
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { FileProperties } from '../../../services/file-upload/interfaces/file-properties.interface'
import { xlsxUtil } from '../../../utils/xlsx.util'

function CreateSourceDataset({ router }: any): JSX.Element {
    const [fileList, setFileList] = React.useState<any[]>([])
    const [sampleType, setSampleType] = React.useState('')

    const [sourceDatasetName, setSourceDatasetName] = React.useState<string>()
    const [loading, setLoading] = React.useState(false)
    const [sourceGroup, setSourceGroup] = React.useState<ISourceGroup>()
    const [tableData, setTableData] = React.useState<
        ISourceDataset['DatasetMetadataV2']
    >([])
    const [fileProperties, setFileProperties] = React.useState<FileProperties>({
        FileLocation: '',
        FileName: '',
        FileType: 'source_dataset',
        FilePreviewData: [],
    })

    useEffect(() => {
        const sourceGroupIdFromQuery = new URLSearchParams(
            router?.location?.search
        ).get('sourceGroupId')
        if (sourceGroupIdFromQuery) {
            sourceGroupService
                .getById(Number(sourceGroupIdFromQuery))
                .then((data) => {
                    setSourceGroup(data)
                })
        } else {
            window.location?.replace(routeMapMini.sourceGroup)
        }
    }, [router?.location?.search])

    const beforeUpload = async (file: RcFile): Promise<boolean> => {
        const isLt2M = file.size / 1024 / 1024 < 1
        if (!isLt2M) {
            notification.error({
                message: 'Sample file must be smaller than 1MB.',
            })
            return false
        }
        const arrayBuffer = await file.arrayBuffer()
        const workbook = XLSX.read(arrayBuffer, {
            type: 'array',
            sheetRows: 50,
        })
        const sheet1 = workbook.Sheets[workbook.SheetNames[0]]
        const sheetInJson: [][] = XLSX.utils.sheet_to_json(sheet1, {
            header: 1,
        })

        const tempTableData: ISourceDataset['DatasetMetadataV2'] = []

        sheetInJson?.[0]?.forEach((value: string, index: number) => {
            const dataType = typeof sheetInJson?.[1]?.[index]
            tempTableData.push({
                columnName: value,
                dataType,
                type: dataType === 'number' ? 'measure' : 'dimension',
            })
        })

        setTableData(tempTableData)
        setFileList([file])
        setFileProperties({
            ...fileProperties,
            FilePreviewData: xlsxUtil.convertCSV2DArrayToObject(sheetInJson),
            FileName: file.name,
        })

        return false
    }

    const uploadFile = async (options: UploadRequestOption): Promise<void> => {
        const { onSuccess, onError, file, onProgress } = options
        try {
            const resp = await FileUploadService.uploadFile(
                // @ts-ignore
                file,
                // @ts-ignore
                generateFileName(file.name),
                onProgress
            )
            if (resp) {
                setFileProperties({
                    ...fileProperties,
                    FileLocation: resp.fileLocation,
                })
            }
            onSuccess?.('Ok')
        } catch (err: any) {
            // @ts-ignore
            onError?.({ err })
            handleAPIError(err)
        }
    }

    const resetSaveSourceDataset = (): void => {
        setSourceDatasetName(undefined)
        setTableData([])
        setSampleType('')
        setFileList([])
    }

    const handleOk = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): Promise<void> => {
        e.preventDefault()
        setLoading(true)
        try {
            if (!sourceDatasetName) {
                notification.error({
                    message: 'Dataset name is required',
                })
                return
            }
            if (tableData.length === 0) {
                notification.error({
                    message: 'Dataset Sample File is required',
                })
                return
            }

            const metadata: Record<string, string> = {}

            tableData?.forEach((item) => {
                metadata[item.columnName] = item.dataType
            })

            await sourceDatasetService.create({
                SourceGroupID: sourceGroup?.SourceGroupID || 0,
                DatasetMetadata: JSON.stringify(metadata),
                DatasetMetadataV2: tableData,
                DatasetName: sourceDatasetName,
                FileLocation: fileProperties.FileLocation,
                FileName: fileProperties.FileName,
                FilePreviewData: fileProperties.FilePreviewData,
                FileType: fileProperties.FileType,
            })
            notification.success({
                message: 'Dataset created successfully.',
            })
            resetSaveSourceDataset()
        } catch (err: any) {
            handleAPIError(err)
        }
        setLoading(false)
    }

    const handleCancel = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => {
        e.preventDefault()
        resetSaveSourceDataset()
    }

    const columns = [
        {
            title: 'Column Name',
            dataIndex: 'columnName',
            key: 'id',
        },
        {
            title: 'Data Type',
            dataIndex: 'dataType',
            key: 'id',
            render: (
                text: string,
                record: ISourceDataset['DatasetMetadataV2'][0]
            ) => {
                return (
                    <Select
                        style={{ minWidth: '100px' }}
                        value={text}
                        onChange={(e: string) => {
                            setTableData(
                                tableData.map((item) => {
                                    if (item.columnName === record.columnName) {
                                        return {
                                            ...item,
                                            dataType: e,
                                        }
                                    }
                                    return item
                                })
                            )
                        }}
                    >
                        <Select.Option key="number"> Number </Select.Option>
                        <Select.Option key="string"> String </Select.Option>
                        <Select.Option key="date"> Date </Select.Option>
                    </Select>
                )
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'id',
            render: (
                text: string,
                record: ISourceDataset['DatasetMetadataV2'][0]
            ) => {
                return (
                    <Select
                        style={{ minWidth: '100px' }}
                        value={text}
                        onChange={(e: string) => {
                            setTableData(
                                tableData.map((item) => {
                                    if (item.columnName === record.columnName) {
                                        return {
                                            ...item,
                                            type: e,
                                        }
                                    }
                                    return item
                                })
                            )
                        }}
                    >
                        <Select.Option key="measure"> Measure </Select.Option>
                        <Select.Option key="dimension">Dimension</Select.Option>
                        <Select.Option key="attribute">Attribute</Select.Option>
                        <Select.Option key="undefined">Undefined</Select.Option>
                    </Select>
                )
            },
        },
    ]

    return (
        <Row style={{ marginTop: '10px' }}>
            <Col span={1} />
            <Col span={7}>
                <Space direction="vertical">
                    <span>
                        <Typography.Title level={3}>
                            Creating dataset in{' '}
                            <b>{sourceGroup?.SourceGroupName}</b>
                        </Typography.Title>
                    </span>
                    <Input
                        onChange={(e) => setSourceDatasetName(e.target.value)}
                        placeholder="Enter Dataset Name"
                        value={sourceDatasetName}
                    />
                    Select Sample Type
                    <Select
                        value={sampleType}
                        onChange={(value) => setSampleType(value)}
                        style={{ minWidth: '180px' }}
                        placeholder="Select Sample Type"
                    >
                        <Select.Option key="file" value="file">
                            File
                        </Select.Option>
                        <Select.Option key="database" value="database" disabled>
                            Database
                        </Select.Option>
                    </Select>
                    {sampleType && (
                        <Upload
                            beforeUpload={beforeUpload}
                            customRequest={uploadFile}
                            onRemove={() => {
                                setTableData([])
                                setFileList([])
                            }}
                            multiple={false}
                            fileList={fileList}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        >
                            <Button type="primary"> Upload Sample </Button>
                        </Upload>
                    )}
                    <div style={{ marginTop: '20px' }}>
                        <Space>
                            <Button
                                type="primary"
                                onClick={handleOk}
                                loading={loading}
                            >
                                Submit
                            </Button>
                            <Button onClick={handleCancel}>Reset</Button>
                        </Space>
                    </div>
                </Space>
            </Col>
            <Col span={2} />
            <Col span={4}>
                {tableData.length > 0 && (
                    <Space direction="vertical">
                        <Typography.Paragraph>
                            <b>Change Structure</b>
                        </Typography.Paragraph>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            pagination={false}
                            scroll={{ x: true, y: 400 }}
                        />
                    </Space>
                )}
            </Col>
        </Row>
    )
}

export default withRouter(CreateSourceDataset)
