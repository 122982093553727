import React, { useEffect, useMemo } from 'react'
import {
    Button,
    Col,
    Input,
    Modal,
    notification,
    Popconfirm,
    Row,
    Select,
    Spin,
    Tooltip,
    Typography,
} from 'antd'
import { CalculationRuleColumns } from './calculation-rule-columns'
import { ICalculationRuleStep } from './calculation-rule-steps'
import { ISourceDataset } from '../../services/source-group/interfaces/source-dataset.interface'
import { ISourceGroup } from '../../services/source-group/interfaces/source-group.interface'
import { sourceGroupService } from '../../services/source-group/source-group.service'
import { handleAPIError } from '../../utils/catch-error'
import { sourceDatasetService } from '../../services/source-group/source-dataset.service'
import { QuestionCircleFilled } from '@ant-design/icons'
import { calculationRuleService } from '../../services/calculation-rule/calculation-rule.service'
import { withRouter } from '../../utils/withRouter'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { routeMapMini } from '../../constants/routeMap'

export interface ICalculationRuleColumn {
    columnID: number
    columnName: string
    steps: ICalculationRuleStep[]
}

export interface ICalculationRuleContext {
    columns: ICalculationRuleColumn[]
    setColumns: React.Dispatch<React.SetStateAction<ICalculationRuleColumn[]>>
    isEditingColumnId: number
    setIsEditingColumnId: React.Dispatch<React.SetStateAction<number>>
    isModalVisible: boolean
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    isViewing: boolean
    dataset?: ISourceDataset
}

export const CalculationRuleContext = React.createContext<
    ICalculationRuleContext | undefined
>(undefined)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function NewCalculationRule({ router }: any): JSX.Element {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [isEditingColumnId, setIsEditingColumnId] = React.useState<number>(0)
    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false)
    const [sourceGroups, setSourceGroups] = React.useState<ISourceGroup[]>([])
    const [selectedSourceGroup, setSelectedSourceGroup] = React.useState<
        ISourceGroup | undefined
    >(undefined)
    const [datasets, setDatasets] = React.useState<ISourceDataset[]>([])
    const [selectedDataset, setSelectedDataset] = React.useState<
        ISourceDataset | undefined
    >(undefined)

    const [calculationRuleName, setCalculationRuleName] =
        React.useState<string>('')

    const [columns, setColumns] = React.useState<ICalculationRuleColumn[]>([])

    const [columnName, setColumnName] = React.useState<string>('')

    const [saveLoading, setSaveLoading] = React.useState<boolean>(false)

    const [viewCalculationRuleID, setViewCalculationRuleID] =
        React.useState<number>(0)

    const [isViewLoading, setIsViewLoading] = React.useState<boolean>(false)

    useEffect(() => {
        const calculationIDFromQuery = searchParams.get('viewRuleID')
        if (calculationIDFromQuery) {
            setViewCalculationRuleID(Number(calculationIDFromQuery))
        }
    }, [searchParams])

    useEffect(() => {
        if (!viewCalculationRuleID) {
            return
        }
        setIsViewLoading(true)
        calculationRuleService
            .getCalculationRuleStructure(viewCalculationRuleID)
            .then((resp) => {
                setCalculationRuleName(resp.calculationRuleName)
                setColumns(resp.columns)
                setIsViewLoading(false)
            })
            .catch((e) => {
                handleAPIError(e)
                setIsViewLoading(false)
            })
    }, [viewCalculationRuleID])

    useEffect(() => {
        sourceGroupService
            .getAll()
            .then((res) => {
                setSourceGroups(res)
            })
            .catch(handleAPIError)
    }, [])

    useEffect(() => {
        sourceDatasetService
            .getDatasetsForGroup(selectedSourceGroup?.SourceGroupID || 0)
            .then((res) => {
                setDatasets(res)
            })
            .catch(handleAPIError)
    }, [selectedSourceGroup?.SourceGroupID])

    function handleOkForColumnModal(
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): void {
        e.preventDefault()
        if (columnName.length === 0) {
            notification.error({
                message: 'Error',
                description: 'Column name is required',
            })
            return
        }
        if (!isEditingColumnId) {
            if (
                columns.find(
                    (column) =>
                        column.columnName.trim().toLowerCase() ===
                        columnName.trim().toLowerCase()
                )
            ) {
                notification.error({
                    message: 'Error',
                    description: 'Column name already exists',
                })
                return
            }

            const newColumnID =
                columns.length > 0
                    ? columns[columns.length - 1].columnID + 1
                    : 1

            setColumns([
                ...columns,
                {
                    columnID: newColumnID,
                    columnName,
                    steps: [],
                },
            ])
        } else {
            if (
                columns.find(
                    (column) =>
                        column.columnName.trim().toLowerCase() ===
                            columnName.trim().toLowerCase() &&
                        column.columnID !== isEditingColumnId
                )
            ) {
                notification.error({
                    message: 'Error',
                    description: 'Column name already exists',
                })
                return
            }

            setColumns(
                columns.map((column) => {
                    if (column.columnID === isEditingColumnId) {
                        return { ...column, columnName }
                    }
                    return column
                })
            )
        }
        setIsEditingColumnId(0)
        setIsModalVisible(false)
        setColumnName('')
    }

    function handleCancelForColumnModal(
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): void {
        e.preventDefault()
        setIsEditingColumnId(0)
        setIsModalVisible(false)
        setColumnName('')
    }

    function createNewColumn(): void {
        if (!selectedDataset) {
            notification.error({
                message: 'Error',
                description: 'Please select a dataset',
            })
            return
        }
        if (!calculationRuleName) {
            notification.error({
                message: 'Error',
                description: 'Please enter a name for the calculation rule',
            })
            return
        }
        setIsModalVisible(true)
        setIsEditingColumnId(0)
    }

    const calculationRuleContextValue: ICalculationRuleContext = useMemo(
        () => ({
            columns,
            setColumns,
            isEditingColumnId,
            setIsEditingColumnId,
            setIsModalVisible,
            isModalVisible,
            dataset: selectedDataset,
            isViewing: viewCalculationRuleID > 0,
        }),
        [
            columns,
            isEditingColumnId,
            isModalVisible,
            selectedDataset,
            viewCalculationRuleID,
        ]
    )

    const saveCalculationRule = async (): Promise<void> => {
        if (calculationRuleName.length <= 3) {
            notification.error({
                message: 'Error',
                description:
                    'Please enter a name for the calculation rule with minimum 4 characters',
            })
            return
        }

        if (!selectedDataset) {
            notification.error({
                message: 'Error',
                description: 'Please select a dataset',
            })
            return
        }

        if (columns.length < 1) {
            notification.error({
                message: 'Error',
                description: 'Please add at least one column',
            })
            return
        }

        setSaveLoading(true)
        try {
            await calculationRuleService.saveCalculationRule(
                calculationRuleContextValue.columns,
                calculationRuleContextValue.dataset,
                calculationRuleName
            )
            notification.success({
                message: 'Success',
                description: `Calculation rule ${calculationRuleName} has been saved.`,
            })
            setCalculationRuleName('')
            setColumns([])
            setSelectedDataset(undefined)
            setSelectedSourceGroup(undefined)
            navigate(routeMapMini.rulesCalculation, { replace: true })
        } catch (e) {
            handleAPIError(e)
        }

        setSaveLoading(false)
    }

    return (
        <CalculationRuleContext.Provider value={calculationRuleContextValue}>
            <div style={{ padding: '10px' }}>
                <Typography.Title level={3}>Calculation Rule</Typography.Title>
                <Row style={{ margin: '10px' }}>
                    <Col span={3}> Rule Name </Col>
                    <Col span={4}>
                        <Input
                            style={{ maxWidth: '200px' }}
                            value={calculationRuleName}
                            onChange={(e) => {
                                setCalculationRuleName(e.target.value)
                            }}
                            disabled={viewCalculationRuleID > 0}
                        />
                    </Col>
                </Row>
                {!viewCalculationRuleID && (
                    <>
                        <Row style={{ margin: '10px' }}>
                            <Col span={3}> Data Group </Col>
                            <Col span={4}>
                                <Select
                                    style={{ minWidth: '200px' }}
                                    onChange={(id: number) => {
                                        setSelectedSourceGroup(
                                            sourceGroups.find(
                                                (group) =>
                                                    group.SourceGroupID === id
                                            )
                                        )
                                        setSelectedDataset(undefined)
                                    }}
                                    value={selectedSourceGroup?.SourceGroupID}
                                    disabled={columns.length !== 0}
                                >
                                    {sourceGroups.map((group) => {
                                        return (
                                            <Select.Option
                                                key={group.SourceGroupID}
                                                value={group.SourceGroupID}
                                            >
                                                {group.SourceGroupName}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                                {columns.length !== 0 && (
                                    <Tooltip title="disabled to change if you have a column created">
                                        <QuestionCircleFilled />
                                    </Tooltip>
                                )}
                            </Col>
                        </Row>
                        <Row style={{ margin: '10px' }}>
                            <Col span={3}> Dataset </Col>
                            <Col span={4}>
                                <Select
                                    style={{ minWidth: '200px' }}
                                    disabled={
                                        !selectedSourceGroup ||
                                        columns.length !== 0
                                    }
                                    value={selectedDataset?.DatasetID}
                                    onChange={(id: number) => {
                                        setSelectedDataset(
                                            datasets.find(
                                                (dataset) =>
                                                    dataset.DatasetID === id
                                            )
                                        )
                                    }}
                                >
                                    {datasets.map((dataset) => {
                                        return (
                                            <Select.Option
                                                key={dataset.DatasetID}
                                                value={dataset.DatasetID}
                                            >
                                                {dataset.DatasetName}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                                {columns.length !== 0 && (
                                    <Tooltip title="disabled to change if you have a column created">
                                        <QuestionCircleFilled />
                                    </Tooltip>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
                {isViewLoading && <Spin spinning={isViewLoading} />}
                <CalculationRuleColumns />
                {!viewCalculationRuleID && (
                    <Row style={{ paddingTop: '20px' }} gutter={10}>
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => createNewColumn()}
                            >
                                Add New Column
                            </Button>
                        </Col>
                        <Col>
                            <Popconfirm
                                title="Are you sure you want to save the rule?"
                                onConfirm={() => {
                                    if (!saveLoading) {
                                        saveCalculationRule()
                                    } else {
                                        notification.error({
                                            message: 'Please wait',
                                        })
                                    }
                                }}
                            >
                                <Button type="primary" loading={saveLoading}>
                                    Save Rule
                                </Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                )}
            </div>
            <Modal
                title={
                    isEditingColumnId
                        ? `Editing ${
                              columns.find(
                                  (item) => item.columnID === isEditingColumnId
                              )?.columnName
                          }`
                        : 'Add New Column'
                }
                visible={isModalVisible}
                onOk={(e) => handleOkForColumnModal(e)}
                onCancel={(e) => handleCancelForColumnModal(e)}
                destroyOnClose
            >
                <Input
                    onChange={(e) => setColumnName(e.target.value)}
                    placeholder="Enter Column Name"
                    value={
                        isEditingColumnId && !columnName
                            ? columns.find(
                                  (item) => item.columnID === isEditingColumnId
                              )?.columnName
                            : columnName
                    }
                />
            </Modal>
        </CalculationRuleContext.Provider>
    )
}

export default withRouter(NewCalculationRule)
