/* eslint-disable no-unused-vars */
import React, { useContext, useMemo } from 'react'
import { Col, Row, Space } from 'antd'
import { CalculationRuleContext } from './new-calculation-rule'
import {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    CalColumnTypes,
    ArithmeticSymbolMapping,
} from '../../services/calculation-rule/interfaces/calculation-rule.interface'
import { calculationRuleHelper } from './calculation-rule.helper'

function ViewCalculationRuleArithmeticStep({
    stepId,
    columnId,
    name,
}: {
    stepId: number
    columnId: number
    name: string
}): JSX.Element {
    const calculationRuleValue = useContext(CalculationRuleContext)

    const currentStepDetails = useMemo(() => {
        return calculationRuleValue?.columns
            ?.find((column) => column.columnID === columnId)
            ?.steps?.find((step) => step.stepID === stepId)
    }, [calculationRuleValue, columnId, stepId])

    return (
        <Row justify="start" style={{ marginTop: '10px' }}>
            <Col span={4}>
                <b>{name}</b>
                <i> (arithmetic)</i>
            </Col>
            <Col span={17}>
                <Row>
                    <Col>
                        <span
                            style={{ paddingRight: '10px', fontWeight: 'bold' }}
                        >
                            =
                        </span>
                    </Col>
                    <Col>
                        <Space>
                            {calculationRuleHelper.getColumnNameFromColumnType(
                                calculationRuleValue,
                                // @ts-ignore
                                currentStepDetails?.arithmetic?.Column1Type,
                                // @ts-ignore
                                currentStepDetails?.arithmetic?.Column1,
                                columnId
                            )}
                            <p>
                                {
                                    // @ts-ignore
                                    ArithmeticSymbolMapping[
                                        // @ts-ignore
                                        currentStepDetails?.arithmetic?.Operator
                                    ]
                                }
                            </p>
                            {calculationRuleHelper.getColumnNameFromColumnType(
                                calculationRuleValue,
                                // @ts-ignore
                                currentStepDetails?.arithmetic?.Column2Type,
                                // @ts-ignore
                                currentStepDetails?.arithmetic?.Column2,
                                columnId
                            )}
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ViewCalculationRuleArithmeticStep
