import { Form, Input, Modal } from 'antd'
import React from 'react'
import { IUsers } from '../../services/user-management/interfaces/users.interface'
import { usersService } from '../../services/user-management/users.service'
import { handleAPIError } from '../../utils/catch-error'

function CreateUsers({
    visible,
    setVisible,
}: {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element {
    const [editingUsersId, setEditingUsersId] = React.useState<number>()
    const [users] = React.useState<IUsers[] | undefined>(undefined)

    const [createIsLoading, setCreateIsLoading] = React.useState(false)

    const [form] = Form.useForm()

    const resetSaveUsers = (): void => {
        form.resetFields()
        setVisible(false)
        setEditingUsersId(undefined)
    }

    const handleOk = async (): Promise<void> => {
        setCreateIsLoading(true)
        try {
            if (editingUsersId) {
                await usersService.updateById(editingUsersId, {
                    UserName: form.getFieldValue('User Name'),
                    FirstName: form.getFieldValue('First Name'),
                    LastName: form.getFieldValue('Last Name'),
                })
            } else {
                await usersService.create({
                    UserName: form.getFieldValue('User Name'),
                    FirstName: form.getFieldValue('First Name'),
                    LastName: form.getFieldValue('Last Name'),
                })
            }
            resetSaveUsers()
        } catch (err: any) {
            handleAPIError(err)
        }
        setCreateIsLoading(false)
    }

    const handleCancel = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => {
        e.preventDefault()
        resetSaveUsers()
    }

    return (
        <Modal
            bodyStyle={{ height: '270px' }}
            visible={visible}
            onCancel={(e) => handleCancel(e)}
            title={
                editingUsersId
                    ? `Editing ${
                          users?.find((item) => item.UserID === editingUsersId)
                              ?.UserName
                      } Data Group`
                    : 'Create New User'
            }
            onOk={async () => {
                form.validateFields().then(async () => {
                    await handleOk()
                })
            }}
            confirmLoading={createIsLoading}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
                name="users"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                autoComplete="off"
            >
                <Form.Item
                    label="User Name"
                    name="User Name"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                        },
                    ]}
                >
                    <Input placeholder="Enter User Name" />
                </Form.Item>

                <Form.Item
                    label="First Name"
                    name="First Name"
                    rules={[
                        {
                            required: true,
                            min: 3,
                        },
                    ]}
                >
                    <Input placeholder="Enter First Name" />
                </Form.Item>

                <Form.Item
                    label="Last Name"
                    name="Last Name"
                    rules={[
                        {
                            required: true,
                            min: 3,
                        },
                    ]}
                >
                    <Input placeholder="Enter Last Name" />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateUsers
