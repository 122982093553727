/* eslint-disable react/jsx-props-no-spreading */
import { BellOutlined } from '@ant-design/icons'
import { Badge, Spin, Tabs } from 'antd'
import React, { useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames'
import NoticeList from './NoticeList'

import HeaderDropdown from '../HeaderDropDown'
// @ts-ignore
import styles from './NoticeIcon.less'
import { useThemeSwitcher } from 'react-css-theme-switcher'

const { TabPane } = Tabs

function NoticeIcon(props: any): JSX.Element {
    const getNotificationBox = (): JSX.Element | null => {
        const {
            children,
            loading,
            onClear,
            onTabChange,
            onItemClick,
            onViewMore,
            clearText,
            viewMoreText,
        } = props
        if (!children) {
            return null
        }
        const panes: any[] = []
        React.Children.forEach(children, (child) => {
            if (!child) {
                return
            }
            const { list, title, count, tabKey, showClear, showViewMore } =
                child.props
            const len = list && list.length ? list.length : 0
            const msgCount = count || count === 0 ? count : len
            const tabTitle = msgCount > 0 ? `${title} (${msgCount})` : title
            panes.push(
                <TabPane tab={tabTitle} key={tabKey}>
                    <NoticeList
                        {...child.props}
                        clearText={clearText}
                        viewMoreText={viewMoreText}
                        data={list}
                        onClear={() => {
                            onClear?.(title, tabKey)
                        }}
                        onClick={(item: any) => {
                            onItemClick?.(item, child.props)
                        }}
                        onViewMore={(event: any) => {
                            onViewMore?.(child.props, event)
                        }}
                        showClear={showClear}
                        showViewMore={showViewMore}
                        title={title}
                    />
                </TabPane>
            )
        })
        return (
            <Spin spinning={loading} delay={300}>
                <Tabs className={styles.tabs} onChange={onTabChange}>
                    {panes}
                </Tabs>
            </Spin>
        )
    }

    const { className, count, bell } = props

    const [visible, setVisible] = useState()
    // useMergedState <
    // boolean >
    // (false,
    // {
    //   value: props.popupVisible,
    //   onChange: props.onPopupVisibleChange,
    // });
    const noticeButtonClass = classNames(className, styles.noticeButton)
    const notificationBox = getNotificationBox()
    const { currentTheme } = useThemeSwitcher()
    const NoticeBellIcon = bell || (
        <BellOutlined
            className={styles.icon}
            style={{
                color: currentTheme === 'dark' ? '#0ea3d2' : '#f15c2d',
                marginTop: '2.3rem',
                marginLeft: '5rem',
            }}
        />
    )
    const trigger = (
        <span className={classNames(noticeButtonClass, { opened: visible })}>
            <Badge
                count={count}
                style={{ boxShadow: 'none' }}
                className={styles.badge}
            >
                {NoticeBellIcon}
            </Badge>
        </span>
    )
    if (!notificationBox) {
        return trigger
    }

    return (
        <HeaderDropdown
            placement="bottomRight"
            overlay={notificationBox}
            overlayClassName={styles.popover}
            trigger={['click']}
            visible={visible}
            onVisibleChange={setVisible}
        >
            {trigger}
        </HeaderDropdown>
    )
}

NoticeIcon.defaultProps = {
    emptyImage:
        'https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg',
}

NoticeIcon.Tab = NoticeList

export default NoticeIcon
