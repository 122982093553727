import { RcFile } from 'antd/es/upload'
import { uploadFileToAzureBlob } from './azure-blob-storage.service'
import { FileProperties } from './interfaces/file-properties.interface'
import { AxiosX } from '../../utils/axios'

const DEFAULT_FILE_UPLOAD = 'AZURE_STORAGE_ACCOUNT'

export const FileUploadService = {
    uploadFile: async (
        file: RcFile,
        fileName: string,
        onProgress: any
    ): Promise<{ fileLocation: string } | undefined> => {
        if (DEFAULT_FILE_UPLOAD === 'AZURE_STORAGE_ACCOUNT') {
            return uploadFileToAzureBlob(file, fileName, onProgress)
        }
        throw new Error('File upload service not found')
    },

    getFileById: async (fileId: number): Promise<FileProperties> => {
        const resp = await AxiosX.get(`v1/file-upload/by-id/${fileId}`)

        return resp.data
    },
}
