import { Form, Modal, Select } from 'antd'
import React, { useEffect } from 'react'
import { teamUserService } from '../../../services/user-management/team-user.service'
import { handleAPIError } from '../../../utils/catch-error'
import { ITeams } from '../../../services/user-management/interfaces/teams.interface'
import { teamsService } from '../../../services/user-management/teams.service'

function AddUserToTeam({
    visible,
    setVisible,
    userId,
    userName,
}: {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    userId: number
    userName: string | undefined
}): JSX.Element {
    const [createIsLoading, setCreateIsLoading] = React.useState(false)
    const [form] = Form.useForm()
    const [teams, setTeams] = React.useState<ITeams[] | undefined>(undefined)

    const handleOk = async (): Promise<void> => {
        setCreateIsLoading(true)
        try {
            await teamUserService.create({
                TeamID: Number(form.getFieldValue('TeamID')),
                UserID: userId,
                Role: form.getFieldValue('Role'),
            })

            form.resetFields()
            setVisible(false)
        } catch (err: any) {
            handleAPIError(err)
        }
        setCreateIsLoading(false)
    }

    const handleCancel = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => {
        form.resetFields()
        e.preventDefault()
        setVisible(false)
    }

    useEffect(() => {
        teamsService
            .getAll()
            .then((res) => {
                setTeams(res)
            })
            .catch(handleAPIError)
    }, [])

    return (
        <Modal
            bodyStyle={{ height: '200px' }}
            visible={visible}
            onCancel={(e) => handleCancel(e)}
            title={`Add "${userName}" To New Team`}
            onOk={async () => {
                form.validateFields().then(async () => {
                    await handleOk()
                })
            }}
            confirmLoading={createIsLoading}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
                name="teams"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                autoComplete="off"
            >
                <Form.Item
                    name="TeamID"
                    label="Select TeamName"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select>
                        {teams?.map((teamItem: ITeams) => (
                            <Select.Option
                                key={teamItem.TeamID}
                                value={teamItem.TeamID}
                            >
                                {teamItem.TeamName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="Role"
                    label="Select Role"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select>
                        <Select.Option value="Admin">Admin</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddUserToTeam
