import { Drawer, Table } from 'antd'
import React from 'react'
import { handleAPIError } from '../../utils/catch-error'
import { allocationRuleGroupService } from '../../services/allocation-rule/allocation-rule-group.service'
import { IAllocationRuleGroup } from '../../services/allocation-rule/interfaces/allocation-rule-group.interface'

type IProps = {
    ruleGroupID: number
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    groupName: string
}

function AllocationRuleGroupDrawer({
    ruleGroupID,
    visible,
    setVisible,
    groupName,
}: IProps): JSX.Element {
    const [loading, setLoading] = React.useState(false)
    const [groupConfig, setGroupConfig] = React.useState<
        IAllocationRuleGroup['AllocationRuleGroupConfig']
    >([])

    React.useEffect(() => {
        setLoading(true)
        if (ruleGroupID) {
            allocationRuleGroupService
                .getConfig(ruleGroupID)
                .then(setGroupConfig)
                .catch(handleAPIError)
        }
        setLoading(false)
    }, [ruleGroupID])

    return (
        <Drawer
            visible={visible}
            title={`${groupName} structure`}
            onClose={() => {
                setVisible(false)
            }}
            placement="right"
            size="large"
        >
            {groupConfig.length > 0 && (
                <div>
                    <b style={{ fontSize: '16px' }}>Config</b>
                    <Table
                        columns={[
                            {
                                title: `Column`,
                                dataIndex: 'ColumnName',
                                key: 'ColumnName',
                            },
                            {
                                title: 'RangeFrom',
                                dataIndex: 'RangeFrom',
                                key: 'RangeFrom',
                            },
                            {
                                title: 'RangeTo',
                                dataIndex: 'RangeTo',
                                key: 'RangeTo',
                            },
                            {
                                title: 'Dest',
                                dataIndex: 'Dest',
                                key: 'Dest',
                            },
                        ]}
                        dataSource={groupConfig}
                        loading={loading}
                        pagination={false}
                        scroll={{ x: true }}
                    />
                </div>
            )}
        </Drawer>
    )
}

export default AllocationRuleGroupDrawer
