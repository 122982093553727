import React, { useContext, useEffect } from 'react'
import { Col, Popconfirm, Row, Select, Space } from 'antd'
import {
    CalColumnTypes,
    CalAggregateOperations,
} from '../../services/calculation-rule/interfaces/calculation-rule.interface'
import { getEnumKeys } from '../../utils/enum'
import { DeleteOutlined } from '@ant-design/icons'
import { CalculationRuleContext } from './new-calculation-rule'
import {
    calculationRuleHelper,
    ICalcColumnOption,
} from './calculation-rule.helper'
import * as Sentry from '@sentry/react'
import { parseJson } from '../../utils/parse.util'

export interface ICalculationRuleAggregateStep {
    column1?: number | string
    column1Type?: CalColumnTypes
    statusOfColumn1?: boolean
    operation?: CalAggregateOperations
    groupBy?: { columnValue: number | string; columnType: CalColumnTypes }[]
    statusOfGroupBy?: boolean
    textDecorationOfColumn1?: boolean
    textDecorationOfGroupBy?: boolean
}

function CalculationRuleAggregateStep({
    name,
    stepId,
    columnId,
    title,
}: {
    name: string
    stepId: number
    columnId: number
    title: string
}): JSX.Element {
    const calculationRuleValue = useContext(CalculationRuleContext)

    const [availableSelectOptions, setAvailableSelectOptions] = React.useState<
        ICalcColumnOption[]
    >([])
    const [nonNumberSelectOptions, setNonNumberSelectOptions] = React.useState<
        ICalcColumnOption[]
    >([])

    useEffect(() => {
        if (!calculationRuleValue) {
            return
        }
        const tempAvailableColumns =
            calculationRuleHelper.filterAvailableColumns(
                calculationRuleValue,
                columnId,
                stepId
            )

        setAvailableSelectOptions(tempAvailableColumns)

        let datasetMetadata: Record<string, string> = {}
        try {
            datasetMetadata = parseJson(
                calculationRuleValue?.dataset?.DatasetMetadata || '{}'
            )
        } catch (e) {
            Sentry.captureException(e)
        }

        const tempNonNumberColumns: ICalcColumnOption[] = Object.keys(
            datasetMetadata
        )
            .filter((key) => datasetMetadata[key] !== 'number')
            .map((key) => {
                return {
                    name: key,
                    id: key,
                    type: CalColumnTypes.dataset,
                    uniqueID: `${key}-${CalColumnTypes.dataset}`,
                }
            })

        setNonNumberSelectOptions(tempNonNumberColumns)
    }, [
        calculationRuleValue,
        calculationRuleValue?.columns,
        calculationRuleValue?.dataset?.DatasetMetadata,
        columnId,
        stepId,
    ])

    function onChangeColumnValue(
        type: 'column1' | 'groupBy' | 'operation',
        columnOption?: ICalcColumnOption,
        aggreagteOperations?: CalAggregateOperations,
        groupByOptions?: ICalculationRuleAggregateStep['groupBy']
    ): void {
        const columnsUpdate = calculationRuleValue?.columns?.map((column) => ({
            ...column,
            steps:
                column.columnID !== columnId
                    ? column.steps
                    : column.steps?.map((item) => {
                          if (item.stepID !== stepId) {
                              return item
                          }
                          const aggregateStepValue = {
                              ...item.aggregate,
                          }

                          if (type === 'column1' && columnOption) {
                              aggregateStepValue.column1 = columnOption.id
                              aggregateStepValue.column1Type = columnOption.type
                              aggregateStepValue.statusOfColumn1 = true
                          } else if (
                              type === 'operation' &&
                              aggreagteOperations
                          ) {
                              aggregateStepValue.operation = aggreagteOperations
                          } else if (type === 'groupBy' && groupByOptions) {
                              aggregateStepValue.groupBy = groupByOptions
                              aggregateStepValue.statusOfGroupBy = true
                          }

                          return {
                              ...item,
                              aggregate: aggregateStepValue,
                          }
                      }),
        }))

        // @ts-ignore
        calculationRuleValue?.setColumns(columnsUpdate || [])
    }

    function getGroupByValues(): string[] {
        const uniqueIDGroupBy: string[] = []
        availableSelectOptions.find(() => {
            if (
                calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                    stepId - 1
                ]?.aggregate?.groupBy !== undefined
            ) {
                for (
                    let index = 0;
                    index <
                    Number(
                        calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                            stepId - 1
                        ]?.aggregate?.groupBy?.length
                    );
                    index += 1
                ) {
                    const element =
                        calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                            stepId - 1
                        ]?.aggregate?.groupBy?.[index]

                    uniqueIDGroupBy.push(
                        `${element?.columnValue}-${element?.columnType}`
                    )
                }
            }
            return uniqueIDGroupBy
        })
        return uniqueIDGroupBy
    }

    function getStatusCol1(): 'warning' | 'error' | undefined {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .aggregate?.column1 === undefined &&
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .aggregate?.statusOfColumn1 === false
        ) {
            return 'error'
        }
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .aggregate?.textDecorationOfColumn1 === false
        ) {
            return 'warning'
        }
        return undefined
    }

    function getStatusGroupBy(): 'warning' | 'error' | undefined {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .aggregate?.statusOfGroupBy === false
        ) {
            return 'error'
        }
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .aggregate?.textDecorationOfGroupBy === false
        ) {
            return 'warning'
        }
        return undefined
    }

    function getTextDecorationCol1(): 'line-through' | undefined {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .aggregate?.textDecorationOfColumn1 === false
        ) {
            return 'line-through'
        }
        return undefined
    }

    return (
        <Row style={{ marginTop: '15px' }} gutter={[18, 16]}>
            <Space size="large">
                <Col>
                    <span
                        style={{
                            paddingLeft: '42px',
                        }}
                    >
                        <b>{name}</b>
                        <i> (aggregate)</i>
                    </span>
                </Col>
                <Col>
                    <span style={{ paddingRight: '10px', fontWeight: 'bold' }}>
                        Col 1
                    </span>
                    <Select
                        value={
                            availableSelectOptions.find((item) => {
                                const uniqueID = `${
                                    calculationRuleValue?.columns?.[
                                        columnId - 1
                                    ]?.steps?.[stepId - 1]?.aggregate?.column1
                                }-${
                                    calculationRuleValue?.columns?.[
                                        columnId - 1
                                    ]?.steps?.[stepId - 1].aggregate
                                        ?.column1Type
                                }`

                                if (uniqueID === item.uniqueID) {
                                    return true
                                }
                                return false
                            })?.uniqueID
                        }
                        status={getStatusCol1()}
                        style={{
                            minWidth: '160px',
                            textDecorationLine: getTextDecorationCol1(),
                        }}
                        onChange={(item: string) => {
                            const selectedItem = availableSelectOptions.find(
                                (column) => column.uniqueID === item
                            )
                            if (selectedItem) {
                                onChangeColumnValue('column1', selectedItem)
                            }
                        }}
                    >
                        {availableSelectOptions.map((column) => (
                            <Select.Option
                                key={column.uniqueID}
                                value={column.uniqueID}
                            >
                                {column.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col>
                    <span style={{ paddingRight: '10px', fontWeight: 'bold' }}>
                        Operation
                    </span>
                    <Select
                        // @ts-ignore
                        value={getEnumKeys(CalAggregateOperations).find(
                            (item) => {
                                const uniqueID = `${
                                    calculationRuleValue?.columns?.[
                                        columnId - 1
                                    ]?.steps?.[stepId - 1]?.aggregate?.operation
                                }`

                                if (uniqueID === item) {
                                    return true
                                }
                                return false
                            }
                        )}
                        style={{ minWidth: '160px' }}
                        onChange={(item: CalAggregateOperations) => {
                            onChangeColumnValue('operation', undefined, item)
                        }}
                    >
                        {getEnumKeys(CalAggregateOperations).map((key) => (
                            <Select.Option key={key} value={key}>
                                {key}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col>
                    <span style={{ paddingRight: '10px', fontWeight: 'bold' }}>
                        Group By
                    </span>
                    <Select
                        value={getGroupByValues()}
                        status={getStatusGroupBy()}
                        mode="multiple"
                        allowClear
                        style={{ width: '200px' }}
                        onChange={(uniqueIds: string[]) => {
                            const selectedColumns = uniqueIds.map(
                                (uniqueId) => {
                                    const selectedColumn = [
                                        ...availableSelectOptions,
                                        ...nonNumberSelectOptions,
                                    ].find(
                                        (column) => column.uniqueID === uniqueId
                                    )
                                    if (selectedColumn) {
                                        return {
                                            columnValue: selectedColumn.id,
                                            columnType: selectedColumn.type,
                                        }
                                    }
                                    throw new Error(
                                        `Column with uniqueID ${uniqueId} not found`
                                    )
                                }
                            )

                            onChangeColumnValue(
                                'groupBy',
                                undefined,
                                undefined,
                                selectedColumns
                            )
                        }}
                    >
                        {[
                            ...availableSelectOptions,
                            ...nonNumberSelectOptions,
                        ].map((column) => (
                            <Select.Option
                                key={column.uniqueID}
                                value={column.uniqueID}
                            >
                                {column.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        onMouseEnter={() => {
                            if (!calculationRuleValue) {
                                return
                            }
                            calculationRuleHelper.onMouseEnter(
                                calculationRuleValue,
                                columnId,
                                stepId
                            )
                        }}
                        onMouseLeave={() => {
                            if (!calculationRuleValue) {
                                return
                            }
                            calculationRuleHelper.onMouseLeave(
                                calculationRuleValue,
                                columnId,
                                stepId
                            )
                        }}
                    >
                        <Popconfirm
                            title={title}
                            onConfirm={() => {
                                if (!calculationRuleValue) {
                                    return
                                }
                                calculationRuleHelper.deleteStep(
                                    calculationRuleValue,
                                    columnId,
                                    stepId
                                )
                            }}
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                    </a>
                </Col>
            </Space>
        </Row>
    )
}

export default CalculationRuleAggregateStep
