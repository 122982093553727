import React, { useEffect } from 'react'
import { Button, Checkbox, Input, notification, Row, Select, Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import { DeleteTwoTone } from '@ant-design/icons'

type IColumnData = {
    id: number
    leftColumnName: string
    rightColumnName: string
    alias: string
}

type IProps = {
    columns: { leftColumns: string[]; rightColumns: string[] }
    selectedColumns: IColumnData[]
    setSelectedColumns: React.Dispatch<React.SetStateAction<IColumnData[]>>
}

function MergeSelectors({
    columns,
    selectedColumns,
    setSelectedColumns,
}: IProps): JSX.Element {
    const [checkedAll, setCheckedAll] = React.useState<boolean>()

    useEffect(() => {
        setCheckedAll(
            columns.leftColumns.length > columns.rightColumns.length
                ? selectedColumns.length === columns.leftColumns.length
                : selectedColumns.length === columns.rightColumns.length
        )
    }, [selectedColumns, columns.leftColumns, columns.rightColumns])

    const buildColumns = (): ColumnType<IColumnData>[] => {
        return [
            {
                title: 'Left DataSet Columns',
                dataIndex: 'leftColumnName',
                key: 'id',
                render: (text: string, record) => {
                    return (
                        <Select
                            style={{ minWidth: '180px' }}
                            value={text}
                            allowClear
                            onChange={(e: string) => {
                                setSelectedColumns(
                                    selectedColumns.map((item) => {
                                        if (item.id === record.id) {
                                            return {
                                                ...item,
                                                leftColumnName: e,
                                            }
                                        }
                                        return item
                                    })
                                )
                            }}
                        >
                            {columns.leftColumns
                                .filter((item) => {
                                    return !selectedColumns.some(
                                        (selectedItem) =>
                                            selectedItem.leftColumnName === item
                                    )
                                })
                                .map((item) => {
                                    return (
                                        <Select.Option key={item} value={item}>
                                            {item}
                                        </Select.Option>
                                    )
                                })}
                        </Select>
                    )
                },
            },
            {
                title: 'Right DataSet Columns',
                dataIndex: 'rightColumnName',
                key: 'id',
                render: (text: string, record) => {
                    return (
                        <Select
                            style={{ minWidth: '180px' }}
                            value={text}
                            allowClear
                            onChange={(e: string) => {
                                setSelectedColumns(
                                    selectedColumns.map((item) => {
                                        if (item.id === record.id) {
                                            return {
                                                ...item,
                                                rightColumnName: e,
                                            }
                                        }
                                        return item
                                    })
                                )
                            }}
                        >
                            {columns.rightColumns
                                .filter((item) => {
                                    return !selectedColumns.some(
                                        (selectedItem) =>
                                            selectedItem.rightColumnName ===
                                            item
                                    )
                                })
                                .map((item) => {
                                    return (
                                        <Select.Option key={item} value={item}>
                                            {item}
                                        </Select.Option>
                                    )
                                })}
                        </Select>
                    )
                },
            },
            {
                title: 'Alias',
                dataIndex: 'alias',
                key: 'id',
                render: (text: string, record) => {
                    return (
                        <Input
                            value={text}
                            onChange={(value) => {
                                setSelectedColumns(
                                    selectedColumns.map((item) => {
                                        if (item.id === record.id) {
                                            return {
                                                ...item,
                                                alias: value.target.value,
                                            }
                                        }
                                        return item
                                    })
                                )
                            }}
                        />
                    )
                },
            },
            {
                title: 'Act',
                key: 'id',
                render: (_text: string, record) => {
                    return (
                        <DeleteTwoTone
                            twoToneColor="red"
                            onClick={() => {
                                setSelectedColumns(
                                    selectedColumns.filter(
                                        (item) => item.id !== record.id
                                    )
                                )
                            }}
                        />
                    )
                },
            },
        ]
    }

    return (
        <Row style={{ marginTop: '20px' }}>
            <Checkbox
                checked={checkedAll}
                onChange={(e) => {
                    setCheckedAll(e.target.checked)
                    if (e.target.checked) {
                        if (
                            columns.leftColumns.length >
                            columns.rightColumns.length
                        ) {
                            setSelectedColumns(
                                columns.leftColumns.map((item, index) => {
                                    if (
                                        columns.rightColumns.length - 1 >=
                                        index
                                    ) {
                                        return {
                                            id: index + 1,
                                            alias: item,
                                            leftColumnName: item,
                                            rightColumnName: item,
                                        }
                                    }
                                    return {
                                        id: index + 1,
                                        alias: '',
                                        leftColumnName: item,
                                        rightColumnName: '',
                                    }
                                })
                            )
                        } else {
                            setSelectedColumns(
                                columns.rightColumns.map((item, index) => {
                                    if (
                                        columns.leftColumns.length - 1 >=
                                        index
                                    ) {
                                        return {
                                            id: index + 1,
                                            alias: item,
                                            leftColumnName: item,
                                            rightColumnName: item,
                                        }
                                    }
                                    return {
                                        id: index + 1,
                                        alias: '',
                                        leftColumnName: '',
                                        rightColumnName: item,
                                    }
                                })
                            )
                        }
                    } else {
                        setSelectedColumns([])
                    }
                }}
            >
                Select All Columns
            </Checkbox>
            <Table
                columns={buildColumns()}
                dataSource={selectedColumns}
                pagination={false}
                scroll={{ x: true, y: 400 }}
                style={{ width: '95%' }}
            />
            <Button
                size="small"
                type="primary"
                disabled={
                    !columns.leftColumns.some(
                        (item) =>
                            !selectedColumns.some((selectedItem) => {
                                return selectedItem.leftColumnName === item
                            })
                    ) &&
                    !columns.rightColumns.some(
                        (item) =>
                            !selectedColumns.some(
                                (selectedItem) =>
                                    selectedItem.rightColumnName === item
                            )
                    )
                }
                onClick={() => {
                    let id = 1
                    if (selectedColumns.length > 0) {
                        id = selectedColumns[selectedColumns.length - 1].id + 1
                    }

                    const unleftUsedColumn = columns.leftColumns.filter(
                        (item) =>
                            !selectedColumns.some(
                                (selectedItem) =>
                                    selectedItem.leftColumnName === item
                            )
                    )

                    const unrightUsedColumn = columns.rightColumns.filter(
                        (item) =>
                            !selectedColumns.some(
                                (selectedItem) =>
                                    selectedItem.rightColumnName === item
                            )
                    )

                    if (!unleftUsedColumn.length && !unrightUsedColumn.length) {
                        notification.error({
                            message: 'Error',
                            description: 'All columns are selected',
                        })
                        return
                    }

                    setSelectedColumns([
                        ...selectedColumns,
                        {
                            id,
                            leftColumnName: unleftUsedColumn[0],
                            rightColumnName: unrightUsedColumn[0],
                            alias:
                                unleftUsedColumn[0] === unrightUsedColumn[0]
                                    ? unleftUsedColumn[0]
                                    : '',
                        },
                    ])
                }}
            >
                Add Selection
            </Button>
        </Row>
    )
}

export default MergeSelectors
