import { Dropdown } from 'antd'
import React from 'react'

function HeaderDropdown({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    overlayClassName: cls,
    ...restProps
}: any): JSX.Element {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Dropdown {...restProps} />
}

export default HeaderDropdown
