import { AxiosX } from '../../utils/axios'
import { ICreateNewTeamInterface } from './interfaces/createneweam.interface'
import { ITeams } from './interfaces/teams.interface'

export const teamsService = {
    getAll: async (): Promise<ITeams[]> => {
        const data = await AxiosX.get<ITeams[]>('v1/team/getAll')

        return data.data
    },

    create: async (
        data: ICreateNewTeamInterface
    ): Promise<ICreateNewTeamInterface> => {
        const response = await AxiosX.post<ICreateNewTeamInterface>(
            `v1/team/create`,
            data
        )

        return response.data
    },

    updateById: async (id: number, data: ITeams): Promise<void> => {
        const response = await AxiosX.put<void>(`v1/team/update/${id}`, data)

        return response.data
    },

    findTeamname: async (teamname: string): Promise<ITeams[]> => {
        const data = await AxiosX.get<ITeams[]>(
            `v1/team/findteamname/${teamname}`
        )

        return data.data
    },
}
