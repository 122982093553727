import {
    LogoutOutlined,
    SettingOutlined,
    UserOutlined,
    SwapOutlined,
} from '@ant-design/icons'
import { Menu, Space } from 'antd'
import React from 'react'
import HeaderDropdown from './HeaderDropDown'
import { withRouter } from '../../../utils/withRouter'
import { useAuth0 } from '@auth0/auth0-react'
import { useThemeSwitcher } from 'react-css-theme-switcher'

function AvatarDropdown(): JSX.Element {
    const { logout, user } = useAuth0()

    const { switcher, currentTheme } = useThemeSwitcher()

    const switchTheme = (): void => {
        switcher({ theme: currentTheme === 'dark' ? 'light' : 'dark' })
        localStorage.setItem(
            'theme',
            currentTheme === 'dark' ? 'light' : 'dark'
        )
    }

    const truncateEmail = (email: string | undefined): string => {
        if (!email) return 'Guest'
        if (email.length > 10) {
            return `${email.substring(0, 10)}...`
        }
        return email
    }

    const menuHeaderDropdown = (
        <Menu selectedKeys={[]} style={{ maxWidth: '140px' }}>
            <Menu.Item
                key="user"
                style={{
                    textOverflow: 'ellipsis',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}
            >
                {truncateEmail(user?.email)}
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="settings">
                <Space>
                    <SettingOutlined />
                    Settings
                </Space>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="theme-switcher">
                <Space onClick={switchTheme} key="theme-switcher-span">
                    <SwapOutlined /> Theme
                </Space>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
                key="logout"
                onClick={async () => {
                    logout({ returnTo: window.location.origin })
                }}
            >
                <Space>
                    <LogoutOutlined />
                    Logout
                </Space>
            </Menu.Item>
        </Menu>
    )
    return (
        <HeaderDropdown
            overlay={menuHeaderDropdown}
            overlayClassName={undefined}
        >
            <Space
                style={{
                    color: currentTheme === 'dark' ? 'white' : 'black',
                    marginTop: '0.75rem',
                    paddingRight: '1.5rem',
                    marginLeft: '-5px',
                }}
            >
                <span>
                    <UserOutlined
                        style={{
                            color: '#0ea3d2',
                            marginRight: '5px',
                        }}
                    />
                </span>
                {truncateEmail(user?.name)}
            </Space>
        </HeaderDropdown>
    )
}

export default withRouter(AvatarDropdown)
