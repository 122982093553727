import * as React from 'react'
import { Table } from 'antd'
import { DatabaseOutlined } from '@ant-design/icons'
import { ISourceGroup } from '../../services/source-group/interfaces/source-group.interface'
import { routeMapMini } from '../../constants/routeMap'
import { Link } from 'react-router-dom'
import { getColumnSearchProps } from '../../utils/columnsearch-props'
import moment from 'moment'

function SourceGroupTable({
    sourceGroup,
    loading,
}: {
    sourceGroup: ISourceGroup[] | undefined
    loading: boolean
}): JSX.Element {
    const [searchText, setSearchText] = React.useState('')

    const columns = [
        {
            title: 'ID',
            dataIndex: 'SourceGroupID',
            key: 'SourceGroupID',
            sorter: (a: ISourceGroup, b: ISourceGroup) => {
                return (a.SourceGroupID || 1) > (b.SourceGroupID || 1) ? 1 : -1
            },
        },
        {
            title: 'Group Name',
            dataIndex: 'SourceGroupName',
            key: 'SourceGroupID',
            ...getColumnSearchProps(
                'SourceGroupName',
                searchText,
                setSearchText
            ),
        },
        {
            title: 'Datasets',
            dataIndex: 'SourceGroupID',
            key: 'SourceGroupID',
            render: (_: string, record: ISourceGroup) => (
                <Link
                    to={`${routeMapMini.sourceDataset}?sourceGroupId=${record.SourceGroupID}`}
                >
                    <DatabaseOutlined />
                </Link>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'Auditdatetime',
            key: 'RunId',
            render: (text: string) => {
                return moment(text).format('YYYY-MM-DD HH:mm')
            },
            sorter: (a: ISourceGroup, b: ISourceGroup) => {
                return (
                    moment(a.Auditdatetime).valueOf() -
                    moment(b.Auditdatetime).valueOf()
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'RunId',
            ellipsis: true,
        },
    ]

    return (
        <Table columns={columns} dataSource={sourceGroup} loading={loading} />
    )
}

export default SourceGroupTable
