import React from 'react'
import { ICalculationRuleContext } from './new-calculation-rule'
import {
    CalColumnTypes,
    CalculationRuleStepType,
    excludecondition,
} from '../../services/calculation-rule/interfaces/calculation-rule.interface'
import * as Sentry from '@sentry/react'
import { ICalculationRuleStep } from './calculation-rule-steps'
import { parseJson } from '../../utils/parse.util'

export interface ICalcColumnOption {
    name: string
    id: number | string
    type: CalColumnTypes
    uniqueID: string
}

function getConditionArithmeticSteps(
    element: ICalculationRuleStep,
    stepId: number,
    conditionType: 'if' | 'else'
): void {
    if (element.condition !== undefined) {
        if (
            element.condition[conditionType].arithmetic?.column1Type ===
                CalColumnTypes.sibling_step &&
            element.condition[conditionType].arithmetic?.column1 === stepId
        ) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].arithmetic.column1Type = undefined
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].arithmetic.column1 = undefined
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].arithmetic.statusOfColumn1 = false
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[
                conditionType
            ].arithmetic.textDecorationOfColumn1 = true
        } else if (
            element.condition[conditionType].arithmetic?.column1Type ===
                CalColumnTypes.sibling_step &&
            Number(element.condition[conditionType].arithmetic?.column1) >
                stepId
        ) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].arithmetic.column1 =
                Number(element.condition[conditionType].arithmetic?.column1) - 1
        }

        if (
            element.condition[conditionType].arithmetic?.column2Type ===
                CalColumnTypes.sibling_step &&
            element.condition[conditionType].arithmetic?.column2 === stepId
        ) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].arithmetic.column2Type = undefined
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].arithmetic.column2 = undefined
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].arithmetic.statusOfColumn2 = false
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[
                conditionType
            ].arithmetic.textDecorationOfColumn2 = true
        } else if (
            element.condition[conditionType].arithmetic?.column2Type ===
                CalColumnTypes.sibling_step &&
            Number(element.condition[conditionType].arithmetic?.column2) >
                stepId
        ) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].arithmetic.column2 =
                Number(element.condition[conditionType].arithmetic?.column2) - 1
        }
    }
}

function getConditionAggregateSteps(
    element: ICalculationRuleStep,
    stepId: number,
    conditionType: 'if' | 'else'
): void {
    if (element.condition !== undefined) {
        if (
            element.condition[conditionType].aggregate?.column1Type ===
                CalColumnTypes.sibling_step &&
            element.condition[conditionType].aggregate?.column1 === stepId
        ) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].aggregate.column1Type = undefined
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].aggregate.column1 = undefined
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].aggregate.statusOfColumn1 = false
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].aggregate.textDecorationOfColumn1 =
                true
        } else if (
            element.condition[conditionType].aggregate?.column1Type ===
                CalColumnTypes.sibling_step &&
            Number(element.condition[conditionType].aggregate?.column1) > stepId
        ) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].aggregate.column1 =
                Number(element.condition[conditionType].aggregate?.column1) - 1
        }

        if (element.condition[conditionType].aggregate?.groupBy !== undefined) {
            for (
                let indexGroupBy = 0;
                indexGroupBy <
                // @ts-ignore
                element.condition[conditionType].aggregate.groupBy.length;
                indexGroupBy += 1
            ) {
                const groupBy =
                    // @ts-ignore
                    element.condition[conditionType].aggregate.groupBy[
                        indexGroupBy
                    ]

                if (
                    groupBy.columnType === CalColumnTypes.sibling_step &&
                    groupBy.columnValue === stepId
                ) {
                    // @ts-ignore
                    element.condition[conditionType].aggregate.groupBy.splice(
                        indexGroupBy,
                        1
                    )
                    indexGroupBy -= 1
                    // @ts-ignore
                    // eslint-disable-next-line no-param-reassign
                    element.condition[conditionType].aggregate.statusOfGroupBy =
                        false
                    // @ts-ignore
                    // eslint-disable-next-line no-param-reassign
                    element.condition[
                        conditionType
                    ].aggregate.textDecorationOfGroupBy = true
                } else if (
                    groupBy.columnType === CalColumnTypes.sibling_step &&
                    Number(groupBy.columnValue) > stepId
                ) {
                    groupBy.columnValue = Number(groupBy.columnValue) - 1
                }
            }
        }
    }
}

function getConditionColumnSteps(
    element: ICalculationRuleStep,
    stepId: number,
    conditionType: 'if' | 'else'
): void {
    if (element.condition !== undefined) {
        if (
            element.condition[conditionType].column?.column1Type ===
                CalColumnTypes.sibling_step &&
            element.condition[conditionType].column?.column1 === stepId
        ) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].column.column1Type = undefined
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].column.column1 = undefined
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].column.statusOfColumn1 = false
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].column.textDecorationOfColumn1 =
                true
        } else if (
            element.condition[conditionType].column?.column1Type ===
                CalColumnTypes.sibling_step &&
            Number(element.condition[conditionType].column?.column1) > stepId
        ) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            element.condition[conditionType].column.column1 =
                Number(element.condition[conditionType].column?.column1) - 1
        }
    }
}

export const calculationRuleHelper = {
    deleteStep: (
        calculationRuleValue: ICalculationRuleContext,
        columnId: number,
        stepId: number
    ) => {
        const columnsUpdate = calculationRuleValue?.columns?.map((column) => ({
            ...column,
            steps:
                column.columnID !== columnId
                    ? column.steps
                    : column.steps?.filter((step) => step.stepID !== stepId),
        }))
        for (
            let index = stepId - 1;
            index < columnsUpdate[columnId - 1]?.steps?.length;
            index += 1
        ) {
            const element = columnsUpdate[columnId - 1].steps[index]

            element.stepID = index + 1

            element.tempStepName = `step ${index + 1}`

            if (element.stepID === 1) {
                element.parentStepID = -1
            } else {
                element.parentStepID = index
            }

            if (element.stepType === CalculationRuleStepType.arithmetic) {
                if (
                    element.arithmetic?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    element.arithmetic.column1 === stepId
                ) {
                    element.arithmetic.column1Type = undefined
                    element.arithmetic.column1 = undefined
                    element.arithmetic.statusOfColumn1 = false
                    element.arithmetic.textDecorationOfColumn1 = true
                } else if (
                    element.arithmetic?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    Number(element.arithmetic.column1) > stepId
                ) {
                    element.arithmetic.column1 =
                        Number(element.arithmetic.column1) - 1
                }

                if (
                    element.arithmetic?.column2Type ===
                        CalColumnTypes.sibling_step &&
                    element.arithmetic.column2 === stepId
                ) {
                    element.arithmetic.column2Type = undefined
                    element.arithmetic.column2 = undefined
                    element.arithmetic.statusOfColumn2 = false
                    element.arithmetic.textDecorationOfColumn2 = true
                } else if (
                    element.arithmetic?.column2Type ===
                        CalColumnTypes.sibling_step &&
                    Number(element.arithmetic.column2) > stepId
                ) {
                    element.arithmetic.column2 =
                        Number(element.arithmetic.column2) - 1
                }
            }

            if (element.stepType === CalculationRuleStepType.aggregate) {
                if (
                    element.aggregate?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    element.aggregate.column1 === stepId
                ) {
                    element.aggregate.column1Type = undefined
                    element.aggregate.column1 = undefined
                    element.aggregate.statusOfColumn1 = false
                    element.aggregate.textDecorationOfColumn1 = true
                } else if (
                    element.aggregate?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    Number(element.aggregate.column1) > stepId
                ) {
                    element.aggregate.column1 =
                        Number(element.aggregate.column1) - 1
                }
                if (element.aggregate?.groupBy !== undefined) {
                    for (
                        let indexGroupBy = 0;
                        indexGroupBy < element.aggregate.groupBy.length;
                        indexGroupBy += 1
                    ) {
                        const groupBy = element.aggregate.groupBy[indexGroupBy]

                        if (
                            groupBy.columnType ===
                                CalColumnTypes.sibling_step &&
                            groupBy.columnValue === stepId
                        ) {
                            element.aggregate.groupBy.splice(indexGroupBy, 1)
                            element.aggregate.statusOfGroupBy = false
                            element.aggregate.textDecorationOfGroupBy = true
                            indexGroupBy -= 1
                        } else if (
                            groupBy.columnType ===
                                CalColumnTypes.sibling_step &&
                            Number(groupBy.columnValue) > stepId
                        ) {
                            groupBy.columnValue =
                                Number(groupBy.columnValue) - 1
                        }
                    }
                }
            }

            if (element.stepType === CalculationRuleStepType.condition) {
                if (
                    element.condition?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    element.condition.column1 === stepId
                ) {
                    element.condition.column1Type = undefined
                    element.condition.column1 = undefined
                    element.condition.statusOfColumn1 = false
                    element.condition.textDecorationOfColumn1 = true
                } else if (
                    element.condition?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    Number(element.condition.column1) > stepId
                ) {
                    element.condition.column1 =
                        Number(element.condition.column1) - 1
                }

                if (
                    element.condition?.column2Type ===
                        CalColumnTypes.sibling_step &&
                    element.condition.column2 === stepId
                ) {
                    element.condition.column2Type = undefined
                    element.condition.column2 = undefined
                    element.condition.statusOfColumn2 = false
                    element.condition.textDecorationOfColumn2 = true
                } else if (
                    element.condition?.column2Type ===
                        CalColumnTypes.sibling_step &&
                    Number(element.condition.column2) > stepId
                ) {
                    element.condition.column2 =
                        Number(element.condition.column2) - 1
                }

                if (
                    element.condition?.if?.conditionStepType ===
                    excludecondition.arithmetic
                ) {
                    getConditionArithmeticSteps(element, stepId, 'if')
                } else if (
                    element.condition?.if?.conditionStepType ===
                    excludecondition.aggregate
                ) {
                    getConditionAggregateSteps(element, stepId, 'if')
                } else if (
                    element.condition?.if?.conditionStepType ===
                    excludecondition.column
                ) {
                    getConditionColumnSteps(element, stepId, 'if')
                }

                if (
                    element.condition?.else?.conditionStepType ===
                    excludecondition.arithmetic
                ) {
                    getConditionArithmeticSteps(element, stepId, 'else')
                } else if (
                    element.condition?.else?.conditionStepType ===
                    excludecondition.aggregate
                ) {
                    getConditionAggregateSteps(element, stepId, 'else')
                } else if (
                    element.condition?.else?.conditionStepType ===
                    excludecondition.column
                ) {
                    getConditionColumnSteps(element, stepId, 'else')
                }
            }
        }
        calculationRuleValue?.setColumns(columnsUpdate || [])
    },

    onMouseEnter: (
        calculationRuleValue: ICalculationRuleContext,
        columnId: number,
        stepId: number
    ) => {
        const columnsUpdate = calculationRuleValue.columns.map((column) => ({
            ...column,
            steps: column.steps,
        }))
        for (
            let index = stepId - 1;
            index < columnsUpdate[columnId - 1]?.steps?.length;
            index += 1
        ) {
            const element = columnsUpdate[columnId - 1].steps[index]

            if (element.stepType === CalculationRuleStepType.arithmetic) {
                if (
                    element.arithmetic?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    element.arithmetic.column1 === stepId
                ) {
                    element.arithmetic.textDecorationOfColumn1 = false
                }

                if (
                    element.arithmetic?.column2Type ===
                        CalColumnTypes.sibling_step &&
                    element.arithmetic.column2 === stepId
                ) {
                    element.arithmetic.textDecorationOfColumn2 = false
                }
            }

            if (element.stepType === CalculationRuleStepType.aggregate) {
                if (
                    element.aggregate?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    element.aggregate.column1 === stepId
                ) {
                    element.aggregate = {
                        ...element.aggregate,
                        textDecorationOfColumn1: false,
                    }
                }

                if (element.aggregate?.groupBy !== undefined) {
                    for (
                        let indexGroupBy = 0;
                        indexGroupBy < element.aggregate.groupBy.length;
                        indexGroupBy += 1
                    ) {
                        const groupBy = element.aggregate.groupBy[indexGroupBy]
                        if (
                            groupBy.columnType ===
                                CalColumnTypes.sibling_step &&
                            groupBy.columnValue === stepId
                        ) {
                            element.aggregate.textDecorationOfGroupBy = false
                        }
                    }
                }
            }
            if (element.stepType === CalculationRuleStepType.condition) {
                if (
                    element.condition?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    element.condition.column1 === stepId
                ) {
                    element.condition.textDecorationOfColumn1 = false
                }

                if (
                    element.condition?.column2Type ===
                        CalColumnTypes.sibling_step &&
                    element.condition.column2 === stepId
                ) {
                    element.condition.textDecorationOfColumn2 = false
                }

                if (
                    element.condition?.if?.conditionStepType ===
                    excludecondition.arithmetic
                ) {
                    if (
                        element.condition.if.arithmetic?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.if.arithmetic?.column1 === stepId
                    ) {
                        element.condition.if.arithmetic.textDecorationOfColumn1 =
                            false
                    }
                    if (
                        element.condition.if.arithmetic?.column2Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.if.arithmetic?.column2 === stepId
                    ) {
                        element.condition.if.arithmetic.textDecorationOfColumn2 =
                            false
                    }
                } else if (
                    element.condition?.if?.conditionStepType ===
                    excludecondition.aggregate
                ) {
                    if (
                        element.condition.if.aggregate?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.if.aggregate?.column1 === stepId
                    ) {
                        element.condition.if.aggregate.textDecorationOfColumn1 =
                            false
                    }
                    if (element.condition.if.aggregate?.groupBy !== undefined) {
                        for (
                            let indexGroupBy = 0;
                            indexGroupBy <
                            element.condition.if.aggregate.groupBy.length;
                            indexGroupBy += 1
                        ) {
                            const groupBy =
                                element.condition.if.aggregate.groupBy[
                                    indexGroupBy
                                ]
                            if (
                                groupBy.columnType ===
                                    CalColumnTypes.sibling_step &&
                                groupBy.columnValue === stepId
                            ) {
                                element.condition.if.aggregate.textDecorationOfGroupBy =
                                    false
                            }
                        }
                    }
                } else if (
                    element.condition?.if?.conditionStepType ===
                    excludecondition.column
                ) {
                    if (
                        element.condition.if.column?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.if.column?.column1 === stepId
                    ) {
                        element.condition.if.column.textDecorationOfColumn1 =
                            false
                    }
                }

                if (
                    element.condition?.else?.conditionStepType ===
                    excludecondition.arithmetic
                ) {
                    if (
                        element.condition.else.arithmetic?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.else.arithmetic?.column1 === stepId
                    ) {
                        element.condition.else.arithmetic.textDecorationOfColumn1 =
                            false
                    }
                    if (
                        element.condition.else.arithmetic?.column2Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.else.arithmetic?.column2 === stepId
                    ) {
                        element.condition.else.arithmetic.textDecorationOfColumn2 =
                            false
                    }
                } else if (
                    element.condition?.else?.conditionStepType ===
                    excludecondition.aggregate
                ) {
                    if (
                        element.condition.else.aggregate?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.else.aggregate?.column1 === stepId
                    ) {
                        element.condition.else.aggregate.textDecorationOfColumn1 =
                            false
                    }
                    if (
                        element.condition.else.aggregate?.groupBy !== undefined
                    ) {
                        for (
                            let indexGroupBy = 0;
                            indexGroupBy <
                            element.condition.else.aggregate.groupBy.length;
                            indexGroupBy += 1
                        ) {
                            const groupBy =
                                element.condition.else.aggregate.groupBy[
                                    indexGroupBy
                                ]
                            if (
                                groupBy.columnType ===
                                    CalColumnTypes.sibling_step &&
                                groupBy.columnValue === stepId
                            ) {
                                element.condition.else.aggregate.textDecorationOfGroupBy =
                                    false
                            }
                        }
                    }
                } else if (
                    element.condition?.else?.conditionStepType ===
                    excludecondition.column
                ) {
                    if (
                        element.condition.else.column?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.else.column?.column1 === stepId
                    ) {
                        element.condition.else.column.textDecorationOfColumn1 =
                            false
                    }
                }
            }
        }
        calculationRuleValue?.setColumns(columnsUpdate)
    },

    onMouseLeave: (
        calculationRuleValue: ICalculationRuleContext,
        columnId: number,
        stepId: number
    ) => {
        const columnsUpdate = calculationRuleValue.columns.map((column) => ({
            ...column,
            steps: column.steps,
        }))
        for (
            let index = stepId - 1;
            index < columnsUpdate[columnId - 1]?.steps?.length;
            index += 1
        ) {
            const element = columnsUpdate[columnId - 1].steps[index]
            if (element.stepType === CalculationRuleStepType.arithmetic) {
                if (
                    element.arithmetic?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    element.arithmetic.column1 === stepId
                ) {
                    element.arithmetic.textDecorationOfColumn1 = true
                }
                if (
                    element.arithmetic?.column2Type ===
                        CalColumnTypes.sibling_step &&
                    element.arithmetic.column2 === stepId
                ) {
                    element.arithmetic.textDecorationOfColumn2 = true
                }
            }
            if (element.stepType === CalculationRuleStepType.aggregate) {
                if (
                    element.aggregate?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    element.aggregate.column1 === stepId
                ) {
                    element.aggregate.textDecorationOfColumn1 = true
                }
                if (element.aggregate?.groupBy !== undefined) {
                    for (
                        let indexGroupBy = 0;
                        indexGroupBy < element.aggregate.groupBy.length;
                        indexGroupBy += 1
                    ) {
                        const groupBy = element.aggregate.groupBy[indexGroupBy]
                        if (
                            groupBy.columnType ===
                                CalColumnTypes.sibling_step &&
                            groupBy.columnValue === stepId
                        ) {
                            element.aggregate.textDecorationOfGroupBy = true
                        }
                    }
                }
            }
            if (element.stepType === CalculationRuleStepType.condition) {
                if (
                    element.condition?.column1Type ===
                        CalColumnTypes.sibling_step &&
                    element.condition.column1 === stepId
                ) {
                    element.condition.textDecorationOfColumn1 = true
                }

                if (
                    element.condition?.column2Type ===
                        CalColumnTypes.sibling_step &&
                    element.condition.column2 === stepId
                ) {
                    element.condition.textDecorationOfColumn2 = true
                }

                if (
                    element.condition?.if?.conditionStepType ===
                    excludecondition.arithmetic
                ) {
                    if (
                        element.condition.if.arithmetic?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.if.arithmetic?.column1 === stepId
                    ) {
                        element.condition.if.arithmetic.textDecorationOfColumn1 =
                            true
                    }
                    if (
                        element.condition.if.arithmetic?.column2Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.if.arithmetic?.column2 === stepId
                    ) {
                        element.condition.if.arithmetic.textDecorationOfColumn2 =
                            true
                    }
                } else if (
                    element.condition?.if?.conditionStepType ===
                    excludecondition.aggregate
                ) {
                    if (
                        element.condition.if.aggregate?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.if.aggregate?.column1 === stepId
                    ) {
                        element.condition.if.aggregate.textDecorationOfColumn1 =
                            true
                    }
                    if (element.condition.if.aggregate?.groupBy !== undefined) {
                        for (
                            let indexGroupBy = 0;
                            indexGroupBy <
                            element.condition.if.aggregate.groupBy.length;
                            indexGroupBy += 1
                        ) {
                            const groupBy =
                                element.condition.if.aggregate.groupBy[
                                    indexGroupBy
                                ]
                            if (
                                groupBy.columnType ===
                                    CalColumnTypes.sibling_step &&
                                groupBy.columnValue === stepId
                            ) {
                                element.condition.if.aggregate.textDecorationOfGroupBy =
                                    true
                            }
                        }
                    }
                } else if (
                    element.condition?.if?.conditionStepType ===
                    excludecondition.column
                ) {
                    if (
                        element.condition.if.column?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.if.column?.column1 === stepId
                    ) {
                        element.condition.if.column.textDecorationOfColumn1 =
                            true
                    }
                }

                if (
                    element.condition?.else?.conditionStepType ===
                    excludecondition.arithmetic
                ) {
                    if (
                        element.condition.else.arithmetic?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.else.arithmetic?.column1 === stepId
                    ) {
                        element.condition.else.arithmetic.textDecorationOfColumn1 =
                            true
                    }
                    if (
                        element.condition.else.arithmetic?.column2Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.else.arithmetic?.column2 === stepId
                    ) {
                        element.condition.else.arithmetic.textDecorationOfColumn2 =
                            true
                    }
                } else if (
                    element.condition?.else?.conditionStepType ===
                    excludecondition.aggregate
                ) {
                    if (
                        element.condition.else.aggregate?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.else.aggregate?.column1 === stepId
                    ) {
                        element.condition.else.aggregate.textDecorationOfColumn1 =
                            true
                    }
                    if (
                        element.condition.else.aggregate?.groupBy !== undefined
                    ) {
                        for (
                            let indexGroupBy = 0;
                            indexGroupBy <
                            element.condition.else.aggregate.groupBy.length;
                            indexGroupBy += 1
                        ) {
                            const groupBy =
                                element.condition.else.aggregate.groupBy[
                                    indexGroupBy
                                ]
                            if (
                                groupBy.columnType ===
                                    CalColumnTypes.sibling_step &&
                                groupBy.columnValue === stepId
                            ) {
                                element.condition.else.aggregate.textDecorationOfGroupBy =
                                    true
                            }
                        }
                    }
                } else if (
                    element.condition?.else?.conditionStepType ===
                    excludecondition.column
                ) {
                    if (
                        element.condition.else.column?.column1Type ===
                            CalColumnTypes.sibling_step &&
                        element.condition.else.column?.column1 === stepId
                    ) {
                        element.condition.else.column.textDecorationOfColumn1 =
                            true
                    }
                }
            }
        }
        calculationRuleValue?.setColumns(columnsUpdate)
    },

    filterAvailableColumns: (
        calculationRuleValue: ICalculationRuleContext,
        columnId: number,
        stepId: number
    ): ICalcColumnOption[] => {
        const tempAvailableColumns: ICalcColumnOption[] | undefined =
            calculationRuleValue?.columns
                ?.filter((column) => column.columnID < columnId)
                .map((column) => {
                    return {
                        name: column.columnName,
                        id: column.columnID,
                        type: CalColumnTypes.sibling_column,
                        uniqueID: `${column.columnID}-${CalColumnTypes.sibling_column}`,
                    }
                })

        const tempAvailableSteps: ICalcColumnOption[] | undefined =
            calculationRuleValue?.columns
                ?.find((column) => column.columnID === columnId)
                ?.steps?.filter((step) => step.stepID < stepId)
                ?.map((step) => {
                    return {
                        name: step.tempStepName,
                        id: step.stepID,
                        type: CalColumnTypes.sibling_step,
                        uniqueID: `${step.stepID}-${CalColumnTypes.sibling_step}`,
                    }
                })

        let datasetMetadata: Record<string, string> = {}
        try {
            datasetMetadata = parseJson(
                calculationRuleValue?.dataset?.DatasetMetadata || '{}'
            )
        } catch (e) {
            Sentry.captureException(e)
        }

        const tempAvailableDataset: ICalcColumnOption[] = Object.keys(
            datasetMetadata
        )
            .filter((key) => datasetMetadata[key] === 'number')
            .map((key) => {
                return {
                    name: key,
                    id: key,
                    type: CalColumnTypes.dataset,
                    uniqueID: `${key}-${CalColumnTypes.dataset}`,
                }
            })

        return [
            ...(tempAvailableColumns || []),
            ...(tempAvailableSteps || []),
            ...tempAvailableDataset,
        ]
    },

    getColumnNameFromColumnType: (
        calculationRuleValue: ICalculationRuleContext | undefined,
        columnType: CalColumnTypes,
        columnValue: string,
        columnId: number,
        addComma?: boolean
    ): JSX.Element => {
        switch (columnType) {
            case 'sibling_column':
                return (
                    <p>
                        <b>
                            [
                            {
                                calculationRuleValue?.columns.find(
                                    (item) =>
                                        item.columnID === Number(columnValue)
                                )?.columnName
                            }
                            ]
                        </b>
                        {addComma && ','}
                    </p>
                )
            case 'sibling_step':
                return (
                    <p>
                        <b>
                            {
                                calculationRuleValue?.columns
                                    .find((item) => item.columnID === columnId)
                                    ?.steps.find(
                                        (item) =>
                                            item.stepID === Number(columnValue)
                                    )?.tempStepName
                            }
                        </b>
                        {addComma && ','}
                    </p>
                )
            case 'dataset':
                return (
                    <p>
                        {columnValue}
                        {addComma && ','}
                    </p>
                )
            case 'constant':
                return (
                    <p>
                        Constant {columnValue}
                        {addComma && ','}
                    </p>
                )
            default:
                return <p />
        }
    },
}
