import React, { Component, ErrorInfo, ReactNode } from 'react'
import { Button, Result } from 'antd'
import * as Sentry from '@sentry/react'

interface Props {
    children: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        this.setState({ hasError: true })
        Sentry.captureException(error)
    }

    public render(): ReactNode {
        const { hasError } = this.state
        const { children } = this.props

        if (hasError) {
            return (
                <Result
                    status="500"
                    title="500"
                    subTitle="Sorry, something went wrong."
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                window.location.reload()
                            }}
                        >
                            Reload
                        </Button>
                    }
                />
            )
        }

        return children
    }
}

export default ErrorBoundary
