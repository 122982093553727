import { BlobServiceClient } from '@azure/storage-blob'
import { RcFile } from 'antd/es/upload'

const SASToken =
    process.env.storagesastoken ||
    // eslint-disable-next-line max-len
    'sp=racw&st=2022-09-14T10:22:55Z&se=2022-12-31T18:22:55Z&spr=https&sv=2021-06-08&sr=c&sig=5%2FhAtKppwOfHrwJfvuJupnaJLJmHNhy6VbleroR1HTs%3D'
const containerName = `xyentaae`
const storageAccountName = process.env.storageresourcename || 'xytech'

export const uploadFileToAzureBlob = async (
    file: RcFile,
    fileName: string,
    onProgress: any
): Promise<{ fileLocation: string } | undefined> => {
    if (!file) {
        throw new Error('File is not provided')
    }

    const blobService = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net/?${SASToken}`
    )
    const containerClient = blobService.getContainerClient(containerName)

    const blobClient = containerClient.getBlockBlobClient(fileName)

    await blobClient.upload(file, file.size, {
        blobHTTPHeaders: {
            blobContentType: file.type,
        },
        onProgress: (progress) => {
            onProgress?.({
                percent: progress.loadedBytes / file.size,
            })
        },
    })

    return { fileLocation: fileName }
}
