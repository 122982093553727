import * as React from 'react'
import { useEffect } from 'react'
import { sourceGroupService } from '../../services/source-group/source-group.service'
import { ISourceGroup } from '../../services/source-group/interfaces/source-group.interface'
import { IAllocationRuleGroup } from '../../services/allocation-rule/interfaces/allocation-rule-group.interface'
import { allocationRuleGroupService } from '../../services/allocation-rule/allocation-rule-group.service'
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { Button, Input, Select, Space, Upload } from 'antd'
import { FileUploadService } from '../../services/file-upload/file-upload.service'
import { ISourceDataset } from '../../services/source-group/interfaces/source-dataset.interface'
import { sourceDatasetService } from '../../services/source-group/source-dataset.service'
import { handleAPIError } from '../../utils/catch-error'

type IProps = {
    setConfigName: React.Dispatch<React.SetStateAction<string>>
    configName: string | undefined
    // fileLocation: string | undefined
    setFileLocation: React.Dispatch<React.SetStateAction<string>>
    allocationRuleGroupId: number | undefined
    setAllocationRuleGroupId: React.Dispatch<
        React.SetStateAction<number | undefined>
    >
    sourceDatasetId: number | undefined
    setSourceDatasetId: React.Dispatch<React.SetStateAction<number | undefined>>
}

function NewConfigFile({
    allocationRuleGroupId,
    configName,
    setAllocationRuleGroupId,
    setConfigName,
    setFileLocation,
    setSourceDatasetId,
    sourceDatasetId,
}: IProps): JSX.Element {
    const [sourceGroups, setSourceGroups] = React.useState<ISourceGroup[]>([])
    const [allocationRuleGroup, setAllocationRuleGroup] = React.useState<
        IAllocationRuleGroup[]
    >([])
    const [selectedSourceGroupId, setSelectedSourceGroupId] = React.useState<
        number | undefined
    >(undefined)
    const [sourceDatasets, setSourceDatasets] = React.useState<
        ISourceDataset[]
    >([])

    const [datasetsLoading, setDatasetsLoading] = React.useState(false)
    const [groupsLoading, setGroupsLoading] = React.useState(false)
    const [allocationRuleGroupsLoading, setAllocationRuleGroupsLoading] =
        React.useState(false)

    const loadSourceDatasets = async (): Promise<void> => {
        setDatasetsLoading(true)
        try {
            if (selectedSourceGroupId) {
                const sourceDatasetsTemp =
                    await sourceDatasetService.getDatasetsForGroup(
                        selectedSourceGroupId
                    )
                setSourceDatasets(sourceDatasetsTemp)
            }
        } catch (error: any) {
            handleAPIError(error)
        }
        setDatasetsLoading(false)
    }

    const loadSourceGroups = async (): Promise<void> => {
        setGroupsLoading(true)
        try {
            const sourceGroupsTemp = await sourceGroupService.getAll()
            setSourceGroups(sourceGroupsTemp)
        } catch (error: any) {
            handleAPIError(error)
        }
        setGroupsLoading(false)
    }

    const loadAllocationRuleGroup = async (): Promise<void> => {
        setAllocationRuleGroupsLoading(true)
        try {
            const allocationGroup = await allocationRuleGroupService.getAll()
            setAllocationRuleGroup(allocationGroup)
        } catch (error: any) {
            handleAPIError(error)
        }
        setAllocationRuleGroupsLoading(false)
    }

    useEffect(() => {
        loadAllocationRuleGroup()
        loadSourceGroups()
    }, [])

    useEffect(() => {
        if (selectedSourceGroupId) {
            loadSourceDatasets()
        }
    }, [selectedSourceGroupId])

    const generateFileName = (originalFileName: string): string => {
        const fileName = originalFileName.split('.')
        const fileExtension = fileName[fileName.length - 1]
        return `config_files/${Date.now()}.${fileExtension}`
    }

    const uploadFile = async (options: UploadRequestOption): Promise<void> => {
        const { onSuccess, onError, file, onProgress } = options
        try {
            const resp = await FileUploadService.uploadFile(
                // @ts-ignore
                file,
                // @ts-ignore
                generateFileName(file.name),
                onProgress
            )
            if (resp) setFileLocation(resp.fileLocation)
            onSuccess?.('Ok')
        } catch (err: any) {
            // @ts-ignore
            onError?.({ err })
            handleAPIError(err)
        }
    }

    return (
        <Space direction="vertical" size="middle">
            <Input
                onChange={(e) => setConfigName(e.target.value)}
                placeholder="Enter Config Name"
                value={configName}
            />
            Select Allocation Rule Group
            <Select
                value={allocationRuleGroupId}
                onChange={(value) => {
                    setAllocationRuleGroupId(value)
                }}
                style={{ minWidth: '150px' }}
                loading={allocationRuleGroupsLoading}
            >
                {allocationRuleGroup.map((allocationRuleGroupItem) => (
                    <Select.Option
                        key={allocationRuleGroupItem.AllocationRuleGroupID}
                        value={allocationRuleGroupItem.AllocationRuleGroupID}
                    >
                        {allocationRuleGroupItem.AllocationRuleGroupName}
                    </Select.Option>
                ))}
            </Select>
            Select Data Group
            <Select
                value={selectedSourceGroupId}
                onChange={(value) => {
                    setSourceDatasetId(undefined)
                    setSelectedSourceGroupId(value)
                }}
                style={{ minWidth: '150px' }}
                loading={groupsLoading}
            >
                {sourceGroups.map((sourceGroupItem) => (
                    <Select.Option
                        key={sourceGroupItem.SourceGroupID}
                        value={sourceGroupItem.SourceGroupID}
                    >
                        {sourceGroupItem.SourceGroupName}
                    </Select.Option>
                ))}
            </Select>
            Select Dataset
            <Select
                value={sourceDatasetId}
                onChange={(value) => {
                    setSourceDatasetId(value)
                }}
                style={{ minWidth: '150px' }}
                loading={datasetsLoading}
            >
                {sourceDatasets.map((sourceGroupItem) => (
                    <Select.Option
                        key={sourceGroupItem.DatasetID}
                        value={sourceGroupItem.DatasetID}
                    >
                        {sourceGroupItem.DatasetName}
                    </Select.Option>
                ))}
            </Select>
            <Upload customRequest={uploadFile}>
                <Button>Upload File</Button>
            </Upload>
        </Space>
    )
}

export default NewConfigFile
