import * as React from 'react'
import { Button, Modal, notification, Select, Space } from 'antd'
import { useEffect } from 'react'
import { withRouter } from '../../../utils/withRouter'
import { IAllocationRuleGroup } from '../../../services/allocation-rule/interfaces/allocation-rule-group.interface'
import { allocationRuleGroupService } from '../../../services/allocation-rule/allocation-rule-group.service'
import AllocationRuleFilesTable from './allocation-rule-files-table'
import CreateAllocationFile from './create-allocation-file'
import { allocationRuleFileService } from '../../../services/allocation-rule/allocation-rule-file.service'
import { handleAPIError } from '../../../utils/catch-error'
import AllocationRuleGroupDrawer from '../allocation-rule-group-drawer'
import { useSearchParams } from 'react-router-dom'
import { FileProperties } from '../../../services/file-upload/interfaces/file-properties.interface'

const defaultFileProperties: FileProperties = {
    FileLocation: '',
    FileName: '',
    FileType: 'allocation_rule_file',
    FilePreviewData: [],
}

function AllocationRuleFiles(): JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams()

    const [allocationRuleGroup, setAllocationRuleGroup] = React.useState<
        IAllocationRuleGroup[] | undefined
    >(undefined)
    const [selectedAllocationRuleGroup, setSelectedAllocationRuleGroup] =
        React.useState<IAllocationRuleGroup | undefined>(undefined)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isTableLoading, setIsTableLoading] = React.useState(false)

    const [fileProperties, setFileProperties] = React.useState<FileProperties>({
        ...defaultFileProperties,
    })
    const [allocationFileName, setAllocationFileName] = React.useState<
        string | undefined
    >(undefined)
    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false)
    const [createIsLoading, setCreateIsLoading] = React.useState<boolean>(false)
    const [newFileColumns, setNewFileColumns] = React.useState<string[]>([])
    const [groupConfigDrawerVisible, setGroupConfigDrawerVisible] =
        React.useState(false)

    useEffect(() => {
        allocationRuleGroupService
            .getAll()
            .then((res) => {
                setAllocationRuleGroup(res)
                const allocationRuleGroupIdFromQuery = searchParams.get(
                    'AllocationRuleGroupId'
                )
                if (allocationRuleGroupIdFromQuery) {
                    const sourceGroupItem = res?.find(
                        (sg) =>
                            sg.AllocationRuleGroupID ===
                            Number(allocationRuleGroupIdFromQuery)
                    )
                    if (sourceGroupItem) {
                        setSelectedAllocationRuleGroup(sourceGroupItem)
                    }
                } else {
                    setSelectedAllocationRuleGroup(res[0])
                }
            })
            .catch(handleAPIError)
    }, [searchParams])

    const handleOk = async (e: any): Promise<void> => {
        e.preventDefault()
        if (!fileProperties || !allocationFileName) {
            notification.error({
                message: `Error: Please fill in all the required fields`,
            })
            return
        }
        if (!selectedAllocationRuleGroup) {
            notification.error({
                message: `Error: Please select an allocation rule group`,
            })
        }
        setCreateIsLoading(true)
        try {
            await allocationRuleFileService.create({
                AllocationRuleName: allocationFileName,
                AllocationRuleGroupID:
                    selectedAllocationRuleGroup?.AllocationRuleGroupID,
                AllocationRuleFileColumns: newFileColumns,
                FileName: fileProperties.FileName,
                FileType: fileProperties.FileType,
                FilePreviewData: fileProperties.FilePreviewData,
                FileLocation: fileProperties.FileLocation,
            })
        } catch (err: any) {
            handleAPIError(err)
        }
        setCreateIsLoading(false)
        setFileProperties({ ...defaultFileProperties })
        setAllocationFileName(undefined)
        setIsModalVisible(false)
    }

    const handleCancel = (e: any): void => {
        e.preventDefault()
        setFileProperties({ ...defaultFileProperties })
        setAllocationFileName(undefined)
        setIsModalVisible(false)
    }

    return (
        <div>
            <div
                style={{
                    float: 'left',
                    marginLeft: '15px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            >
                <Space>
                    <a>
                        <Button
                            type="primary"
                            onClick={() => {
                                setIsModalVisible(true)
                            }}
                        >
                            Create New Allocation Rule File
                        </Button>
                    </a>
                    Group:
                    <Select
                        style={{ minWidth: '120px' }}
                        value={
                            selectedAllocationRuleGroup?.AllocationRuleGroupID
                        }
                        onChange={(value) => {
                            const sourceGroupItem = allocationRuleGroup?.find(
                                (sg) =>
                                    sg.AllocationRuleGroupID === Number(value)
                            )
                            if (sourceGroupItem) {
                                setSearchParams({
                                    AllocationRuleGroupId: String(value),
                                })
                                setSelectedAllocationRuleGroup(sourceGroupItem)
                            }
                        }}
                    >
                        {allocationRuleGroup?.map(
                            (allocationRuleGroupItem: IAllocationRuleGroup) => (
                                <Select.Option
                                    key={
                                        allocationRuleGroupItem.AllocationRuleGroupID
                                    }
                                    value={
                                        allocationRuleGroupItem.AllocationRuleGroupID
                                    }
                                >
                                    {
                                        allocationRuleGroupItem.AllocationRuleGroupName
                                    }
                                </Select.Option>
                            )
                        )}
                    </Select>
                    {selectedAllocationRuleGroup && (
                        <Button
                            onClick={() => {
                                setGroupConfigDrawerVisible(true)
                            }}
                        >
                            View Group Config
                        </Button>
                    )}
                </Space>
            </div>
            <div style={{ margin: '15px' }}>
                <AllocationRuleFilesTable
                    groupId={selectedAllocationRuleGroup?.AllocationRuleGroupID}
                    loading={isTableLoading}
                    refreshData={isModalVisible}
                />
            </div>
            <Modal
                title="Create New Allocation Rule File"
                visible={isModalVisible}
                onOk={async (e) => {
                    await handleOk(e)
                }}
                confirmLoading={createIsLoading}
                onCancel={(e) => handleCancel(e)}
                destroyOnClose
            >
                <CreateAllocationFile
                    allocationFileName={allocationFileName}
                    setAllocationFileName={setAllocationFileName}
                    allocationGroupId={
                        selectedAllocationRuleGroup?.AllocationRuleGroupID
                    }
                    setFileProperties={setFileProperties}
                    fileProperties={fileProperties}
                    allocationGroupName={
                        selectedAllocationRuleGroup?.AllocationRuleGroupName
                    }
                    setNewFileColumns={setNewFileColumns}
                />
            </Modal>
            {selectedAllocationRuleGroup && (
                <AllocationRuleGroupDrawer
                    ruleGroupID={
                        selectedAllocationRuleGroup.AllocationRuleGroupID || 0
                    }
                    visible={groupConfigDrawerVisible}
                    setVisible={setGroupConfigDrawerVisible}
                    groupName={
                        selectedAllocationRuleGroup.AllocationRuleGroupName
                    }
                />
            )}
        </div>
    )
}

export default withRouter(AllocationRuleFiles)
