import {
    Button,
    Col,
    Form,
    Input,
    notification,
    Row,
    Select,
    Space,
    Typography,
} from 'antd'
import React, { useEffect } from 'react'
import { IMergeRuleResponse } from '../../services/merge-rule/interfaces/merge-rule-response.interface'
import { mergeRuleService } from '../../services/merge-rule/merge-rule.service'
import { ISourceFileInfo } from '../../services/source-group/interfaces/source-file-info.interface'
import { handleAPIError } from '../../utils/catch-error'
import { sourceFileInfoService } from '../../services/source-group/source-file-info.service'
import { runService } from '../../services/run/run.service'

function CreateMergeRun({ nextStep }: { nextStep: () => void }): JSX.Element {
    const [form] = Form.useForm()
    const [loading, setLoading] = React.useState(false)

    const [mergeRules, setMergeRules] = React.useState<IMergeRuleResponse[]>([])
    const [selectedMergeRule, setSelectedMergeRule] =
        React.useState<IMergeRuleResponse>()
    const [leftSourceFiles, setLeftSourceFiles] = React.useState<
        ISourceFileInfo[]
    >([])
    const [rightSourceFiles, setRightSourceFiles] = React.useState<
        ISourceFileInfo[]
    >([])

    useEffect(() => {
        mergeRuleService.get().then(setMergeRules).catch(handleAPIError)
    }, [])

    useEffect(() => {
        if (!selectedMergeRule) {
            return
        }
        sourceFileInfoService
            .getAllForDataset(selectedMergeRule.LeftDatasetID)
            .then(setLeftSourceFiles)
            .catch(handleAPIError)
        sourceFileInfoService
            .getAllForDataset(selectedMergeRule.RightDatasetID)
            .then(setRightSourceFiles)
            .catch(handleAPIError)
    }, [selectedMergeRule])

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true)
        try {
            await runService.createMergRun({
                RunName: values['Run Name'],
                MergeRuleID: values['Merge Rule'],
                LeftSourceFileID: values['Left Source Name'],
                RightSourceFileID: values['Right Source Name'],
                OutDatasetName: values['New Dataset Name'],
                OutSourceFileName: values['New File Name'],
            })
            notification.success({
                message: 'Success',
                description: 'Run created successfully',
            })
            form.resetFields()
            setLoading(false)
            nextStep()
        } catch (e) {
            handleAPIError(e)
            setLoading(false)
        }
    }

    const onReset = (): void => {
        form.resetFields()
        setSelectedMergeRule(undefined)
        setLeftSourceFiles([])
        setRightSourceFiles([])
    }

    return (
        <div
            style={{
                paddingTop: '30px',
                margin: '0 auto',
                display: 'table',
                minWidth: '400px',
            }}
        >
            <Form
                layout="vertical"
                form={form}
                name="create-run"
                onFinish={onFinish}
                style={{ maxWidth: '400px' }}
            >
                <Form.Item
                    name="Run Name"
                    label="Run Name"
                    rules={[
                        {
                            required: true,
                            min: 3,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="Merge Rule"
                    label="Merge Rule"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Select Merge Rule"
                        filterOption={(input, option) =>
                            option?.children
                                // @ts-ignore
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value: number) => {
                            setSelectedMergeRule(
                                mergeRules.find(
                                    (item) => item.MergeRuleID === value
                                )
                            )
                            form.resetFields([
                                'Left Source Name',
                                'Right Source Name',
                            ])
                        }}
                        allowClear
                    >
                        {mergeRules.map((item) => (
                            <Select.Option
                                key={item.MergeRuleID}
                                value={item.MergeRuleID}
                            >
                                {item.MergeRuleName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {selectedMergeRule && (
                    <Typography.Paragraph
                        style={{
                            marginTop: '-16px',
                            fontSize: '13px',
                            marginLeft: '3px',
                        }}
                    >
                        Associated to Data Group:{' '}
                        {selectedMergeRule.SourceGroupName}
                    </Typography.Paragraph>
                )}
                <Form.Item
                    name="Left Source Name"
                    label={
                        selectedMergeRule
                            ? `Source from dataset ${selectedMergeRule.leftDatasetName}`
                            : 'Left Source Name'
                    }
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Select Left Source name"
                        filterOption={(input, option) =>
                            option?.children
                                // @ts-ignore
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        disabled={!selectedMergeRule}
                    >
                        {leftSourceFiles.map((item) => {
                            return (
                                <Select.Option
                                    key={item.SourceFileID}
                                    value={item.SourceFileID}
                                >
                                    {item.SourceName}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="Right Source Name"
                    label={
                        selectedMergeRule
                            ? `Source from dataset ${selectedMergeRule.rightDatasetName}`
                            : 'Right Source Name'
                    }
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Select Right Source name"
                        filterOption={(input, option) =>
                            option?.children
                                // @ts-ignore
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        disabled={!selectedMergeRule}
                    >
                        {rightSourceFiles.map((item) => {
                            return (
                                <Select.Option
                                    key={item.SourceFileID}
                                    value={item.SourceFileID}
                                >
                                    {item.SourceName}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>
                <Row gutter={[20, 0]}>
                    <Col span={12}>
                        <Form.Item
                            name="New Dataset Name"
                            label="New Dataset Name"
                            rules={[
                                {
                                    required: true,
                                    min: 3,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="New File Name"
                            label="New File Name"
                            rules={[
                                {
                                    required: true,
                                    min: 3,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Form.Item>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Submit
                            </Button>
                            <Button
                                htmlType="button"
                                onClick={onReset}
                                disabled={loading}
                            >
                                Reset
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    )
}

export default CreateMergeRun
