import { Col, Row, Card, Tag, Statistic } from 'antd'
import React, { useEffect, useState } from 'react'
import { handleAPIError } from '../../utils/catch-error'
import { runService } from '../../services/run/run.service'
import {
    IAllocationRunHistoryCount,
    IRunHistory,
} from '../../services/run/interfaces/run-history.interface'
import { allocationRuleFileService } from '../../services/allocation-rule/allocation-rule-file.service'
import { IAllocationRuleFileInfo } from '../../services/allocation-rule/interfaces/allocation-rule-file.interface'
import { routeMapMini } from '../../constants/routeMap'
import { useNavigate } from 'react-router-dom'
import DemoPie from './usersRunsPiechart'
import moment from 'moment'
import DatePicker, { RangePickerProps } from 'antd/lib/date-picker'

function Dashboard(this: any): JSX.Element {
    const navigate = useNavigate()
    const [runHistoryData, setRunHistoryData] = React.useState<IRunHistory[]>(
        []
    )
    const [firstRunHistoryData, setFirstRunHistoryData] =
        React.useState<IRunHistory>()
    const [runHistoryStatus, setRunHistoryStatus] = useState<JSX.Element>()

    const [allocationData, setAllocationData] = React.useState<
        IAllocationRuleFileInfo[]
    >([])
    const [allocationRunCountData, setAllocationRunCountData] =
        React.useState<IAllocationRunHistoryCount>()
    const [allocationFirstData, setAllocationFirstData] =
        React.useState<IAllocationRuleFileInfo>()
    const [allocationStatus, setAllocationStatus] = useState<JSX.Element>()

    const [fromDate, setFromDate] = React.useState<any>(
        moment().subtract(30, 'days')
    )
    const [toDate, setToDate] = React.useState<any>(moment())
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current > moment().endOf('day')
    }

    useEffect(() => {
        runService
            .getAllByEmail()
            .then((res) => {
                setRunHistoryData(res)

                setFirstRunHistoryData(res[0])
                if (res.length === 0) {
                    setRunHistoryStatus(<Tag color="grey">No Record Found</Tag>)
                } else {
                    switch (res[0].Status) {
                        case 'running':
                            setRunHistoryStatus(
                                <Tag color="green">Running</Tag>
                            )
                            break
                        case 'finished':
                            setRunHistoryStatus(
                                <Tag color="blue">Finished</Tag>
                            )
                            break
                        case 'failed':
                            setRunHistoryStatus(<Tag color="red">Failed</Tag>)
                            break
                        default:
                            setRunHistoryStatus(
                                <Tag
                                    color="orange"
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    {res[0].Status}
                                </Tag>
                            )
                    }
                }
            })
            .catch((err) => {
                handleAPIError(err)
            })
    }, [])

    useEffect(() => {
        allocationRuleFileService
            .getAllForAuthenticatedUser()
            .then((res) => {
                setAllocationData(res)
                setAllocationFirstData(res[0])

                if (res.length === 0) {
                    setAllocationStatus(<Tag color="grey">No Record Found</Tag>)
                } else {
                    switch (res[0]?.StageStatus) {
                        case 'running':
                            setAllocationStatus(
                                <Tag color="green">Running</Tag>
                            )
                            break
                        case 'finished':
                            setAllocationStatus(
                                <Tag color="blue">Finished</Tag>
                            )
                            break
                        case 'failure':
                            setAllocationStatus(<Tag color="red">Failed</Tag>)
                            break
                        default:
                            setAllocationStatus(
                                <Tag color="orange">{res[0]?.StageStatus}</Tag>
                            )
                    }
                }
            })
            .catch((err) => {
                handleAPIError(err)
            })
    }, [])

    useEffect(() => {
        runService
            .getAllocationRunsCountByUserId()
            .then((res) => {
                setAllocationRunCountData(res?.[0])
            })
            .catch((err) => {
                handleAPIError(err)
            })
    }, [])

    const onClickHandlerForRunHistory = (): any => {
        if (runHistoryStatus?.props.children === 'Failed') {
            navigate(routeMapMini.runHistory, {
                replace: true,
            })
        }
    }

    const onClickHandlerForAllocation = (): any => {
        if (allocationStatus?.props.children === 'Failed') {
            navigate(
                // eslint-disable-next-line max-len
                `${routeMapMini.rulesAllocationFiles}?AllocationRuleGroupId=${allocationFirstData?.AllocationRuleGroupID}`,
                {
                    replace: true,
                }
            )
        }
    }

    return (
        <div style={{ paddingTop: '1%', paddingLeft: '1%' }}>
            <Row className="ro1" gutter={[15, 15]}>
                <Col xl={4} lg={6} md={8} xs={12}>
                    <Card
                        size="small"
                        hoverable
                        style={{
                            height: 'auto',
                            padding: '10px',
                            borderRadius: '10px',
                            border: '.5px solid #B0B0B0',
                        }}
                    >
                        <Row
                            wrap={false}
                            style={{
                                alignContent: 'space-between',
                                marginBottom: '20px',
                            }}
                        >
                            <Col flex="auto">
                                <strong>Last Created Run</strong>
                            </Col>
                            <Col flex="auto">
                                {firstRunHistoryData?.RunName ?? 'No Data'}
                            </Col>
                        </Row>
                        <Row wrap={false}>
                            <Col flex="auto">Status</Col>
                            <Col
                                flex="none"
                                style={{ cursor: 'pointer' }}
                                onClick={onClickHandlerForRunHistory}
                                aria-hidden="true"
                                onKeyPress={onClickHandlerForRunHistory}
                            >
                                {runHistoryStatus}
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xl={4} lg={6} md={8} xs={12}>
                    <Card
                        size="small"
                        hoverable
                        style={{
                            height: 'auto',
                            borderRadius: '10px',
                            border: '.5px solid #B0B0B0',
                        }}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <p>Allocation Runs created to date</p>
                        </div>
                        <Statistic
                            style={{ textAlign: 'center' }}
                            value={allocationRunCountData?.count}
                            valueStyle={{ fontSize: 'xx-large' }}
                        />
                    </Card>
                </Col>
                <Col xl={4} lg={6} md={8} xs={12}>
                    <Card
                        size="small"
                        hoverable
                        style={{
                            height: 'auto',
                            padding: '10px',
                            borderRadius: '10px',
                            border: '.5px solid #B0B0B0',
                        }}
                    >
                        <Row wrap={false} style={{ marginBottom: '20px' }}>
                            <Col flex="auto">
                                <strong>Last Created Allocation Rule</strong>
                            </Col>
                            <Col flex="auto">
                                {allocationFirstData?.AllocationRuleName ??
                                    'No data'}
                            </Col>
                        </Row>
                        <Row wrap={false} style={{ marginBottom: '20px' }}>
                            <Col flex="auto">Status</Col>
                            <Col
                                flex="none"
                                style={{ cursor: 'pointer' }}
                                onClick={onClickHandlerForAllocation}
                                aria-hidden="true"
                                onKeyPress={onClickHandlerForAllocation}
                            >
                                {allocationStatus}
                            </Col>
                        </Row>
                        <Row wrap={false}>
                            <Col flex="auto">Count</Col>
                            <Col flex="none">{allocationData.length}</Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row
                className="row2"
                style={{ maxHeight: 'auto', marginTop: '1%' }}
                gutter={[15, 15]}
            >
                <Col xl={4} lg={6} md={8} xs={12}>
                    <Card
                        size="small"
                        hoverable
                        style={{
                            height: 'auto',
                            borderRadius: '10px',
                            border: '.5px solid #B0B0B0',
                        }}
                    >
                        <Col>
                            <DatePicker.RangePicker
                                style={{
                                    minWidth: '100%',
                                    position: 'sticky',
                                    zIndex: 1,
                                }}
                                onChange={(e) => {
                                    if (e?.[0] && e?.[1]) {
                                        setFromDate(e?.[0])
                                        setToDate(e?.[1])
                                    } else {
                                        setFromDate(
                                            moment().subtract(15, 'days')
                                        )
                                        setToDate(moment())
                                    }
                                }}
                                format="DD-MM-YYYY"
                                disabledDate={disabledDate}
                                ranges={{
                                    Today: [moment(), moment()],
                                    'Last 7 Days': [
                                        moment().subtract(7, 'd'),
                                        moment(),
                                    ],
                                    'Last 15 Days': [
                                        moment().subtract(15, 'd'),
                                        moment(),
                                    ],
                                }}
                                value={[moment(fromDate), moment(toDate)]}
                            />
                        </Col>
                        <DemoPie fromDate={fromDate} toDate={toDate} />
                        <Row wrap={false} justify="center">
                            <strong>Run History</strong>
                        </Row>
                    </Card>
                </Col>
                <Col xl={4} lg={6} md={8} xs={12}>
                    <Card
                        size="small"
                        hoverable
                        style={{
                            height: 'auto',
                            borderRadius: '10px',
                            border: '.5px solid #B0B0B0',
                        }}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <p>Total Runs created to date</p>
                        </div>
                        <Statistic
                            style={{ textAlign: 'center' }}
                            value={runHistoryData.length}
                            valueStyle={{ fontSize: 'xx-large' }}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Dashboard
