import * as React from 'react'
import { Space, Tabs, Button } from 'antd'
import { ITeams } from '../../services/user-management/interfaces/teams.interface'
import { IUsers } from '../../services/user-management/interfaces/users.interface'
import UsersTable from './users-table'
import TeamsTable from './teams-table'
import { handleAPIError } from '../../utils/catch-error'
import { teamsService } from '../../services/user-management/teams.service'
import { usersService } from '../../services/user-management/users.service'
import CreateUsers from './create-users'
import { routeMapMini } from '../../constants/routeMap'
import { Link } from 'react-router-dom'

const { TabPane } = Tabs

function UserManagement(): JSX.Element {
    const [teams, setTeams] = React.useState<ITeams[] | undefined>(undefined)
    const [users, setUsers] = React.useState<IUsers[] | undefined>(undefined)
    const [userTableLoading, setUserTableLoading] = React.useState(false)
    const [teamTableLoading, setTeamTableLoading] = React.useState(false)

    const [isUserModalVisible, setIsUserModalVisible] = React.useState(false)
    const [isTeamModalVisible, setIsTeamModalVisible] = React.useState(false)

    React.useEffect(() => {
        setTeamTableLoading(true)
        teamsService
            .getAll()
            .then((res) => {
                setTeamTableLoading(false)
                setTeams(res)
            })
            .catch((err) => {
                handleAPIError(err)
                setTeamTableLoading(false)
            })
    }, [isTeamModalVisible])

    React.useEffect(() => {
        setUserTableLoading(true)
        usersService
            .getAll()
            .then((res) => {
                setUserTableLoading(false)
                setUsers(res)
            })
            .catch((err) => {
                handleAPIError(err)
                setUserTableLoading(false)
            })
    }, [isUserModalVisible])

    return (
        <div style={{ marginLeft: '10px' }}>
            <Space>
                <Link to={`${routeMapMini.createNewTeam}`}>
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsTeamModalVisible(true)
                        }}
                        style={{ marginTop: '10px' }}
                    >
                        Create Team
                    </Button>
                </Link>
                <Button
                    type="primary"
                    onClick={() => {
                        setIsUserModalVisible(true)
                    }}
                    style={{ marginTop: '10px' }}
                >
                    Create User
                </Button>
            </Space>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Teams" key="1">
                    <div>
                        <TeamsTable teams={teams} loading={teamTableLoading} />
                    </div>
                </TabPane>
                <TabPane tab="Users" key="2">
                    <div>
                        <UsersTable users={users} loading={userTableLoading} />
                    </div>
                </TabPane>
            </Tabs>
            <CreateUsers
                visible={isUserModalVisible}
                setVisible={setIsUserModalVisible}
            />
        </div>
    )
}

export default UserManagement
