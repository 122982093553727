import React, { useContext, useState } from 'react'
import { Button, notification, Select, Space } from 'antd'
import CalculationRuleArithmeticStep, {
    ICalculationRuleArithmeticStep,
} from './calculation-rule-arithmetic-step'

import { getEnumKeys } from '../../utils/enum'
import { CalculationRuleContext } from './new-calculation-rule'
// eslint-disable-next-line max-len
import {
    CalculationRuleStepType,
    excludecondition,
} from '../../services/calculation-rule/interfaces/calculation-rule.interface'
import ViewCalculationRuleArithmeticStep from './view-cal-rule-arithmetic-step'
import CalculationRuleAggregateStep, {
    ICalculationRuleAggregateStep,
} from './calculation-rule-aggregate-step'
import ViewCalculationRuleAggregateStep from './view-cal-rule-aggregate-step'
import { handleAPIError } from '../../utils/catch-error'

import ViewCalculationRuleConditionStep from './view-cal-rule-condition-step'
import CalculationRuleConditionalStep, {
    ICalculationRuleConditionStep,
} from './calculation-rule-conditional-step'

export interface ICalculationRuleStep {
    stepID: number
    stepType: CalculationRuleStepType
    tempStepName: string
    parentStepID: number
    arithmetic?: ICalculationRuleArithmeticStep
    aggregate?: ICalculationRuleAggregateStep
    condition?: ICalculationRuleConditionStep
}

function CalculationRuleSteps({ columnID }: { columnID: number }): JSX.Element {
    const calculationRulesValue = useContext(CalculationRuleContext)
    const [addStepClicked, setAddStepClicked] = useState(false)
    const [addStepType, setAddStepType] = useState<
        CalculationRuleStepType | undefined
    >()

    function getTitle(stepId: number): string {
        if (calculationRulesValue?.columns !== undefined) {
            for (
                let index = 0;
                index < calculationRulesValue.columns.length;
                index += 1
            ) {
                const element = calculationRulesValue?.columns[index]
                if (element.steps.length === stepId) {
                    return 'Are you sure you want to delete this step?'
                }
            }
        }

        return 'Deleting this step may effect other steps. Do you want to proceed?'
    }

    const addStep = (): void => {
        if (!addStepType) {
            notification.error({
                message: 'Error',
                description: 'Please select step type',
            })
            return
        }
        try {
            const updatedColumns = calculationRulesValue?.columns.map(
                (column) => {
                    if (column.columnID === columnID) {
                        const lastStep = column.steps[column.steps.length - 1]

                        if (
                            lastStep &&
                            lastStep.stepType ===
                                CalculationRuleStepType.aggregate &&
                            (!lastStep.aggregate?.column1 ||
                                !lastStep.aggregate?.operation)
                        ) {
                            throw new Error(
                                `please select all fields in the previous step.`
                            )
                        } else if (
                            lastStep &&
                            lastStep.stepType ===
                                CalculationRuleStepType.arithmetic &&
                            (!lastStep.arithmetic?.column1 ||
                                !lastStep.arithmetic.column2 ||
                                !lastStep.arithmetic.operation)
                        ) {
                            throw new Error(
                                `please select all fields in the previous step.`
                            )
                        } else if (
                            lastStep &&
                            lastStep.stepType ===
                                CalculationRuleStepType.condition &&
                            (!lastStep.condition?.column1 ||
                                !lastStep.condition?.column2 ||
                                !lastStep.condition?.operation)
                        ) {
                            throw new Error(
                                `please select all fields in the condition step.`
                            )
                        } else if (
                            (lastStep &&
                                lastStep.condition?.if.conditionStepType ===
                                    excludecondition.arithmetic &&
                                (!lastStep.condition.if.arithmetic?.column1 ||
                                    !lastStep.condition.if.arithmetic
                                        ?.column2 ||
                                    !lastStep.condition.if.arithmetic
                                        ?.operation)) ||
                            (lastStep &&
                                lastStep.condition?.if.conditionStepType ===
                                    excludecondition.aggregate &&
                                (!lastStep.condition.if.aggregate?.column1 ||
                                    !lastStep.condition.if.aggregate
                                        ?.operation)) ||
                            (lastStep &&
                                lastStep.condition?.if.conditionStepType ===
                                    excludecondition.column &&
                                !lastStep.condition.if.column?.column1)
                        ) {
                            throw new Error(
                                `please select all fields in the condition step then block`
                            )
                        } else if (
                            (lastStep &&
                                lastStep.condition?.else.conditionStepType ===
                                    excludecondition.arithmetic &&
                                (!lastStep.condition.else.arithmetic?.column1 ||
                                    !lastStep.condition.else.arithmetic
                                        ?.column2 ||
                                    !lastStep.condition.else.arithmetic
                                        ?.operation)) ||
                            (lastStep &&
                                lastStep.condition?.else.conditionStepType ===
                                    excludecondition.aggregate &&
                                (!lastStep.condition.else.aggregate?.column1 ||
                                    !lastStep.condition.else.aggregate
                                        ?.operation)) ||
                            (lastStep &&
                                lastStep.condition?.else.conditionStepType ===
                                    excludecondition.column &&
                                !lastStep.condition.else.column?.column1)
                        ) {
                            throw new Error(
                                `please select all fields in the condition step else block`
                            )
                        }

                        const newStepId =
                            column.steps.length > 0
                                ? column.steps[column.steps.length - 1].stepID +
                                  1
                                : 1

                        const newStep: ICalculationRuleStep = {
                            stepID: newStepId,
                            stepType: addStepType,
                            tempStepName: `Step ${newStepId}`,
                            parentStepID:
                                column?.steps?.length <= 0
                                    ? -1
                                    : column.steps[column.steps.length - 1]
                                          .stepID,
                        }
                        column.steps.push(newStep)
                        return column
                    }
                    return column
                }
            )

            calculationRulesValue?.setColumns(updatedColumns || [])
            setAddStepType(undefined)
            setAddStepClicked(false)
        } catch (e) {
            handleAPIError(e)
        }
    }

    const renderStep = (step: ICalculationRuleStep): JSX.Element | null => {
        if (step.stepType === CalculationRuleStepType.arithmetic) {
            if (calculationRulesValue?.isViewing) {
                return (
                    <ViewCalculationRuleArithmeticStep
                        stepId={step.stepID}
                        columnId={columnID}
                        name={step.tempStepName}
                    />
                )
            }
            return (
                <CalculationRuleArithmeticStep
                    key={step.stepID}
                    stepId={step.stepID}
                    columnId={columnID}
                    name={step.tempStepName}
                    title={getTitle(step.stepID)}
                />
            )
        }
        if (step.stepType === CalculationRuleStepType.aggregate) {
            if (calculationRulesValue?.isViewing) {
                return (
                    <ViewCalculationRuleAggregateStep
                        key={step.stepID}
                        stepId={step.stepID}
                        columnId={columnID}
                        name={step.tempStepName}
                    />
                )
            }
            return (
                <CalculationRuleAggregateStep
                    key={step.stepID}
                    stepId={step.stepID}
                    columnId={columnID}
                    name={step.tempStepName}
                    title={getTitle(step.stepID)}
                />
            )
        }
        if (step.stepType === CalculationRuleStepType.condition) {
            if (calculationRulesValue?.isViewing) {
                return (
                    <ViewCalculationRuleConditionStep
                        stepId={step.stepID}
                        columnId={columnID}
                        name={step.tempStepName}
                    />
                )
            }
            return (
                <CalculationRuleConditionalStep
                    key={step.stepID}
                    stepId={step.stepID}
                    columnId={columnID}
                    name={step.tempStepName}
                    title={getTitle(step.stepID)}
                />
            )
        }
        return null
    }

    return (
        <>
            {calculationRulesValue?.columns
                ?.find?.((item) => item.columnID === columnID)
                ?.steps?.map?.((item) =>
                    // eslint-disable-next-line no-nested-ternary
                    renderStep(item)
                )}
            <div style={{ marginTop: '20px' }}>
                {addStepClicked && (
                    <Space>
                        Choose the type of step:
                        <Select
                            style={{ width: 120 }}
                            onChange={(value: CalculationRuleStepType) => {
                                setAddStepType(value)
                            }}
                        >
                            {getEnumKeys(CalculationRuleStepType).map(
                                (item) => (
                                    <Select.Option key={item} value={item}>
                                        {item}
                                    </Select.Option>
                                )
                            )}
                        </Select>
                        <Button type="primary" onClick={addStep}>
                            Continue
                        </Button>
                        <Button
                            type="dashed"
                            onClick={() => {
                                setAddStepClicked(false)
                                setAddStepType(undefined)
                            }}
                        >
                            Cancel
                        </Button>
                    </Space>
                )}
                {!addStepClicked && !calculationRulesValue?.isViewing && (
                    <Button
                        type="primary"
                        onClick={() => {
                            setAddStepClicked(true)
                        }}
                    >
                        Add Step
                    </Button>
                )}
            </div>
        </>
    )
}

export default CalculationRuleSteps
