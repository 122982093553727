import * as React from 'react'
import ConfigFileTable from './config-file-table'
import { Button, Modal, notification } from 'antd'
import { IConfigFile } from '../../services/config-file/interfaces/config-file.interface'
import { configFileService } from '../../services/config-file/config-file.service'
import { useEffect } from 'react'
import NewConfigFile from './new-config-file'
import { handleAPIError } from '../../utils/catch-error'

function ConfigFile(): JSX.Element {
    const [isModalVisible, setIsModalVisible] = React.useState(false)
    const [configFiles, setConfigFiles] = React.useState<IConfigFile[]>([])
    const [editingConfigFileId, setEditingConfigFileId] =
        React.useState<number>()
    const [createIsLoading, setCreateIsLoading] = React.useState(false)
    const [tableLoading, setTableLoading] = React.useState(false)

    // Config file data
    const [configName, setConfigName] = React.useState('')
    const [fileLocation, setFileLocation] = React.useState('')
    const [allocationRuleGroupId, setAllocationRuleGroupId] =
        React.useState<number>()
    const [sourceDatasetId, setSourceDatasetId] = React.useState<number>()

    const loadConfigFiles = async (): Promise<void> => {
        setTableLoading(true)
        try {
            const tempConfigFiles = await configFileService.getAll()
            setConfigFiles(tempConfigFiles)
        } catch (error: any) {
            handleAPIError(error)
        }
        setTableLoading(false)
    }

    useEffect(() => {
        loadConfigFiles()
    }, [])

    const clearConfigFileData = (): void => {
        setConfigName('')
        setFileLocation('')
        setAllocationRuleGroupId(undefined)
        setSourceDatasetId(undefined)
        setEditingConfigFileId(undefined)
    }

    const handleOk = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): Promise<void> => {
        e.preventDefault()
        if (
            !configName ||
            !fileLocation ||
            !allocationRuleGroupId ||
            !sourceDatasetId
        ) {
            notification.error({
                message: 'Error',
                description: 'Please fill in all fields',
            })
            return
        }

        setCreateIsLoading(true)

        const configFilePayload: IConfigFile = {
            ConfigName: configName,
            ConfigFileLocation: fileLocation,
            AllocationRuleGroupID: allocationRuleGroupId,
            DatasetID: sourceDatasetId,
        }

        try {
            await configFileService.create(configFilePayload)
        } catch (err: any) {
            handleAPIError(err)
        }
        setCreateIsLoading(false)
        setIsModalVisible(false)
        clearConfigFileData()
        await loadConfigFiles()
    }

    const handleCancel = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => {
        e.preventDefault()
        setIsModalVisible(false)
        clearConfigFileData()
    }

    return (
        <>
            <div>
                <div
                    style={{
                        float: 'left',
                        marginLeft: '15px',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}
                >
                    <a>
                        <Button
                            type="primary"
                            onClick={() => {
                                setIsModalVisible(true)
                            }}
                        >
                            Add New File
                        </Button>
                    </a>
                </div>
                <div style={{ margin: '15px' }}>
                    <ConfigFileTable
                        configFiles={configFiles}
                        loading={tableLoading}
                    />
                </div>
            </div>
            <Modal
                title={
                    editingConfigFileId
                        ? `Editing ${
                              configFiles?.find(
                                  (item) =>
                                      item.ConfigFileID === editingConfigFileId
                              )?.ConfigName
                          } Config File`
                        : 'Create New Config File'
                }
                visible={isModalVisible}
                onOk={async (e) => {
                    await handleOk(e)
                }}
                confirmLoading={createIsLoading}
                onCancel={(e) => handleCancel(e)}
                destroyOnClose
            >
                <NewConfigFile
                    setConfigName={setConfigName}
                    configName={configName}
                    // fileLocation={fileLocation}
                    setFileLocation={setFileLocation}
                    allocationRuleGroupId={allocationRuleGroupId}
                    setAllocationRuleGroupId={setAllocationRuleGroupId}
                    sourceDatasetId={sourceDatasetId}
                    setSourceDatasetId={setSourceDatasetId}
                />
            </Modal>
        </>
    )
}

export default ConfigFile
