import { AxiosX } from '../../utils/axios'
import { IUsers } from './interfaces/users.interface'

export const usersService = {
    getAll: async (): Promise<IUsers[]> => {
        const data = await AxiosX.get<IUsers[]>('v1/user/getAll')
        return data.data
    },

    create: async (data: IUsers): Promise<IUsers> => {
        const response = await AxiosX.post<IUsers>(`v1/user/create`, data)

        return response.data
    },

    updateById: async (id: number, data: IUsers): Promise<void> => {
        const response = await AxiosX.put<void>(`v1/team/update/${id}`, data)

        return response.data
    },
}
