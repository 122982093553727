export enum JoinTypesEnum {
    INNER = 'INNER',
    OUTER = 'OUTER',
    LEFT = 'LEFT',
    LEFTOUTER = 'LEFTOUTER',
    RIGHT = 'RIGHT',
    RIGHTOUTER = 'RIGHTOUTER',
    FULLOUTER = 'FULLOUTER',
    CROSS = 'CROSS',
}
