import * as React from 'react'
import { useEffect } from 'react'
import { Table, Tag } from 'antd'
import { CloudOutlined } from '@ant-design/icons'
import {
    ISourceFileInfo,
    SourceFileStatus,
    SourceFileStatusMapping,
} from '../../../services/source-group/interfaces/source-file-info.interface'
import { sourceFileInfoService } from '../../../services/source-group/source-file-info.service'
import { getColumnSearchProps } from '../../../utils/columnsearch-props'
import moment from 'moment'
import { handleAPIError } from '../../../utils/catch-error'
import SourceFileDrawer from './source-file-drawer'
import PreviewTable from '../../preview-table/preview-table'
import { FileUploadService } from '../../../services/file-upload/file-upload.service'
import { Link } from 'react-router-dom'
import { routeMapMini } from '../../../constants/routeMap'

function SourceFileTable({
    groupId,
    refresh,
}: {
    groupId: number | undefined
    refresh: boolean
}): JSX.Element {
    const [sourceFiles, setSourceFiles] = React.useState<
        ISourceFileInfo[] | undefined
    >(undefined)
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState('')
    const [drawerVisible, setDrawerVisible] = React.useState(false)
    const [drawerProperties, setDrawerProperties] = React.useState<
        | {
              title: string
              status: string
              sourceFileID: number
          }
        | undefined
    >(undefined)
    const [previewVisible, setPreviewVisible] = React.useState(false)
    const [previewProps, setPreviewProps] = React.useState<{
        title: string
        data: any
    }>({
        title: '',
        data: [],
    })

    useEffect(() => {
        setLoading(true)
        if (groupId) {
            sourceFileInfoService
                .getAllForDataset(groupId)
                .then((res) => {
                    setSourceFiles(res)
                })
                .catch(handleAPIError)
        } else {
            setSourceFiles([])
        }
        setLoading(false)
    }, [groupId, refresh])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'SourceFileID',
            key: 'SourceFileID',
            sorter: (a: ISourceFileInfo, b: ISourceFileInfo) => {
                return (a.SourceFileID || 1) > (b.SourceFileID || 1) ? 1 : -1
            },
        },
        {
            title: 'SourceName',
            dataIndex: 'SourceName',
            key: 'SourceFileID',
            ...getColumnSearchProps('SourceName', searchText, setSearchText),
        },
        {
            title: 'Stage Status',
            dataIndex: 'StageStatus',
            key: 'SourceFileID',
            render: (status: SourceFileStatus) => {
                const text = SourceFileStatusMapping[status]?.message || status
                if (status === SourceFileStatus.validated) {
                    return <Tag color="green">{text}</Tag>
                }
                if (status === SourceFileStatus.pendingProfiling) {
                    return <Tag color="orange">{text}</Tag>
                }
                return <Tag color="red">{status}</Tag>
            },
        },
        {
            title: 'File',
            dataIndex: 'SourceFileLocation',
            key: 'SourceFileID',
            render: () => (
                <a>
                    <CloudOutlined />
                </a>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'Auditdatetime',
            key: 'RunId',
            render: (text: string) => {
                return moment(text).format('YYYY-MM-DD HH:mm')
            },
            sorter: (a: ISourceFileInfo, b: ISourceFileInfo) => {
                return (
                    moment(a.Auditdatetime).valueOf() -
                    moment(b.Auditdatetime).valueOf()
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'RunId',
            ellipsis: true,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: unknown, record: ISourceFileInfo): JSX.Element => (
                <>
                    {record.RunID && (
                        <>
                            <Link
                                to={`${routeMapMini.topLevelLineage}?sourceFileId=${record.SourceFileID}&datasetId=${record.DatasetID}`}
                                target="_blank"
                            >
                                Lineage
                            </Link>
                            |
                        </>
                    )}
                    <a
                        role="none"
                        onClick={() => {
                            setDrawerVisible(true)
                            setDrawerProperties({
                                title: record.SourceName,
                                status: record.StageStatus || '',
                                sourceFileID: record.SourceFileID || 0,
                            })
                        }}
                        style={{ padding: '0px 2px' }}
                    >
                        View
                    </a>
                    |
                    <a
                        role="none"
                        onClick={async () => {
                            if (record.UploadedFileID) {
                                const fileData =
                                    await FileUploadService.getFileById(
                                        record.UploadedFileID
                                    )
                                if (fileData) {
                                    setPreviewVisible(true)
                                    setPreviewProps({
                                        title: `Preview of source - ${record.SourceName}`,
                                        data: fileData.FilePreviewData,
                                    })
                                }
                            }
                        }}
                        style={{ padding: '0px 2px' }}
                    >
                        Preview
                    </a>
                </>
            ),
        },
    ]

    return (
        <>
            <Table
                columns={columns}
                dataSource={sourceFiles}
                loading={loading}
            />
            {drawerProperties && (
                <SourceFileDrawer
                    sourceFileID={drawerProperties.sourceFileID}
                    visible={drawerVisible}
                    setVisible={setDrawerVisible}
                    StageStatus={drawerProperties.status}
                    title={drawerProperties.title}
                />
            )}
            <PreviewTable
                visible={previewVisible}
                onCancel={() => {
                    setPreviewVisible(false)
                }}
                onOk={() => {
                    setPreviewVisible(false)
                }}
                title={previewProps.title}
                data={previewProps.data}
            />
        </>
    )
}

export default SourceFileTable
