import { Drawer, Table } from 'antd'
import React from 'react'
import { IMergeSelectorsResponse } from '../../services/merge-rule/interfaces/merge-rule-response.interface'
import { mergeRuleService } from '../../services/merge-rule/merge-rule.service'
import { handleAPIError } from '../../utils/catch-error'

type IProps = {
    MergeRuleID: number | undefined
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    title: string | undefined
}

function MergeColumnDrawer({
    MergeRuleID,
    visible,
    setVisible,
    title,
}: IProps): JSX.Element {
    const [loading, setLoading] = React.useState(false)
    const [mergeSelectors, setMergeSelectors] = React.useState<
        IMergeSelectorsResponse[]
    >([])

    React.useEffect(() => {
        setLoading(true)
        if (MergeRuleID) {
            mergeRuleService
                .getSelectors(MergeRuleID)
                .then(setMergeSelectors)
                .catch(handleAPIError)
        }
        setLoading(false)
    }, [MergeRuleID])

    return (
        <Drawer
            visible={visible}
            title={title}
            onClose={() => {
                setVisible(false)
            }}
            placement="right"
            width="500"
        >
            {mergeSelectors.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <b style={{ fontSize: '16px' }}>Selectors</b>
                    <Table
                        columns={[
                            {
                                title: 'Left Column Name',
                                dataIndex: 'LeftColumnName',
                                key: 'LeftColumnName',
                            },
                            {
                                title: 'Right ColumnName',
                                dataIndex: 'RightColumnName',
                                key: 'RightColumnName',
                            },
                            {
                                title: 'Alias',
                                dataIndex: 'Alias',
                                key: 'Alias',
                            },
                        ]}
                        dataSource={mergeSelectors.map((item) => {
                            return {
                                LeftColumnName: item.LeftColumnName,
                                RightColumnName: item.RightColumnName,
                                Alias: item.Alias,
                            }
                        })}
                        loading={loading}
                        pagination={false}
                        scroll={{ x: true }}
                    />
                </div>
            )}
        </Drawer>
    )
}

export default MergeColumnDrawer
