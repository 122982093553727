import { AxiosError } from 'axios'
import { notification } from 'antd'
import * as Sentry from '@sentry/react'

/*
this function will show a notification with details for the user
*/
export const handleAPIError = (
    error: Error | AxiosError<{ message: string; statusCode: number }> | unknown
): void => {
    if (error instanceof AxiosError) {
        if (error.response?.status !== 400 && error.response?.status !== 401) {
            Sentry.captureException(error)
        } else {
            Sentry.captureMessage(error.response?.data?.message, 'log')
        }
        notification.error({
            message: 'Error',
            description: error.response?.data?.message || error?.message,
        })
    } else if (error instanceof Error) {
        Sentry.captureException(error)
        notification.error({
            message: 'Error',
            description: error?.message,
        })
    } else {
        Sentry.captureException(error)
        notification.error({
            message: 'Error',
            description: 'Unknown Error',
        })
    }
}
