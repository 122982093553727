import React from 'react'
import { Button, Col, Row, Select } from 'antd'
import { sourceDatasetService } from '../../services/source-group/source-dataset.service'
import { ISourceDataset } from '../../services/source-group/interfaces/source-dataset.interface'
import { IJoinRuleRequest } from '../../services/join-rule/interfaces/join-rule-request.interface'
import { DeleteTwoTone } from '@ant-design/icons'

type IProps = {
    selectedLeftDataset: ISourceDataset | undefined
    joinConditions: (IJoinRuleRequest['JoinConditions'][0] & { id?: number })[]
    setJoinConditions: React.Dispatch<
        React.SetStateAction<
            (IJoinRuleRequest['JoinConditions'][0] & { id?: number })[]
        >
    >
    selectedRightDataset: ISourceDataset | undefined
}

function NewJoinRuleConditions({
    joinConditions,
    setJoinConditions,
    selectedRightDataset,
    selectedLeftDataset,
}: IProps): JSX.Element {
    return (
        <>
            {joinConditions.map((joinCondition, index) => {
                return (
                    <>
                        <Row
                            style={{ margin: '10px' }}
                            key={`${joinCondition.leftColumn}_${joinCondition.rightColumn}`}
                        >
                            <Col span={3}>ON</Col>
                            <Col span={4}>
                                <Select
                                    style={{ minWidth: '200px' }}
                                    disabled={!selectedLeftDataset}
                                    onChange={(type: string) => {
                                        const tempJoinConditions = [
                                            ...joinConditions,
                                        ]
                                        tempJoinConditions[index].leftColumn =
                                            type
                                        setJoinConditions(tempJoinConditions)
                                    }}
                                    value={joinCondition.leftColumn}
                                >
                                    {sourceDatasetService
                                        .getDatasetColumnNames(
                                            selectedLeftDataset
                                        )
                                        .map((column) => {
                                            return (
                                                <Select.Option
                                                    key={column}
                                                    value={column}
                                                >
                                                    {column}
                                                </Select.Option>
                                            )
                                        })}
                                </Select>
                            </Col>
                            <Col span={3} style={{ textAlign: 'center' }}>
                                =
                            </Col>
                            <Col span={2} />
                            <Col span={4}>
                                <Select
                                    style={{ minWidth: '200px' }}
                                    disabled={!selectedRightDataset}
                                    onChange={(type: string) => {
                                        const tempJoinConditions = [
                                            ...joinConditions,
                                        ]
                                        tempJoinConditions[index].rightColumn =
                                            type
                                        setJoinConditions(tempJoinConditions)
                                    }}
                                    value={joinCondition.rightColumn}
                                >
                                    {sourceDatasetService
                                        .getDatasetColumnNames(
                                            selectedRightDataset
                                        )
                                        .map((column) => {
                                            return (
                                                <Select.Option
                                                    key={column}
                                                    value={column}
                                                >
                                                    {column}
                                                </Select.Option>
                                            )
                                        })}
                                </Select>
                            </Col>
                            <Col style={index === 0 ? { display: 'none' } : {}}>
                                <DeleteTwoTone
                                    twoToneColor="red"
                                    onClick={() => {
                                        const tempConditions =
                                            joinConditions.filter((item) => {
                                                return !(
                                                    item.id === joinCondition.id
                                                )
                                            })
                                        if (!tempConditions.length) {
                                            tempConditions.push({
                                                leftColumn: '',
                                                rightColumn: '',
                                            })
                                        }
                                        setJoinConditions(tempConditions)
                                    }}
                                />
                            </Col>
                        </Row>
                        {joinConditions.length - 1 !== index && <Row>And</Row>}
                    </>
                )
            })}
            <Button
                type="primary"
                disabled={
                    !selectedRightDataset ||
                    !selectedLeftDataset ||
                    !joinConditions[joinConditions.length - 1].leftColumn ||
                    !joinConditions[joinConditions.length - 1].rightColumn
                }
                size="small"
                onClick={() => {
                    const newId =
                        joinConditions.length > 0
                            ? (joinConditions?.[joinConditions.length - 1]
                                  ?.id || 0) + 1
                            : 1

                    setJoinConditions([
                        ...joinConditions,
                        {
                            id: newId,
                            leftColumn: '',
                            rightColumn: '',
                        },
                    ])
                }}
            >
                Add Condition
            </Button>
        </>
    )
}

export default NewJoinRuleConditions
