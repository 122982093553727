import {
    ISourceDataset,
    ISourceDatasetRequest,
} from './interfaces/source-dataset.interface'
import { AxiosX } from '../../utils/axios'
import * as Sentry from '@sentry/react'
import { parseJson } from '../../utils/parse.util'

export const sourceDatasetService = {
    getDatasetsForGroup: async (
        sourceGroupId: number
    ): Promise<ISourceDataset[]> => {
        const resp = await AxiosX.get<ISourceDataset[]>(
            `/v1/source/dataset/for-group/${sourceGroupId}`
        )

        return resp.data
    },

    create: async (payload: ISourceDatasetRequest): Promise<ISourceDataset> => {
        const resp = await AxiosX.post<ISourceDataset>(
            `/v1/source/dataset/create`,
            payload
        )

        return resp.data
    },

    getDatasetColumnNames(dataset: ISourceDataset | undefined): string[] {
        if (!dataset) {
            return []
        }
        try {
            return Object.keys(parseJson(dataset?.DatasetMetadata))
        } catch (error) {
            Sentry.captureException(error)
            return []
        }
    },

    getDatasetColumnNamesForId: async (
        datasetID: number
    ): Promise<string[]> => {
        const resp = await AxiosX.get<ISourceDataset>(
            `/v1/source/dataset/by-id/${datasetID}`
        )

        return sourceDatasetService.getDatasetColumnNames(resp.data)
    },
}
