import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Divider,
    Input,
    notification,
    Row,
    Select,
    Typography,
} from 'antd'
import { ISourceGroup } from '../../services/source-group/interfaces/source-group.interface'
import { ISourceDataset } from '../../services/source-group/interfaces/source-dataset.interface'
import { sourceDatasetService } from '../../services/source-group/source-dataset.service'
import { handleAPIError } from '../../utils/catch-error'
import { sourceGroupService } from '../../services/source-group/source-group.service'
import MergeSelectors from './merge-selectors'
import { routeMapMini } from '../../constants/routeMap'
import { useNavigate } from 'react-router-dom'
import { mergeRuleService } from '../../services/merge-rule/merge-rule.service'

function NewMergeRule(): JSX.Element {
    const navigate = useNavigate()
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [mergeRuleName, setMergeRuleName] = useState<string>('')
    const [selectedSourceGroup, setSelectedSourceGroup] =
        useState<ISourceGroup>()
    const [sourceGroups, setSourceGroups] = useState<ISourceGroup[]>([])
    const [selectedLeftDataset, setSelectedLeftDataset] =
        useState<ISourceDataset>()
    const [selectedRightDataset, setSelectedRightDataset] =
        useState<ISourceDataset>()
    const [datasets, setDatasets] = useState<ISourceDataset[]>([])
    const [selectedColumns, setSelectedColumns] = useState<
        {
            id: number
            leftColumnName: string
            rightColumnName: string
            alias: string
        }[]
    >([])

    useEffect(() => {
        sourceGroupService.getAll().then(setSourceGroups).catch(handleAPIError)
    }, [])

    useEffect(() => {
        setSelectedRightDataset(undefined)
        setSelectedLeftDataset(undefined)
        sourceDatasetService
            .getDatasetsForGroup(selectedSourceGroup?.SourceGroupID || 0)
            .then(setDatasets)
            .catch(handleAPIError)
    }, [selectedSourceGroup])

    const clearState = (): void => {
        setSelectedLeftDataset(undefined)
        setSelectedRightDataset(undefined)
        setSelectedColumns([])
        setMergeRuleName('')
        setSelectedSourceGroup(undefined)
    }

    const saveRule = async (): Promise<void> => {
        selectedColumns.map((item) => {
            if (
                item.alias === '' ||
                item.leftColumnName === '' ||
                item.rightColumnName === ''
            ) {
                notification.error({
                    message: 'Error',
                    description: 'Please fill all details',
                })
            }
            return undefined
        })
        if (!selectedSourceGroup) {
            notification.error({
                message: 'Error',
                description: 'Please select a data group',
            })
            return
        }
        if (!selectedLeftDataset || !selectedRightDataset) {
            notification.error({
                message: 'Error',
                description: 'Please select both datasets',
            })
            return
        }
        setSaveLoading(false)
        try {
            await mergeRuleService.createJoinRule({
                MergeRuleName: mergeRuleName,
                LeftDatasetID: selectedLeftDataset.DatasetID || -1,
                RightDatasetID: selectedRightDataset.DatasetID || -1,
                MergeSelectors: [
                    ...selectedColumns.map((item) => ({
                        LeftColumnName: item.leftColumnName,
                        RightColumnName: item.rightColumnName,
                        Alias: item.alias,
                    })),
                ],
                SourceGroupID: selectedSourceGroup?.SourceGroupID || -1,
            })
            notification.success({
                message: 'Merge Rule Saved successfully',
            })
            clearState()
            navigate(routeMapMini.rulesMerge, { replace: true })
        } catch (e) {
            handleAPIError(e)
        }
        setSaveLoading(false)
    }

    return (
        <div style={{ padding: '10px' }}>
            <Typography.Title level={3}>Merge Rule</Typography.Title>
            <Row style={{ margin: '10px' }}>
                <Col span={3}> Merge Name </Col>
                <Col span={4}>
                    <Input
                        style={{ maxWidth: '200px' }}
                        value={mergeRuleName}
                        onChange={(e) => {
                            setMergeRuleName(e.target.value)
                        }}
                    />
                </Col>
                <Col span={2} />
                <Col span={3}> Data Group </Col>
                <Col span={4}>
                    <Select
                        style={{ minWidth: '200px' }}
                        onChange={(id: number) => {
                            setSelectedSourceGroup(
                                sourceGroups.find(
                                    (group) => group.SourceGroupID === id
                                )
                            )
                            setSelectedLeftDataset(undefined)
                            setSelectedRightDataset(undefined)
                        }}
                        value={selectedSourceGroup?.SourceGroupID}
                    >
                        {sourceGroups.map((group) => {
                            return (
                                <Select.Option
                                    key={group.SourceGroupID}
                                    value={group.SourceGroupID}
                                >
                                    {group.SourceGroupName}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Col>
            </Row>
            <Row style={{ margin: '10px' }}>
                <Col span={3}> Left Dataset </Col>
                <Col span={4}>
                    <Select
                        style={{ minWidth: '200px' }}
                        value={selectedLeftDataset?.DatasetID}
                        disabled={!selectedSourceGroup}
                        onChange={(id: number) => {
                            setSelectedLeftDataset(
                                datasets.find(
                                    (dataset) => dataset.DatasetID === id
                                )
                            )
                        }}
                    >
                        {datasets
                            .filter(
                                (dataset) =>
                                    selectedRightDataset?.DatasetID !==
                                    dataset.DatasetID
                            )
                            .map((dataset) => {
                                return (
                                    <Select.Option
                                        key={dataset.DatasetID}
                                        value={dataset.DatasetID}
                                    >
                                        {dataset.DatasetName}
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Col>
                <Col span={2} />
                <Col span={3}> Right Dataset </Col>
                <Col span={4}>
                    <Select
                        style={{ minWidth: '200px' }}
                        value={selectedRightDataset?.DatasetID}
                        disabled={!selectedSourceGroup}
                        onChange={(id: number) => {
                            setSelectedRightDataset(
                                datasets.find(
                                    (dataset) => dataset.DatasetID === id
                                )
                            )
                        }}
                    >
                        {datasets
                            .filter(
                                (dataset) =>
                                    selectedLeftDataset?.DatasetID !==
                                    dataset.DatasetID
                            )
                            .map((dataset) => {
                                return (
                                    <Select.Option
                                        key={dataset.DatasetID}
                                        value={dataset.DatasetID}
                                    >
                                        {dataset.DatasetName}
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Col>
            </Row>
            {selectedRightDataset && selectedLeftDataset && (
                <>
                    <Divider />
                    <MergeSelectors
                        columns={{
                            leftColumns:
                                sourceDatasetService.getDatasetColumnNames(
                                    selectedLeftDataset
                                ),
                            rightColumns:
                                sourceDatasetService.getDatasetColumnNames(
                                    selectedRightDataset
                                ),
                        }}
                        selectedColumns={selectedColumns}
                        setSelectedColumns={setSelectedColumns}
                    />
                </>
            )}
            <Button
                type="primary"
                disabled={
                    !selectedRightDataset ||
                    !selectedLeftDataset ||
                    selectedColumns.some((item) => {
                        if (
                            item.alias === '' ||
                            item.leftColumnName === '' ||
                            item.rightColumnName === ''
                        ) {
                            return true
                        }
                        return false
                    })
                }
                loading={saveLoading}
                onClick={saveRule}
            >
                Save Rule
            </Button>
        </div>
    )
}

export default NewMergeRule
