import * as React from 'react'
import { Button } from 'antd'
import AllocationRuleGroupTable from './allocation-rule-group-table'
import { IAllocationRuleGroup } from '../../services/allocation-rule/interfaces/allocation-rule-group.interface'
import { useEffect } from 'react'
import { allocationRuleGroupService } from '../../services/allocation-rule/allocation-rule-group.service'
import { handleAPIError } from '../../utils/catch-error'
import { Link } from 'react-router-dom'
import { routeMapMini } from '../../constants/routeMap'

function AllocationRuleGroup(): JSX.Element {
    const [allocationRuleGroup, setAllocationRuleGroup] = React.useState<
        IAllocationRuleGroup[]
    >([])
    const [tableLoading, setTableLoading] = React.useState(false)

    const getAllocationRuleGroups = async (): Promise<void> => {
        setTableLoading(true)
        try {
            const allocationRuleGroups =
                await allocationRuleGroupService.getAll()
            setAllocationRuleGroup(allocationRuleGroups)
        } catch (e: any) {
            handleAPIError(e)
        }
        setTableLoading(false)
    }

    useEffect(() => {
        getAllocationRuleGroups()
    }, [])

    return (
        <div>
            <div
                style={{
                    float: 'left',
                    marginLeft: '15px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            >
                <a>
                    <Link to={routeMapMini.rulesAllocationNew}>
                        <Button
                            type="primary"
                            // onClick={() => {
                            //     setIsModalVisible(true)
                            // }}
                        >
                            Create New Group
                        </Button>
                    </Link>
                </a>
            </div>
            <div style={{ margin: '15px' }}>
                <AllocationRuleGroupTable
                    allocationRuleGroup={allocationRuleGroup}
                    loading={tableLoading}
                />
            </div>
        </div>
    )
}

export default AllocationRuleGroup
