import { AxiosX } from '../../utils/axios'
import { ISourceGroup } from './interfaces/source-group.interface'

export const sourceGroupService = {
    getAll: async (): Promise<ISourceGroup[]> => {
        const data = await AxiosX.get<ISourceGroup[]>('v1/source/group/all')

        return data.data
    },

    getById: async (id: number): Promise<ISourceGroup> => {
        const data = await AxiosX.get<ISourceGroup>(
            `v1/source/group/by-id/${id}`
        )

        return data.data
    },

    updateById: async (id: number, data: ISourceGroup): Promise<void> => {
        const response = await AxiosX.put<void>(
            `v1/source/group/by-id/${id}`,
            data
        )

        return response.data
    },

    create: async (data: ISourceGroup): Promise<ISourceGroup> => {
        const response = await AxiosX.post<ISourceGroup>(
            'v1/source/group/create',
            data
        )

        return response.data
    },

    deleteById: async (id: number): Promise<void> => {
        const response = await AxiosX.delete<void>(
            `v1/source/group/by-id/${id}`
        )

        return response.data
    },
}
