import { Drawer, Table } from 'antd'
import React from 'react'
import { joinRuleService } from '../../services/join-rule/join-rule.service'
import { handleAPIError } from '../../utils/catch-error'
import {
    IJoinConditionResponse,
    IJoinSelectorsResponse,
} from '../../services/join-rule/interfaces/join-rule-response.interface'

type IProps = {
    joinRuleID: number | undefined
    LeftDatasetName: string | undefined
    RightDatasetName: string | undefined
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    title: string | undefined
}

function JoinColumnDrawer({
    joinRuleID,
    LeftDatasetName,
    RightDatasetName,
    visible,
    setVisible,
    title,
}: IProps): JSX.Element {
    const [loading, setLoading] = React.useState(false)
    const [joinSelectors, setJoinSelectors] = React.useState<
        IJoinSelectorsResponse[]
    >([])
    const [joinConditions, setJoinConditions] = React.useState<
        IJoinConditionResponse[]
    >([])

    React.useEffect(() => {
        setLoading(true)
        if (joinRuleID) {
            joinRuleService
                .getSelectors(joinRuleID)
                .then(setJoinSelectors)
                .catch(handleAPIError)
            joinRuleService
                .getConditions(joinRuleID)
                .then(setJoinConditions)
                .catch(handleAPIError)
        }
        setLoading(false)
    }, [joinRuleID])

    return (
        <Drawer
            visible={visible}
            title={title}
            onClose={() => {
                setVisible(false)
            }}
            placement="right"
            width="500"
        >
            {joinConditions.length > 0 && (
                <div>
                    <b style={{ fontSize: '16px' }}>Conditions</b>
                    <Table
                        columns={[
                            {
                                title: `${LeftDatasetName} Column`,
                                dataIndex: 'LeftColumn',
                                key: 'LeftColumn',
                            },
                            {
                                title: `${RightDatasetName} Column`,
                                dataIndex: 'RightColumn',
                                key: 'RightColumn',
                            },
                        ]}
                        dataSource={joinConditions}
                        loading={loading}
                        pagination={false}
                        scroll={{ x: true }}
                    />
                </div>
            )}
            {joinSelectors.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <b style={{ fontSize: '16px' }}>Selectors</b>
                    <Table
                        columns={[
                            {
                                title: 'Dataset Name',
                                dataIndex: 'SelectorSide',
                                key: 'SelectorSide',
                            },
                            {
                                title: 'Column',
                                dataIndex: 'ColumnName',
                                key: 'Column',
                            },
                            {
                                title: 'Alias',
                                dataIndex: 'Alias',
                                key: 'Alias',
                            },
                        ]}
                        dataSource={joinSelectors.map((item) => {
                            let selectorDataset: string | undefined =
                                item.SelectorSide

                            if (item.SelectorSide === 'left') {
                                selectorDataset = LeftDatasetName
                            } else {
                                selectorDataset = RightDatasetName
                            }

                            return {
                                SelectorSide: selectorDataset,
                                ColumnName: item.ColumnName,
                                Alias: item.Alias,
                            }
                        })}
                        loading={loading}
                        pagination={false}
                        scroll={{ x: true }}
                    />
                </div>
            )}
        </Drawer>
    )
}

export default JoinColumnDrawer
