/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import { Pie } from '@ant-design/plots'
import { IRunHistoryCount } from '../../services/run/interfaces/run-history.interface'
import { runService } from '../../services/run/run.service'
import { handleAPIError } from '../../utils/catch-error'
import moment from 'moment'
import { Empty } from 'antd'

function DemoPie({
    fromDate,
    toDate,
}: {
    fromDate: moment.Moment
    toDate: moment.Moment
}): JSX.Element {
    const [runHistoryData, setRunHistoryData] = React.useState<
        IRunHistoryCount[]
    >([])
    const [noData, setNoData] = React.useState<boolean>(false)
    useEffect(() => {
        runService
            .getAllRunsCountByUserId({ fromDate, toDate })
            .then((res) => {
                if (res.length !== 0) {
                    setRunHistoryData(res)
                    setNoData(false)
                } else {
                    setNoData(true)
                }
            })
            .catch((err) => {
                handleAPIError(err)
            })
    }, [fromDate, toDate])
    const data = runHistoryData.map((item: any) => {
        return {
            type: item.RunType,
            value: item.count,
        }
    })
    const config: any = {
        appendPadding: 5,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.85,
        label: {
            type: 'inner',
            offset: '-30%',
            content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
            style: {
                fontSize: 10,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    }
    return (
        <>
            {noData && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {!noData && <Pie style={{ margin: '-75px 0' }} {...config} />}
        </>
    )
}
export default DemoPie
