export enum SourceFileStatus {
    created = 'created',
    pendingProfiling = 'pendingProfiling',
    // priorityValidated = 'priorityValidated',
    validating = 'validating',
    validated = 'validated',
}

export const SourceFileStatusMapping: Record<
    SourceFileStatus | string,
    { message: string; value: number }
> = {
    created: { message: 'File Uploaded', value: 0 },
    pendingProfiling: { message: 'Pre Processing', value: 1 },
    priorityValidated: { message: 'Validating', value: 2 },
    validating: { message: 'Validating', value: 3 },
    validated: { message: 'Completed', value: 4 },
}

export interface ISourceFileInfo {
    SourceFileID?: number
    SourceName: string
    DatasetID: number // ref to SourceDataset
    SourceFileLocation: string
    SourceMetadata: string
    Auditdatetime?: Date
    Stage?: 'RAW' | 'cleanse' | 'transform'
    StageStatus?: SourceFileStatus
    DeltaLakeFileID?: number
    UploadedFileID?: number
    RunID?: number
}

export interface ISourceFileInfoRequest {
    SourceName: string
    DatasetID: number // ref to SourceDataset
    SourceMetadata: string
    priorityValidators: { CalculationRuleID: number }[]
    FileName: string
    FileLocation: string
    FilePreviewData: any[]
    FileType: string
}
