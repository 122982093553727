import { Space, Table } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { routeMapMini } from '../../constants/routeMap'
import { ITeams } from '../../services/user-management/interfaces/teams.interface'
import { ITeamUsers } from '../../services/user-management/interfaces/teamusers.interface'
import { getColumnSearchProps } from '../../utils/columnsearch-props'

function TeamsTable({
    teams,
    loading,
}: {
    teams: ITeams[] | undefined
    loading: boolean
}): JSX.Element {
    const [searchText, setSearchText] = React.useState('')

    const columns = [
        {
            title: 'Team ID',
            dataIndex: 'TeamID',
            key: 'TeamID',
            sorter: (a: ITeams, b: ITeams) => {
                return (a.TeamID || 1) > (b.TeamID || 1) ? 1 : -1
            },
        },
        {
            title: 'Team Name',
            dataIndex: 'TeamName',
            key: 'TeamID',
            ...getColumnSearchProps('TeamName', searchText, setSearchText),
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'TeamId',
            ellipsis: true,
        },
        {
            title: 'Managed By',
            dataIndex: 'UserName',
            key: 'TeamId',
            ellipsis: true,
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'TeamID',
            render: (_: string, record: ITeamUsers) => (
                <Space>
                    <Link
                        to={`${routeMapMini.teamUsers}?teamId=${record.TeamID}`}
                    >
                        manage users
                    </Link>
                    | <a>edit</a>
                </Space>
            ),
        },
    ]

    return (
        <Table
            columns={columns}
            dataSource={teams}
            loading={loading}
            style={{ padding: '0 15px 0 7.5px' }}
        />
    )
}

export default TeamsTable
