import * as React from 'react'
import { Input, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
// @ts-ignore
import Highlighter from 'react-highlight-words'

export const getColumnSearchProps = (
    dataIndex: string,
    searchText: string,
    setSearchText: React.Dispatch<React.SetStateAction<string>>
): any => {
    const handleSearch = (selectedKeys: string[], confirm: any): void => {
        confirm()
        setSearchText(selectedKeys[0])
    }

    const handleReset = (clearFilters: any, confirm: any): void => {
        clearFilters()
        setSearchText('')
        confirm()
    }

    return {
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    // ref={node => {
                    //   this.searchInput = node;
                    // }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters, confirm)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value: string, record: any) =>
            record[dataIndex]
                ?.toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                // setTimeout(() => this.searchInput.select());
            }
        },
        render: (text: string) => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text?.toString()}
            />
        ),
    }
}
