import { Breadcrumb } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from './withRouter'
import { breadCrumb } from '../constants/breadCrumb'
import { routeMapMini } from '../constants/routeMap'

function BreadCrumb({ router }: any): JSX.Element {
    const [path, setPath] = useState<routeMapMini>(routeMapMini.Login)
    const [queryParams, setQueryParams] = useState<any>({})

    useEffect(() => {
        setQueryParams(router.location.search)
    }, [router.location.search])

    useEffect(() => {
        setPath(router?.location?.pathname)
    }, [router?.location?.pathname])

    return (
        <Breadcrumb>
            {breadCrumb[path]?.map((item) => {
                return (
                    <Breadcrumb.Item key={item.path}>
                        <Link to={`${item.path}${queryParams}`}>
                            {item.name}
                        </Link>
                    </Breadcrumb.Item>
                )
            })}
        </Breadcrumb>
    )
}

export default withRouter(BreadCrumb)
