/* eslint-disable no-unused-vars */
import { ICalculationRuleArithmeticStep } from '../../../components/calculation-rules/calculation-rule-arithmetic-step'
import { ICalculationRuleAggregateStep } from '../../../components/calculation-rules/calculation-rule-aggregate-step'
import { ICalculationRuleConditionStep } from '../../../components/calculation-rules/calculation-rule-conditional-step'

export enum ArithmeticOperations {
    Addition = 'Addition',
    Subtraction = 'Subtraction',
    Multiplication = 'Multiplication',
    Division = 'Division',
    Modulo = 'Modulo',
}

export enum CalAggregateOperations {
    Sum = 'Sum',
    Average = 'Average',
    Min = 'Min',
    Max = 'Max',
}

export enum CalConditionalOperations {
    '<' = '<',
    '>' = '>',
    '=' = '=',
    '!=' = '!=',
}

export const ArithmeticSymbolMapping: Record<ArithmeticOperations, string> = {
    Addition: '+',
    Subtraction: '-',
    Multiplication: '*',
    Division: '/',
    Modulo: '%',
}

export enum CalColumnTypes {
    dataset = 'dataset',
    constant = 'constant',
    sibling_step = 'sibling_step',
    sibling_column = 'sibling_column',
}

export enum CalculationRuleStepType {
    arithmetic = 'arithmetic',
    condition = 'condition',
    aggregate = 'aggregate',
}

export enum excludecondition {
    arithmetic = 'arithmetic',
    aggregate = 'aggregate',
    column = 'column',
}

export interface IServiceCalculationRule {
    calculationRuleName: string
    datasetID: number
    columns: {
        columnID: number
        columnName: string
        steps: {
            stepID: number
            parentStepID: number
            stepType: CalculationRuleStepType
            tempStepName: string
            arithmetic?: ICalculationRuleArithmeticStep
            aggregate?: ICalculationRuleAggregateStep
            condition?: ICalculationRuleConditionStep
        }[]
    }[]
}
