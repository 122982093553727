import React, { useEffect, useMemo } from 'react'
import { Drawer, Space, Timeline } from 'antd'
import {
    CheckCircleTwoTone,
    ClockCircleOutlined,
    ClockCircleTwoTone,
    CloseCircleTwoTone,
} from '@ant-design/icons'
import { getEnumKeys } from '../../../utils/enum'
import {
    SourceFileStatus,
    SourceFileStatusMapping,
} from '../../../services/source-group/interfaces/source-file-info.interface'
import { sourceFileInfoService } from '../../../services/source-group/source-file-info.service'
import { handleAPIError } from '../../../utils/catch-error'

type IProps = {
    sourceFileID: number
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    title: string | undefined
    StageStatus: string
}

export type IValidations = {
    IsValid: boolean
    IsValidated: boolean
    CalculationRuleName: string
    ErrorDescriptions: string
    WarnDescriptions: string
}

function SourceFileDrawer({
    sourceFileID,
    StageStatus,
    visible,
    setVisible,
    title,
}: IProps): JSX.Element {
    const [validations, setValidations] = React.useState<IValidations[]>([])

    useEffect(() => {
        sourceFileInfoService
            .getDataProfileValidations(sourceFileID)
            .then((data) => {
                setValidations(data)
            })
            .catch(handleAPIError)
    }, [sourceFileID])

    const validationsList = useMemo(() => {
        return (
            <div>
                <i>
                    Calculation Rules (
                    {validations.filter((item) => item.IsValidated).length}/
                    {validations.length} )
                </i>
                {validations.map((validation) => {
                    return (
                        <div>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {!validation.IsValidated ? (
                                <ClockCircleTwoTone twoToneColor="orange" />
                            ) : validation.IsValid ? (
                                <CheckCircleTwoTone twoToneColor="#08d148" />
                            ) : (
                                <CloseCircleTwoTone twoToneColor="red" />
                            )}{' '}
                            {validation.CalculationRuleName}
                            <br />
                            {validation.ErrorDescriptions && (
                                <Space direction="vertical">
                                    <b>Errors:</b>
                                    <p>{validation.ErrorDescriptions}</p>
                                </Space>
                            )}
                            {validation.WarnDescriptions && (
                                <Space direction="vertical">
                                    <b>Warnings:</b>
                                    <p>{validation.WarnDescriptions}</p>
                                </Space>
                            )}
                        </div>
                    )
                })}
                <i>Allocation Rules ( 0/0 )</i>
            </div>
        )
    }, [validations])

    return (
        <Drawer
            visible={visible}
            title={`Source File Name: ${title} status`}
            onClose={() => {
                setVisible(false)
            }}
            placement="right"
        >
            <Timeline>
                {getEnumKeys(SourceFileStatus).map((item) => {
                    const currentMapping = SourceFileStatusMapping[StageStatus]
                    const renderMapping = SourceFileStatusMapping[item]

                    if (!renderMapping) {
                        return null
                    }

                    if (
                        currentMapping?.value === renderMapping?.value &&
                        StageStatus !== SourceFileStatus.validated
                    ) {
                        return (
                            <Timeline.Item dot={<ClockCircleOutlined />}>
                                <b>{renderMapping.message}...</b>
                                {validations.length > 0 &&
                                    renderMapping.value === 3 &&
                                    validationsList}
                            </Timeline.Item>
                        )
                    }

                    const color =
                        renderMapping.value > currentMapping.value
                            ? 'grey'
                            : 'green'

                    return (
                        <Timeline.Item color={color}>
                            {renderMapping.message}
                            {validations.length > 0 &&
                                renderMapping.value === 3 &&
                                validationsList}
                        </Timeline.Item>
                    )
                })}
            </Timeline>
        </Drawer>
    )
}

export default SourceFileDrawer
