import React, { useEffect } from 'react'
import { Modal, Table, TableColumnType } from 'antd'
import * as Sentry from '@sentry/react'
import { parseJson } from '../../utils/parse.util'

type IProps = {
    visible: boolean
    onCancel: () => void
    title: string
    onOk: () => void
    data: any
}

function PreviewTable({
    visible,
    onCancel,
    title,
    onOk,
    data,
}: IProps): JSX.Element {
    const [dataColumns, setDataColumns] = React.useState<
        TableColumnType<any>[]
    >([])

    const [dataRows, setDataRows] = React.useState<any[]>([])

    useEffect(() => {
        if (typeof data === 'string') {
            try {
                const tempData = parseJson(data)

                if (tempData.length > 0) {
                    const tempColumns = Object.keys(tempData[0])
                    const tempRows = tempData.slice(1)
                    setDataColumns(
                        tempColumns.map((column) => ({
                            title: column,
                            dataIndex: column,
                            key: column,
                            ellipsis: true,
                        }))
                    )
                    setDataRows(tempRows)
                }
            } catch (e) {
                Sentry.captureException(e)
            }
        }
    }, [data])

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={title}
            destroyOnClose
            onOk={onOk}
            width={800}
        >
            <Table
                columns={dataColumns}
                dataSource={dataRows}
                size="small"
                scroll={{ x: true }}
                pagination={{ size: 'small', pageSize: 10 }}
            />
        </Modal>
    )
}

export default PreviewTable
