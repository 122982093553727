import * as React from 'react'
import { Button, Modal } from 'antd'
import SourceGroupTable from './source-group-table'
import { ISourceGroup } from '../../services/source-group/interfaces/source-group.interface'
import { useEffect } from 'react'
import { sourceGroupService } from '../../services/source-group/source-group.service'
import CreateSourceGroup from './create-source-group'
import { handleAPIError } from '../../utils/catch-error'

function SourceGroup(): JSX.Element {
    const [isModalVisible, setIsModalVisible] = React.useState(false)
    const [editingSourceGroupId, setEditingSourceGroupId] =
        React.useState<number>()
    const [sourceGroupName, setSourceGroupName] = React.useState<
        string | undefined
    >()

    const [sourceGroup, setSourceGroup] = React.useState<
        ISourceGroup[] | undefined
    >(undefined)

    const [createIsLoading, setCreateIsLoading] = React.useState(false)
    const [tableLoading, setTableLoading] = React.useState(false)

    const getAllSourceGroup = async (): Promise<void> => {
        setTableLoading(true)
        try {
            const sourceGroups = await sourceGroupService.getAll()
            setSourceGroup(sourceGroups)
        } catch (e: any) {
            handleAPIError(e)
        }
        setTableLoading(false)
    }

    useEffect(() => {
        getAllSourceGroup()
    }, [])

    const resetSaveSourceGroup = (): void => {
        setEditingSourceGroupId(undefined)
        setSourceGroupName(undefined)
    }

    const handleOk = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): Promise<void> => {
        e.preventDefault()
        setCreateIsLoading(true)
        try {
            if (!sourceGroupName) {
                throw new Error('Data group name is required')
            }
            if (editingSourceGroupId) {
                await sourceGroupService.updateById(editingSourceGroupId, {
                    SourceGroupName: sourceGroupName,
                })
            } else {
                await sourceGroupService.create({
                    SourceGroupName: sourceGroupName,
                })
            }
            setIsModalVisible(false)
            resetSaveSourceGroup()
            await getAllSourceGroup()
        } catch (err: any) {
            handleAPIError(err)
        }
        setCreateIsLoading(false)
    }

    const handleCancel = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => {
        e.preventDefault()
        setIsModalVisible(false)
        resetSaveSourceGroup()
    }

    return (
        <>
            <div>
                <div
                    style={{
                        float: 'left',
                        marginLeft: '15px',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}
                >
                    <a>
                        <Button
                            type="primary"
                            onClick={() => {
                                setIsModalVisible(true)
                            }}
                        >
                            Create New Group
                        </Button>
                    </a>
                </div>
                <div style={{ margin: '15px' }}>
                    <SourceGroupTable
                        sourceGroup={sourceGroup}
                        loading={tableLoading}
                    />
                </div>
            </div>
            <Modal
                title={
                    editingSourceGroupId
                        ? `Editing ${
                              sourceGroup?.find(
                                  (item) =>
                                      item.SourceGroupID ===
                                      editingSourceGroupId
                              )?.SourceGroupName
                          } Data Group`
                        : 'Create New Data Group'
                }
                visible={isModalVisible}
                onOk={async (e) => {
                    await handleOk(e)
                }}
                confirmLoading={createIsLoading}
                onCancel={(e) => handleCancel(e)}
                destroyOnClose
            >
                <CreateSourceGroup
                    setSourceGroupName={setSourceGroupName}
                    sourceGroupName={
                        editingSourceGroupId && !sourceGroupName
                            ? sourceGroup?.find(
                                  (item) =>
                                      item.SourceGroupID ===
                                      editingSourceGroupId
                              )?.SourceGroupName
                            : sourceGroupName
                    }
                />
            </Modal>
        </>
    )
}

export default SourceGroup
