import React, { useEffect } from 'react'
import {
    Button,
    Col,
    Input,
    notification,
    Row,
    Select,
    Space,
    Table,
    Typography,
    Upload,
} from 'antd'
import { handleAPIError } from '../../utils/catch-error'
import { RcFile } from 'antd/es/upload'
import * as XLSX from 'xlsx'
import { IAllocationRuleGroup } from '../../services/allocation-rule/interfaces/allocation-rule-group.interface'
import { ISourceDataset } from '../../services/source-group/interfaces/source-dataset.interface'
import { ISourceGroup } from '../../services/source-group/interfaces/source-group.interface'
import { sourceGroupService } from '../../services/source-group/source-group.service'
import { sourceDatasetService } from '../../services/source-group/source-dataset.service'
import { allocationRuleGroupService } from '../../services/allocation-rule/allocation-rule-group.service'
import { useNavigate } from 'react-router-dom'
import { routeMapMini } from '../../constants/routeMap'
import { FileProperties } from '../../services/file-upload/interfaces/file-properties.interface'
import { xlsxUtil } from '../../utils/xlsx.util'
import { FileUploadService } from '../../services/file-upload/file-upload.service'
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { parseJson } from '../../utils/parse.util'

function CreateAllocationRuleGroup(): JSX.Element {
    const navigate = useNavigate()
    const [allocationRuleGroupName, setAllocationRuleGroupName] =
        React.useState('')
    const [fileList, setFileList] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(false)
    const [sourceGroups, setSourceGroups] = React.useState<ISourceGroup[]>([])
    const [sourceDatasets, setSourceDatasets] = React.useState<
        ISourceDataset[]
    >([])
    const [selectedSourceGroup, setSelectedSourceGroup] =
        React.useState<ISourceGroup>()
    const [selectedSourceDataset, setSelectedSourceDataset] =
        React.useState<ISourceDataset>()
    const [tableData, setTableData] = React.useState<
        IAllocationRuleGroup['AllocationRuleGroupConfig']
    >([])

    const [rangeFromSelectors, setRangeFromSelectors] = React.useState<
        string[]
    >([])
    const [rangeToSelectors, setRangeToSelectors] = React.useState<string[]>([])
    const [destSelectors, setDestSelectors] = React.useState<string[]>([])
    const [fileProperties, setFileProperties] = React.useState<FileProperties>({
        FileLocation: '',
        FileName: '',
        FileType: 'allocation_rule_group_file',
        FilePreviewData: [],
    })

    useEffect(() => {
        sourceGroupService.getAll().then(setSourceGroups).catch(handleAPIError)
    }, [])

    useEffect(() => {
        if (!selectedSourceGroup) {
            return
        }
        sourceDatasetService
            .getDatasetsForGroup(selectedSourceGroup.SourceGroupID || 0)
            .then(setSourceDatasets)
            .catch(handleAPIError)
    }, [selectedSourceGroup])

    const resetTable = (): void => {
        setTableData([])
        setDestSelectors([])
        setRangeFromSelectors([])
        setRangeToSelectors([])
    }

    const beforeUpload = async (file: RcFile): Promise<boolean> => {
        resetTable()
        const isLt2M = file.size / 1024 / 1024 < 1
        if (!isLt2M) {
            notification.error({
                message: 'Sample file must be smaller than 1MB.',
            })
            return false
        }
        const arrayBuffer = await file.arrayBuffer()
        const workbook = XLSX.read(arrayBuffer, {
            type: 'array',
            sheetRows: 50,
        })
        const sheet1 = workbook.Sheets[workbook.SheetNames[0]]
        const sheetInJson: [][] = XLSX.utils.sheet_to_json(sheet1, {
            header: 1,
        })

        let tempTableData: IAllocationRuleGroup['AllocationRuleGroupConfig'] =
            []
        const tempToSelectors: string[] = []
        const tempFromSelectors: string[] = []
        const tempDestSelectors: string[] = []

        sheetInJson?.[0]?.forEach((value: string) => {
            if (value.endsWith('From') || value.endsWith('from')) {
                tempFromSelectors.push(value)
            } else if (value.endsWith('To') || value.endsWith('to')) {
                tempToSelectors.push(value)
            } else if (value.endsWith('Dest') || value.endsWith('dest')) {
                tempDestSelectors.push(value)
            }
        })

        setRangeToSelectors(tempToSelectors)
        setRangeFromSelectors(tempFromSelectors)
        setDestSelectors(tempDestSelectors)

        if (selectedSourceDataset?.DatasetMetadataV2) {
            const datasetMetadata = parseJson(
                // @ts-ignore
                selectedSourceDataset.DatasetMetadataV2
            )
            tempTableData = datasetMetadata.map(
                (item: ISourceDataset['DatasetMetadataV2'][0]) => {
                    return {
                        ...item,
                        rangeFrom: tempFromSelectors.find(
                            (selector) =>
                                selector.toLowerCase() ===
                                `${item.columnName.toLowerCase()}from`
                        ),
                        rangeTo: tempToSelectors.find(
                            (selector) =>
                                selector.toLowerCase() ===
                                `${item.columnName.toLowerCase()}to`
                        ),
                        dest: tempDestSelectors.find(
                            (selector) =>
                                selector.toLowerCase() ===
                                `${item.columnName.toLowerCase()}dest`
                        ),
                    }
                }
            )
        }

        setTableData(tempTableData)
        setFileList([file])
        setFileProperties({
            ...fileProperties,
            FilePreviewData: xlsxUtil.convertCSV2DArrayToObject(sheetInJson),
            FileName: file.name,
        })

        return false
    }

    const uploadFile = async (options: UploadRequestOption): Promise<void> => {
        const { onSuccess, onError, file, onProgress } = options
        try {
            const resp = await FileUploadService.uploadFile(
                // @ts-ignore
                file,
                // @ts-ignore
                generateFileName(file.name),
                onProgress
            )
            if (resp) {
                setFileProperties({
                    ...fileProperties,
                    FileLocation: resp.fileLocation,
                })
            }
            onSuccess?.('Ok')
        } catch (err: any) {
            // @ts-ignore
            onError?.({ err })
            handleAPIError(err)
        }
    }

    const columns: any[] = [
        {
            title: 'Column Name',
            dataIndex: 'columnName',
            key: 'id',
            fixed: 'left',
        },
        {
            title: 'Data Type',
            dataIndex: 'dataType',
            key: 'id',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'id',
        },
        {
            title: 'Range From',
            dataIndex: 'rangeFrom',
            key: 'id',
            render: (
                text: string,
                record: IAllocationRuleGroup['AllocationRuleGroupConfig'][0]
            ) => {
                return (
                    <Select
                        style={{ minWidth: '150px' }}
                        value={text}
                        allowClear
                        onChange={(e: string) => {
                            setTableData(
                                tableData.map((item) => {
                                    if (item.columnName === record.columnName) {
                                        return {
                                            ...item,
                                            rangeFrom: e,
                                        }
                                    }
                                    return item
                                })
                            )
                        }}
                    >
                        {rangeFromSelectors
                            .filter((rangeFrom) => {
                                return !tableData.some(
                                    (item) => item.rangeFrom === rangeFrom
                                )
                            })
                            .map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                    </Select>
                )
            },
        },
        {
            title: 'Range To',
            dataIndex: 'rangeTo',
            key: 'id',
            render: (
                text: string,
                record: IAllocationRuleGroup['AllocationRuleGroupConfig'][0]
            ) => {
                return (
                    <Select
                        style={{ minWidth: '150px' }}
                        value={text}
                        allowClear
                        onChange={(e: string) => {
                            setTableData(
                                tableData.map((item) => {
                                    if (item.columnName === record.columnName) {
                                        return {
                                            ...item,
                                            rangeTo: e,
                                        }
                                    }
                                    return item
                                })
                            )
                        }}
                    >
                        {rangeToSelectors
                            .filter((rangeTo) => {
                                return !tableData.some(
                                    (item) => item.rangeTo === rangeTo
                                )
                            })
                            .map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                    </Select>
                )
            },
        },
        {
            title: 'Dest',
            dataIndex: 'dest',
            key: 'id',
            render: (
                text: string,
                record: IAllocationRuleGroup['AllocationRuleGroupConfig'][0]
            ) => {
                return (
                    <Select
                        style={{ minWidth: '150px' }}
                        value={text}
                        allowClear
                        onChange={(e: string) => {
                            setTableData(
                                tableData.map((item) => {
                                    if (item.columnName === record.columnName) {
                                        return {
                                            ...item,
                                            dest: e,
                                        }
                                    }
                                    return item
                                })
                            )
                        }}
                    >
                        {destSelectors
                            .filter((dest) => {
                                return !tableData.some(
                                    (item) => item.dest === dest
                                )
                            })
                            .map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                    </Select>
                )
            },
        },
    ]

    const resetState = (): void => {
        setAllocationRuleGroupName('')
        setSelectedSourceGroup(undefined)
        setSelectedSourceDataset(undefined)
        resetTable()
        setFileList([])
    }

    const handleOk = async (): Promise<void> => {
        setLoading(true)
        try {
            const tempTableData = tableData.filter(
                (item) => item.dest && item.rangeTo && item.rangeFrom
            )
            tempTableData.forEach((item) => {
                if (!(item.rangeFrom && item.rangeTo && item.dest)) {
                    throw new Error(
                        `Please fill in all the fields in ${item.columnName}`
                    )
                }
            })
            await allocationRuleGroupService.create({
                AllocationRuleGroupName: allocationRuleGroupName,
                DatasetID: selectedSourceDataset?.DatasetID || 0,
                AllocationRuleGroupConfig: tempTableData.map(
                    (
                        item: IAllocationRuleGroup['AllocationRuleGroupConfig'][0]
                    ) => {
                        return {
                            ColumnName: item.columnName,
                            RangeFrom: item.rangeFrom,
                            RangeTo: item.rangeTo,
                            Dest: item.dest,
                        }
                    }
                ),
                FileLocation: fileProperties.FileLocation,
                FileName: fileProperties.FileName,
                FilePreviewData: fileProperties.FilePreviewData,
                FileType: fileProperties.FileType,
            })
            resetState()
            notification.success({
                message: 'Successfully Saved Allocation Rule Group',
            })
            navigate(routeMapMini.rulesAllocation, { replace: true })
        } catch (e) {
            handleAPIError(e)
        }
        setLoading(false)
    }

    const handleCancel = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => {
        e.preventDefault()
        resetState()
    }

    return (
        <Row style={{ marginTop: '10px' }}>
            <Col span={1} />
            <Col span={7}>
                <Space direction="vertical">
                    <span>
                        <Typography.Title level={3}>
                            Creating Allocation Rule Group
                        </Typography.Title>
                    </span>
                    <Input
                        onChange={(e) =>
                            setAllocationRuleGroupName(e.target.value)
                        }
                        placeholder="Enter Allocation Rule Group Name"
                        value={allocationRuleGroupName}
                    />
                    <Row gutter={[30, 12]} style={{ marginBottom: '10px' }}>
                        <Col span={12}>
                            <Row>Data Group</Row>
                            <Row>
                                <Select
                                    value={selectedSourceGroup?.SourceGroupID}
                                    style={{ minWidth: '150px' }}
                                    onChange={(e: number) => {
                                        setSelectedSourceGroup(
                                            sourceGroups.find(
                                                (item) =>
                                                    item.SourceGroupID === e
                                            )
                                        )
                                        setSelectedSourceDataset(undefined)
                                        setFileList([])
                                        setTableData([])
                                    }}
                                >
                                    {sourceGroups.map((item) => (
                                        <Select.Option
                                            key={item.SourceGroupID}
                                            value={item.SourceGroupID}
                                        >
                                            {item.SourceGroupName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>Dataset</Row>
                            <Row>
                                <Select
                                    value={selectedSourceDataset?.DatasetID}
                                    disabled={!selectedSourceGroup}
                                    style={{ minWidth: '150px' }}
                                    onChange={(e: number) => {
                                        setSelectedSourceDataset(
                                            sourceDatasets.find(
                                                (item) => item.DatasetID === e
                                            )
                                        )
                                        setFileList([])
                                        resetTable()
                                    }}
                                >
                                    {sourceDatasets.map((item) => (
                                        <Select.Option
                                            key={item.DatasetID}
                                            value={item.DatasetID}
                                        >
                                            {item.DatasetName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Row>
                        </Col>
                    </Row>
                    {selectedSourceDataset && (
                        <>
                            Upload Allocation Rule Sample File
                            <Upload
                                beforeUpload={beforeUpload}
                                customRequest={uploadFile}
                                onRemove={() => {
                                    resetTable()
                                    setFileList([])
                                }}
                                multiple={false}
                                fileList={fileList}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            >
                                <Button type="primary"> Upload Sample </Button>
                            </Upload>
                        </>
                    )}
                    <div style={{ marginTop: '20px' }}>
                        <Space>
                            <Button
                                type="primary"
                                onClick={handleOk}
                                loading={loading}
                            >
                                Submit
                            </Button>
                            <Button onClick={handleCancel}>Reset</Button>
                        </Space>
                    </div>
                </Space>
            </Col>
            <Col span={1} />
            <Col span={14}>
                {tableData.length > 0 && (
                    <>
                        <Typography.Paragraph>
                            <b>Change Structure</b>
                        </Typography.Paragraph>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            pagination={false}
                            scroll={{ x: true, y: 400 }}
                        />
                    </>
                )}
            </Col>
        </Row>
    )
}

export default CreateAllocationRuleGroup
