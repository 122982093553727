import { routeMapMini } from './routeMap'

export const breadCrumb: Record<
    routeMapMini,
    { name: string; path: string }[]
> = {
    [routeMapMini.userManagement]: [
        {
            name: 'User Management',
            path: routeMapMini.userManagement,
        },
    ],
    [routeMapMini.teamUsers]: [
        {
            name: 'User Management',
            path: routeMapMini.userManagement,
        },
        {
            name: 'Team Users',
            path: routeMapMini.teamUsers,
        },
    ],
    [routeMapMini.createNewTeam]: [
        {
            name: 'User Management',
            path: routeMapMini.userManagement,
        },
        {
            name: 'Create New Team',
            path: routeMapMini.createNewTeam,
        },
    ],
    [routeMapMini.userTeams]: [
        {
            name: 'User Management',
            path: routeMapMini.userManagement,
        },
        {
            name: 'User Teams',
            path: routeMapMini.userTeams,
        },
    ],
    [routeMapMini.sourceGroup]: [
        {
            name: 'Data Group',
            path: routeMapMini.sourceGroup,
        },
    ],
    [routeMapMini.sourceDataset]: [
        {
            name: 'Data Group',
            path: routeMapMini.sourceGroup,
        },
        {
            name: 'Datasets',
            path: routeMapMini.sourceDataset,
        },
    ],
    [routeMapMini.sourceDatasetNew]: [
        {
            name: 'Data Group',
            path: routeMapMini.sourceGroup,
        },
        {
            name: 'Datasets',
            path: routeMapMini.sourceDataset,
        },
        {
            name: 'New',
            path: routeMapMini.sourceDatasetNew,
        },
    ],
    [routeMapMini.sourceFiles]: [
        {
            name: 'Data Group',
            path: routeMapMini.sourceGroup,
        },
        {
            name: 'Datasets',
            path: routeMapMini.sourceDataset,
        },
        {
            name: 'Source Files',
            path: routeMapMini.sourceFiles,
        },
    ],
    [routeMapMini.sourceCloud]: [
        {
            name: 'Data Group',
            path: routeMapMini.sourceGroup,
        },
        {
            name: 'Source Cloud',
            path: routeMapMini.sourceCloud,
        },
    ],
    [routeMapMini.sourceOnPremise]: [
        {
            name: 'Data Group',
            path: routeMapMini.sourceGroup,
        },
        {
            name: 'Source On Premise',
            path: routeMapMini.sourceOnPremise,
        },
    ],
    [routeMapMini.rulesCalculation]: [
        {
            name: 'Calculation Rules',
            path: routeMapMini.rulesCalculation,
        },
    ],
    [routeMapMini.rulesCalculationNew]: [
        {
            name: 'Calculation Rules',
            path: routeMapMini.rulesCalculation,
        },
        {
            name: 'New',
            path: routeMapMini.rulesCalculationNew,
        },
    ],
    [routeMapMini.rulesCalculationView]: [
        {
            name: 'Calculation Rules',
            path: routeMapMini.rulesCalculation,
        },
        {
            name: 'View',
            path: routeMapMini.rulesCalculationView,
        },
    ],
    [routeMapMini.rulesJoin]: [
        { name: 'Join Rules', path: routeMapMini.rulesJoin },
    ],
    [routeMapMini.rulesJoinNew]: [
        { name: 'Join Rules', path: routeMapMini.rulesJoin },
        { name: 'New', path: routeMapMini.rulesJoinNew },
    ],
    [routeMapMini.rulesAllocation]: [
        {
            name: 'Allocation Rules',
            path: routeMapMini.rulesAllocation,
        },
    ],
    [routeMapMini.rulesAllocationNew]: [
        {
            name: 'Allocation Rules',
            path: routeMapMini.rulesAllocation,
        },
        {
            name: 'New',
            path: routeMapMini.rulesAllocationNew,
        },
    ],
    [routeMapMini.rulesAllocationFiles]: [
        {
            name: 'Allocation Rules',
            path: routeMapMini.rulesAllocation,
        },
        {
            name: 'Allocation Rules Files',
            path: routeMapMini.rulesAllocationFiles,
        },
    ],
    [routeMapMini.rulesConsolidation]: [
        {
            name: 'Consolidation Rules',
            path: routeMapMini.rulesConsolidation,
        },
    ],
    [routeMapMini.rulesFx]: [
        {
            name: 'FX Rules',
            path: routeMapMini.rulesFx,
        },
    ],
    [routeMapMini.rulesMerge]: [
        { name: 'Merge Rules', path: routeMapMini.rulesMerge },
    ],
    [routeMapMini.rulesMergeNew]: [
        { name: 'Merge Rules', path: routeMapMini.rulesMerge },
        { name: 'New', path: routeMapMini.rulesMergeNew },
    ],
    [routeMapMini.configMetadata]: [
        {
            name: 'Config Metadata',
            path: routeMapMini.configMetadata,
        },
    ],
    [routeMapMini.configLineage]: [
        {
            name: 'Config Lineage',
            path: routeMapMini.configLineage,
        },
    ],
    [routeMapMini.configDimensions]: [
        {
            name: 'Config Dimensions',
            path: routeMapMini.configDimensions,
        },
    ],
    [routeMapMini.configMeasure]: [
        {
            name: 'Config Mesaure',
            path: routeMapMini.configMeasure,
        },
    ],
    [routeMapMini.configFxRates]: [
        {
            name: 'Config FX Rates',
            path: routeMapMini.configFxRates,
        },
    ],
    [routeMapMini.runHistory]: [
        {
            name: 'Run History',
            path: routeMapMini.runHistory,
        },
    ],
    [routeMapMini.createRun]: [
        {
            name: 'Create Run',
            path: routeMapMini.createRun,
        },
    ],
    [routeMapMini.reporting]: [
        {
            name: 'Reporting',
            path: routeMapMini.reporting,
        },
    ],
    [routeMapMini.Login]: [
        {
            name: 'Home',
            path: '',
        },
    ],
    [routeMapMini.dashboard]: [
        {
            name: 'Dashboard',
            path: routeMapMini.dashboard,
        },
    ],
    [routeMapMini.topLevelLineage]: [
        {
            name: 'Lineage',
            path: routeMapMini.topLevelLineage,
        },
    ],
}
