import React, { Component } from 'react'
import { message, Tag } from 'antd'
// @ts-ignore
import groupBy from 'lodash/groupBy'
import moment from 'moment'
import NoticeIcon from './NoticeIcon/NoticeIcon'
// @ts-ignore
import styles from './Notification.less'

class Notification extends Component {
    componentDidMount(): void {
        // @ts-ignore
        const { dispatch } = this.props

        if (dispatch) {
            dispatch({
                type: 'global/fetchNotices',
            })
        }
    }

    changeReadState = (clickedItem: any): void => {
        const { id } = clickedItem
        // @ts-ignore
        const { dispatch } = this.props

        if (dispatch) {
            dispatch({
                type: 'global/changeNoticeReadState',
                payload: id,
            })
        }
    }

    handleNoticeClear = (title: string, key: string): void => {
        // @ts-ignore
        const { dispatch } = this.props
        message.success(`${'Success'} ${title}`)

        if (dispatch) {
            dispatch({
                type: 'global/clearNotices',
                payload: key,
            })
        }
    }

    getNoticeData = (): any => {
        // @ts-ignore
        const { notices = [] } = this.props

        if (!notices || notices.length === 0 || !Array.isArray(notices)) {
            return {}
        }

        // @ts-ignore
        // @ts-ignore
        const newNotices = notices.map((notice) => {
            const newNotice = { ...notice }

            if (newNotice.datetime) {
                newNotice.datetime = moment(notice.datetime).fromNow()
            }

            if (newNotice.id) {
                newNotice.key = newNotice.id
            }

            if (newNotice.extra && newNotice.status) {
                // @ts-ignore
                const color = {
                    todo: '',
                    processing: 'blue',
                    urgent: 'red',
                    doing: 'gold',
                }[newNotice.status]
                newNotice.extra = (
                    <Tag
                        color={color}
                        style={{
                            marginRight: 0,
                        }}
                    >
                        {newNotice.extra}
                    </Tag>
                )
            }

            return newNotice
        })
        return groupBy(newNotices, 'type')
    }

    // eslint-disable-next-line class-methods-use-this
    getUnreadData = (noticeData: any): any => {
        const unreadMsg: any = {}
        Object.keys(noticeData).forEach((key) => {
            const value = noticeData[key]

            if (!unreadMsg[key]) {
                unreadMsg[key] = 0
            }

            if (Array.isArray(value)) {
                unreadMsg[key] = value.filter((item) => !item.read).length
            }
        })
        return unreadMsg
    }

    render(): JSX.Element {
        // @ts-ignore
        const { currentUser, fetchingNotices, onNoticeVisibleChange } =
            this.props
        const noticeData = this.getNoticeData()
        const unreadMsg = this.getUnreadData(noticeData)
        return (
            <NoticeIcon
                className={styles.action}
                count={currentUser && currentUser.unreadCount}
                onItemClick={(item: any) => {
                    this.changeReadState(item)
                }}
                loading={fetchingNotices}
                clearText="Empty"
                viewMoreText="see more"
                onClear={this.handleNoticeClear}
                onPopupVisibleChange={onNoticeVisibleChange}
                onViewMore={() => message.info('Click on view more')}
                clearClose
            >
                <NoticeIcon.Tab
                    tabKey="notification"
                    count={unreadMsg.notification}
                    list={noticeData.notification}
                    title="Notice"
                    emptyText="You have viewed all notifications"
                    showViewMore
                />
                <NoticeIcon.Tab
                    tabKey="message"
                    count={unreadMsg.message}
                    list={noticeData.message}
                    title="news"
                    emptyText="You have read all messages"
                    showViewMore
                />
                <NoticeIcon.Tab
                    tabKey="event"
                    title="Upcoming"
                    emptyText="You have completed all to do"
                    count={unreadMsg.event}
                    list={noticeData.event}
                    showViewMore
                />
            </NoticeIcon>
        )
    }
}

export default Notification
