import * as React from 'react'
import { useEffect } from 'react'
import { Space, Table, Tag } from 'antd'
import { IAllocationRuleFileInfo } from '../../../services/allocation-rule/interfaces/allocation-rule-file.interface'
import { allocationRuleFileService } from '../../../services/allocation-rule/allocation-rule-file.service'
import { getColumnSearchProps } from '../../../utils/columnsearch-props'
import moment from 'moment'
import { handleAPIError } from '../../../utils/catch-error'
import { FileUploadService } from '../../../services/file-upload/file-upload.service'
import PreviewTable from '../../preview-table/preview-table'

function AllocationRuleFileTable({
    groupId,
    loading,
    refreshData,
}: {
    groupId: number | undefined
    loading: boolean
    refreshData: boolean
}): JSX.Element {
    const [allocationRuleFiles, setAllocationRuleFiles] = React.useState<
        IAllocationRuleFileInfo[] | undefined
    >(undefined)
    const [searchText, setSearchText] = React.useState('')
    const [previewVisible, setPreviewVisible] = React.useState(false)
    const [previewProps, setPreviewProps] = React.useState<{
        title: string
        data: any
    }>({
        title: '',
        data: [],
    })

    useEffect(() => {
        if (groupId) {
            allocationRuleFileService
                .getAllForGroup(groupId)
                .then((res) => {
                    setAllocationRuleFiles(res)
                })
                .catch(handleAPIError)
        }
    }, [groupId, refreshData])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'AllocationRuleFileID',
            key: 'AllocationRuleFileID',
            sorter: (
                a: IAllocationRuleFileInfo,
                b: IAllocationRuleFileInfo
            ) => {
                return (a.AllocationRuleFileID || 1) >
                    (b.AllocationRuleFileID || 1)
                    ? 1
                    : -1
            },
        },
        {
            title: 'AllocationRuleName',
            dataIndex: 'AllocationRuleName',
            key: 'AllocationRuleFileID',
            ...getColumnSearchProps(
                'AllocationRuleName',
                searchText,
                setSearchText
            ),
        },
        {
            title: 'Stage',
            dataIndex: 'Stage',
            key: 'AllocationRuleFileID',
        },
        {
            title: 'Stage Status',
            dataIndex: 'StageStatus',
            key: 'AllocationRuleFileID',
            render: (text: string) => {
                if (text === 'running') {
                    return <Tag color="green">Running</Tag>
                }
                if (text === 'finished') {
                    return <Tag color="blue">Finished</Tag>
                }
                if (text === 'failed') {
                    return <Tag color="red">Failed</Tag>
                }
                return <Tag color="orange">{text}</Tag>
            },
        },
        {
            title: 'Created At',
            dataIndex: 'Auditdatetime',
            key: 'RunId',
            render: (text: string) => {
                return moment(text).format('YYYY-MM-DD HH:mm')
            },
            sorter: (
                a: IAllocationRuleFileInfo,
                b: IAllocationRuleFileInfo
            ) => {
                return (
                    moment(a.Auditdatetime).valueOf() -
                    moment(b.Auditdatetime).valueOf()
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'RunId',
            ellipsis: true,
        },
        {
            title: 'Action',
            key: 'action',
            render: (
                _: unknown,
                record: IAllocationRuleFileInfo
            ): JSX.Element => (
                <Space size="middle">
                    <a
                        role="none"
                        onClick={async () => {
                            if (record.UploadedFileID) {
                                const fileData =
                                    await FileUploadService.getFileById(
                                        record.UploadedFileID
                                    )
                                if (fileData) {
                                    setPreviewVisible(true)
                                    setPreviewProps({
                                        title: `Preview of allocation rule file - ${record.AllocationRuleName}`,
                                        data: fileData.FilePreviewData,
                                    })
                                }
                            }
                        }}
                    >
                        Preview
                    </a>
                </Space>
            ),
        },
    ]

    return (
        <>
            <Table
                columns={columns}
                dataSource={allocationRuleFiles}
                loading={loading}
            />
            <PreviewTable
                visible={previewVisible}
                onCancel={() => {
                    setPreviewVisible(false)
                }}
                onOk={() => {
                    setPreviewVisible(false)
                }}
                title={previewProps.title}
                data={previewProps.data}
            />
        </>
    )
}

export default AllocationRuleFileTable
