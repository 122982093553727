import React, { useEffect, useState } from 'react'
import { Layout, Menu } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import './Slider.css'
import { withRouter } from '../../utils/withRouter'
import { routeMap } from '../../constants/routeMap'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const { Sider } = Layout

function HomeSider({ siderCollapsed, router, toggleSider }: any): JSX.Element {
    const [selectedNavItem, setSelectedNavItem] = useState(['/'])

    const [routeMapState, setRouteMapState] = useState<any>()

    const { currentTheme } = useThemeSwitcher()

    useEffect(() => {
        setRouteMapState(
            routeMap?.map((item, i) => {
                if (Number(item?.child?.length) > 0) {
                    return item.inLayout ? (
                        <Menu.SubMenu
                            key={item.route}
                            icon={
                                <item.icon
                                    style={{
                                        color: '#0ea3d2',
                                    }}
                                />
                            }
                            title={item.name}
                            style={{
                                marginTop: i === 0 ? '0px' : '',
                            }}
                        >
                            {item.child?.map((childItem) => {
                                return childItem.inLayout ? (
                                    <Menu.Item key={childItem.route}>
                                        {childItem.name}
                                    </Menu.Item>
                                ) : null
                            })}
                        </Menu.SubMenu>
                    ) : null
                }
                return item.inLayout ? (
                    <Menu.Item
                        key={item.route}
                        icon={<item.icon style={{ color: '#0ea3d2' }} />}
                        style={{
                            marginTop: i === 0 ? '0px' : '',
                        }}
                    >
                        {item.name}
                    </Menu.Item>
                ) : null
            })
        )
        setSelectedNavItem([
            router.location.pathname,
            `/${router.location.pathname?.split('/')[1]}`,
        ])
    }, [router.location.pathname])

    const [collapsedWidth, setCollapsedWidth] = useState(80)

    const screens = useBreakpoint()

    useEffect(() => {
        if (screens?.xl || screens?.xxl || screens?.lg) {
            setCollapsedWidth(80)
        } else {
            setCollapsedWidth(0)
        }
    }, [screens])

    const onMenuClick = (event: any): void => {
        const { key } = event
        if (collapsedWidth === 0) {
            toggleSider()
        }
        router.navigate(key, { replace: true })
    }

    return (
        <Sider
            className="menu_sider"
            collapsible
            onCollapse={toggleSider}
            trigger={null}
            collapsed={siderCollapsed}
            collapsedWidth={collapsedWidth}
            style={{
                position: 'fixed',
                left: 0,
                zIndex: 1000,
                backgroundColor:
                    currentTheme === 'dark' ? '#504f4f' : '#f1f2f3',
                boxShadow: `3.5px 10px 10px 3.5px ${
                    currentTheme === 'dark'
                        ? 'rgba(35, 35, 35, .5)'
                        : 'rgba(196, 196, 196, .5)'
                }`,
            }}
            defaultCollapsed
        >
            {/* <div className="logo" style={{ height: '60px' }} /> */}
            <Menu
                theme="light"
                mode="inline"
                selectedKeys={selectedNavItem}
                onClick={onMenuClick}
                style={{
                    backgroundColor:
                        currentTheme === 'dark' ? '#504f4f' : '#f1f2f3',
                }}
            >
                {routeMapState}
            </Menu>
            <div>
                {React.createElement(
                    siderCollapsed ? RightOutlined : LeftOutlined,
                    {
                        style: {
                            cursor: 'pointer',
                            transitionTimingFunction: 'ease-in-out',
                            backgroundColor:
                                currentTheme === 'dark' ? '#504f4f' : '#0e0d0c',
                            padding: `17.5px ${
                                siderCollapsed ? '30.75px' : '92.5px'
                            }`,
                            color: currentTheme === 'dark' ? 'white' : 'white',
                            bottom: 0,
                            left: 0,
                            position: 'fixed',
                            filter:
                                currentTheme === 'dark'
                                    ? undefined
                                    : 'invert(1)',
                        },
                        className: 'trigger',
                        onClick: toggleSider,
                    }
                )}
            </div>
        </Sider>
    )
}

export default withRouter(HomeSider)
