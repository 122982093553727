import React from 'react'
import { Button, Col, DatePicker, Row } from 'antd'
import RunnersTable from './runners-table'
import { routeMapMini } from '../../constants/routeMap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import type { RangePickerProps } from 'antd/es/date-picker'

function Run(): JSX.Element {
    const [fromDate, setFromDate] = React.useState<any>(
        moment().subtract(15, 'days')
    )
    const [toDate, setToDate] = React.useState<any>(moment())
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current > moment().endOf('day')
    }

    return (
        <div>
            <div
                style={{
                    float: 'left',
                    marginLeft: '15px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            >
                <Row gutter={[10, 0]}>
                    <Col>
                        <Link to={routeMapMini.createRun}>
                            <Button type="primary">Create New Run</Button>
                        </Link>
                    </Col>
                    <Col>
                        <DatePicker.RangePicker
                            style={{ minWidth: '100%' }}
                            onChange={(e) => {
                                if (e?.[0] && e?.[1]) {
                                    setFromDate(e[0])
                                    setToDate(e[1])
                                } else {
                                    setFromDate(moment().subtract(15, 'days'))
                                    setToDate(moment())
                                }
                            }}
                            format="DD-MM-YYYY"
                            disabledDate={disabledDate}
                            ranges={{
                                Today: [moment(), moment()],
                                'Last 7 Days': [
                                    moment().subtract(7, 'd'),
                                    moment(),
                                ],
                                'Last 15 Days': [
                                    moment().subtract(15, 'd'),
                                    moment(),
                                ],
                            }}
                            value={[moment(fromDate), moment(toDate)]}
                        />
                    </Col>
                </Row>
            </div>
            <div style={{ margin: '15px' }}>
                <RunnersTable fromDate={fromDate} toDate={toDate} />
            </div>
        </div>
    )
}

export default Run
