import { Button, Select, Space } from 'antd'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IUsers } from '../../../services/user-management/interfaces/users.interface'
import { usersService } from '../../../services/user-management/users.service'
import { handleAPIError } from '../../../utils/catch-error'
import AddUserToTeam from './add-user-to-team'
import UserTeamsTable from './user-teams-table'

function UserTeams(): JSX.Element {
    const [tableLoading, setTableLoading] = React.useState(false)
    const [selectedUser, setSelectedUser] = React.useState<IUsers | undefined>(
        undefined
    )
    const [users, setUsers] = React.useState<IUsers[] | undefined>(undefined)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isUserTeamModalVisible, setIsUserTeamModalVisible] =
        React.useState(false)

    useEffect(() => {
        usersService
            .getAll()
            .then((res) => {
                setUsers(res)
                const userIdFromQuery = searchParams.get('userId')
                if (userIdFromQuery) {
                    const userItem = res?.find(
                        (sg) => sg.UserID === Number(userIdFromQuery)
                    )
                    if (userItem) {
                        setSelectedUser(userItem)
                    }
                }
            })
            .catch(handleAPIError)
    }, [searchParams])

    return (
        <>
            <div
                style={{
                    float: 'left',
                    marginLeft: '15px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            >
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsUserTeamModalVisible(true)
                        }}
                    >
                        Add User To Team
                    </Button>
                    User:
                    <Select
                        style={{ minWidth: '120px' }}
                        value={selectedUser?.UserID}
                        onChange={(value) => {
                            const userItem = users?.find(
                                (t) => t.UserID === Number(value)
                            )
                            if (userItem) {
                                setSelectedUser(userItem)
                                setSearchParams({
                                    userId: String(userItem.UserID),
                                })
                            }
                        }}
                    >
                        {users?.map((userItem: IUsers) => (
                            <Select.Option
                                key={userItem.UserID}
                                value={userItem.UserID}
                            >
                                {userItem.UserName}
                            </Select.Option>
                        ))}
                    </Select>
                </Space>
            </div>
            <AddUserToTeam
                visible={isUserTeamModalVisible}
                setVisible={setIsUserTeamModalVisible}
                userId={selectedUser?.UserID || -1}
                userName={selectedUser?.UserName || undefined}
            />
            <div style={{ margin: '15px' }}>
                <UserTeamsTable
                    userId={selectedUser?.UserID || 0}
                    loading={tableLoading}
                    setLoading={setTableLoading}
                    refreshTable={!isUserTeamModalVisible}
                />
            </div>
        </>
    )
}
export default UserTeams
