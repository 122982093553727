import { Col, Input, Row, Select, Space } from 'antd'
import React, { useContext, useEffect, useMemo } from 'react'
import {
    CalColumnTypes,
    CalConditionalOperations,
} from '../../services/calculation-rule/interfaces/calculation-rule.interface'
import {
    calculationRuleHelper,
    ICalcColumnOption,
} from './calculation-rule.helper'
import { CalculationRuleContext } from './new-calculation-rule'
import { getEnumKeys } from '../../utils/enum'
import { calculationRuleConditionHelper } from './calculation-rule-condition.helper'

function CalculationRuleConditionalStepCollapseWhenClosed({
    name,
    stepId,
    columnId,
}: {
    name: string
    stepId: number
    columnId: number
}): JSX.Element {
    const calculationRuleValue = useContext(CalculationRuleContext)

    const [availableSelectOptions, setAvailableSelectOptions] = React.useState<
        ICalcColumnOption[]
    >([])
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        if (!calculationRuleValue) {
            return
        }
        const tempAvailableColumns =
            calculationRuleHelper.filterAvailableColumns(
                calculationRuleValue,
                columnId,
                stepId
            )

        setAvailableSelectOptions([
            ...(tempAvailableColumns || []),
            {
                name: '__constant',
                id: -1,
                type: CalColumnTypes.constant,
                uniqueID: `-1-${CalColumnTypes.constant}`,
            },
        ])
    }, [
        calculationRuleValue,
        calculationRuleValue?.columns,
        calculationRuleValue?.dataset?.DatasetMetadata,
        columnId,
        stepId,
    ])

    function onChangeColumnValue(
        type: 'column1' | 'column2' | 'operation',
        columnOption?: ICalcColumnOption,
        ConditionOperation?: CalConditionalOperations
    ): void {
        setLoading(true)
        const columnsUpdate = calculationRuleValue?.columns?.map((column) => ({
            ...column,
            steps:
                column.columnID !== columnId
                    ? column.steps
                    : column.steps?.map((item) => {
                          if (item.stepID !== stepId) {
                              return item
                          }
                          const conditionStepValue = {
                              ...item.condition,
                          }

                          if (type === 'column1' && columnOption) {
                              conditionStepValue.column1 = columnOption.id
                              conditionStepValue.column1Type = columnOption.type
                              conditionStepValue.statusOfColumn1 = true
                          } else if (type === 'column2' && columnOption) {
                              conditionStepValue.column2 = columnOption.id
                              conditionStepValue.column2Type = columnOption.type
                              conditionStepValue.statusOfColumn2 = true
                          } else if (
                              type === 'operation' &&
                              ConditionOperation
                          ) {
                              conditionStepValue.operation = ConditionOperation
                          }

                          return {
                              ...item,
                              condition: conditionStepValue,
                          }
                      }),
        }))

        // @ts-ignore
        calculationRuleValue?.setColumns(columnsUpdate || [])
        setLoading(false)
    }

    const currentStepDetails = useMemo(() => {
        return calculationRuleValue?.columns
            ?.find((column) => column.columnID === columnId)
            ?.steps?.find((step) => step.stepID === stepId)
    }, [calculationRuleValue, columnId, stepId])

    return (
        <div>
            <Row>
                <Space size="large">
                    <Col
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <b>{name}</b>
                        <i> (condition)</i>
                    </Col>
                    <Col
                        span={24}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <span
                            style={{
                                paddingRight: '28px',
                                fontWeight: 'bold',
                            }}
                        >
                            If
                        </span>
                    </Col>
                    <Col
                        span={24}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <span
                            style={{
                                paddingRight: '10px',
                                fontWeight: 'bold',
                            }}
                        >
                            Col 1
                        </span>
                        <Select
                            value={calculationRuleConditionHelper.getCol1Value(
                                availableSelectOptions,
                                calculationRuleValue,
                                columnId,
                                stepId
                            )}
                            status={calculationRuleConditionHelper.getStatusCol1(
                                calculationRuleValue,
                                columnId,
                                stepId
                            )}
                            style={{
                                minWidth: '160px',
                                textDecorationLine:
                                    calculationRuleConditionHelper.getTextDecorationCol1(
                                        calculationRuleValue,
                                        columnId,
                                        stepId
                                    ),
                            }}
                            loading={loading}
                            disabled={loading}
                            onChange={(item: string) => {
                                setLoading(true)
                                const selectedItem =
                                    availableSelectOptions.find(
                                        (column) => column.uniqueID === item
                                    )
                                if (selectedItem) {
                                    onChangeColumnValue('column1', selectedItem)
                                }
                                setLoading(false)
                            }}
                        >
                            {availableSelectOptions
                                .filter((item) => {
                                    return !(
                                        currentStepDetails?.condition
                                            ?.column2 &&
                                        currentStepDetails.condition.column2 ===
                                            item.id &&
                                        currentStepDetails.condition
                                            .column2Type === item.type
                                    )
                                })
                                .map((column) => (
                                    <Select.Option
                                        key={column.uniqueID}
                                        value={column.uniqueID}
                                    >
                                        {column.name}
                                    </Select.Option>
                                ))}
                        </Select>
                        {currentStepDetails?.condition?.column1Type ===
                            CalColumnTypes.constant && (
                            <Input
                                value={calculationRuleConditionHelper.getInputValueForCol1(
                                    calculationRuleValue,
                                    columnId,
                                    stepId
                                )}
                                type="number"
                                onChange={(e) => {
                                    setLoading(true)
                                    const selectedItem =
                                        availableSelectOptions.find(
                                            (column) =>
                                                column.uniqueID ===
                                                `-1-${CalColumnTypes.constant}`
                                        )
                                    if (selectedItem) {
                                        onChangeColumnValue('column1', {
                                            ...selectedItem,
                                            id: e.target.value,
                                        })
                                    }
                                    setLoading(false)
                                }}
                                placeholder="constant value"
                                style={{ width: '100px' }}
                            />
                        )}
                    </Col>
                    <Col
                        span={24}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <span
                            style={{
                                paddingRight: '10px',
                                fontWeight: 'bold',
                            }}
                        >
                            Operation
                        </span>
                        <Select
                            // @ts-ignore
                            value={calculationRuleConditionHelper.getOperationValue(
                                calculationRuleValue,
                                columnId,
                                stepId
                            )}
                            style={{ minWidth: '160px' }}
                            onChange={(item: CalConditionalOperations) => {
                                onChangeColumnValue(
                                    'operation',
                                    undefined,
                                    item
                                )
                            }}
                        >
                            {getEnumKeys(CalConditionalOperations).map(
                                (key) => (
                                    <Select.Option key={key} value={key}>
                                        {key}
                                    </Select.Option>
                                )
                            )}
                        </Select>
                    </Col>
                    <Col
                        span={24}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <span
                            style={{
                                paddingRight: '10px',
                                fontWeight: 'bold',
                            }}
                        >
                            Col 2
                        </span>
                        <Select
                            value={calculationRuleConditionHelper.getCol2Value(
                                availableSelectOptions,
                                calculationRuleValue,
                                columnId,
                                stepId
                            )}
                            status={calculationRuleConditionHelper.getStatusCol2(
                                calculationRuleValue,
                                columnId,
                                stepId
                            )}
                            style={{
                                minWidth: '160px',
                                textDecorationLine:
                                    calculationRuleConditionHelper.getTextDecorationCol2(
                                        calculationRuleValue,
                                        columnId,
                                        stepId
                                    ),
                            }}
                            onChange={(item: string) => {
                                setLoading(true)
                                const selectedItem =
                                    availableSelectOptions.find(
                                        (column) => column.uniqueID === item
                                    )
                                if (selectedItem) {
                                    onChangeColumnValue('column2', selectedItem)
                                }
                                setLoading(false)
                            }}
                            loading={loading}
                            disabled={loading}
                        >
                            {availableSelectOptions
                                .filter((item) => {
                                    return !(
                                        currentStepDetails?.condition
                                            ?.column1 &&
                                        currentStepDetails.condition.column1 ===
                                            item.id &&
                                        currentStepDetails.condition
                                            .column1Type === item.type
                                    )
                                })
                                .map((column) => (
                                    <Select.Option
                                        key={column.uniqueID}
                                        value={column.uniqueID}
                                    >
                                        {column.name}
                                    </Select.Option>
                                ))}
                        </Select>
                        {currentStepDetails?.condition?.column2Type ===
                            CalColumnTypes.constant && (
                            <Input
                                value={calculationRuleConditionHelper.getInputValueForCol2(
                                    calculationRuleValue,
                                    columnId,
                                    stepId
                                )}
                                type="number"
                                onChange={(e) => {
                                    setLoading(true)
                                    const selectedItem =
                                        availableSelectOptions.find(
                                            (column) =>
                                                column.uniqueID ===
                                                `-1-${CalColumnTypes.constant}`
                                        )
                                    if (selectedItem) {
                                        onChangeColumnValue('column2', {
                                            ...selectedItem,
                                            id: e.target.value,
                                        })
                                    }
                                    setLoading(false)
                                }}
                                placeholder="constant value"
                                style={{ width: '100px' }}
                            />
                        )}
                    </Col>
                </Space>
            </Row>
        </div>
    )
}
export default CalculationRuleConditionalStepCollapseWhenClosed
