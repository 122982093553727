import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Divider,
    Input,
    notification,
    Row,
    Select,
    Typography,
} from 'antd'
import { ISourceGroup } from '../../services/source-group/interfaces/source-group.interface'
import { ISourceDataset } from '../../services/source-group/interfaces/source-dataset.interface'
import { sourceGroupService } from '../../services/source-group/source-group.service'
import { handleAPIError } from '../../utils/catch-error'
import { sourceDatasetService } from '../../services/source-group/source-dataset.service'
import JoinSelectors from './join-selectors'
import { JoinTypesEnum } from '../../services/join-rule/interfaces/join-types.enum'
import NewJoinRuleConditions from './new-join-rule-conditions'
import { IJoinRuleRequest } from '../../services/join-rule/interfaces/join-rule-request.interface'
import { joinRuleService } from '../../services/join-rule/join-rule.service'
import { useNavigate } from 'react-router-dom'
import { routeMapMini } from '../../constants/routeMap'

function NewJoinRule(): JSX.Element {
    const navigate = useNavigate()
    const [joinRuleName, setJoinRuleName] = useState<string>('')
    const [sourceGroups, setSourceGroups] = useState<ISourceGroup[]>([])
    const [datasets, setDatasets] = useState<ISourceDataset[]>([])
    const [selectedJoinType, setSelectedJoinType] = useState<JoinTypesEnum>()
    const [selectedSourceGroup, setSelectedSourceGroup] =
        useState<ISourceGroup>()
    const [selectedLeftDataset, setSelectedLeftDataset] =
        useState<ISourceDataset>()
    const [selectedRightDataset, setSelectedRightDataset] =
        useState<ISourceDataset>()
    const [joinConditions, setJoinConditions] = useState<
        IJoinRuleRequest['JoinConditions']
    >([
        {
            rightColumn: '',
            leftColumn: '',
        },
    ])
    const [selectedLeftColumns, setSelectedLeftColumns] = useState<
        { id: number; columnName: string; alias: string }[]
    >([])
    const [selectedRightColumns, setSelectedRightColumns] = useState<
        { id: number; columnName: string; alias: string }[]
    >([])
    const [saveLoading, setSaveLoading] = useState<boolean>(false)

    useEffect(() => {
        sourceGroupService.getAll().then(setSourceGroups).catch(handleAPIError)
    }, [])

    useEffect(() => {
        setSelectedRightDataset(undefined)
        setSelectedLeftDataset(undefined)
        sourceDatasetService
            .getDatasetsForGroup(selectedSourceGroup?.SourceGroupID || 0)
            .then(setDatasets)
            .catch(handleAPIError)
    }, [selectedSourceGroup])

    const clearState = (): void => {
        setSelectedLeftDataset(undefined)
        setSelectedRightDataset(undefined)
        setSelectedLeftColumns([])
        setSelectedRightColumns([])
        setJoinConditions([{ leftColumn: '', rightColumn: '' }])
        setSelectedJoinType(undefined)
        setJoinRuleName('')
        setSelectedSourceGroup(undefined)
    }

    const saveRule = async (): Promise<void> => {
        if (!selectedSourceGroup) {
            notification.error({
                message: 'Error',
                description: 'Please select a data group',
            })
            return
        }
        if (!selectedLeftDataset || !selectedRightDataset) {
            notification.error({
                message: 'Error',
                description: 'Please select both datasets',
            })
            return
        }
        if (!selectedLeftColumns.length || !selectedRightColumns.length) {
            notification.error({
                message: 'Error',
                description: 'Please select at least one column',
            })
            return
        }
        if (!joinConditions.length) {
            notification.error({
                message: 'Error',
                description: 'Please select at least one join condition',
            })
            return
        }
        if (!selectedJoinType) {
            notification.error({
                message: 'Error',
                description: 'Please select join type',
            })
            return
        }
        setSaveLoading(true)
        try {
            await joinRuleService.createJoinRule({
                JoinRuleName: joinRuleName,
                JoinType: selectedJoinType,
                LeftDatasetID: selectedLeftDataset.DatasetID || -1,
                RightDatasetID: selectedRightDataset.DatasetID || -1,
                JoinSelectors: [
                    ...selectedLeftColumns.map((item) => ({
                        SelectorSide: 'left',
                        ColumnName: item.columnName,
                        Alias: item.alias,
                    })),
                    ...selectedRightColumns.map((item) => ({
                        SelectorSide: 'right',
                        ColumnName: item.columnName,
                        Alias: item.alias,
                    })),
                ],
                SourceGroupID: selectedSourceGroup?.SourceGroupID || -1,
                JoinConditions: joinConditions.map((item) => {
                    return {
                        leftColumn: item.leftColumn,
                        rightColumn: item.rightColumn,
                    }
                }),
            })
            notification.success({
                message: 'Join Rule Saved successfully',
            })
            clearState()
            navigate(routeMapMini.rulesJoin, { replace: true })
        } catch (e) {
            handleAPIError(e)
        }
        setSaveLoading(false)
    }

    return (
        <div style={{ padding: '10px' }}>
            <Typography.Title level={3}>Join Rule</Typography.Title>
            <Row style={{ margin: '10px' }}>
                <Col span={3}> Rule Name </Col>
                <Col span={4}>
                    <Input
                        style={{ maxWidth: '200px' }}
                        value={joinRuleName}
                        onChange={(e) => {
                            setJoinRuleName(e.target.value)
                        }}
                    />
                </Col>
                <Col span={2} />
                <Col span={3}> Data Group </Col>
                <Col span={4}>
                    <Select
                        style={{ minWidth: '200px' }}
                        onChange={(id: number) => {
                            setSelectedSourceGroup(
                                sourceGroups.find(
                                    (group) => group.SourceGroupID === id
                                )
                            )
                            setSelectedLeftDataset(undefined)
                            setSelectedRightDataset(undefined)
                        }}
                        value={selectedSourceGroup?.SourceGroupID}
                    >
                        {sourceGroups.map((group) => {
                            return (
                                <Select.Option
                                    key={group.SourceGroupID}
                                    value={group.SourceGroupID}
                                >
                                    {group.SourceGroupName}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Col>
            </Row>
            <Row style={{ margin: '10px' }}>
                <Col span={3}> Left Dataset </Col>
                <Col span={4}>
                    <Select
                        style={{ minWidth: '200px' }}
                        value={selectedLeftDataset?.DatasetID}
                        disabled={!selectedSourceGroup}
                        onChange={(id: number) => {
                            setSelectedLeftDataset(
                                datasets.find(
                                    (dataset) => dataset.DatasetID === id
                                )
                            )
                        }}
                    >
                        {datasets
                            .filter(
                                (dataset) =>
                                    selectedRightDataset?.DatasetID !==
                                    dataset.DatasetID
                            )
                            .map((dataset) => {
                                return (
                                    <Select.Option
                                        key={dataset.DatasetID}
                                        value={dataset.DatasetID}
                                    >
                                        {dataset.DatasetName}
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Col>
                <Col span={2} />
                <Col span={3}> Right Dataset </Col>
                <Col span={4}>
                    <Select
                        style={{ minWidth: '200px' }}
                        value={selectedRightDataset?.DatasetID}
                        disabled={!selectedSourceGroup}
                        onChange={(id: number) => {
                            setSelectedRightDataset(
                                datasets.find(
                                    (dataset) => dataset.DatasetID === id
                                )
                            )
                        }}
                    >
                        {datasets
                            .filter(
                                (dataset) =>
                                    selectedLeftDataset?.DatasetID !==
                                    dataset.DatasetID
                            )
                            .map((dataset) => {
                                return (
                                    <Select.Option
                                        key={dataset.DatasetID}
                                        value={dataset.DatasetID}
                                    >
                                        {dataset.DatasetName}
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Col>
            </Row>
            <Row style={{ margin: '10px' }}>
                <Col span={3}> Join Type </Col>
                <Col span={4}>
                    <Select
                        style={{ minWidth: '200px' }}
                        value={selectedJoinType}
                        onChange={(type: JoinTypesEnum) => {
                            setSelectedJoinType(type)
                        }}
                    >
                        {Object.values(JoinTypesEnum).map((type) => {
                            return (
                                <Select.Option key={type} value={type}>
                                    {type}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Col>
            </Row>
            <NewJoinRuleConditions
                selectedLeftDataset={selectedLeftDataset}
                selectedRightDataset={selectedRightDataset}
                joinConditions={joinConditions}
                setJoinConditions={setJoinConditions}
            />
            {selectedRightDataset && selectedLeftDataset && (
                <>
                    <Divider />
                    <JoinSelectors
                        leftColumns={sourceDatasetService.getDatasetColumnNames(
                            selectedLeftDataset
                        )}
                        rightColumns={sourceDatasetService.getDatasetColumnNames(
                            selectedRightDataset
                        )}
                        selectedLeftColumns={selectedLeftColumns}
                        selectedRightColumns={selectedRightColumns}
                        setSelectedLeftColumns={setSelectedLeftColumns}
                        setSelectedRightColumns={setSelectedRightColumns}
                        leftSourceDatasetName={selectedLeftDataset.DatasetName}
                        rightSourceDatasetName={
                            selectedRightDataset.DatasetName
                        }
                    />
                </>
            )}
            <Divider />
            <Button
                type="primary"
                disabled={!selectedRightDataset || !selectedLeftDataset}
                loading={saveLoading}
                onClick={saveRule}
            >
                Save Rule
            </Button>
        </div>
    )
}

export default NewJoinRule
