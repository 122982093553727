import * as React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { Button, Input, Space, Typography, Upload } from 'antd'
import { FileUploadService } from '../../../services/file-upload/file-upload.service'
import { handleAPIError } from '../../../utils/catch-error'
import { RcFile } from 'antd/es/upload'
import * as XLSX from 'xlsx'
import { FileProperties } from '../../../services/file-upload/interfaces/file-properties.interface'
import { xlsxUtil } from '../../../utils/xlsx.util'

type IProps = {
    setAllocationFileName: React.Dispatch<
        React.SetStateAction<string | undefined>
    >
    allocationFileName: string | undefined
    fileProperties: FileProperties
    setFileProperties: React.Dispatch<React.SetStateAction<FileProperties>>
    allocationGroupName: string | undefined
    allocationGroupId: number | undefined
    setNewFileColumns: React.Dispatch<React.SetStateAction<string[]>>
}

function CreateAllocationFile({
    allocationGroupName,
    allocationFileName,
    setAllocationFileName,
    fileProperties,
    setFileProperties,
    allocationGroupId,
    setNewFileColumns,
}: IProps): JSX.Element {
    const [fileUploadLoading, setFileUploadLoading] = React.useState(false)

    const generateFileName = (originalFileName: string): string => {
        const fileName = originalFileName.split('.')
        const fileExtension = fileName[fileName.length - 1]
        return `allocation_rule_group_files/${allocationGroupId}/${Date.now()}.${fileExtension}`
    }

    const uploadFile = async (options: UploadRequestOption): Promise<void> => {
        const { onSuccess, onError, file, onProgress } = options
        try {
            const resp = await FileUploadService.uploadFile(
                // @ts-ignore
                file,
                // @ts-ignore
                generateFileName(file.name),
                onProgress
            )
            if (resp)
                setFileProperties({
                    ...fileProperties,
                    FileLocation: resp.fileLocation,
                })
            onSuccess?.('Ok')
        } catch (err: any) {
            // @ts-ignore
            onError?.({ err })
            handleAPIError(err)
        }
    }

    const beforeUpload = async (file: RcFile): Promise<boolean> => {
        setFileUploadLoading(true)
        const arrayBuffer = await file.arrayBuffer()
        const workbook = XLSX.read(arrayBuffer, {
            type: 'array',
            sheetRows: 50,
        })
        const sheet1 = workbook.Sheets[workbook.SheetNames[0]]
        const sheetInJson: [][] = XLSX.utils.sheet_to_json(sheet1, {
            header: 1,
        })
        setNewFileColumns(sheetInJson?.[0])
        setFileUploadLoading(false)
        setFileProperties({
            ...fileProperties,
            FilePreviewData: xlsxUtil.convertCSV2DArrayToObject(sheetInJson),
            FileName: file.name,
        })
        return true
    }

    return (
        <Space direction="vertical" size="middle">
            <Typography.Text>
                Creating Allocation Rule File in Group{' '}
                <b>{allocationGroupName}</b>
            </Typography.Text>
            <Input
                onChange={(e) => setAllocationFileName(e.target.value)}
                placeholder="Enter Allocation File Name"
                value={allocationFileName}
            />
            <Upload customRequest={uploadFile} beforeUpload={beforeUpload}>
                <Button loading={fileUploadLoading}>Upload File</Button>
            </Upload>
        </Space>
    )
}

export default CreateAllocationFile
