import { TopLevelLineage } from './itnerfaces/lineage.interface'
import { AxiosX } from '../../utils/axios'

export const lineageService = {
    getTopLevelLineage: async (
        sourceId: number
    ): Promise<TopLevelLineage[]> => {
        const resp = await AxiosX.get(`v1/lineage/top-level/${sourceId}`)

        return resp.data
    },
}
