import React from 'react'
import { Route, Routes as DomRoutes } from 'react-router-dom'
import { routeMapMini } from './constants/routeMap'
import Run from './components/run'
import NewCalculationRule from './components/calculation-rules/new-calculation-rule'
import SourceGroup from './components/source-group/source-group'
import SourceFile from './components/source-group/source-files/source-file'
import AllocationRuleGroup from './components/allocation-rule-group/allocation-rule-group'
import AllocationRuleFiles from './components/allocation-rule-group/allocation-rule-files/allocation-rule-files'
import ConfigFile from './components/config-metadata/config-file'
import SourceDataset from './components/source-group/source-dataset/source-dataset'
import CreateRun from './components/run/create-run'
import CalculationRule from './components/calculation-rules'
import NewJoinRule from './components/join-rules/new-join-rule'
import JoinRule from './components/join-rules'
import CreateSourceDataset from './components/source-group/source-dataset/create-source-dataset'
import CreateAllocationRuleGroup from './components/allocation-rule-group/create-allocation-rule-group'
import UserManagement from './components/user-management/user-management'
import MergeRuleIndexRule from './components/merge-rules/index'
import NewMergeRule from './components/merge-rules/new-merge-rule'
import TeamUsers from './components/user-management/team-users/team-users'
import UserTeams from './components/user-management/user-teams/user-teams'
import CreateNewTeam from './components/user-management/create-new-team'
import Lineage from './components/lineage/lineage'
import Dashboard from './components/dashboard/dashboard'

function Routes(): JSX.Element {
    return (
        <DomRoutes>
            <Route path={routeMapMini.dashboard} element={<Dashboard />} />
            <Route
                path={routeMapMini.userManagement}
                element={<UserManagement />}
            />
            <Route path={routeMapMini.teamUsers} element={<TeamUsers />} />
            <Route path={routeMapMini.userTeams} element={<UserTeams />} />
            <Route
                path={routeMapMini.createNewTeam}
                element={<CreateNewTeam />}
            />
            <Route path={routeMapMini.runHistory} element={<Run />} />
            <Route path={routeMapMini.createRun} element={<CreateRun />} />
            <Route
                path={routeMapMini.rulesCalculation}
                element={<CalculationRule />}
            />
            <Route
                path={routeMapMini.rulesCalculationNew}
                element={<NewCalculationRule />}
            />
            <Route
                path={routeMapMini.rulesCalculationView}
                element={<NewCalculationRule />}
            />
            <Route path={routeMapMini.rulesJoin} element={<JoinRule />} />
            <Route path={routeMapMini.rulesJoinNew} element={<NewJoinRule />} />
            <Route
                path={routeMapMini.rulesMerge}
                element={<MergeRuleIndexRule />}
            />
            <Route
                path={routeMapMini.rulesMergeNew}
                element={<NewMergeRule />}
            />
            <Route path={routeMapMini.sourceGroup} element={<SourceGroup />} />
            <Route
                path={routeMapMini.sourceDataset}
                element={<SourceDataset />}
            />
            <Route
                path={routeMapMini.sourceDatasetNew}
                element={<CreateSourceDataset />}
            />
            <Route path={routeMapMini.sourceFiles} element={<SourceFile />} />
            <Route
                path={routeMapMini.rulesAllocation}
                element={<AllocationRuleGroup />}
            />
            <Route
                path={routeMapMini.rulesAllocationNew}
                element={<CreateAllocationRuleGroup />}
            />
            <Route
                path={routeMapMini.rulesAllocationFiles}
                element={<AllocationRuleFiles />}
            />
            <Route
                path={routeMapMini.configMetadata}
                element={<ConfigFile />}
            />
            <Route path={routeMapMini.topLevelLineage} element={<Lineage />} />
        </DomRoutes>
    )
}

export default Routes
