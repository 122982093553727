import { ICalcColumnOption } from './calculation-rule.helper'
import { ICalculationRuleContext } from './new-calculation-rule'
import {
    CalColumnTypes,
    CalConditionalOperations,
} from '../../services/calculation-rule/interfaces/calculation-rule.interface'
import { getEnumKeys } from '../../utils/enum'

export const calculationRuleConditionHelper = {
    getCol1Value: (
        availableSelectOptions: ICalcColumnOption[],
        calculationRuleValue: ICalculationRuleContext | undefined,
        columnId: number,
        stepId: number
    ): string | undefined => {
        return availableSelectOptions.find((item) => {
            let uniqueID
            uniqueID = `${
                calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                    stepId - 1
                ]?.condition?.column1
            }-${
                calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                    stepId - 1
                ].condition?.column1Type
            }`

            if (
                calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                    stepId - 1
                ].condition?.column1Type === CalColumnTypes.constant
            ) {
                uniqueID = `-1-constant`
            }

            if (uniqueID === item.uniqueID) {
                return true
            }
            return false
        })?.uniqueID
    },

    getInputValueForCol1: (
        calculationRuleValue: ICalculationRuleContext | undefined,
        columnId: number,
        stepId: number
    ): string | undefined => {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.column1 === -1
        ) {
            return undefined
        }
        return String(
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.column1
        )
    },

    getOperationValue: (
        calculationRuleValue: ICalculationRuleContext | undefined,
        columnId: number,
        stepId: number
    ): string | undefined => {
        return getEnumKeys(CalConditionalOperations).find((item) => {
            const uniqueID = `${
                calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                    stepId - 1
                ]?.condition?.operation
            }`

            if (uniqueID === item) {
                return true
            }

            return false
        })
    },

    getCol2Value: (
        availableSelectOptions: ICalcColumnOption[],
        calculationRuleValue: ICalculationRuleContext | undefined,
        columnId: number,
        stepId: number
    ): string | undefined => {
        return availableSelectOptions.find((item) => {
            let uniqueID
            uniqueID = `${
                calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                    stepId - 1
                ]?.condition?.column2
            }-${
                calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                    stepId - 1
                ].condition?.column2Type
            }`

            if (
                calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                    stepId - 1
                ].condition?.column2Type === CalColumnTypes.constant
            ) {
                uniqueID = `-1-constant`
            }

            if (uniqueID === item.uniqueID) {
                return true
            }

            return false
        })?.uniqueID
    },

    getInputValueForCol2: (
        calculationRuleValue: ICalculationRuleContext | undefined,
        columnId: number,
        stepId: number
    ): string | undefined => {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.column2 === -1
        ) {
            return undefined
        }
        return String(
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.column2
        )
    },

    getStatusCol1: (
        calculationRuleValue: ICalculationRuleContext | undefined,
        columnId: number,
        stepId: number
    ): 'warning' | 'error' | undefined => {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.column1 === undefined &&
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.statusOfColumn1 === false
        ) {
            return 'error'
        }
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.textDecorationOfColumn1 === false
        ) {
            return 'warning'
        }
        return undefined
    },

    getStatusCol2: (
        calculationRuleValue: ICalculationRuleContext | undefined,
        columnId: number,
        stepId: number
    ): 'warning' | 'error' | undefined => {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.column2 === undefined &&
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.statusOfColumn2 === false
        ) {
            return 'error'
        }
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.textDecorationOfColumn2 === false
        ) {
            return 'warning'
        }
        return undefined
    },

    getTextDecorationCol1: (
        calculationRuleValue: ICalculationRuleContext | undefined,
        columnId: number,
        stepId: number
    ): 'line-through' | undefined => {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.textDecorationOfColumn1 === false
        ) {
            return 'line-through'
        }
        return undefined
    },

    getTextDecorationCol2: (
        calculationRuleValue: ICalculationRuleContext | undefined,
        columnId: number,
        stepId: number
    ): 'line-through' | undefined => {
        if (
            calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
                .condition?.textDecorationOfColumn2 === false
        ) {
            return 'line-through'
        }
        return undefined
    },
}
