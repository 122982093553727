import React, { useEffect } from 'react'
import {
    Badge,
    Button,
    Col,
    Form,
    Input,
    notification,
    Row,
    Select,
    Space,
    Typography,
} from 'antd'
import { ICalculationRuleResponse } from '../../services/calculation-rule/interfaces/calculation-rule-response.interface'
import { calculationRuleService } from '../../services/calculation-rule/calculation-rule.service'
import { handleAPIError } from '../../utils/catch-error'
import { sourceFileInfoService } from '../../services/source-group/source-file-info.service'
import { runService } from '../../services/run/run.service'
import { InfoCircleOutlined } from '@ant-design/icons'

function CreateCalculationRun({
    nextStep,
}: {
    nextStep: () => void
}): JSX.Element {
    const [form] = Form.useForm()
    const [loading, setLoading] = React.useState(false)

    const [calRules, setCalRules] = React.useState<ICalculationRuleResponse[]>(
        []
    )
    const [selectedCalRule, setSelectedCalRule] =
        React.useState<ICalculationRuleResponse>()
    const [sourceFiles, setSourceFiles] = React.useState<any[]>([])
    const [warningMessage, setWarningMessage] = React.useState('')

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true)
        try {
            await runService.createCalculationRun({
                RunName: values['Run Name'],
                CalculationRuleID: values['Calculation Rule'],
                SourceFileID: values['Source Name'],
                OutDatasetName: values['New Dataset Name'],
                OutSourceFileName: values['New File Name'],
            })
            notification.success({
                message: 'Success',
                description: 'Run created successfully',
            })
            form.resetFields()
            setLoading(false)
            nextStep()
        } catch (error) {
            handleAPIError(error)
            setLoading(false)
        }
    }

    const onReset = (): void => {
        form.resetFields()
        setSelectedCalRule(undefined)
        setSourceFiles([])
        setWarningMessage('')
    }

    useEffect(() => {
        calculationRuleService.getAll().then(setCalRules).catch(handleAPIError)
    }, [])

    useEffect(() => {
        sourceFileInfoService
            .getDataProfileValidationsForCalRuleID(
                selectedCalRule?.CalculationRuleID || 0
            )
            .then(setSourceFiles)
            .catch(handleAPIError)
    }, [selectedCalRule])

    return (
        <div
            style={{
                paddingTop: '30px',
                margin: '0 auto',
                display: 'table',
                minWidth: '400px',
            }}
        >
            <Form
                layout="vertical"
                form={form}
                name="create-run"
                onFinish={onFinish}
                style={{ maxWidth: '400px' }}
            >
                <Form.Item
                    name="Run Name"
                    label="Run Name"
                    rules={[
                        {
                            required: true,
                            min: 3,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="Calculation Rule"
                    label="Calculation Rule"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Select Calculation Rule"
                        filterOption={(input, option) =>
                            option?.children
                                // @ts-ignore
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value: number) => {
                            setSelectedCalRule(
                                calRules.find(
                                    (item) => item.CalculationRuleID === value
                                )
                            )
                            form.resetFields(['Source Name'])
                        }}
                        allowClear
                    >
                        {calRules.map((item) => (
                            <Select.Option
                                key={item.CalculationRuleID}
                                value={item.CalculationRuleID}
                            >
                                {item.CalculationRuleName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {selectedCalRule && (
                    <Typography.Paragraph
                        style={{
                            marginTop: '-16px',
                            fontSize: '13px',
                            marginLeft: '3px',
                        }}
                    >
                        Associated to Data Group:{' '}
                        {selectedCalRule.SourceGroupName} | Dataset:{' '}
                        {selectedCalRule.DatasetName}
                    </Typography.Paragraph>
                )}
                <Form.Item
                    name="Source Name"
                    label="Source Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    tooltip={{
                        title: `Black - Validations Pending 
                         | Amber - Validations passed with warnings 
                         | Red - Validations Failed 
                         | Green - Validations Passed`,
                        icon: <InfoCircleOutlined />,
                    }}
                >
                    <Select
                        showSearch
                        placeholder="Select Source name"
                        filterOption={(input, option) =>
                            option?.children
                                // @ts-ignore
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => {
                            const selectedSourceFile = sourceFiles.find(
                                (item) => item.SourceFileID === e
                            )
                            if (!selectedSourceFile.IsValidated) {
                                setWarningMessage(
                                    'File has not been validated.'
                                )
                            } else if (selectedSourceFile.WarnDescriptions) {
                                setWarningMessage(
                                    selectedSourceFile.WarnDescriptions
                                )
                            } else {
                                setWarningMessage('')
                            }
                        }}
                        allowClear
                        disabled={!selectedCalRule}
                    >
                        {sourceFiles.map((item) => {
                            let color = 'black'
                            if (item.IsValidated) {
                                if (item.WarnDescriptions && item.IsValid) {
                                    color = 'orange'
                                } else {
                                    color = item.IsValid ? 'green' : 'red'
                                }
                            }

                            return (
                                <Select.Option
                                    key={item.SourceFileID}
                                    value={item.SourceFileID}
                                    disabled={item.IsValid === 0}
                                >
                                    <Badge color={color} />
                                    {item.SourceName}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>
                {warningMessage && (
                    <Typography.Paragraph
                        style={{
                            marginTop: '-16px',
                            fontSize: '13px',
                            marginLeft: '3px',
                        }}
                    >
                        warning: {warningMessage}
                    </Typography.Paragraph>
                )}
                <Row gutter={[20, 0]}>
                    <Col span={12}>
                        <Form.Item
                            name="New Dataset Name"
                            label="New Dataset Name"
                            rules={[
                                {
                                    required: true,
                                    min: 3,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="New File Name"
                            label="New File Name"
                            rules={[
                                {
                                    required: true,
                                    min: 3,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Form.Item>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Submit
                            </Button>
                            <Button
                                htmlType="button"
                                onClick={onReset}
                                disabled={loading}
                            >
                                Reset
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    )
}

export default CreateCalculationRun
