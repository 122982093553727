/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useContext, useMemo } from 'react'
import { Col, Row, Space } from 'antd'
import { CalculationRuleContext } from './new-calculation-rule'
import {
    ArithmeticSymbolMapping,
    CalAggregateOperations,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    CalConditionalOperations,
    excludecondition,
} from '../../services/calculation-rule/interfaces/calculation-rule.interface'
import { calculationRuleHelper } from './calculation-rule.helper'

function ViewCalculationRuleConditionStep({
    stepId,
    columnId,
    name,
}: {
    stepId: number
    columnId: number
    name: string
}): JSX.Element {
    const calculationRuleValue = useContext(CalculationRuleContext)

    const currentStepDetails = useMemo(() => {
        return calculationRuleValue?.columns
            ?.find((column) => column.columnID === columnId)
            ?.steps?.find((step) => step.stepID === stepId)
    }, [calculationRuleValue, columnId, stepId])

    return (
        <Row justify="start" style={{ marginTop: '10px' }}>
            <Col span={4}>
                <b>{name}</b>
                <i> (condition)</i>
            </Col>
            <Col span={17}>
                <Row>
                    <Col>
                        <span
                            style={{
                                paddingRight: '10px',
                                fontWeight: 'bold',
                            }}
                        >
                            =
                        </span>
                    </Col>
                    <Col>
                        <Space>
                            {calculationRuleHelper.getColumnNameFromColumnType(
                                calculationRuleValue,
                                currentStepDetails?.condition?.Column1Type,
                                currentStepDetails?.condition?.Column1,
                                columnId
                            )}
                            <p>
                                {
                                    CalConditionalOperations[
                                        currentStepDetails?.condition?.Operator
                                    ]
                                }
                            </p>
                            {calculationRuleHelper.getColumnNameFromColumnType(
                                calculationRuleValue,
                                currentStepDetails?.condition?.Column2Type,
                                currentStepDetails?.condition?.Column2,
                                columnId
                            )}
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <span
                            style={{
                                paddingRight: '20px',
                                fontWeight: 'bold',
                            }}
                        />
                        Then (
                        {currentStepDetails?.condition?.if.ConditionStepType})
                    </Col>
                    {currentStepDetails?.condition?.if.ConditionStepType ===
                    excludecondition.arithmetic ? (
                        <Col span={17}>
                            <Row>
                                <Col>
                                    <span
                                        style={{
                                            paddingRight: '10px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        =
                                    </span>
                                </Col>
                                <Col>
                                    <Space>
                                        {calculationRuleHelper.getColumnNameFromColumnType(
                                            calculationRuleValue,
                                            currentStepDetails?.condition?.if
                                                .arithmetic?.Column1Type,
                                            currentStepDetails?.condition?.if
                                                .arithmetic.Column1,
                                            columnId
                                        )}
                                        <p>
                                            {
                                                ArithmeticSymbolMapping[
                                                    currentStepDetails
                                                        ?.condition?.if
                                                        .arithmetic.Operator
                                                ]
                                            }
                                        </p>
                                        {calculationRuleHelper.getColumnNameFromColumnType(
                                            calculationRuleValue,
                                            currentStepDetails?.condition?.if
                                                .arithmetic?.Column2Type,
                                            currentStepDetails?.condition?.if
                                                .arithmetic?.Column2,
                                            columnId
                                        )}
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    ) : currentStepDetails?.condition?.if.ConditionStepType ===
                      excludecondition.aggregate ? (
                        <Col span={17}>
                            <Row>
                                <Col>
                                    <span
                                        style={{
                                            paddingRight: '10px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        =
                                    </span>
                                </Col>
                                <Col>
                                    <Space>
                                        <p>
                                            <b>
                                                {
                                                    currentStepDetails
                                                        ?.condition?.if
                                                        .aggregate?.Operator
                                                }
                                            </b>
                                        </p>
                                        <p>
                                            <i>
                                                {currentStepDetails?.condition
                                                    ?.if.aggregate?.Operator ===
                                                CalAggregateOperations.Average
                                                    ? 'on'
                                                    : 'of'}
                                            </i>
                                        </p>
                                        {calculationRuleHelper.getColumnNameFromColumnType(
                                            calculationRuleValue,
                                            currentStepDetails?.condition.if
                                                .aggregate?.Column1Type,
                                            currentStepDetails?.condition.if
                                                .aggregate?.Column1,
                                            columnId
                                        )}
                                        {currentStepDetails?.condition?.if
                                            .aggregate?.groupBy?.length && (
                                            <>
                                                <p>
                                                    <i>Group By</i>
                                                </p>
                                                {currentStepDetails?.condition.if.aggregate?.groupBy.map(
                                                    (groupBy, index) => {
                                                        return (
                                                            <>
                                                                {calculationRuleHelper.getColumnNameFromColumnType(
                                                                    calculationRuleValue,
                                                                    groupBy.ColumnType,
                                                                    groupBy.ColumnValue,
                                                                    columnId,
                                                                    (currentStepDetails
                                                                        .condition
                                                                        ?.if
                                                                        .aggregate
                                                                        ?.groupBy
                                                                        ?.length ||
                                                                        0) -
                                                                        1 !==
                                                                        index
                                                                )}
                                                            </>
                                                        )
                                                    }
                                                )}
                                            </>
                                        )}
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    ) : currentStepDetails?.condition?.if.ConditionStepType ===
                      excludecondition.column ? (
                        <Col span={17}>
                            <Row>
                                <Col>
                                    <span
                                        style={{
                                            paddingRight: '10px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        =
                                    </span>
                                </Col>
                                <Col>
                                    <Space>
                                        {calculationRuleHelper.getColumnNameFromColumnType(
                                            calculationRuleValue,
                                            currentStepDetails?.condition?.if
                                                .column?.Column1Type,
                                            currentStepDetails?.condition?.if
                                                .column.Column1,
                                            columnId
                                        )}
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    ) : null}
                </Row>
                <Row>
                    <Col span={4}>
                        <span
                            style={{
                                paddingRight: '20px',
                                fontWeight: 'bold',
                            }}
                        />
                        Else (
                        {currentStepDetails?.condition?.else.ConditionStepType})
                    </Col>
                    {currentStepDetails?.condition?.else.ConditionStepType ===
                    excludecondition.arithmetic ? (
                        <Col span={17}>
                            <Row>
                                <Col>
                                    <span
                                        style={{
                                            paddingRight: '10px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        =
                                    </span>
                                </Col>
                                <Col>
                                    <Space>
                                        {calculationRuleHelper.getColumnNameFromColumnType(
                                            calculationRuleValue,
                                            currentStepDetails?.condition?.else
                                                .arithmetic?.Column1Type,
                                            currentStepDetails?.condition?.else
                                                .arithmetic.Column1,
                                            columnId
                                        )}
                                        <p>
                                            {
                                                ArithmeticSymbolMapping[
                                                    currentStepDetails
                                                        ?.condition?.else
                                                        .arithmetic.Operator
                                                ]
                                            }
                                        </p>
                                        {calculationRuleHelper.getColumnNameFromColumnType(
                                            calculationRuleValue,
                                            currentStepDetails?.condition?.else
                                                .arithmetic?.Column2Type,
                                            currentStepDetails?.condition?.else
                                                .arithmetic?.Column2,
                                            columnId
                                        )}
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    ) : currentStepDetails?.condition?.else
                          .ConditionStepType === excludecondition.aggregate ? (
                        <Col span={17}>
                            <Row>
                                <Col>
                                    <span
                                        style={{
                                            paddingRight: '10px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        =
                                    </span>
                                </Col>
                                <Col>
                                    <Space>
                                        <p>
                                            <b>
                                                {
                                                    currentStepDetails
                                                        ?.condition?.else
                                                        .aggregate?.Operator
                                                }
                                            </b>
                                        </p>
                                        <p>
                                            <i>
                                                {currentStepDetails?.condition
                                                    ?.else.aggregate
                                                    ?.Operator ===
                                                CalAggregateOperations.Average
                                                    ? 'on'
                                                    : 'of'}
                                            </i>
                                        </p>
                                        {calculationRuleHelper.getColumnNameFromColumnType(
                                            calculationRuleValue,
                                            currentStepDetails?.condition.else
                                                .aggregate?.Column1Type,
                                            currentStepDetails?.condition.else
                                                .aggregate?.Column1,
                                            columnId
                                        )}
                                        {currentStepDetails?.condition?.else
                                            .aggregate?.groupBy?.length && (
                                            <>
                                                <p>
                                                    <i>Group By</i>
                                                </p>
                                                {currentStepDetails?.condition.else.aggregate?.groupBy.map(
                                                    (groupBy, index) => {
                                                        return (
                                                            <>
                                                                {calculationRuleHelper.getColumnNameFromColumnType(
                                                                    calculationRuleValue,
                                                                    groupBy.ColumnType,
                                                                    groupBy.ColumnValue,
                                                                    columnId,
                                                                    (currentStepDetails
                                                                        .condition
                                                                        ?.else
                                                                        .aggregate
                                                                        ?.groupBy
                                                                        ?.length ||
                                                                        0) -
                                                                        1 !==
                                                                        index
                                                                )}
                                                            </>
                                                        )
                                                    }
                                                )}
                                            </>
                                        )}
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    ) : currentStepDetails?.condition?.else
                          .ConditionStepType === excludecondition.column ? (
                        <Col span={17}>
                            <Row>
                                <Col>
                                    <span
                                        style={{
                                            paddingRight: '10px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        =
                                    </span>
                                </Col>
                                <Col>
                                    <Space>
                                        {calculationRuleHelper.getColumnNameFromColumnType(
                                            calculationRuleValue,
                                            currentStepDetails?.condition?.else
                                                .column?.Column1Type,
                                            currentStepDetails?.condition?.else
                                                .column.Column1,
                                            columnId
                                        )}
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    ) : null}
                </Row>
            </Col>
        </Row>
    )
}

export default ViewCalculationRuleConditionStep
