import { Space, Table } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { routeMapMini } from '../../../constants/routeMap'
import { ITeams } from '../../../services/user-management/interfaces/teams.interface'
import { ITeamUsers } from '../../../services/user-management/interfaces/teamusers.interface'
import { IUsers } from '../../../services/user-management/interfaces/users.interface'
import { teamUserService } from '../../../services/user-management/team-user.service'
import { handleAPIError } from '../../../utils/catch-error'
import { getColumnSearchProps } from '../../../utils/columnsearch-props'

function UserTeamsTable({
    userId,
    loading,
    setLoading,
    refreshTable,
}: {
    userId: number
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    refreshTable: boolean
}): JSX.Element {
    const [userTeams, setUserTeams] = React.useState<IUsers[] | undefined>(
        undefined
    )
    const [searchText, setSearchText] = React.useState('')

    const getUserTeams = async (): Promise<void> => {
        setLoading(true)
        try {
            const teamUser = await teamUserService.getUserTeams(userId)
            setUserTeams(teamUser)
        } catch (error: unknown) {
            handleAPIError(error)
        }
        setLoading(false)
    }

    React.useEffect(() => {
        if (userId && refreshTable) {
            getUserTeams()
        }
    }, [userId, refreshTable])

    const columns = [
        {
            title: 'Team ID',
            dataIndex: 'TeamID',
            key: 'TeamID',
            sorter: (a: ITeams, b: ITeams) => {
                return (a.TeamID || 1) > (b.TeamID || 1) ? 1 : -1
            },
        },
        {
            title: 'Team Name',
            dataIndex: 'TeamName',
            key: 'TeamUserID',
            ...getColumnSearchProps('TeamName', searchText, setSearchText),
        },
        {
            title: 'Added By',
            dataIndex: 'AddedByUser',
            key: 'TeamUserID',
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'TeamUserID',
            render: (_: string, record: ITeamUsers) => (
                <Space>
                    <Link
                        to={`${routeMapMini.teamUsers}?teamId=${record.TeamID}`}
                    >
                        Browse Team
                    </Link>
                    |<a>Remove from team</a>
                </Space>
            ),
        },
    ]

    return <Table columns={columns} dataSource={userTeams} loading={loading} />
}

export default UserTeamsTable
