import { Button, Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { routeMapMini } from '../../constants/routeMap'
import CalculationRuleTable from './calculation-rule-table'

function CalculationRule(): JSX.Element {
    return (
        <div>
            <div
                style={{
                    float: 'left',
                    marginLeft: '15px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            >
                <Row gutter={[10, 0]}>
                    <Col>
                        <Link to={routeMapMini.rulesCalculationNew}>
                            <Button type="primary">Create New Rule</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div style={{ margin: '15px' }}>
                <CalculationRuleTable />
            </div>
        </div>
    )
}

export default CalculationRule
