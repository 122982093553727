/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import HomeSider from './Sider'
import HomeHeader from './Header/Header'
import { withRouter } from '../../utils/withRouter'
import Routes from '../../Routes'
import BreadCrumb from '../../utils/breadcrumb'
import ErrorBoundary from '../error-boundary/error-boundary'
import './Slider.css'
import { useThemeSwitcher } from 'react-css-theme-switcher'

function HomeLayout(): JSX.Element {
    const [siderCollapsed, setSiderCollapsed] = useState(true)
    const { currentTheme } = useThemeSwitcher()

    const toggleSider = (): void => {
        setSiderCollapsed(!siderCollapsed)
    }

    const [collapsedWidth, setCollapsedWidth] = useState(80)

    const screens = useBreakpoint()

    useEffect(() => {
        if (screens?.xl || screens?.xxl || screens?.lg) {
            setCollapsedWidth(80)
        } else {
            setCollapsedWidth(0)
        }
    }, [screens])

    return (
        <Layout>
            <HomeSider
                siderCollapsed={siderCollapsed}
                toggleSider={toggleSider}
            />
            <Layout className="site-layout">
                <HomeHeader
                    toggleSider={toggleSider}
                    siderCollapsed={siderCollapsed}
                />
                <div
                    style={{
                        marginLeft: siderCollapsed ? collapsedWidth : 200,
                        marginTop: '85px',
                    }}
                >
                    <div
                        className="xp-breadcrumb"
                        style={{
                            backgroundColor:
                                currentTheme === 'dark' ? '#1f1f1f' : '#ffffff',
                            zIndex: 5,
                        }}
                    >
                        <BreadCrumb />
                    </div>
                    <div
                        className="xp-body"
                        style={{
                            backgroundColor:
                                currentTheme === 'dark' ? '#303030' : '#ebe9e9',
                        }}
                    >
                        <ErrorBoundary>
                            <Routes />
                        </ErrorBoundary>
                    </div>
                </div>
                <Layout.Footer
                    className="xp-footer"
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        right: 0,
                        height: '55px',
                        background:
                            currentTheme === 'dark'
                                ? `#3d3f42 url(${'/dark-theme-footer-image.png'}) no-repeat center center`
                                : `#e7f6fb url(${'/light-theme-footer-image.png'}) no-repeat center center`,
                        width: siderCollapsed
                            ? 'calc(100% - 68px)'
                            : 'calc(100% - 200px)',
                    }}
                />
            </Layout>
        </Layout>
    )
}

export default withRouter(HomeLayout)
