import {
    ArithmeticOperations,
    CalColumnTypes,
    CalculationRuleStepType,
    excludecondition,
    IServiceCalculationRule,
} from './interfaces/calculation-rule.interface'
import { AxiosX } from '../../utils/axios'
import { ICalculationRuleContext } from '../../components/calculation-rules/new-calculation-rule'
import { ISourceDataset } from '../source-group/interfaces/source-dataset.interface'
import { ICalculationRuleResponse } from './interfaces/calculation-rule-response.interface'
import { ICalculationRuleArithmeticStep } from '../../components/calculation-rules/calculation-rule-arithmetic-step'
import { ICalculationRuleAggregateStep } from '../../components/calculation-rules/calculation-rule-aggregate-step'
import {
    ICalculationConditionReturn,
    ICalculationRuleConditionStep,
} from '../../components/calculation-rules/calculation-rule-conditional-step'
import { ICalculationRuleStep } from '../../components/calculation-rules/calculation-rule-steps'

export const calculationRuleService = {
    getAll: async (): Promise<ICalculationRuleResponse[]> => {
        const data = await AxiosX.get<ICalculationRuleResponse[]>(
            'v1/calculation-rule/all'
        )

        return data.data
    },

    getCalRulesForDataset: async (datasetID: number): Promise<any[]> => {
        const data = await AxiosX.get(
            `v1/calculation-rule/by-dataset-id/${datasetID}`
        )

        return data.data
    },

    getCalculationRuleStructure: async (
        calculationRuleId: number
    ): Promise<IServiceCalculationRule> => {
        const data = await AxiosX.get(
            `v1/calculation-rule/structure/by-id/${calculationRuleId}`
        )

        return data.data
    },

    async saveCalculationRule(
        data: ICalculationRuleContext['columns'],
        dataset: ISourceDataset | undefined,
        ruleName: string
    ): Promise<void> {
        // let con1: ICalculationConditionReturn
        // let con2: ICalculationConditionReturn
        const requestPayload: IServiceCalculationRule = {
            datasetID: dataset?.DatasetID || -1,
            calculationRuleName: ruleName,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            columns: data.map((column, index) => {
                if (column.steps.length < 1) {
                    throw new Error(`Column ${column.columnName} has no steps.`)
                }
                return {
                    columnName: column.columnName,
                    columnID: column.columnID,
                    steps: column.steps.map((step) => {
                        let arithmetic:
                            | ICalculationRuleArithmeticStep
                            | undefined
                        let aggregate: ICalculationRuleAggregateStep | undefined
                        let condition: ICalculationRuleConditionStep | undefined

                        if (
                            step.stepType === CalculationRuleStepType.arithmetic
                        ) {
                            if (!step.arithmetic?.operation) {
                                throw new Error(
                                    `No operation selected in column ${column.columnName} and step ${step.tempStepName}`
                                )
                            }

                            if (
                                !step.arithmetic?.column1Type ||
                                !step.arithmetic?.column1
                            ) {
                                throw new Error(
                                    `column1 not selected in column ${column.columnName} and step ${step.tempStepName}`
                                )
                            }

                            if (
                                !step.arithmetic?.column2Type ||
                                !step.arithmetic?.column2
                            ) {
                                throw new Error(
                                    `column2 not selected in column ${column.columnName} and step ${step.tempStepName}`
                                )
                            }

                            arithmetic = {
                                column1: String(step.arithmetic.column1),
                                column2: String(step.arithmetic.column2),
                                column1Type: step.arithmetic.column1Type,
                                column2Type: step.arithmetic.column2Type,
                                operation: step.arithmetic.operation,
                            }
                        } else if (
                            step.stepType === CalculationRuleStepType.aggregate
                        ) {
                            if (
                                !step.aggregate?.column1Type ||
                                !step.aggregate?.column1
                            ) {
                                throw new Error(
                                    `column1 not selected in column ${column.columnName} and step ${step.tempStepName}`
                                )
                            }

                            if (!step.aggregate?.operation) {
                                throw new Error(
                                    `No operation selected in column ${column.columnName} and step ${step.tempStepName}`
                                )
                            }

                            aggregate = {
                                column1: String(step.aggregate?.column1),
                                column1Type: step.aggregate?.column1Type,
                                operation: step.aggregate?.operation,
                                groupBy: step.aggregate?.groupBy || [],
                            }
                        } else if (
                            step.stepType === CalculationRuleStepType.condition
                        ) {
                            if (!step.condition?.operation) {
                                throw new Error(
                                    `No operation selected in column ${column.columnName} and step ${step.tempStepName}`
                                )
                            }

                            if (
                                !step.condition?.column1Type ||
                                !step.condition?.column1
                            ) {
                                throw new Error(
                                    `column1 not selected in column ${column.columnName} and step ${step.tempStepName}`
                                )
                            }

                            if (
                                !step.condition?.column2Type ||
                                !step.condition?.column2
                            ) {
                                throw new Error(
                                    `column2 not selected in column ${column.columnName} and step ${step.tempStepName}`
                                )
                            }

                            condition = {
                                column1: String(step.condition.column1),
                                column2: String(step.condition.column2),
                                column1Type: step.condition.column1Type,
                                column2Type: step.condition.column2Type,
                                operation: step.condition.operation,
                                if: this.getConditionalBlock(step, 'if'),
                                else: this.getConditionalBlock(step, 'else'),
                            }
                        }
                        return {
                            stepID: step.stepID,
                            parentStepID: step.parentStepID,
                            stepType: step.stepType,
                            tempStepName: step.tempStepName,
                            arithmetic,
                            aggregate,
                            condition,
                        }
                    }),
                }
            }),
        }

        await AxiosX.post(`/v1/calculation-rule/new`, requestPayload)
    },

    getConditionalBlock: (
        step: ICalculationRuleStep,
        type: 'if' | 'else'
        // eslint-disable-next-line consistent-return
    ): ICalculationConditionReturn => {
        let block: ICalculationConditionReturn = {
            conditionStepType:
                excludecondition.arithmetic ||
                excludecondition.aggregate ||
                excludecondition.column,
        }
        if (
            step?.condition?.[type].conditionStepType ===
            excludecondition.arithmetic
        ) {
            block = {
                conditionStepType: excludecondition.arithmetic,
                arithmetic: {
                    column1: String(step.condition[type].arithmetic?.column1),
                    column2: String(step.condition[type].arithmetic?.column2),
                    column1Type:
                        step.condition[type].arithmetic?.column1Type ||
                        CalColumnTypes.dataset,
                    column2Type:
                        step.condition[type].arithmetic?.column2Type ||
                        CalColumnTypes.dataset,
                    operation:
                        step.condition[type].arithmetic?.operation ||
                        ArithmeticOperations.Addition,
                },

                aggregate: undefined,
                column: undefined,
            }
        } else if (
            step?.condition?.[type].conditionStepType ===
            excludecondition.aggregate
        ) {
            block = {
                conditionStepType: excludecondition.aggregate,
                arithmetic: undefined,
                column: undefined,
                aggregate: {
                    column1: String(step.condition[type].aggregate?.column1),
                    column1Type: step.condition[type].aggregate?.column1Type,
                    operation: step.condition[type].aggregate?.operation,
                    groupBy: step.condition[type].aggregate?.groupBy || [],
                },
            }
        } else if (
            step?.condition?.[type].conditionStepType ===
            excludecondition.column
        ) {
            block = {
                conditionStepType: excludecondition.column,
                arithmetic: undefined,
                aggregate: undefined,
                column: {
                    column1: String(step.condition[type].column?.column1),
                    column1Type:
                        step.condition[type].column?.column1Type ||
                        CalColumnTypes.dataset,
                },
            }
        }
        return block
    },
}
