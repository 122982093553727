import React, { useEffect, useState } from 'react'

import { lineageService } from '../../services/lineage/lineage.service'
import { lineageToReactFlowShape } from '../helpers/lineage.helper'
import { useSearchParams } from 'react-router-dom'
import { Row, Tabs } from 'antd'
import { sourceDatasetService } from '../../services/source-group/source-dataset.service'
import { handleAPIError } from '../../utils/catch-error'
import LineageOverviewFlow from './lineage-overview-flow'
import ColumnLineageFlow from './column-lineage-flow'

function Lineage(): JSX.Element {
    const [, setLineage] = useState<{ nodes: any[]; edges: any[] }>({
        nodes: [],
        edges: [],
    })
    const [columnNames, setColumnNames] = useState<string[]>([])
    const [searchParams] = useSearchParams()
    const [sourceFileId, setSourceFileId] = useState<number | undefined>(
        undefined
    )

    useEffect(() => {
        const sourceFileIdParam = searchParams.get('sourceFileId')
        const datasetId = searchParams.get('datasetId')
        if (sourceFileIdParam && Number(sourceFileIdParam)) {
            setSourceFileId(Number(sourceFileIdParam))
        }
        if (datasetId && Number(datasetId)) {
            sourceDatasetService
                .getDatasetColumnNamesForId(Number(datasetId))
                .then((res) => {
                    setColumnNames(res)
                })
                .catch(handleAPIError)
        }
    }, [searchParams])

    const lineageTagChange = async (key: string): Promise<void> => {
        if (Number(key) === 1) {
            const rawResp = await lineageService.getTopLevelLineage(
                Number(searchParams.get('sourceFileId'))
            )
            const items = lineageToReactFlowShape(rawResp)
            setLineage(items)
        }
        setLineage({
            nodes: [],
            edges: [],
        })
    }

    return (
        <div>
            <Row style={{ paddingLeft: '10px', width: '100%' }}>
                <Tabs
                    defaultActiveKey="1"
                    onChange={async (e) => {
                        await lineageTagChange(e)
                    }}
                    style={{ width: '100%' }}
                >
                    <Tabs.TabPane tab="Overview" key={1}>
                        <LineageOverviewFlow sourceFileId={sourceFileId || 0} />
                    </Tabs.TabPane>
                    {columnNames.map((columnName) => (
                        <Tabs.TabPane tab={columnName} key={columnName}>
                            <ColumnLineageFlow columnName={columnName} />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Row>
        </div>
    )
}

export default Lineage
