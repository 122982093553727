import { Form, Modal, Select } from 'antd'
import React, { useEffect } from 'react'
import { teamUserService } from '../../../services/user-management/team-user.service'
import { usersService } from '../../../services/user-management/users.service'
import { handleAPIError } from '../../../utils/catch-error'
import { IUsers } from '../../../services/user-management/interfaces/users.interface'

function CreateTeamUsers({
    visible,
    setVisible,
    teamId,
    teamName,
}: {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    teamId: number
    teamName: string | undefined
}): JSX.Element {
    const [createIsLoading, setCreateIsLoading] = React.useState(false)
    const [form] = Form.useForm()
    const [users, setUsers] = React.useState<IUsers[] | undefined>(undefined)

    const handleOk = async (): Promise<void> => {
        setCreateIsLoading(true)
        try {
            await teamUserService.create({
                TeamID: teamId,
                UserID: Number(form.getFieldValue('UserID')),
                Role: form.getFieldValue('Role'),
            })

            form.resetFields()
            setVisible(false)
        } catch (err: any) {
            handleAPIError(err)
        }
        setCreateIsLoading(false)
    }

    const handleCancel = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => {
        form.resetFields()
        e.preventDefault()
        setVisible(false)
    }

    useEffect(() => {
        usersService
            .getAll()
            .then((res) => {
                setUsers(res)
            })
            .catch(handleAPIError)
    }, [])

    return (
        <Modal
            bodyStyle={{ height: '200px' }}
            visible={visible}
            onCancel={(e) => handleCancel(e)}
            title={`Add new member to ${teamName}`}
            onOk={async () => {
                form.validateFields().then(async () => {
                    await handleOk()
                })
            }}
            confirmLoading={createIsLoading}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
                name="teams"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                autoComplete="off"
            >
                <Form.Item
                    name="UserID"
                    label="Select User"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select>
                        {users?.map((userItem: IUsers) => (
                            <Select.Option
                                key={userItem.UserID}
                                value={userItem.UserID}
                            >
                                {userItem.UserName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="Role"
                    label="Select Role"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select>
                        <Select.Option value="Admin">Admin</Select.Option>
                        <Select.Option value="User">User</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateTeamUsers
