/* eslint-disable no-unused-vars */
import {
    ApiOutlined,
    DatabaseOutlined,
    HistoryOutlined,
    HomeOutlined,
    PieChartOutlined,
    ToolOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons'

const enum routeMapMini {
    dashboard = '/',
    userManagement = '/user/management',
    sourceGroup = '/source',
    sourceDataset = '/source/dataset',
    sourceDatasetNew = '/source/dataset/new',
    sourceFiles = '/source/files',
    sourceCloud = '/source/cloud',
    sourceOnPremise = '/source/on-premise',
    rulesCalculationNew = '/rules/calculation/new',
    rulesCalculationView = '/rules/calculation/view',
    rulesCalculation = '/rules/calculation',
    rulesJoin = '/rules/join',
    rulesJoinNew = '/rules/join/new',
    rulesAllocation = '/rules/allocation',
    rulesAllocationNew = '/rules/allocation/new',
    rulesAllocationFiles = '/rules/allocation/files',
    rulesConsolidation = '/rules/consolidation',
    rulesFx = '/rules/fx',
    configMetadata = '/config/metadata',
    configLineage = '/config/lineage',
    configDimensions = '/config/dimensions',
    configMeasure = '/config/measure',
    configFxRates = '/config/fx-rates',
    runHistory = '/run-history',
    createRun = '/run-create',
    reporting = '/reporting',
    Login = '/login',
    teamUsers = '/users/management/team-users',
    rulesMerge = '/rules/merge',
    rulesMergeNew = '/rules/merge/new',
    userTeams = '/users/management/user-teams',
    createNewTeam = '/users/management/create-new-team',
    topLevelLineage = '/lineage',
}

const routeMap = [
    {
        route: routeMapMini.dashboard,
        icon: HomeOutlined,
        inLayout: true,
        name: 'Dashboard',
    },
    {
        route: routeMapMini.userManagement,
        icon: UsergroupAddOutlined,
        inLayout: true,
        name: 'User management',
    },
    {
        route: routeMapMini.sourceGroup,
        icon: DatabaseOutlined,
        inLayout: true,
        name: 'Source',
    },
    {
        route: routeMapMini.rulesCalculationNew,
        icon: ApiOutlined,
        inLayout: true,
        name: 'Rule management',
        child: [
            {
                route: routeMapMini.rulesAllocation,
                name: 'Allocation Rules',
                inLayout: true,
            },
            {
                route: routeMapMini.rulesCalculation,
                name: 'Calculation Rules',
                inLayout: true,
            },
            {
                route: routeMapMini.rulesConsolidation,
                name: 'Consolidation Rules',
                inLayout: false,
            },
            {
                route: routeMapMini.rulesFx,
                name: 'FX Rules',
                inLayout: false,
            },
            {
                route: routeMapMini.rulesJoin,
                name: 'Join Rules',
                inLayout: true,
            },
            {
                route: routeMapMini.rulesMerge,
                name: 'Merge Rules',
                inLayout: true,
            },
        ],
    },
    {
        route: routeMapMini.configMetadata,
        icon: ToolOutlined,
        inLayout: false,
        name: 'Config',
        child: [
            {
                route: routeMapMini.configMetadata,
                name: 'Config Metadata',
                inLayout: false,
            },
            {
                route: routeMapMini.configLineage,
                name: 'Config Lineage',
                inLayout: true,
            },
            {
                route: routeMapMini.configDimensions,
                name: 'Config Dimensions',
                inLayout: true,
            },
            {
                route: routeMapMini.configMeasure,
                name: 'Config Measure',
                inLayout: true,
            },
            {
                route: routeMapMini.configFxRates,
                name: 'Config FX Rates',
                inLayout: true,
            },
        ],
    },
    {
        route: routeMapMini.runHistory,
        icon: HistoryOutlined,
        inLayout: true,
        name: 'Run management',
    },
    {
        route: routeMapMini.reporting,
        icon: PieChartOutlined,
        inLayout: true,
        name: 'Reporting',
    },
]

export { routeMap, routeMapMini }
