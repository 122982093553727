import { Table } from 'antd'
import React from 'react'
import { ITeamUsers } from '../../../services/user-management/interfaces/teamusers.interface'
import { IUsers } from '../../../services/user-management/interfaces/users.interface'
import { teamUserService } from '../../../services/user-management/team-user.service'
import { handleAPIError } from '../../../utils/catch-error'

function TeamUsersTable({
    teamId,
    loading,
    setLoading,
    refreshTable,
}: {
    teamId: number
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    refreshTable: boolean
}): JSX.Element {
    const [teamUsers, setTeamUsers] = React.useState<ITeamUsers[] | undefined>(
        undefined
    )

    const getTeamUsers = async (): Promise<void> => {
        setLoading(true)
        try {
            const teamUser = await teamUserService.getTeamUserForTeam(teamId)
            setTeamUsers(teamUser)
        } catch (error: unknown) {
            handleAPIError(error)
        }
        setLoading(false)
    }

    React.useEffect(() => {
        if (teamId && refreshTable) {
            getTeamUsers()
        }
    }, [teamId, refreshTable])

    const columns = [
        {
            title: 'User ID',
            dataIndex: 'UserID',
            key: 'UserID',
            sorter: (a: IUsers, b: IUsers) => {
                return (a.UserID || 1) > (b.UserID || 1) ? 1 : -1
            },
        },
        {
            title: 'First Name',
            dataIndex: 'FirstName',
            key: 'TeamUserID',
        },
        {
            title: 'Last Name',
            dataIndex: 'LastName',
            key: 'TeamUserID',
        },
        {
            title: 'Email ID',
            dataIndex: 'UserName',
            key: 'TeamUserID',
            ellipsis: true,
        },
        {
            title: 'Role',
            dataIndex: 'Role',
            key: 'TeamUserID',
        },
        {
            title: 'Added By',
            dataIndex: 'AddedByUser',
            key: 'TeamUserID',
        },
    ]

    return <Table columns={columns} dataSource={teamUsers} loading={loading} />
}

export default TeamUsersTable
