export const parseJson = (
    json: string,
    allowError = true,
    defaultValue = {}
): any => {
    if (!json) {
        return defaultValue
    }
    try {
        return JSON.parse(json)
    } catch (e) {
        if (allowError) {
            return defaultValue
        }
        throw e
    }
}
