import { IConfigFile } from './interfaces/config-file.interface'
import { AxiosX } from '../../utils/axios'

export const configFileService = {
    getAll: async (): Promise<IConfigFile[]> => {
        const resp = await AxiosX.get<IConfigFile[]>('/v1/config-file/all')
        return resp.data
    },

    create: async (configFile: IConfigFile): Promise<IConfigFile> => {
        const resp = await AxiosX.post<IConfigFile>(
            '/v1/config-file/create',
            configFile
        )
        return resp.data
    },

    getByDatasetAndAllocationGroup: async (
        datasetId: number,
        allocationGroupId: number
    ): Promise<IConfigFile[]> => {
        const resp = await AxiosX.get<IConfigFile[]>(
            `/v1/config-file/by-dataset-and-allocation-rule/${datasetId}/${allocationGroupId}`
        )
        return resp.data
    },
}
