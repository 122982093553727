import * as React from 'react'
import { Input } from 'antd'

type IProps = {
    setSourceGroupName: React.Dispatch<React.SetStateAction<string | undefined>>
    sourceGroupName: string | undefined
}

function CreateSourceGroup({
    setSourceGroupName,
    sourceGroupName,
}: IProps): JSX.Element {
    return (
        <Input
            onChange={(e) => setSourceGroupName(e.target.value)}
            placeholder="Enter Data Group Name"
            value={sourceGroupName}
        />
    )
}

export default CreateSourceGroup
