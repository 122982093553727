import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import ErrorBoundary from './components/error-boundary/error-boundary'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'

if (process.env.REACT_APP_ENV !== 'dev') {
    Sentry.init({
        dsn: 'https://19442eb7e77e4ff990ef317e80108aa0@o1278916.ingest.sentry.io/6478999',
        integrations: [new BrowserTracing()],
        environment: process.env.REACT_APP_ENV,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

export const themes = {
    dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/light-theme.css`,
}

const getDefaultTheme = (): string => {
    const defaultTheme = localStorage.getItem('theme')
    if (defaultTheme && (defaultTheme === 'dark' || defaultTheme === 'light')) {
        return defaultTheme
    }
    return 'dark'
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            {/* sreeteja.dev@gmail.com account */}
            <Auth0Provider
                domain="supercharged.eu.auth0.com"
                clientId="63MFjlmlNH7jOPY94FApHcF4MKu3hN7J"
                redirectUri={window.location.origin}
                audience="https://x.supercharged.dev"
            >
                <ErrorBoundary>
                    <ThemeSwitcherProvider
                        themeMap={themes}
                        defaultTheme={getDefaultTheme()}
                    >
                        <App />
                    </ThemeSwitcherProvider>
                </ErrorBoundary>
            </Auth0Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
