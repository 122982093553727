import * as Sentry from '@sentry/react'

export const xlsxUtil = {
    convertCSV2DArrayToObject: (array: any[][]): any[] => {
        try {
            const header = array[0]
            const data = array.slice(1)
            const result: any[] = []
            data.forEach((row) => {
                const obj: any = {}
                row.forEach((value, index) => {
                    obj[header[index]] = value
                })
                result.push(obj)
            })
            return result
        } catch (error) {
            Sentry.captureException(error)
            return []
        }
    },
}
