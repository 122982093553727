import * as React from 'react'
import { Button, Select, Space } from 'antd'
import { ISourceGroup } from '../../../services/source-group/interfaces/source-group.interface'
import { useEffect } from 'react'
import { sourceGroupService } from '../../../services/source-group/source-group.service'
import { withRouter } from '../../../utils/withRouter'
import SourceDatasetTable from './source-dataset-table'
import { handleAPIError } from '../../../utils/catch-error'
import { Link, useSearchParams } from 'react-router-dom'
import { routeMapMini } from '../../../constants/routeMap'

function SourceDataset(): JSX.Element {
    const [sourceGroup, setSourceGroup] = React.useState<
        ISourceGroup[] | undefined
    >(undefined)
    const [selectedSourceGroup, setSelectedSourceGroup] = React.useState<
        ISourceGroup | undefined
    >(undefined)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        sourceGroupService
            .getAll()
            .then((res) => {
                setSourceGroup(res)
                const sourceGroupIdFromQuery = searchParams.get('sourceGroupId')
                if (sourceGroupIdFromQuery) {
                    const sourceGroupItem = res?.find(
                        (sg) =>
                            sg.SourceGroupID === Number(sourceGroupIdFromQuery)
                    )
                    if (sourceGroupItem) {
                        setSelectedSourceGroup(sourceGroupItem)
                    }
                }
            })
            .catch(handleAPIError)
    }, [searchParams])

    useEffect(() => {
        const sourceGroupIdFromQuery = searchParams.get('sourceGroupId')
        if (sourceGroupIdFromQuery) {
            const sourceGroupItem = sourceGroup?.find(
                (sg) => sg.SourceGroupID === Number(sourceGroupIdFromQuery)
            )
            if (sourceGroupItem) {
                setSelectedSourceGroup(sourceGroupItem)
            }
        }
    }, [searchParams, sourceGroup])

    return (
        <div>
            <div
                style={{
                    float: 'left',
                    marginLeft: '15px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            >
                <Space>
                    <a>
                        <Link
                            to={`${routeMapMini.sourceDatasetNew}?sourceGroupId=${selectedSourceGroup?.SourceGroupID}`}
                        >
                            <Button type="primary">Create New Dataset</Button>
                        </Link>
                    </a>
                    Group:
                    <Select
                        style={{ minWidth: '120px' }}
                        value={selectedSourceGroup?.SourceGroupID}
                        onChange={(value) => {
                            const sourceGroupItem = sourceGroup?.find(
                                (sg) => sg.SourceGroupID === Number(value)
                            )
                            if (sourceGroupItem) {
                                setSelectedSourceGroup(sourceGroupItem)
                                setSearchParams({
                                    sourceGroupId: String(
                                        sourceGroupItem.SourceGroupID
                                    ),
                                })
                            }
                        }}
                    >
                        {sourceGroup?.map((sourceGroupItem: ISourceGroup) => (
                            <Select.Option
                                key={sourceGroupItem.SourceGroupID}
                                value={sourceGroupItem.SourceGroupID}
                            >
                                {sourceGroupItem.SourceGroupName}
                            </Select.Option>
                        ))}
                    </Select>
                </Space>
            </div>
            <div style={{ margin: '15px' }}>
                <SourceDatasetTable
                    sourceGroupId={selectedSourceGroup?.SourceGroupID || 0}
                />
            </div>
        </div>
    )
}

export default withRouter(SourceDataset)
