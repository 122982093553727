import React, { useContext } from 'react'
import { Collapse, Popconfirm, Space } from 'antd'
import CalculationRuleSteps from './calculation-rule-steps'
import {
    CalculationRuleContext,
    ICalculationRuleColumn,
} from './new-calculation-rule'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
// import CalculationRuleConditionalStep from './calculation-rule-conditional-step'

export const CalculationRuleConditionStepContext = React.createContext<
    ICalculationRuleColumn | undefined
>(undefined)

function ColumnCollapsePanelExtraProps(columnId: number): JSX.Element {
    const calculationRules = useContext(CalculationRuleContext)

    return (
        <Space>
            <EditOutlined
                onClick={(event) => {
                    event.stopPropagation()
                    calculationRules?.setIsEditingColumnId(columnId)
                    calculationRules?.setIsModalVisible(true)
                }}
            />
            <div
                role="none"
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <Popconfirm
                    title="Are you sure you want to delete this column?"
                    onConfirm={() => {
                        calculationRules?.setColumns(
                            calculationRules?.columns.filter(
                                (column) => column.columnID !== columnId
                            )
                        )
                    }}
                >
                    <DeleteOutlined />
                </Popconfirm>
            </div>
        </Space>
    )
}

export function CalculationRuleColumns(): JSX.Element {
    const calculationRulesValue = useContext(CalculationRuleContext)

    return (
        <div style={{ paddingTop: '10px' }}>
            <Collapse>
                {calculationRulesValue?.columns?.map?.((item) => {
                    return (
                        <Collapse.Panel
                            key={item.columnID}
                            header={item.columnName}
                            extra={
                                !calculationRulesValue?.isViewing &&
                                ColumnCollapsePanelExtraProps(item.columnID)
                            }
                        >
                            <CalculationRuleSteps columnID={item.columnID} />
                        </Collapse.Panel>
                    )
                })}
            </Collapse>
        </div>
    )
}
