import {
    ISourceFileInfo,
    ISourceFileInfoRequest,
} from './interfaces/source-file-info.interface'
import { AxiosX } from '../../utils/axios'
import { IValidations } from '../../components/source-group/source-files/source-file-drawer'

export const sourceFileInfoService = {
    getAllForDataset: async (datasetId: number): Promise<ISourceFileInfo[]> => {
        const resp = await AxiosX.get<ISourceFileInfo[]>(
            `/v1/source/files/for-dataset/${datasetId}`
        )

        return resp.data
    },
    create: async (
        payload: ISourceFileInfoRequest
    ): Promise<ISourceFileInfo> => {
        const resp = await AxiosX.post<ISourceFileInfo>(
            '/v1/source/files/create',
            payload
        )

        return resp.data
    },
    getDataProfileValidations: async (
        sourceFileID: number
    ): Promise<IValidations[]> => {
        const resp = await AxiosX.get(
            `v1/source/data-profile-validation/by-source-file-id/${sourceFileID}`
        )

        return resp.data
    },
    getDataProfileValidationsForCalRuleID: async (
        calculationRuleID: number
    ): Promise<any[]> => {
        const resp = await AxiosX.get(
            `v1/source/data-profile-validation/by-calculation-rule-id/${calculationRuleID}`
        )

        return resp.data
    },
}
