import Steps from 'antd/lib/steps'
import * as React from 'react'
import { useState } from 'react'
import { Button, notification, Row, Select, Space } from 'antd'
import { Link } from 'react-router-dom'
import { routeMapMini } from '../../constants/routeMap'
import CreateAllocationRun from './create-allocation-run'
import { RunTypes } from '../../services/run/interfaces/run-types.enum'
import CreateCalculationRun from './create-calculation-run'
import CreateJoinRun from './create-join-run'
import CreateMergeRun from './create-merge-run'

function CreateRun(): JSX.Element {
    const [currentStep, setCurrentStep] = useState(0)
    const [selectedRunType, setSelectedRunType] = useState<RunTypes>()

    const nextStep = (): void => {
        if (currentStep < 2) {
            setCurrentStep(currentStep + 1)
        }
    }

    const prevStep = (): void => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1)
        }
    }

    return (
        <div style={{ margin: '20px' }}>
            <Steps current={currentStep}>
                <Steps.Step
                    title="Select Run Type"
                    description=""
                    style={{ marginTop: '20px' }}
                />
                <Steps.Step
                    title="Configure Run"
                    style={{ marginTop: '20px' }}
                />
                <Steps.Step title="Done" style={{ marginTop: '20px' }} />
            </Steps>
            <div style={{ margin: '20px' }}>
                {currentStep === 0 && (
                    <div>
                        <p>Select the type of run you want to create.</p>
                        <Row>
                            <Select
                                style={{ minWidth: '200px' }}
                                value={selectedRunType}
                                onChange={(value: RunTypes): void => {
                                    setSelectedRunType(value)
                                }}
                            >
                                <Select.Option value={RunTypes.ALLOCATION}>
                                    Allocation Run
                                </Select.Option>
                                <Select.Option value={RunTypes.CALCULATION}>
                                    Calculation Run
                                </Select.Option>
                                <Select.Option value={RunTypes.JOIN}>
                                    Join Run
                                </Select.Option>
                                <Select.Option value={RunTypes.MERGE}>
                                    Merge Run
                                </Select.Option>
                            </Select>
                        </Row>
                        <Button
                            onClick={() => {
                                if (!selectedRunType) {
                                    notification.error({
                                        message: 'Error',
                                        description:
                                            'Please select a run type.',
                                    })
                                    return
                                }
                                nextStep()
                            }}
                            type="primary"
                            style={{ marginTop: '14px' }}
                        >
                            Next
                        </Button>
                    </div>
                )}
                {currentStep === 1 && selectedRunType === RunTypes.ALLOCATION && (
                    <div>
                        <p>Provide the allocation run configuration.</p>
                        <CreateAllocationRun nextStep={nextStep} />
                        <Space>
                            <Button onClick={prevStep}>Previous</Button>
                        </Space>
                    </div>
                )}
                {currentStep === 1 && selectedRunType === RunTypes.CALCULATION && (
                    <div>
                        <p>Provide the calculation run configuration.</p>
                        <CreateCalculationRun nextStep={nextStep} />
                        <Space>
                            <Button onClick={prevStep}>Previous</Button>
                        </Space>
                    </div>
                )}
                {currentStep === 1 && selectedRunType === RunTypes.JOIN && (
                    <div>
                        <p>Provide the join run configuration.</p>
                        <CreateJoinRun nextStep={nextStep} />
                        <Space>
                            <Button onClick={prevStep}>Previous</Button>
                        </Space>
                    </div>
                )}
                {currentStep === 1 && selectedRunType === RunTypes.MERGE && (
                    <div>
                        <p>Provide the merge run configuration.</p>
                        <CreateMergeRun nextStep={nextStep} />
                        <Space>
                            <Button onClick={prevStep}>Previous</Button>
                        </Space>
                    </div>
                )}
                {currentStep === 2 && (
                    <div>
                        <p>
                            Run is submitted successfully. Go to{' '}
                            <Link to={routeMapMini.runHistory}>
                                Run History
                            </Link>{' '}
                            to check status or create another{' '}
                            <a href={routeMapMini.createRun}>Run</a>.
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CreateRun
