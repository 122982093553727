import { IMergeRuleRequest } from './interfaces/merge-rule-request.interface'
import { AxiosX } from '../../utils/axios'
import {
    IMergeRuleResponse,
    IMergeSelectorsResponse,
} from './interfaces/merge-rule-response.interface'

export const mergeRuleService = {
    createJoinRule: async (
        requestPayload: IMergeRuleRequest
    ): Promise<{ MergeRuleID: number }> => {
        const { data } = await AxiosX.post(
            'v1/merge-rule/create',
            requestPayload
        )
        return data
    },

    get: async (): Promise<IMergeRuleResponse[]> => {
        const data = await AxiosX.get<IMergeRuleResponse[]>('v1/merge-rule/all')

        return data.data
    },

    getSelectors: async (
        MergeRuleID: number
    ): Promise<IMergeSelectorsResponse[]> => {
        const data = await AxiosX.get<IMergeSelectorsResponse[]>(
            `v1/merge-rule/selectors/${MergeRuleID}`
        )

        return data.data
    },
}
