import { Button, Select, Space } from 'antd'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ITeams } from '../../../services/user-management/interfaces/teams.interface'
import { teamsService } from '../../../services/user-management/teams.service'
import { handleAPIError } from '../../../utils/catch-error'
import CreateTeamUsers from './create-team-users'
import TeamUsersTable from './team-users-table'

function TeamUsers(): JSX.Element {
    const [teams, setTeams] = React.useState<ITeams[] | undefined>(undefined)
    const [selectedTeam, setSelectedTeam] = React.useState<ITeams | undefined>(
        undefined
    )
    const [searchParams, setSearchParams] = useSearchParams()
    const [isTeamUserModalVisible, setIsTeamUserModalVisible] =
        React.useState(false)
    const [tableLoading, setTableLoading] = React.useState(false)

    useEffect(() => {
        teamsService
            .getAll()
            .then((res) => {
                setTeams(res)
                const teamIdFromQuery = searchParams.get('teamId')
                if (teamIdFromQuery) {
                    const teamItem = res?.find(
                        (sg) => sg.TeamID === Number(teamIdFromQuery)
                    )
                    if (teamItem) {
                        setSelectedTeam(teamItem)
                    }
                }
            })
            .catch(handleAPIError)
    }, [searchParams])

    return (
        <div>
            <div
                style={{
                    float: 'left',
                    marginLeft: '15px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            >
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsTeamUserModalVisible(true)
                        }}
                    >
                        Add Member
                    </Button>
                    Team:
                    <Select
                        style={{ minWidth: '120px' }}
                        value={selectedTeam?.TeamID}
                        onChange={(value) => {
                            const teamItem = teams?.find(
                                (t) => t.TeamID === Number(value)
                            )
                            if (teamItem) {
                                setSelectedTeam(teamItem)
                                setSearchParams({
                                    teamId: String(teamItem.TeamID),
                                })
                            }
                        }}
                    >
                        {teams?.map((teamItem: ITeams) => (
                            <Select.Option
                                key={teamItem.TeamID}
                                value={teamItem.TeamID}
                            >
                                {teamItem.TeamName}
                            </Select.Option>
                        ))}
                    </Select>
                </Space>
            </div>
            <CreateTeamUsers
                visible={isTeamUserModalVisible}
                setVisible={setIsTeamUserModalVisible}
                teamId={selectedTeam?.TeamID || -1}
                teamName={selectedTeam?.TeamName || undefined}
            />
            <div style={{ margin: '15px' }}>
                <TeamUsersTable
                    teamId={selectedTeam?.TeamID || 0}
                    loading={tableLoading}
                    setLoading={setTableLoading}
                    refreshTable={!isTeamUserModalVisible}
                />
            </div>
        </div>
    )
}

export default TeamUsers
