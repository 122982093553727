import { Drawer, Table } from 'antd'
import React from 'react'
import { ISourceDataset } from '../../../services/source-group/interfaces/source-dataset.interface'

type IProps = {
    metadata: Record<string, string> | undefined
    metadataV2: ISourceDataset['DatasetMetadataV2'] | undefined
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    title: string | undefined
}

function ViewDatasetMetadataDrawer({
    metadata,
    metadataV2,
    visible,
    setVisible,
    title,
}: IProps): JSX.Element {
    return (
        <Drawer
            visible={visible}
            title={title}
            onClose={() => {
                setVisible(false)
            }}
            placement="right"
            width="500"
        >
            {!metadataV2?.length && Object.keys(metadata || {}).length > 0 && (
                <Table
                    columns={[
                        {
                            title: 'Column Name',
                            dataIndex: 'ColumnName',
                            key: 'ColumnName',
                        },
                        {
                            title: 'Data Type',
                            dataIndex: 'DataType',
                            key: 'DataType',
                        },
                    ]}
                    dataSource={Object.keys(metadata || {}).map((item) => {
                        return {
                            ColumnName: item,
                            DataType: (metadata || {})[item],
                        }
                    })}
                    pagination={false}
                    scroll={{ x: true }}
                />
            )}
            {metadataV2?.length ? (
                <Table
                    columns={[
                        {
                            title: 'Column Name',
                            dataIndex: 'columnName',
                            key: 'id',
                        },
                        {
                            title: 'Data Type',
                            dataIndex: 'dataType',
                            key: 'id',
                        },
                        {
                            title: 'Type',
                            dataIndex: 'type',
                            key: 'id',
                        },
                    ]}
                    dataSource={metadataV2}
                    pagination={false}
                    scroll={{ x: true }}
                />
            ) : null}
        </Drawer>
    )
}

export default ViewDatasetMetadataDrawer
