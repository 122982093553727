import { IJoinRuleRequest } from './interfaces/join-rule-request.interface'
import { AxiosX } from '../../utils/axios'
import {
    IJoinConditionResponse,
    IJoinRuleResponse,
    IJoinSelectorsResponse,
} from './interfaces/join-rule-response.interface'

export const joinRuleService = {
    createJoinRule: async (
        requestPayload: IJoinRuleRequest
    ): Promise<{ JoinRuleID: number }> => {
        const { data } = await AxiosX.post(
            'v1/join-rule/create',
            requestPayload
        )
        return data
    },

    get: async (): Promise<IJoinRuleResponse[]> => {
        const data = await AxiosX.get<IJoinRuleResponse[]>('v1/join-rule/all')

        return data.data
    },

    getSelectors: async (
        JoinRuleID: number
    ): Promise<IJoinSelectorsResponse[]> => {
        const data = await AxiosX.get<IJoinSelectorsResponse[]>(
            `v1/join-rule/selectors/${JoinRuleID}`
        )

        return data.data
    },

    getConditions: async (
        JoinRuleID: number
    ): Promise<IJoinConditionResponse[]> => {
        const data = await AxiosX.get<IJoinConditionResponse[]>(
            `v1/join-rule/conditions/${JoinRuleID}`
        )

        return data.data
    },
}
