import React, { useEffect } from 'react'
import ReactFlow, {
    Background,
    Controls,
    MiniMap,
    useEdgesState,
    useNodesState,
} from 'react-flow-renderer'
import { handleAPIError } from '../../utils/catch-error'
import { lineageService } from '../../services/lineage/lineage.service'
import { lineageToReactFlowShape } from '../helpers/lineage.helper'

function LineageOverviewFlow({
    sourceFileId,
}: {
    sourceFileId: number
}): JSX.Element {
    const [nodes, setNodes, onNodesChange] = useNodesState([])
    const [edges, setEdges, onEdgesChange] = useEdgesState([])

    useEffect(() => {
        if (sourceFileId) {
            lineageService
                .getTopLevelLineage(sourceFileId)
                .then(lineageToReactFlowShape)
                .then((resp) => {
                    setNodes(resp.nodes)
                    setEdges(resp.edges)
                })
                .catch(handleAPIError)
        }
    }, [sourceFileId])

    return (
        <div
            style={{
                width: '100%',
                height: '70vh',
                backgroundColor: 'white',
            }}
        >
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                snapToGrid
                snapGrid={[15, 15]}
                fitView
            >
                <MiniMap />
                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow>
        </div>
    )
}

export default LineageOverviewFlow
