import axios from 'axios'

export const BASE_API_URL =
    process.env.REACT_APP_API_URL ||
    'https://xytech-backend.azurewebsites.net/api'

const Axios = axios.create({
    baseURL: BASE_API_URL,
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
    },
})

Axios.interceptors.request.use(async (config) => {
    const accessToken = await localStorage.getItem('accessToken')
    if (accessToken) {
        if (config.headers) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${accessToken}`
        } else {
            // eslint-disable-next-line no-param-reassign
            config.headers = {
                Authorization: `Bearer ${accessToken}`,
            }
        }
    }

    return config
})

export const AxiosX = Axios
