/* eslint-disable */
import * as React from 'react'
import { Space, Table } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import { routeMapMini } from '../../constants/routeMap'
import { IAllocationRuleGroup } from '../../services/allocation-rule/interfaces/allocation-rule-group.interface'
import { Link } from 'react-router-dom'
import { getColumnSearchProps } from '../../utils/columnsearch-props'
import moment from 'moment'
import AllocationRuleGroupDrawer from './allocation-rule-group-drawer'
import { FileUploadService } from '../../services/file-upload/file-upload.service'
import PreviewTable from '../preview-table/preview-table'

function AllocationRuleGroupTable({
    allocationRuleGroup,
    loading,
}: {
    allocationRuleGroup: IAllocationRuleGroup[]
    loading: boolean
}): JSX.Element {
    const [searchText, setSearchText] = React.useState('')
    const [groupConfigDrawerVisible, setGroupConfigDrawerVisible] =
        React.useState(false)
    const [configDrawerProps, setConfigDrawerProps] = React.useState<{
        ruleGroupID: number
        groupName: string
    }>()
    const [previewVisible, setPreviewVisible] = React.useState(false)
    const [previewProps, setPreviewProps] = React.useState<{
        title: string
        data: any
    }>({
        title: '',
        data: [],
    })

    const columns = [
        {
            title: 'Id',
            dataIndex: 'AllocationRuleGroupID',
            key: 'AllocationRuleGroupID',
            sorter: (a: IAllocationRuleGroup, b: IAllocationRuleGroup) => {
                return (a.AllocationRuleGroupID || 1) >
                    (b.AllocationRuleGroupID || 1)
                    ? 1
                    : -1
            },
        },
        {
            title: 'Allocation Rule Group Name',
            dataIndex: 'AllocationRuleGroupName',
            key: 'AllocationRuleGroupID',
            ...getColumnSearchProps(
                'AllocationRuleGroupName',
                searchText,
                setSearchText
            ),
        },
        {
            title: 'Allocation Rule Files',
            dataIndex: 'AllocationRuleFiles',
            key: 'AllocationRuleGroupID',
            render: (_: string, record: IAllocationRuleGroup) => (
                <Link
                    to={`${routeMapMini.rulesAllocationFiles}?AllocationRuleGroupId=${record.AllocationRuleGroupID}`}
                >
                    <FileExcelOutlined />
                </Link>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'Auditdatetime',
            key: 'RunId',
            render: (text: string) => {
                return moment(text).format('YYYY-MM-DD HH:mm')
            },
            sorter: (a: IAllocationRuleGroup, b: IAllocationRuleGroup) => {
                return (
                    moment(a.Auditdatetime).valueOf() -
                    moment(b.Auditdatetime).valueOf()
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'RunId',
            ellipsis: true,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_text: string, record: IAllocationRuleGroup) => (
                <Space>
                    <a
                        onClick={() => {
                            setGroupConfigDrawerVisible(true)
                            setConfigDrawerProps({
                                ruleGroupID: record.AllocationRuleGroupID || 0,
                                groupName: record.AllocationRuleGroupName,
                            })
                        }}
                    >
                        View
                    </a>
                    |
                    <a
                        role="none"
                        onClick={async () => {
                            if (record.UploadedFileID) {
                                const fileData =
                                    await FileUploadService.getFileById(
                                        record.UploadedFileID
                                    )
                                if (fileData) {
                                    setPreviewVisible(true)
                                    setPreviewProps({
                                        title: `Uploaded Sample preview for Allocation Group - ${record.AllocationRuleGroupName}`,
                                        data: fileData.FilePreviewData,
                                    })
                                }
                            }
                        }}
                    >
                        Preview
                    </a>
                </Space>
            ),
        },
    ]

    return (
        <>
            <Table
                columns={columns}
                dataSource={allocationRuleGroup}
                loading={loading}
            />
            {configDrawerProps && (
                <AllocationRuleGroupDrawer
                    ruleGroupID={configDrawerProps.ruleGroupID}
                    visible={groupConfigDrawerVisible}
                    setVisible={setGroupConfigDrawerVisible}
                    groupName={configDrawerProps.groupName}
                />
            )}
            <PreviewTable
                visible={previewVisible}
                onCancel={() => {
                    setPreviewVisible(false)
                }}
                onOk={() => {
                    setPreviewVisible(false)
                }}
                title={previewProps.title}
                data={previewProps.data}
            />
        </>
    )
}

export default AllocationRuleGroupTable
