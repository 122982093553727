import React, { useEffect } from 'react'
import moment from 'moment'
import { handleAPIError } from '../../utils/catch-error'
import { Space, Table } from 'antd'
import { joinRuleService } from '../../services/join-rule/join-rule.service'
import { getColumnSearchProps } from '../../utils/columnsearch-props'
import { IJoinRuleResponse } from '../../services/join-rule/interfaces/join-rule-response.interface'
import JoinColumnDrawer from './join-column-drawer'

function JoinRuleTable(): JSX.Element {
    const [JoinRules, setJoinRules] = React.useState<
        IJoinRuleResponse[] | undefined
    >(undefined)
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState('')
    const [JoinRuleDrawerVisible, setJoinRuleNameDrawerVisible] =
        React.useState(false)
    const [drawerProps, setDrawerProps] = React.useState<{
        title: string
        JoinRuleID: number
        leftDatasetName: string
        rightDatasetName: string
    }>()

    useEffect(() => {
        setLoading(true)
        joinRuleService
            .get()
            .then((resp) => {
                setJoinRules(resp)
                setLoading(false)
            })
            .catch((err) => {
                handleAPIError(err)
                setLoading(false)
            })
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'JoinRuleID',
            key: 'JoinRuleID',
            sorter: (a: IJoinRuleResponse, b: IJoinRuleResponse) => {
                return (a.JoinRuleID || 1) > (b.JoinRuleID || 1) ? 1 : -1
            },
        },
        {
            title: 'Join Rule Name',
            dataIndex: 'JoinRuleName',
            key: 'JoinRuleID',
            ...getColumnSearchProps('JoinRuleName', searchText, setSearchText),
        },
        {
            title: 'Data Group Name',
            dataIndex: 'SourceGroupName',
            key: 'JoinRuleID',
        },
        {
            title: 'Left Dataset Name',
            dataIndex: 'leftDatasetName',
            key: 'JoinRuleID',
        },
        {
            title: 'Right Dataset Name',
            dataIndex: 'rightDatasetName',
            key: 'JoinRuleID',
        },
        {
            title: 'Join Type',
            dataIndex: 'JoinType',
            key: 'JoinRuleID',
        },
        {
            title: 'Created At',
            dataIndex: 'AuditDateTime',
            key: 'JoinRuleID',
            render: (text: string) => {
                return moment(text).format('YYYY-MM-DD HH:mm')
            },
            sorter: (a: IJoinRuleResponse, b: IJoinRuleResponse) => {
                return (
                    moment(a.AuditDateTime).valueOf() -
                    moment(b.AuditDateTime).valueOf()
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'JoinRuleID',
            ellipsis: true,
        },
        {
            title: 'Action',
            key: 'action',
            render: (record: IJoinRuleResponse): JSX.Element => (
                <Space size="middle">
                    <a
                        role="none"
                        onClick={() => {
                            try {
                                setDrawerProps({
                                    title: `${record.JoinRuleName}`,
                                    JoinRuleID: record.JoinRuleID,
                                    leftDatasetName: record.leftDatasetName,
                                    rightDatasetName: record.rightDatasetName,
                                })
                                setJoinRuleNameDrawerVisible(true)
                            } catch (e: unknown) {
                                handleAPIError(e)
                            }
                        }}
                    >
                        view
                    </a>
                </Space>
            ),
        },
    ]

    return (
        <>
            <Table
                columns={columns}
                dataSource={JoinRules}
                loading={loading}
                size="small"
            />
            <JoinColumnDrawer
                visible={JoinRuleDrawerVisible}
                setVisible={setJoinRuleNameDrawerVisible}
                title={drawerProps?.title}
                joinRuleID={drawerProps?.JoinRuleID}
                LeftDatasetName={drawerProps?.leftDatasetName}
                RightDatasetName={drawerProps?.rightDatasetName}
            />
        </>
    )
}

export default JoinRuleTable
