import * as React from 'react'
import { Space, Table, TableColumnType } from 'antd'
import {
    CloudOutlined,
    FileExcelOutlined,
    HomeOutlined,
} from '@ant-design/icons'
import { ISourceDataset } from '../../../services/source-group/interfaces/source-dataset.interface'
import { routeMapMini } from '../../../constants/routeMap'
import { sourceDatasetService } from '../../../services/source-group/source-dataset.service'
import { Link } from 'react-router-dom'
import { getColumnSearchProps } from '../../../utils/columnsearch-props'
import moment from 'moment'
import { handleAPIError } from '../../../utils/catch-error'
import ViewDatasetMetadataDrawer from './view-dataset-metadata-drawer'
import { FileUploadService } from '../../../services/file-upload/file-upload.service'
import PreviewTable from '../../preview-table/preview-table'
import { parseJson } from '../../../utils/parse.util'

function SourceDatasetTable({
    sourceGroupId,
}: {
    sourceGroupId: number
}): JSX.Element {
    const [sourceDatasets, setSourceDatasets] = React.useState<
        ISourceDataset[]
    >([])
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState('')
    const [previewVisible, setPreviewVisible] = React.useState(false)
    const [previewProps, setPreviewProps] = React.useState<{
        title: string
        data: any
    }>({
        title: '',
        data: [],
    })

    // For Metadata Drawer
    const [metadataDrawerVisible, setMetadataDrawerVisible] =
        React.useState(false)
    const [drawerProps, setDrawerProps] = React.useState<{
        title: string
        drawerMetadata: Record<string, string>
        drawerMetadataV2: ISourceDataset['DatasetMetadataV2']
    }>()

    const getSourceDatasets = async (): Promise<void> => {
        setLoading(true)
        try {
            const sourceDatasetsResp =
                await sourceDatasetService.getDatasetsForGroup(sourceGroupId)
            setSourceDatasets(sourceDatasetsResp)
        } catch (error: any) {
            handleAPIError(error)
        }
        setLoading(false)
    }

    React.useEffect(() => {
        if (sourceGroupId) {
            getSourceDatasets()
        }
    }, [sourceGroupId])

    const columns: TableColumnType<ISourceDataset>[] = [
        {
            title: 'Dataset ID',
            dataIndex: 'DatasetID',
            key: 'DatasetID',
            sorter: (a: ISourceDataset, b: ISourceDataset) => {
                return (a.DatasetID || 1) > (b.DatasetID || 1) ? 1 : -1
            },
        },
        {
            title: 'Dataset Name',
            dataIndex: 'DatasetName',
            key: 'DatasetID',
            ...getColumnSearchProps('DatasetName', searchText, setSearchText),
        },
        {
            title: 'Files',
            dataIndex: 'files',
            key: 'DatasetID',
            render: (_: string, record: ISourceDataset) => (
                <Link
                    to={`${routeMapMini.sourceFiles}?sourceGroupId=${sourceGroupId}&datasetId=${record.DatasetID}`}
                >
                    <FileExcelOutlined />
                </Link>
            ),
        },
        {
            title: 'Cloud',
            dataIndex: 'Cloud',
            key: 'DatasetID',
            render: () => (
                <a>
                    <CloudOutlined />
                </a>
            ),
        },
        {
            title: 'On Premise',
            dataIndex: 'OnPremise',
            key: 'DatasetID',
            render: () => (
                <a>
                    <HomeOutlined />
                </a>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'Auditdatetime',
            key: 'RunId',
            render: (text: string) => {
                return moment(text).format('YYYY-MM-DD HH:mm')
            },
            sorter: (a: ISourceDataset, b: ISourceDataset) => {
                return (
                    moment(a.Auditdatetime).valueOf() -
                    moment(b.Auditdatetime).valueOf()
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'RunId',
            ellipsis: true,
        },
        {
            title: 'Action',
            dataIndex: 'DatasetMetadata',
            key: 'DatasetID',
            render: (_metadata: string, record: ISourceDataset) => (
                <Space>
                    <a
                        role="none"
                        onClick={() => {
                            try {
                                setDrawerProps({
                                    title: `${record.DatasetName} Structure`,
                                    drawerMetadata: parseJson(
                                        record.DatasetMetadata
                                    ),
                                    drawerMetadataV2: parseJson(
                                        // @ts-ignore
                                        record.DatasetMetadataV2 || '[]'
                                    ),
                                })
                                setMetadataDrawerVisible(true)
                            } catch (e: unknown) {
                                handleAPIError(e)
                            }
                        }}
                    >
                        view
                    </a>
                    |
                    <a
                        role="none"
                        onClick={async () => {
                            if (record.UploadedFileID) {
                                const fileData =
                                    await FileUploadService.getFileById(
                                        record.UploadedFileID
                                    )
                                if (fileData) {
                                    setPreviewVisible(true)
                                    setPreviewProps({
                                        title: `Uploaded Sample preview for Dataset - ${record.DatasetName}`,
                                        data: fileData.FilePreviewData,
                                    })
                                }
                            }
                        }}
                    >
                        preview
                    </a>
                </Space>
            ),
        },
    ]

    return (
        <>
            <Table
                columns={columns}
                dataSource={sourceDatasets}
                loading={loading}
            />
            <ViewDatasetMetadataDrawer
                visible={metadataDrawerVisible}
                setVisible={setMetadataDrawerVisible}
                title={drawerProps?.title}
                metadata={drawerProps?.drawerMetadata}
                metadataV2={drawerProps?.drawerMetadataV2}
            />
            <PreviewTable
                visible={previewVisible}
                onCancel={() => {
                    setPreviewVisible(false)
                }}
                onOk={() => {
                    setPreviewVisible(false)
                }}
                title={previewProps.title}
                data={previewProps.data}
            />
        </>
    )
}

export default SourceDatasetTable
