import React, { useEffect } from 'react'
import ReactFlow, {
    Background,
    Controls,
    MiniMap,
    useEdgesState,
    useNodesState,
} from 'react-flow-renderer'

function ColumnLineageFlow({
    columnName,
}: {
    columnName: string
}): JSX.Element {
    const [nodes, setNodes, onNodesChange] = useNodesState([])
    const [edges, setEdges, onEdgesChange] = useEdgesState([])

    useEffect(() => {
        setNodes([
            {
                id: '1',
                position: { x: 0, y: 0 },
                data: {
                    label: <b>{columnName}</b>,
                },
            },
        ])
        setEdges([])
    }, [columnName])

    return (
        <div
            style={{
                width: '100%',
                height: '70vh',
                backgroundColor: 'white',
            }}
        >
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                snapToGrid
                snapGrid={[15, 15]}
                fitView
            >
                <MiniMap />
                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow>
        </div>
    )
}

export default ColumnLineageFlow
