import { useLocation, useNavigate, useParams } from 'react-router-dom'

export function withRouter(Component: any): any {
    function ComponentWithRouterProp(props: any): JSX.Element {
        const location = useLocation()
        const navigate = useNavigate()
        const params = useParams()
        // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
        return <Component {...props} router={{ location, navigate, params }} />
    }

    return ComponentWithRouterProp
}
