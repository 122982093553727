import { AxiosX } from '../../utils/axios'
import {
    IAllocationRuleFileInfo,
    IAllocationRuleFileRequest,
} from './interfaces/allocation-rule-file.interface'

export const allocationRuleFileService = {
    getAllForGroup: async (
        groupId: number
    ): Promise<IAllocationRuleFileInfo[]> => {
        const resp = await AxiosX.get<IAllocationRuleFileInfo[]>(
            `/v1/allocation-rule/files/for-group/${groupId}`
        )

        return resp.data
    },

    getAllForAuthenticatedUser: async (): Promise<
        IAllocationRuleFileInfo[]
    > => {
        const resp = await AxiosX.get<IAllocationRuleFileInfo[]>(
            `/v1/allocation-rule/files/user`
        )

        return resp.data
    },

    create: async (
        data: IAllocationRuleFileRequest
    ): Promise<IAllocationRuleFileInfo> => {
        const resp = await AxiosX.post<IAllocationRuleFileInfo>(
            `/v1/allocation-rule/files/create`,
            data
        )
        return resp.data
    },
}
