/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useMemo } from 'react'
import { Col, Input, Row, Select, Space, Popconfirm, Collapse } from 'antd'
import {
    ArithmeticOperations,
    CalAggregateOperations,
    CalColumnTypes,
    CalConditionalOperations,
    excludecondition,
} from '../../services/calculation-rule/interfaces/calculation-rule.interface'
import { getEnumKeys } from '../../utils/enum'
import {
    calculationRuleHelper,
    ICalcColumnOption,
} from './calculation-rule.helper'
import {
    CalculationRuleContext,
    ICalculationRuleContext,
} from './new-calculation-rule'
import * as Sentry from '@sentry/react'
import { ICalculationRuleAggregateStep } from './calculation-rule-aggregate-step'
import { ICalculationRuleArithmeticStep } from './calculation-rule-arithmetic-step'
import { DeleteOutlined } from '@ant-design/icons'
// eslint-disable-next-line max-len
import CalculationRuleConditionalStepCollapseWhenClosed from './calculation-rule-conditional-step-collapse-when-closed'
// eslint-disable-next-line max-len
import CalculationRuleConditionalStepCollapseWhenOpened from './calculation-rule-conditional-step-collapse-when-opened'
import { calculationRuleConditionHelper } from './calculation-rule-condition.helper'

export interface ICalculationRuleColumnStep {
    column1?: number | string
    column1Type?: CalColumnTypes
    statusOfColumn1?: boolean
    textDecorationOfColumn1?: boolean
}

export interface ICalculationConditionReturn {
    conditionStepType:
        | excludecondition.aggregate
        | excludecondition.arithmetic
        | excludecondition.column
    aggregate?: ICalculationRuleAggregateStep
    arithmetic?: ICalculationRuleArithmeticStep
    column?: ICalculationRuleColumnStep
}

export interface ICalculationRuleConditionStep {
    column1?: number | string
    column1Type?: CalColumnTypes
    statusOfColumn1?: boolean
    column2?: number | string
    column2Type?: CalColumnTypes
    statusOfColumn2?: boolean
    operation: CalConditionalOperations
    if: ICalculationConditionReturn
    else: ICalculationConditionReturn
    textDecorationOfColumn1?: boolean
    textDecorationOfColumn2?: boolean
}

function getCol1ValueForArithmetic(
    availableSelectOptions: ICalcColumnOption[],
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string | undefined {
    return availableSelectOptions.find((item) => {
        let uniqueID
        uniqueID = `${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                ?.condition?.[conditionType].arithmetic?.column1
        }-${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                .condition?.[conditionType].arithmetic?.column1Type
        }`

        if (
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                .condition?.[conditionType].arithmetic?.column1Type ===
            CalColumnTypes.constant
        ) {
            uniqueID = `-1-constant`
        }

        if (uniqueID === item.uniqueID) {
            return true
        }
        return false
    })?.uniqueID
}

function getInputValueForCol1Arithmetic(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.column1 === -1
    ) {
        return undefined
    }
    return String(
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.column1
    )
}

function getOperationValueForArithmetic(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string | undefined {
    return getEnumKeys(ArithmeticOperations).find((item) => {
        const uniqueID = `${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                ?.condition?.[conditionType].arithmetic?.operation
        }`

        if (uniqueID === item) {
            return true
        }

        return false
    })
}

function getCol2ValueForArithmetic(
    availableSelectOptions: ICalcColumnOption[],
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string | undefined {
    return availableSelectOptions.find((item) => {
        let uniqueID
        uniqueID = `${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                ?.condition?.[conditionType].arithmetic?.column2
        }-${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                .condition?.[conditionType].arithmetic?.column2Type
        }`

        if (
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                .condition?.[conditionType].arithmetic?.column2Type ===
            CalColumnTypes.constant
        ) {
            uniqueID = `-1-constant`
        }

        if (uniqueID === item.uniqueID) {
            return true
        }

        return false
    })?.uniqueID
}

function getInputValueForCol2Arithmetic(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.column2 === -1
    ) {
        return undefined
    }
    return String(
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.column2
    )
}

function getConditionStepTypeValue(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string | undefined {
    return getEnumKeys(excludecondition).find((item) => {
        const ConditionStepTypeIf: any = `${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                ?.condition?.[conditionType]?.conditionStepType
        }`

        if (ConditionStepTypeIf === item) {
            return true
        }
        return false
    })
}

function getcol1valueForAggregate(
    availableSelectOptionsAgg: ICalcColumnOption[],
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string | undefined {
    return availableSelectOptionsAgg.find((item) => {
        const uniqueID = `${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                ?.condition?.[conditionType]?.aggregate?.column1
        }-${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                ?.condition?.[conditionType]?.aggregate?.column1Type
        }`

        if (uniqueID === item.uniqueID) {
            return true
        }
        return false
    })?.uniqueID
}

function getOperationValueForAggregate(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string | undefined {
    return getEnumKeys(CalAggregateOperations).find((item) => {
        const uniqueID = `${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                ?.condition?.[conditionType]?.aggregate?.operation
        }`

        if (uniqueID === item) {
            return true
        }
        return false
    })
}

function getGroupByValueForAggregate(
    availableSelectOptionsAgg: ICalcColumnOption[],
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string[] {
    const uniqueIDGroupBy: string[] = []
    availableSelectOptionsAgg.find(() => {
        if (
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                ?.condition?.[conditionType]?.aggregate?.groupBy !== undefined
        ) {
            for (
                let index = 0;
                index <
                Number(
                    calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                        stepId - 1
                    ]?.condition?.[conditionType]?.aggregate?.groupBy?.length
                );
                index += 1
            ) {
                const element =
                    calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                        stepId - 1
                    ]?.condition?.[conditionType]?.aggregate?.groupBy?.[index]

                uniqueIDGroupBy.push(
                    `${element?.columnValue}-${element?.columnType}`
                )
            }
        }
        return uniqueIDGroupBy
    })
    return uniqueIDGroupBy
}

function getCol1ValueForColumn(
    availableSelectOptions: ICalcColumnOption[],
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string | undefined {
    return availableSelectOptions.find((item) => {
        let uniqueID
        uniqueID = `${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                ?.condition?.[conditionType].column?.column1
        }-${
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                .condition?.[conditionType].column?.column1Type
        }`

        if (
            calculationRuleValue?.columns?.[columnId - 1]?.steps?.[stepId - 1]
                .condition?.[conditionType]?.column?.column1Type ===
            CalColumnTypes.constant
        ) {
            uniqueID = `-1-constant`
        }

        if (uniqueID === item.uniqueID) {
            return true
        }
        return false
    })?.uniqueID
}

function getInputValueForCol1Column(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): string | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType]?.column?.column1 === -1
    ) {
        return undefined
    }
    return String(
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].column?.column1
    )
}

function getStatusCol1ForArithmetic(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): 'warning' | 'error' | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.column1 === undefined &&
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.statusOfColumn1 === false
    ) {
        return 'error'
    }
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.textDecorationOfColumn1 ===
        false
    ) {
        return 'warning'
    }
    return undefined
}

function getStatusCol2ForArithmetic(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): 'warning' | 'error' | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.column2 === undefined &&
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.statusOfColumn2 === false
    ) {
        return 'error'
    }
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.textDecorationOfColumn2 ===
        false
    ) {
        return 'warning'
    }
    return undefined
}

function getTextDecorationCol1ForArithmetic(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): 'line-through' | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.textDecorationOfColumn1 ===
        false
    ) {
        return 'line-through'
    }
    return undefined
}

function getTextDecorationCol2ForArithmetic(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): 'line-through' | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].arithmetic?.textDecorationOfColumn2 ===
        false
    ) {
        return 'line-through'
    }
    return undefined
}

function getStatusCol1ForAggregate(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): 'warning' | 'error' | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].aggregate?.column1 === undefined &&
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].aggregate?.statusOfColumn1 === false
    ) {
        return 'error'
    }
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].aggregate?.textDecorationOfColumn1 ===
        false
    ) {
        return 'warning'
    }
    return undefined
}

function getStatusGroupByForAggregate(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): 'warning' | 'error' | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].aggregate?.statusOfGroupBy === false
    ) {
        return 'error'
    }
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].aggregate?.textDecorationOfGroupBy ===
        false
    ) {
        return 'warning'
    }
    return undefined
}

function getTextDecorationCol1ForAggregate(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): 'line-through' | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].aggregate?.textDecorationOfColumn1 ===
        false
    ) {
        return 'line-through'
    }
    return undefined
}

function getStatusCol1ForColumn(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): 'warning' | 'error' | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].column?.column1 === undefined &&
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].column?.statusOfColumn1 === false
    ) {
        return 'error'
    }
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].column?.textDecorationOfColumn1 ===
        false
    ) {
        return 'warning'
    }
    return undefined
}

function getTextDecorationCol1ForColumn(
    calculationRuleValue: ICalculationRuleContext | undefined,
    columnId: number,
    stepId: number,
    conditionType: 'if' | 'else'
): 'line-through' | undefined {
    if (
        calculationRuleValue?.columns[columnId - 1].steps[stepId - 1]
            .condition?.[conditionType].column?.textDecorationOfColumn1 ===
        false
    ) {
        return 'line-through'
    }
    return undefined
}

function CalculationRuleConditionalStep({
    name,
    stepId,
    columnId,
    title,
}: {
    name: string
    stepId: number
    columnId: number
    title: string
}): JSX.Element {
    const calculationRuleValue = useContext(CalculationRuleContext)

    const [availableSelectOptions, setAvailableSelectOptions] = React.useState<
        ICalcColumnOption[]
    >([])
    const [availableSelectOptionsAgg, setAvailableSelectOptionsAgg] =
        React.useState<ICalcColumnOption[]>([])
    const [nonNumberSelectOptions, setNonNumberSelectOptions] = React.useState<
        ICalcColumnOption[]
    >([])

    const [loading, setLoading] = React.useState(false)
    const [header, setHeader] = React.useState<JSX.Element>(
        <CalculationRuleConditionalStepCollapseWhenOpened name={name} />
    )

    const onChange = (key: string | string[]): void => {
        if (key.length > 0) {
            setHeader(
                <CalculationRuleConditionalStepCollapseWhenOpened name={name} />
            )
        } else {
            setHeader(
                <CalculationRuleConditionalStepCollapseWhenClosed
                    name={name}
                    stepId={stepId}
                    columnId={columnId}
                />
            )
        }
    }

    useEffect(() => {
        if (!calculationRuleValue) {
            return
        }
        const tempAvailableColumns =
            calculationRuleHelper.filterAvailableColumns(
                calculationRuleValue,
                columnId,
                stepId
            )

        setAvailableSelectOptionsAgg([...(tempAvailableColumns || [])])

        setAvailableSelectOptions([
            ...(tempAvailableColumns || []),
            {
                name: '__constant',
                id: -1,
                type: CalColumnTypes.constant,
                uniqueID: `-1-${CalColumnTypes.constant}`,
            },
        ])

        let datasetMetadata: Record<string, string> = {}
        try {
            datasetMetadata = JSON.parse(
                calculationRuleValue?.dataset?.DatasetMetadata || '{}'
            )
        } catch (e) {
            Sentry.captureException(e)
        }

        const tempNonNumberColumns: ICalcColumnOption[] = Object.keys(
            datasetMetadata
        )
            .filter((key) => datasetMetadata[key] !== 'number')
            .map((key) => {
                return {
                    name: key,
                    id: key,
                    type: CalColumnTypes.dataset,
                    uniqueID: `${key}-${CalColumnTypes.dataset}`,
                }
            })

        setNonNumberSelectOptions(tempNonNumberColumns)
    }, [
        calculationRuleValue,
        calculationRuleValue?.columns,
        calculationRuleValue?.dataset?.DatasetMetadata,
        columnId,
        stepId,
    ])

    function onChangeColumnValueColumn(
        type: 'column1',
        returnOn: 'if' | 'else',
        columnOption?: ICalcColumnOption
    ): void {
        setLoading(true)
        const columnsUpdate = calculationRuleValue?.columns?.map((column) => ({
            ...column,
            steps:
                column.columnID !== columnId
                    ? column.steps
                    : column.steps?.map((item) => {
                          if (item.stepID !== stepId) {
                              return item
                          }
                          const columnStepValue = {
                              ...item.condition?.[returnOn].column,
                          }

                          if (type === 'column1' && columnOption) {
                              columnStepValue.column1 = columnOption.id
                              columnStepValue.column1Type = columnOption.type
                              columnStepValue.statusOfColumn1 = true
                          }
                          return {
                              ...item,
                              condition: {
                                  ...item.condition,
                                  [returnOn]: {
                                      ...item.condition?.[returnOn],
                                      column: columnStepValue,
                                  },
                              },
                          }
                      }),
        }))

        // @ts-ignore
        calculationRuleValue?.setColumns(columnsUpdate || [])
        setLoading(false)
    }

    function onChangeColumnValueAgg(
        type: 'column1' | 'groupBy' | 'operation',
        returnOn: 'if' | 'else',
        columnOption?: ICalcColumnOption,
        aggreagteOperations?: CalAggregateOperations,
        groupByOptions?: ICalculationRuleAggregateStep['groupBy']
    ): void {
        const columnsUpdate = calculationRuleValue?.columns?.map((column) => ({
            ...column,
            steps:
                column.columnID !== columnId
                    ? column.steps
                    : column.steps?.map((item) => {
                          if (item.stepID !== stepId) {
                              return item
                          }
                          const aggregateStepValue = {
                              ...item.condition?.[returnOn].aggregate,
                          }

                          if (type === 'column1' && columnOption) {
                              aggregateStepValue.column1 = columnOption.id
                              aggregateStepValue.column1Type = columnOption.type
                              aggregateStepValue.statusOfColumn1 = true
                          } else if (
                              type === 'operation' &&
                              aggreagteOperations
                          ) {
                              aggregateStepValue.operation = aggreagteOperations
                          } else if (type === 'groupBy' && groupByOptions) {
                              aggregateStepValue.groupBy = groupByOptions
                              aggregateStepValue.statusOfGroupBy = true
                          }

                          return {
                              ...item,
                              condition: {
                                  ...item.condition,
                                  [returnOn]: {
                                      ...item.condition?.[returnOn],
                                      aggregate: aggregateStepValue,
                                  },
                              },
                          }
                      }),
        }))

        // @ts-ignore
        calculationRuleValue?.setColumns(columnsUpdate || [])
    }

    function onChangeColumnValueArth(
        type: 'column1' | 'column2' | 'operation',
        returnOn: 'if' | 'else',
        columnOption?: ICalcColumnOption,
        arithmeticOperation?: ArithmeticOperations
    ): void {
        setLoading(true)
        const columnsUpdate = calculationRuleValue?.columns?.map((column) => ({
            ...column,
            steps:
                column.columnID !== columnId
                    ? column.steps
                    : column.steps?.map((item) => {
                          if (item.stepID !== stepId) {
                              return item
                          }
                          const arithmeticStepValue = {
                              ...item.condition?.[returnOn].arithmetic,
                          }

                          if (type === 'column1' && columnOption) {
                              arithmeticStepValue.column1 = columnOption.id
                              arithmeticStepValue.column1Type =
                                  columnOption.type
                              arithmeticStepValue.statusOfColumn1 = true
                          } else if (type === 'column2' && columnOption) {
                              arithmeticStepValue.column2 = columnOption.id
                              arithmeticStepValue.column2Type =
                                  columnOption.type
                              arithmeticStepValue.statusOfColumn2 = true
                          } else if (
                              type === 'operation' &&
                              arithmeticOperation
                          ) {
                              arithmeticStepValue.operation =
                                  arithmeticOperation
                          }

                          return {
                              ...item,
                              condition: {
                                  ...item.condition,
                                  [returnOn]: {
                                      ...item.condition?.[returnOn],
                                      arithmetic: arithmeticStepValue,
                                  },
                              },
                          }
                      }),
        }))

        // @ts-ignore
        calculationRuleValue?.setColumns(columnsUpdate || [])
        setLoading(false)
    }

    function onChangeColumnValue(
        type: 'column1' | 'column2' | 'operation',
        columnOption?: ICalcColumnOption,
        ConditionOperation?: CalConditionalOperations
    ): void {
        setLoading(true)
        const columnsUpdate = calculationRuleValue?.columns?.map((column) => ({
            ...column,
            steps:
                column.columnID !== columnId
                    ? column.steps
                    : column.steps?.map((item) => {
                          if (item.stepID !== stepId) {
                              return item
                          }
                          const conditionStepValue = {
                              ...item.condition,
                          }

                          if (type === 'column1' && columnOption) {
                              conditionStepValue.column1 = columnOption.id
                              conditionStepValue.column1Type = columnOption.type
                              conditionStepValue.statusOfColumn1 = true
                          } else if (type === 'column2' && columnOption) {
                              conditionStepValue.column2 = columnOption.id
                              conditionStepValue.column2Type = columnOption.type
                              conditionStepValue.statusOfColumn2 = true
                          } else if (
                              type === 'operation' &&
                              ConditionOperation
                          ) {
                              conditionStepValue.operation = ConditionOperation
                          }

                          return {
                              ...item,
                              condition: conditionStepValue,
                          }
                      }),
        }))

        // @ts-ignore
        calculationRuleValue?.setColumns(columnsUpdate || [])
        setLoading(false)
    }

    const onStepTypeChange = (
        returnType: 'if' | 'else',
        conditionStepType:
            | excludecondition.aggregate
            | excludecondition.arithmetic
            | excludecondition.column
    ): void => {
        setLoading(true)
        const columnsUpdate = calculationRuleValue?.columns?.map((column) => ({
            ...column,
            steps:
                column.columnID !== columnId
                    ? column.steps
                    : column.steps?.map((item) => {
                          if (item.stepID !== stepId) {
                              return item
                          }
                          const conditionStepValue = {
                              ...item.condition,
                          }

                          conditionStepValue[returnType] = {
                              conditionStepType,
                          }

                          return {
                              ...item,
                              condition: conditionStepValue,
                          }
                      }),
        }))

        // @ts-ignore
        calculationRuleValue?.setColumns(columnsUpdate || [])
        setLoading(false)
    }

    const currentStepDetails = useMemo(() => {
        return calculationRuleValue?.columns
            ?.find((column) => column.columnID === columnId)
            ?.steps?.find((step) => step.stepID === stepId)
    }, [calculationRuleValue, columnId, stepId])

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handlechangethen = (value: any) => {
        onStepTypeChange('if', value)
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handlechangeelse = (value: any) => {
        onStepTypeChange('else', value)
    }

    return (
        <div style={{ paddingTop: '20px' }}>
            <Collapse defaultActiveKey="stepId" onChange={onChange}>
                <Collapse.Panel
                    key="stepId"
                    header={header}
                    extra={
                        <div
                            role="none"
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            <a
                                onMouseEnter={() => {
                                    if (!calculationRuleValue) {
                                        return
                                    }
                                    calculationRuleHelper.onMouseEnter(
                                        calculationRuleValue,
                                        columnId,
                                        stepId
                                    )
                                }}
                                onMouseLeave={() => {
                                    if (!calculationRuleValue) {
                                        return
                                    }
                                    calculationRuleHelper.onMouseLeave(
                                        calculationRuleValue,
                                        columnId,
                                        stepId
                                    )
                                }}
                            >
                                <Popconfirm
                                    title={title}
                                    onConfirm={() => {
                                        if (!calculationRuleValue) {
                                            return
                                        }
                                        calculationRuleHelper.deleteStep(
                                            calculationRuleValue,
                                            columnId,
                                            stepId
                                        )
                                    }}
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                            </a>
                        </div>
                    }
                >
                    <Row style={{ marginTop: '1px' }} gutter={[50, 16]}>
                        <Space size="large">
                            <Col span={24}>
                                <span
                                    style={{
                                        paddingRight: '28px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    If
                                </span>
                            </Col>
                            <Col span={24}>
                                <span
                                    style={{
                                        paddingRight: '10px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Col 1
                                </span>
                                <Select
                                    value={calculationRuleConditionHelper.getCol1Value(
                                        availableSelectOptions,
                                        calculationRuleValue,
                                        columnId,
                                        stepId
                                    )}
                                    status={calculationRuleConditionHelper.getStatusCol1(
                                        calculationRuleValue,
                                        columnId,
                                        stepId
                                    )}
                                    style={{
                                        minWidth: '160px',
                                        textDecorationLine:
                                            calculationRuleConditionHelper.getTextDecorationCol1(
                                                calculationRuleValue,
                                                columnId,
                                                stepId
                                            ),
                                    }}
                                    loading={loading}
                                    disabled={loading}
                                    onChange={(item: string) => {
                                        setLoading(true)
                                        const selectedItem =
                                            availableSelectOptions.find(
                                                (column) =>
                                                    column.uniqueID === item
                                            )
                                        if (selectedItem) {
                                            onChangeColumnValue(
                                                'column1',
                                                selectedItem
                                            )
                                        }
                                        setLoading(false)
                                    }}
                                >
                                    {availableSelectOptions
                                        .filter((item) => {
                                            return !(
                                                currentStepDetails?.condition
                                                    ?.column2 &&
                                                currentStepDetails.condition
                                                    .column2 === item.id &&
                                                currentStepDetails.condition
                                                    .column2Type === item.type
                                            )
                                        })
                                        .map((column) => (
                                            <Select.Option
                                                key={column.uniqueID}
                                                value={column.uniqueID}
                                            >
                                                {column.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                                {currentStepDetails?.condition?.column1Type ===
                                    CalColumnTypes.constant && (
                                    <Input
                                        value={calculationRuleConditionHelper.getInputValueForCol1(
                                            calculationRuleValue,
                                            columnId,
                                            stepId
                                        )}
                                        type="number"
                                        onChange={(e) => {
                                            setLoading(true)
                                            const selectedItem =
                                                availableSelectOptions.find(
                                                    (column) =>
                                                        column.uniqueID ===
                                                        `-1-${CalColumnTypes.constant}`
                                                )
                                            if (selectedItem) {
                                                onChangeColumnValue('column1', {
                                                    ...selectedItem,
                                                    id: e.target.value,
                                                })
                                            }
                                            setLoading(false)
                                        }}
                                        placeholder="constant value"
                                        style={{ width: '100px' }}
                                    />
                                )}
                            </Col>
                            <Col span={24}>
                                <span
                                    style={{
                                        paddingRight: '10px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Operation
                                </span>
                                <Select
                                    // @ts-ignore
                                    value={calculationRuleConditionHelper.getOperationValue(
                                        calculationRuleValue,
                                        columnId,
                                        stepId
                                    )}
                                    style={{ minWidth: '160px' }}
                                    onChange={(
                                        item: CalConditionalOperations
                                    ) => {
                                        onChangeColumnValue(
                                            'operation',
                                            undefined,
                                            item
                                        )
                                    }}
                                >
                                    {getEnumKeys(CalConditionalOperations).map(
                                        (key) => (
                                            <Select.Option
                                                key={key}
                                                value={key}
                                            >
                                                {key}
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Col>
                            <Col span={24}>
                                <span
                                    style={{
                                        paddingRight: '10px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Col 2
                                </span>
                                <Select
                                    value={calculationRuleConditionHelper.getCol2Value(
                                        availableSelectOptions,
                                        calculationRuleValue,
                                        columnId,
                                        stepId
                                    )}
                                    status={calculationRuleConditionHelper.getStatusCol2(
                                        calculationRuleValue,
                                        columnId,
                                        stepId
                                    )}
                                    style={{
                                        minWidth: '160px',
                                        textDecorationLine:
                                            calculationRuleConditionHelper.getTextDecorationCol2(
                                                calculationRuleValue,
                                                columnId,
                                                stepId
                                            ),
                                    }}
                                    onChange={(item: string) => {
                                        setLoading(true)
                                        const selectedItem =
                                            availableSelectOptions.find(
                                                (column) =>
                                                    column.uniqueID === item
                                            )
                                        if (selectedItem) {
                                            onChangeColumnValue(
                                                'column2',
                                                selectedItem
                                            )
                                        }
                                        setLoading(false)
                                    }}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    {availableSelectOptions
                                        .filter((item) => {
                                            return !(
                                                currentStepDetails?.condition
                                                    ?.column1 &&
                                                currentStepDetails.condition
                                                    .column1 === item.id &&
                                                currentStepDetails.condition
                                                    .column1Type === item.type
                                            )
                                        })
                                        .map((column) => (
                                            <Select.Option
                                                key={column.uniqueID}
                                                value={column.uniqueID}
                                            >
                                                {column.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                                {currentStepDetails?.condition?.column2Type ===
                                    CalColumnTypes.constant && (
                                    <Input
                                        value={calculationRuleConditionHelper.getInputValueForCol2(
                                            calculationRuleValue,
                                            columnId,
                                            stepId
                                        )}
                                        type="number"
                                        onChange={(e) => {
                                            setLoading(true)
                                            const selectedItem =
                                                availableSelectOptions.find(
                                                    (column) =>
                                                        column.uniqueID ===
                                                        `-1-${CalColumnTypes.constant}`
                                                )
                                            if (selectedItem) {
                                                onChangeColumnValue('column2', {
                                                    ...selectedItem,
                                                    id: e.target.value,
                                                })
                                            }
                                            setLoading(false)
                                        }}
                                        placeholder="constant value"
                                        style={{ width: '100px' }}
                                    />
                                )}
                            </Col>
                        </Space>
                    </Row>
                    <br />
                    <Row gutter={[11, 16]}>
                        <Col span={1}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    paddingLeft: '112px',
                                }}
                            >
                                Then
                            </span>
                        </Col>
                        <Col>
                            <span
                                style={{
                                    paddingRight: '10px',
                                    marginLeft: '115px',
                                }}
                            >
                                Choose ConditionStepType:
                            </span>
                            <Select
                                value={getConditionStepTypeValue(
                                    calculationRuleValue,
                                    columnId,
                                    stepId,
                                    'if'
                                )}
                                style={{
                                    minWidth: '3.5cm',
                                    marginTop: '-10px',
                                }}
                                onChange={handlechangethen}
                            >
                                {getEnumKeys(excludecondition).map((key) => (
                                    <Select.Option key={key} value={key}>
                                        {key}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        {calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                            stepId - 1
                        ]?.condition?.if?.conditionStepType === 'arithmetic' ? (
                            <Row>
                                <Space>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                                marginInlineStart: '170px',
                                            }}
                                        >
                                            Col 1
                                        </span>
                                        <Select
                                            value={getCol1ValueForArithmetic(
                                                availableSelectOptions,
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            )}
                                            status={getStatusCol1ForArithmetic(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            )}
                                            key="arithmetic-col1"
                                            style={{
                                                minWidth: '3.5cm',
                                                textDecorationLine:
                                                    getTextDecorationCol1ForArithmetic(
                                                        calculationRuleValue,
                                                        columnId,
                                                        stepId,
                                                        'if'
                                                    ),
                                            }}
                                            loading={loading}
                                            disabled={loading}
                                            onChange={(item: string) => {
                                                setLoading(true)
                                                const selectedItem =
                                                    availableSelectOptions.find(
                                                        (column) =>
                                                            column.uniqueID ===
                                                            item
                                                    )
                                                if (selectedItem) {
                                                    onChangeColumnValueArth(
                                                        'column1',
                                                        'if',
                                                        selectedItem
                                                    )
                                                }
                                                setLoading(false)
                                            }}
                                        >
                                            {availableSelectOptions
                                                .filter((item) => {
                                                    return !(
                                                        currentStepDetails
                                                            ?.condition?.if
                                                            ?.arithmetic
                                                            ?.column2 &&
                                                        currentStepDetails
                                                            ?.condition?.if
                                                            ?.arithmetic
                                                            ?.column2 ===
                                                            item.id &&
                                                        currentStepDetails
                                                            ?.condition?.if
                                                            ?.arithmetic
                                                            .column2Type ===
                                                            item.type
                                                    )
                                                })
                                                .map((column) => (
                                                    <Select.Option
                                                        key={column.uniqueID}
                                                        value={column.uniqueID}
                                                    >
                                                        {column.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                        {currentStepDetails?.condition?.if
                                            ?.arithmetic?.column1Type ===
                                            CalColumnTypes.constant && (
                                            <Input
                                                value={getInputValueForCol1Arithmetic(
                                                    calculationRuleValue,
                                                    columnId,
                                                    stepId,
                                                    'if'
                                                )}
                                                type="number"
                                                onChange={(e) => {
                                                    setLoading(true)
                                                    const selectedItem =
                                                        availableSelectOptions.find(
                                                            (column) =>
                                                                column.uniqueID ===
                                                                `-1-${CalColumnTypes.constant}`
                                                        )
                                                    if (selectedItem) {
                                                        onChangeColumnValueArth(
                                                            'column1',
                                                            'if',
                                                            {
                                                                ...selectedItem,
                                                                id: e.target
                                                                    .value,
                                                            }
                                                        )
                                                    }
                                                    setLoading(false)
                                                }}
                                                placeholder="constant value"
                                                style={{
                                                    width: '100px',
                                                }}
                                            />
                                        )}
                                    </Col>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Operation
                                        </span>
                                        <Select
                                            // @ts-ignore
                                            value={getOperationValueForArithmetic(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            )}
                                            key="arithmetic-operation"
                                            style={{
                                                minWidth: '3.5cm',
                                            }}
                                            onChange={(
                                                item: ArithmeticOperations
                                            ) => {
                                                onChangeColumnValueArth(
                                                    'operation',
                                                    'if',
                                                    undefined,
                                                    item
                                                )
                                            }}
                                        >
                                            {getEnumKeys(
                                                ArithmeticOperations
                                            ).map((key) => (
                                                <Select.Option
                                                    key={key}
                                                    value={key}
                                                >
                                                    {key}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Col 2
                                        </span>
                                        <Select
                                            value={getCol2ValueForArithmetic(
                                                availableSelectOptions,
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            )}
                                            status={getStatusCol2ForArithmetic(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            )}
                                            key="arithmetic-col2"
                                            style={{
                                                minWidth: '3.5cm',
                                                textDecorationLine:
                                                    getTextDecorationCol2ForArithmetic(
                                                        calculationRuleValue,
                                                        columnId,
                                                        stepId,
                                                        'if'
                                                    ),
                                            }}
                                            onChange={(item: string) => {
                                                setLoading(true)
                                                const selectedItem =
                                                    availableSelectOptions.find(
                                                        (column) =>
                                                            column.uniqueID ===
                                                            item
                                                    )
                                                if (selectedItem) {
                                                    onChangeColumnValueArth(
                                                        'column2',
                                                        'if',
                                                        selectedItem
                                                    )
                                                }
                                                setLoading(false)
                                            }}
                                            loading={loading}
                                            disabled={loading}
                                        >
                                            {availableSelectOptions
                                                .filter((item) => {
                                                    return !(
                                                        currentStepDetails
                                                            ?.condition?.if
                                                            ?.arithmetic
                                                            ?.column1 &&
                                                        currentStepDetails
                                                            ?.condition?.if
                                                            ?.arithmetic
                                                            ?.column1 ===
                                                            item.id &&
                                                        currentStepDetails
                                                            ?.condition?.if
                                                            ?.arithmetic
                                                            .column1Type ===
                                                            item.type
                                                    )
                                                })
                                                .map((column) => (
                                                    <Select.Option
                                                        key={column.uniqueID}
                                                        value={column.uniqueID}
                                                    >
                                                        {column.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                        {currentStepDetails?.condition?.if
                                            ?.arithmetic?.column2Type ===
                                            CalColumnTypes.constant && (
                                            <Input
                                                value={getInputValueForCol2Arithmetic(
                                                    calculationRuleValue,
                                                    columnId,
                                                    stepId,
                                                    'if'
                                                )}
                                                type="number"
                                                onChange={(e) => {
                                                    setLoading(true)
                                                    const selectedItem =
                                                        availableSelectOptions.find(
                                                            (column) =>
                                                                column.uniqueID ===
                                                                `-1-${CalColumnTypes.constant}`
                                                        )
                                                    if (selectedItem) {
                                                        onChangeColumnValueArth(
                                                            'column2',
                                                            'if',
                                                            {
                                                                ...selectedItem,
                                                                id: e.target
                                                                    .value,
                                                            }
                                                        )
                                                    }
                                                    setLoading(false)
                                                }}
                                                placeholder="constant value"
                                                style={{
                                                    width: '100px',
                                                }}
                                            />
                                        )}
                                    </Col>
                                </Space>
                            </Row>
                        ) : calculationRuleValue?.columns?.[columnId - 1]
                              ?.steps?.[stepId - 1]?.condition?.if
                              ?.conditionStepType === 'aggregate' ? (
                            <Row>
                                <Space>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                                marginInlineStart: '170px',
                                            }}
                                        >
                                            Col 1
                                        </span>
                                        <Select
                                            value={getcol1valueForAggregate(
                                                availableSelectOptionsAgg,
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            )}
                                            status={getStatusCol1ForAggregate(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            )}
                                            key="aggregate-col-1"
                                            style={{
                                                minWidth: '3.5cm',
                                                textDecorationLine:
                                                    getTextDecorationCol1ForAggregate(
                                                        calculationRuleValue,
                                                        columnId,
                                                        stepId,
                                                        'if'
                                                    ),
                                            }}
                                            onChange={(item: string) => {
                                                const selectedItem =
                                                    availableSelectOptionsAgg.find(
                                                        (column) =>
                                                            column.uniqueID ===
                                                            item
                                                    )
                                                if (selectedItem) {
                                                    onChangeColumnValueAgg(
                                                        'column1',
                                                        'if',
                                                        selectedItem
                                                    )
                                                }
                                            }}
                                        >
                                            {availableSelectOptionsAgg.map(
                                                (column) => (
                                                    <Select.Option
                                                        key={column.uniqueID}
                                                        value={column.uniqueID}
                                                    >
                                                        {column.name}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    </Col>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Operation
                                        </span>
                                        <Select
                                            // @ts-ignore
                                            value={getOperationValueForAggregate(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            )}
                                            key="aggregate-operation"
                                            style={{
                                                minWidth: '3.5cm',
                                            }}
                                            onChange={(
                                                item: CalAggregateOperations
                                            ) => {
                                                onChangeColumnValueAgg(
                                                    'operation',
                                                    'if',
                                                    undefined,
                                                    item
                                                )
                                            }}
                                        >
                                            {getEnumKeys(
                                                CalAggregateOperations
                                            ).map((key) => (
                                                <Select.Option
                                                    key={key}
                                                    value={key}
                                                >
                                                    {key}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Group By
                                        </span>
                                        <Select
                                            value={getGroupByValueForAggregate(
                                                availableSelectOptionsAgg,
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            )}
                                            status={getStatusGroupByForAggregate(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            )}
                                            key="aggregate-group-by"
                                            mode="multiple"
                                            allowClear
                                            style={{
                                                width: '200px',
                                            }}
                                            onChange={(uniqueIds: string[]) => {
                                                const selectedColumns =
                                                    uniqueIds.map(
                                                        (uniqueId) => {
                                                            const selectedColumn =
                                                                [
                                                                    ...availableSelectOptionsAgg,
                                                                    ...nonNumberSelectOptions,
                                                                ].find(
                                                                    (column) =>
                                                                        column.uniqueID ===
                                                                        uniqueId
                                                                )
                                                            if (
                                                                selectedColumn
                                                            ) {
                                                                return {
                                                                    columnValue:
                                                                        selectedColumn.id,
                                                                    columnType:
                                                                        selectedColumn.type,
                                                                }
                                                            }
                                                            throw new Error(
                                                                // eslint-disable-next-line max-len
                                                                `Column with uniqueID ${uniqueId} not found`
                                                            )
                                                        }
                                                    )

                                                onChangeColumnValueAgg(
                                                    'groupBy',
                                                    'if',
                                                    undefined,
                                                    undefined,
                                                    selectedColumns
                                                )
                                            }}
                                        >
                                            {[
                                                ...availableSelectOptionsAgg,
                                                ...nonNumberSelectOptions,
                                            ].map((column) => (
                                                <Select.Option
                                                    key={column.uniqueID}
                                                    value={column.uniqueID}
                                                >
                                                    {column.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Space>
                            </Row>
                        ) : calculationRuleValue?.columns?.[columnId - 1]
                              ?.steps?.[stepId - 1]?.condition?.if
                              ?.conditionStepType === 'column' ? (
                            <Col>
                                <span
                                    style={{
                                        paddingRight: '10px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Col 1
                                </span>
                                <Select
                                    value={getCol1ValueForColumn(
                                        availableSelectOptions,
                                        calculationRuleValue,
                                        columnId,
                                        stepId,
                                        'if'
                                    )}
                                    status={getStatusCol1ForColumn(
                                        calculationRuleValue,
                                        columnId,
                                        stepId,
                                        'if'
                                    )}
                                    key="column-col1"
                                    style={{
                                        minWidth: '3.5cm',
                                        marginTop: '-40cm',
                                        textDecorationLine:
                                            getTextDecorationCol1ForColumn(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'if'
                                            ),
                                    }}
                                    loading={loading}
                                    disabled={loading}
                                    onChange={(item: string) => {
                                        setLoading(true)
                                        const selectedItem =
                                            availableSelectOptions.find(
                                                (column) =>
                                                    column.uniqueID === item
                                            )
                                        if (selectedItem) {
                                            onChangeColumnValueColumn(
                                                'column1',
                                                'if',
                                                selectedItem
                                            )
                                        }
                                        setLoading(false)
                                    }}
                                >
                                    {availableSelectOptions.map((column) => (
                                        <Select.Option
                                            key={column.uniqueID}
                                            value={column.uniqueID}
                                        >
                                            {column.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                                {currentStepDetails?.condition?.if?.column
                                    ?.column1Type ===
                                    CalColumnTypes.constant && (
                                    <Input
                                        value={getInputValueForCol1Column(
                                            calculationRuleValue,
                                            columnId,
                                            stepId,
                                            'if'
                                        )}
                                        type="number"
                                        onChange={(e) => {
                                            setLoading(true)
                                            const selectedItem =
                                                availableSelectOptions.find(
                                                    (column) =>
                                                        column.uniqueID ===
                                                        `-1-${CalColumnTypes.constant}`
                                                )
                                            if (selectedItem) {
                                                onChangeColumnValueColumn(
                                                    'column1',
                                                    'if',
                                                    {
                                                        ...selectedItem,
                                                        id: e.target.value,
                                                    }
                                                )
                                            }
                                            setLoading(false)
                                        }}
                                        placeholder="constant value"
                                        style={{
                                            width: '100px',
                                        }}
                                    />
                                )}
                            </Col>
                        ) : null}
                    </Row>
                    <br />
                    <Row gutter={[11, 16]}>
                        <Col span={1}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Else
                            </span>
                        </Col>
                        <Col>
                            <span
                                style={{
                                    paddingRight: '10px',
                                    marginLeft: '65px',
                                }}
                            >
                                Choose ConditionStepType:
                            </span>
                            <Select
                                value={getConditionStepTypeValue(
                                    calculationRuleValue,
                                    columnId,
                                    stepId,
                                    'else'
                                )}
                                style={{
                                    minWidth: '3.5cm',
                                    marginTop: '-10cm',
                                }}
                                onChange={handlechangeelse}
                            >
                                {getEnumKeys(excludecondition).map((key) => (
                                    <Select.Option key={key} value={key}>
                                        {key}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        {calculationRuleValue?.columns?.[columnId - 1]?.steps?.[
                            stepId - 1
                        ]?.condition?.else?.conditionStepType ===
                        'arithmetic' ? (
                            <Row>
                                <Space>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                                marginLeft: '118px',
                                            }}
                                        >
                                            Col 1
                                        </span>
                                        <Select
                                            status={getStatusCol1ForArithmetic(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            )}
                                            value={getCol1ValueForArithmetic(
                                                availableSelectOptions,
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            )}
                                            key="arithmetic-col1"
                                            style={{
                                                minWidth: '3.5cm',
                                                textDecorationLine:
                                                    getTextDecorationCol1ForArithmetic(
                                                        calculationRuleValue,
                                                        columnId,
                                                        stepId,
                                                        'else'
                                                    ),
                                            }}
                                            loading={loading}
                                            disabled={loading}
                                            onChange={(item: string) => {
                                                setLoading(true)
                                                const selectedItem =
                                                    availableSelectOptions.find(
                                                        (column) =>
                                                            column.uniqueID ===
                                                            item
                                                    )
                                                if (selectedItem) {
                                                    onChangeColumnValueArth(
                                                        'column1',
                                                        'else',
                                                        selectedItem
                                                    )
                                                }
                                                setLoading(false)
                                            }}
                                        >
                                            {availableSelectOptions
                                                .filter((item) => {
                                                    return !(
                                                        currentStepDetails
                                                            ?.condition?.else
                                                            ?.arithmetic
                                                            ?.column2 &&
                                                        currentStepDetails
                                                            ?.condition?.else
                                                            ?.arithmetic
                                                            ?.column2 ===
                                                            item.id &&
                                                        currentStepDetails
                                                            ?.condition?.else
                                                            ?.arithmetic
                                                            .column2Type ===
                                                            item.type
                                                    )
                                                })
                                                .map((column) => (
                                                    <Select.Option
                                                        key={column.uniqueID}
                                                        value={column.uniqueID}
                                                    >
                                                        {column.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                        {currentStepDetails?.condition?.else
                                            ?.arithmetic?.column1Type ===
                                            CalColumnTypes.constant && (
                                            <Input
                                                value={getInputValueForCol1Arithmetic(
                                                    calculationRuleValue,
                                                    columnId,
                                                    stepId,
                                                    'else'
                                                )}
                                                type="number"
                                                onChange={(e) => {
                                                    setLoading(true)
                                                    const selectedItem =
                                                        availableSelectOptions.find(
                                                            (column) =>
                                                                column.uniqueID ===
                                                                `-1-${CalColumnTypes.constant}`
                                                        )
                                                    if (selectedItem) {
                                                        onChangeColumnValueArth(
                                                            'column1',
                                                            'else',
                                                            {
                                                                ...selectedItem,
                                                                id: e.target
                                                                    .value,
                                                            }
                                                        )
                                                    }
                                                    setLoading(false)
                                                }}
                                                placeholder="constant value"
                                                style={{
                                                    width: '100px',
                                                }}
                                            />
                                        )}
                                    </Col>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Operation
                                        </span>
                                        <Select
                                            // @ts-ignore
                                            value={getOperationValueForArithmetic(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            )}
                                            key="arithmetic-operation"
                                            style={{
                                                minWidth: '3.5cm',
                                            }}
                                            onChange={(
                                                item: ArithmeticOperations
                                            ) => {
                                                onChangeColumnValueArth(
                                                    'operation',
                                                    'else',
                                                    undefined,
                                                    item
                                                )
                                            }}
                                        >
                                            {getEnumKeys(
                                                ArithmeticOperations
                                            ).map((key) => (
                                                <Select.Option
                                                    key={key}
                                                    value={key}
                                                >
                                                    {key}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Col 2
                                        </span>
                                        <Select
                                            value={getCol2ValueForArithmetic(
                                                availableSelectOptions,
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            )}
                                            status={getStatusCol2ForArithmetic(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            )}
                                            key="arithmetic-col2"
                                            style={{
                                                minWidth: '3.5cm',
                                                textDecorationLine:
                                                    getTextDecorationCol2ForArithmetic(
                                                        calculationRuleValue,
                                                        columnId,
                                                        stepId,
                                                        'else'
                                                    ),
                                            }}
                                            onChange={(item: string) => {
                                                setLoading(true)
                                                const selectedItem =
                                                    availableSelectOptions.find(
                                                        (column) =>
                                                            column.uniqueID ===
                                                            item
                                                    )
                                                if (selectedItem) {
                                                    onChangeColumnValueArth(
                                                        'column2',
                                                        'else',
                                                        selectedItem
                                                    )
                                                }
                                                setLoading(false)
                                            }}
                                            loading={loading}
                                            disabled={loading}
                                        >
                                            {availableSelectOptions
                                                .filter((item) => {
                                                    return !(
                                                        currentStepDetails
                                                            ?.condition?.else
                                                            ?.arithmetic
                                                            ?.column1 &&
                                                        currentStepDetails
                                                            ?.condition?.else
                                                            ?.arithmetic
                                                            ?.column1 ===
                                                            item.id &&
                                                        currentStepDetails
                                                            ?.condition?.else
                                                            ?.arithmetic
                                                            .column1Type ===
                                                            item.type
                                                    )
                                                })
                                                .map((column) => (
                                                    <Select.Option
                                                        key={column.uniqueID}
                                                        value={column.uniqueID}
                                                    >
                                                        {column.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                        {currentStepDetails?.condition?.else
                                            ?.arithmetic?.column2Type ===
                                            CalColumnTypes.constant && (
                                            <Input
                                                value={getInputValueForCol2Arithmetic(
                                                    calculationRuleValue,
                                                    columnId,
                                                    stepId,
                                                    'else'
                                                )}
                                                type="number"
                                                onChange={(e) => {
                                                    setLoading(true)
                                                    const selectedItem =
                                                        availableSelectOptions.find(
                                                            (column) =>
                                                                column.uniqueID ===
                                                                `-1-${CalColumnTypes.constant}`
                                                        )
                                                    if (selectedItem) {
                                                        onChangeColumnValueArth(
                                                            'column2',
                                                            'else',
                                                            {
                                                                ...selectedItem,
                                                                id: e.target
                                                                    .value,
                                                            }
                                                        )
                                                    }
                                                    setLoading(false)
                                                }}
                                                placeholder="constant value"
                                                style={{
                                                    width: '100px',
                                                }}
                                            />
                                        )}
                                    </Col>
                                </Space>
                            </Row>
                        ) : calculationRuleValue?.columns?.[columnId - 1]
                              ?.steps?.[stepId - 1]?.condition?.else
                              ?.conditionStepType === 'aggregate' ? (
                            <Row>
                                <Space>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                                marginInlineStart: '118px',
                                            }}
                                        >
                                            Col 1
                                        </span>
                                        <Select
                                            value={getcol1valueForAggregate(
                                                availableSelectOptionsAgg,
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            )}
                                            status={getStatusCol1ForAggregate(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            )}
                                            key="aggregate-col1"
                                            style={{
                                                minWidth: '3.5cm',
                                                textDecorationLine:
                                                    getTextDecorationCol1ForAggregate(
                                                        calculationRuleValue,
                                                        columnId,
                                                        stepId,
                                                        'else'
                                                    ),
                                            }}
                                            onChange={(item: string) => {
                                                const selectedItem =
                                                    availableSelectOptionsAgg.find(
                                                        (column) =>
                                                            column.uniqueID ===
                                                            item
                                                    )
                                                if (selectedItem) {
                                                    onChangeColumnValueAgg(
                                                        'column1',
                                                        'else',
                                                        selectedItem
                                                    )
                                                }
                                            }}
                                        >
                                            {availableSelectOptionsAgg.map(
                                                (column) => (
                                                    <Select.Option
                                                        key={column.uniqueID}
                                                        value={column.uniqueID}
                                                    >
                                                        {column.name}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    </Col>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Operation
                                        </span>
                                        <Select
                                            // @ts-ignore
                                            value={getOperationValueForAggregate(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            )}
                                            key="aggregate-operation"
                                            style={{
                                                minWidth: '3.5cm',
                                            }}
                                            onChange={(
                                                item: CalAggregateOperations
                                            ) => {
                                                onChangeColumnValueAgg(
                                                    'operation',
                                                    'else',
                                                    undefined,
                                                    item
                                                )
                                            }}
                                        >
                                            {getEnumKeys(
                                                CalAggregateOperations
                                            ).map((key) => (
                                                <Select.Option
                                                    key={key}
                                                    value={key}
                                                >
                                                    {key}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col>
                                        <span
                                            style={{
                                                paddingRight: '10px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Group By
                                        </span>
                                        <Select
                                            value={getGroupByValueForAggregate(
                                                availableSelectOptionsAgg,
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            )}
                                            status={getStatusGroupByForAggregate(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            )}
                                            key="aggregate-group-by"
                                            mode="multiple"
                                            allowClear
                                            style={{
                                                width: '200px',
                                            }}
                                            onChange={(uniqueIds: string[]) => {
                                                const selectedColumns =
                                                    uniqueIds.map(
                                                        (uniqueId) => {
                                                            const selectedColumn =
                                                                [
                                                                    ...availableSelectOptionsAgg,
                                                                    ...nonNumberSelectOptions,
                                                                ].find(
                                                                    (column) =>
                                                                        column.uniqueID ===
                                                                        uniqueId
                                                                )
                                                            if (
                                                                selectedColumn
                                                            ) {
                                                                return {
                                                                    columnValue:
                                                                        selectedColumn.id,
                                                                    columnType:
                                                                        selectedColumn.type,
                                                                }
                                                            }
                                                            throw new Error(
                                                                // eslint-disable-next-line max-len
                                                                `Column with uniqueID ${uniqueId} not found`
                                                            )
                                                        }
                                                    )

                                                onChangeColumnValueAgg(
                                                    'groupBy',
                                                    'else',
                                                    undefined,
                                                    undefined,
                                                    selectedColumns
                                                )
                                            }}
                                        >
                                            {[
                                                ...availableSelectOptionsAgg,
                                                ...nonNumberSelectOptions,
                                            ].map((column) => (
                                                <Select.Option
                                                    key={column.uniqueID}
                                                    value={column.uniqueID}
                                                >
                                                    {column.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Space>
                            </Row>
                        ) : calculationRuleValue?.columns?.[columnId - 1]
                              ?.steps?.[stepId - 1]?.condition?.else
                              ?.conditionStepType === 'column' ? (
                            <Col>
                                <span
                                    style={{
                                        paddingRight: '10px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Col 1
                                </span>
                                <Select
                                    value={getCol1ValueForColumn(
                                        availableSelectOptions,
                                        calculationRuleValue,
                                        columnId,
                                        stepId,
                                        'else'
                                    )}
                                    status={getStatusCol1ForColumn(
                                        calculationRuleValue,
                                        columnId,
                                        stepId,
                                        'else'
                                    )}
                                    key="column-col1"
                                    style={{
                                        minWidth: '3.5cm',
                                        marginTop: '-40cm',
                                        textDecorationLine:
                                            getTextDecorationCol1ForColumn(
                                                calculationRuleValue,
                                                columnId,
                                                stepId,
                                                'else'
                                            ),
                                    }}
                                    loading={loading}
                                    disabled={loading}
                                    onChange={(item: string) => {
                                        setLoading(true)
                                        const selectedItem =
                                            availableSelectOptions.find(
                                                (column) =>
                                                    column.uniqueID === item
                                            )
                                        if (selectedItem) {
                                            onChangeColumnValueColumn(
                                                'column1',
                                                'else',
                                                selectedItem
                                            )
                                        }
                                        setLoading(false)
                                    }}
                                >
                                    {availableSelectOptions.map((column) => (
                                        <Select.Option
                                            key={column.uniqueID}
                                            value={column.uniqueID}
                                        >
                                            {column.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                                {currentStepDetails?.condition?.else?.column
                                    ?.column1Type ===
                                    CalColumnTypes.constant && (
                                    <Input
                                        value={getInputValueForCol1Column(
                                            calculationRuleValue,
                                            columnId,
                                            stepId,
                                            'else'
                                        )}
                                        type="number"
                                        onChange={(e) => {
                                            setLoading(true)
                                            const selectedItem =
                                                availableSelectOptions.find(
                                                    (column) =>
                                                        column.uniqueID ===
                                                        `-1-${CalColumnTypes.constant}`
                                                )
                                            if (selectedItem) {
                                                onChangeColumnValueColumn(
                                                    'column1',
                                                    'else',
                                                    {
                                                        ...selectedItem,
                                                        id: e.target.value,
                                                    }
                                                )
                                            }
                                            setLoading(false)
                                        }}
                                        placeholder="constant value"
                                        style={{
                                            width: '100px',
                                        }}
                                    />
                                )}
                            </Col>
                        ) : null}
                    </Row>
                </Collapse.Panel>
            </Collapse>
        </div>
    )
}

export default CalculationRuleConditionalStep
