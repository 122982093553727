import React, { useEffect } from 'react'
import moment from 'moment'
import { calculationRuleService } from '../../services/calculation-rule/calculation-rule.service'
import { ICalculationRuleResponse } from '../../services/calculation-rule/interfaces/calculation-rule-response.interface'
import { handleAPIError } from '../../utils/catch-error'
import { Table } from 'antd'
import { getColumnSearchProps } from '../../utils/columnsearch-props'
import { Link } from 'react-router-dom'
import { routeMapMini } from '../../constants/routeMap'

function CalculationRuleTable(): JSX.Element {
    const [calculationRules, setCalculationRules] = React.useState<
        ICalculationRuleResponse[] | undefined
    >(undefined)
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState('')

    useEffect(() => {
        setLoading(true)
        calculationRuleService
            .getAll()
            .then((resp) => {
                setCalculationRules(resp)
                setLoading(false)
            })
            .catch((err) => {
                handleAPIError(err)
                setLoading(false)
            })
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'CalculationRuleID',
            key: 'CalculationRuleID',
            sorter: (
                a: ICalculationRuleResponse,
                b: ICalculationRuleResponse
            ) => {
                return (a.CalculationRuleID || 1) > (b.CalculationRuleID || 1)
                    ? 1
                    : -1
            },
        },
        {
            title: 'Calculation Rule Name',
            dataIndex: 'CalculationRuleName',
            key: 'CalculationRuleID',
            ...getColumnSearchProps(
                'CalculationRuleName',
                searchText,
                setSearchText
            ),
        },
        {
            title: 'Data Group Name',
            dataIndex: 'SourceGroupName',
            key: 'CalculationRuleID',
            filters: Array.from(
                new Set(calculationRules?.map((item) => item.SourceGroupName))
            )?.map((item) => ({
                text: item,
                value: item,
            })),
            onFilter: (
                value: string | number | boolean,
                record: ICalculationRuleResponse
            ) => {
                return (
                    typeof value === 'string' &&
                    record.SourceGroupName?.indexOf?.(value) === 0
                )
            },
        },
        {
            title: 'Dataset Name',
            dataIndex: 'DatasetName',
            key: 'CalculationRuleID',
            filters: Array.from(
                new Set(calculationRules?.map((item) => item.DatasetName))
            )?.map((item) => ({
                text: item,
                value: item,
            })),
            onFilter: (
                value: string | number | boolean,
                record: ICalculationRuleResponse
            ) => {
                return (
                    typeof value === 'string' &&
                    record.DatasetName?.indexOf?.(value) === 0
                )
            },
        },
        {
            title: 'Created At',
            dataIndex: 'AuditDateTime',
            key: 'CalculationRuleID',
            render: (text: string) => {
                return moment(text).format('YYYY-MM-DD HH:mm')
            },
            sorter: (
                a: ICalculationRuleResponse,
                b: ICalculationRuleResponse
            ) => {
                return (
                    moment(a.AuditDateTime).valueOf() -
                    moment(b.AuditDateTime).valueOf()
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'CalculationRuleID',
            ellipsis: true,
        },
        {
            title: 'Action',
            dataIndex: 'CalculationRuleID',
            key: 'CalculationRuleID',
            render: (text: string) => {
                return (
                    <div>
                        <Link
                            to={`${routeMapMini.rulesCalculationView}?viewRuleID=${text}`}
                        >
                            <a>View</a>
                        </Link>
                    </div>
                )
            },
        },
    ]

    return (
        <Table
            columns={columns}
            dataSource={calculationRules}
            loading={loading}
        />
    )
}

export default CalculationRuleTable
