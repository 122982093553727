import * as React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadRequestOption } from 'rc-upload/lib/interface'
import {
    Button,
    Checkbox,
    Divider,
    Input,
    Row,
    Space,
    Spin,
    Typography,
    Upload,
} from 'antd'
import { FileUploadService } from '../../../services/file-upload/file-upload.service'
import * as XLSX from 'xlsx'
import { RcFile } from 'antd/es/upload'
import { handleAPIError } from '../../../utils/catch-error'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useEffect } from 'react'
import { calculationRuleService } from '../../../services/calculation-rule/calculation-rule.service'
import { FileProperties } from '../../../services/file-upload/interfaces/file-properties.interface'
import { xlsxUtil } from '../../../utils/xlsx.util'

type ICalculationRules = {
    CalculationRuleID: number
    CalculationRuleName: string
    key: string
}

type IProps = {
    setSourceFileName: React.Dispatch<React.SetStateAction<string | undefined>>
    sourceFileName: string | undefined
    fileProperties: FileProperties
    setFileProperties: React.Dispatch<React.SetStateAction<FileProperties>>
    datasetName: string | undefined
    datasetId: number | undefined
    setMetadata: React.Dispatch<React.SetStateAction<Record<string, string>>>
    selectedCalculationRules: ICalculationRules[]
    setSelectedCalculationRules: React.Dispatch<
        React.SetStateAction<ICalculationRules[]>
    >
}

function CreateSourceFile({
    datasetName,
    sourceFileName,
    setSourceFileName,
    fileProperties,
    setFileProperties,
    datasetId,
    setMetadata,
    selectedCalculationRules,
    setSelectedCalculationRules,
}: IProps): JSX.Element {
    const [datasetCalculationRules, setDatasetCalculationRules] =
        React.useState<ICalculationRules[]>([])
    const [datasetLoading, setDatasetLoading] = React.useState(false)
    const [fileUploadLoading, setFileUploadLoading] = React.useState(false)

    const generateFileName = (originalFileName: string): string => {
        const fileName = originalFileName.split('.')
        const fileExtension = fileName[fileName.length - 1]
        return `source_files/${datasetId}/${Date.now()}.${fileExtension}`
    }

    const uploadFile = async (options: UploadRequestOption): Promise<void> => {
        const { onSuccess, onError, file, onProgress } = options
        try {
            const resp = await FileUploadService.uploadFile(
                // @ts-ignore
                file,
                // @ts-ignore
                generateFileName(file.name),
                onProgress
            )
            if (resp)
                setFileProperties({
                    ...fileProperties,
                    FileLocation: resp.fileLocation,
                })
            onSuccess?.('Ok')
        } catch (err: any) {
            // @ts-ignore
            onError?.({ err })
            handleAPIError(err)
        }
    }

    const beforeUpload = async (file: RcFile): Promise<boolean> => {
        setFileUploadLoading(true)
        const arrayBuffer = await file.arrayBuffer()
        const workbook = XLSX.read(arrayBuffer, {
            type: 'array',
            sheetRows: 50,
        })
        const sheet1 = workbook.Sheets[workbook.SheetNames[0]]
        const sheetInJson: [][] = XLSX.utils.sheet_to_json(sheet1, {
            header: 1,
        })

        const tempMetadata: Record<string, string> = {}

        sheetInJson?.[0]?.forEach((value: string, index: number) => {
            tempMetadata[value] = typeof sheetInJson?.[1]?.[index]
        })

        setMetadata(tempMetadata)
        setFileUploadLoading(false)
        setFileProperties({
            ...fileProperties,
            FilePreviewData: xlsxUtil.convertCSV2DArrayToObject(sheetInJson),
            FileName: file.name,
        })
        return true
    }

    useEffect(() => {
        if (!datasetId) {
            return
        }
        setDatasetLoading(true)
        calculationRuleService
            .getCalRulesForDataset(datasetId)
            .then((data) => {
                setDatasetCalculationRules(
                    data.map((item) => ({
                        CalculationRuleID: item.CalculationRuleID,
                        CalculationRuleName: item.CalculationRuleName,
                        key: `${item.CalculationRuleName} (Calc-${item.CalculationRuleID})`,
                    }))
                )
            })
            .catch(handleAPIError)
        setDatasetLoading(false)
    }, [datasetId])

    return (
        <>
            <Space direction="vertical" size="middle">
                <Typography.Text>
                    Creating Source File in dataset <b>{datasetName}</b>
                </Typography.Text>
                <Input
                    onChange={(e) => setSourceFileName(e.target.value)}
                    placeholder="Enter Source File Name"
                    value={sourceFileName}
                />
                <Upload customRequest={uploadFile} beforeUpload={beforeUpload}>
                    <Button loading={fileUploadLoading}>Upload File</Button>
                </Upload>
            </Space>
            <Divider />
            {datasetLoading && (
                <Spin>
                    <span>Fetching Linked Rules</span>
                </Spin>
            )}
            {datasetCalculationRules?.length > 0 && (
                <Space direction="vertical">
                    <Row>
                        <b>Select Priority Validators</b>
                    </Row>
                    <Row>
                        <Checkbox.Group
                            options={datasetCalculationRules.map(
                                (item) => item.key
                            )}
                            value={selectedCalculationRules.map(
                                (item) => item.key
                            )}
                            onChange={(checkedValues: CheckboxValueType[]) => {
                                setSelectedCalculationRules(
                                    datasetCalculationRules.filter((item) =>
                                        checkedValues
                                            .map(String)
                                            .includes(item.key)
                                    )
                                )
                            }}
                        />
                    </Row>
                </Space>
            )}
        </>
    )
}

export default CreateSourceFile
