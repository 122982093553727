import * as React from 'react'
import { Button, Modal, notification, Select, Space } from 'antd'
import SourceFileTable from './source-file-table'
import { ISourceGroup } from '../../../services/source-group/interfaces/source-group.interface'
import { useEffect } from 'react'
import { sourceGroupService } from '../../../services/source-group/source-group.service'
import { withRouter } from '../../../utils/withRouter'
import { sourceDatasetService } from '../../../services/source-group/source-dataset.service'
import { ISourceDataset } from '../../../services/source-group/interfaces/source-dataset.interface'
import CreateSourceFile from './create-source-file'
import { sourceFileInfoService } from '../../../services/source-group/source-file-info.service'
import { handleAPIError } from '../../../utils/catch-error'
import ViewDatasetMetadataDrawer from '../source-dataset/view-dataset-metadata-drawer'
import { useSearchParams } from 'react-router-dom'
import { FileProperties } from '../../../services/file-upload/interfaces/file-properties.interface'
import { parseJson } from '../../../utils/parse.util'

const defaultFileProperties: FileProperties = {
    FileLocation: '',
    FileName: '',
    FileType: 'source_file',
    FilePreviewData: [],
}

function SourceFile(): JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams()
    const [sourceGroup, setSourceGroup] = React.useState<
        ISourceGroup[] | undefined
    >(undefined)
    const [dataset, setDataset] = React.useState<ISourceDataset[] | undefined>(
        undefined
    )

    const [selectedSourceGroup, setSelectedSourceGroup] = React.useState<
        ISourceGroup | undefined
    >(undefined)
    const [selectedDataset, setSelectedDataset] = React.useState<
        ISourceDataset | undefined
    >(undefined)
    const [datasetStructureDrawerVisible, setDatasetStructureDrawerVisible] =
        React.useState(false)

    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false)
    const [createIsLoading, setCreateIsLoading] = React.useState<boolean>(false)
    const [sourceFileName, setSourceFileName] = React.useState<
        string | undefined
    >()
    const [fileProperties, setFileProperties] = React.useState<FileProperties>({
        ...defaultFileProperties,
    })
    const [metadata, setMetadata] = React.useState<Record<string, string>>({})
    const [selectedCalculationRules, setSelectedCalculationRules] =
        React.useState<
            {
                CalculationRuleID: number
                CalculationRuleName: string
                key: string
            }[]
        >([])

    const [groupClicked, setGroupClicked] = React.useState(false)

    const setSelectedSourceGroupProxy = (sg: ISourceGroup): void => {
        if (!sg) {
            return
        }
        setSearchParams({
            sourceGroupId: String(sg.SourceGroupID),
        })
        setSelectedSourceGroup(sg)
        setGroupClicked(true)
    }

    const setSelectedDatasetProxy = (ds: ISourceDataset): void => {
        setSearchParams({
            datasetId: ds ? String(ds.DatasetID) : String(0),
            sourceGroupId: ds
                ? String(ds.SourceGroupID)
                : String(selectedSourceGroup?.SourceGroupID),
        })
        setSelectedDataset(ds)
    }

    useEffect(() => {
        sourceGroupService
            .getAll()
            .then((res) => {
                setSourceGroup(res)
                if (searchParams.get('sourceGroupId')) {
                    setSelectedSourceGroup(
                        res.find(
                            (sg) =>
                                sg.SourceGroupID ===
                                Number(searchParams.get('sourceGroupId'))
                        )
                    )
                }
            })
            .catch(handleAPIError)
    }, [searchParams])

    useEffect(() => {
        sourceDatasetService
            .getDatasetsForGroup(selectedSourceGroup?.SourceGroupID || 0)
            .then((res) => {
                setDataset(res)
                if (res.length === 0) {
                    setSelectedDataset(undefined)
                } else {
                    if (groupClicked) {
                        setSearchParams({
                            datasetId: String(res[0].DatasetID),
                            sourceGroupId: String(
                                selectedSourceGroup?.SourceGroupID
                            ),
                        })
                    }
                    if (searchParams.get('datasetId')) {
                        setSelectedDataset(
                            res.find(
                                (ds) =>
                                    ds.DatasetID ===
                                    Number(searchParams.get('datasetId'))
                            )
                        )
                    }
                }
            })
            .catch(handleAPIError)
        setGroupClicked(false)
    }, [selectedSourceGroup, searchParams, setSearchParams, groupClicked])

    const handleOk = async (e: any): Promise<void> => {
        e.preventDefault()
        if (!fileProperties || !sourceFileName) {
            notification.error({
                message: `Error: Please fill in all the required fields`,
            })
            return
        }
        if (!selectedDataset) {
            notification.error({
                message: `Error: Please select an allocation rule group`,
            })
        }
        setCreateIsLoading(true)
        try {
            await sourceFileInfoService.create({
                SourceName: sourceFileName,
                DatasetID: selectedDataset?.DatasetID || 0,
                FileLocation: fileProperties.FileLocation,
                FileName: fileProperties.FileName,
                FileType: fileProperties.FileType,
                FilePreviewData: fileProperties.FilePreviewData,
                SourceMetadata: JSON.stringify(metadata),
                priorityValidators: selectedCalculationRules.map((item) => {
                    return {
                        CalculationRuleID: item.CalculationRuleID,
                    }
                }),
            })
            setFileProperties({ ...defaultFileProperties })
            setSourceFileName(undefined)
            setIsModalVisible(false)
            setSelectedCalculationRules([])
        } catch (err: any) {
            handleAPIError(err)
        }
        setCreateIsLoading(false)
    }

    const handleCancel = (e: any): void => {
        e.preventDefault()
        setFileProperties({ ...defaultFileProperties })
        setSourceFileName(undefined)
        setIsModalVisible(false)
        setSelectedCalculationRules([])
    }

    // @ts-ignore
    return (
        <div>
            <div
                style={{
                    float: 'left',
                    marginLeft: '15px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
            >
                <Space>
                    <a>
                        <Button
                            type="primary"
                            onClick={() => {
                                setIsModalVisible(true)
                            }}
                        >
                            Upload Source File
                        </Button>
                    </a>
                    Group:
                    <Select
                        style={{ minWidth: '120px' }}
                        value={selectedSourceGroup?.SourceGroupID}
                        onChange={(value) => {
                            const sourceGroupItem = sourceGroup?.find(
                                (sg) => sg.SourceGroupID === Number(value)
                            )
                            if (sourceGroupItem) {
                                setSelectedSourceGroupProxy(sourceGroupItem)
                            }
                        }}
                    >
                        {sourceGroup?.map((sourceGroupItem: ISourceGroup) => (
                            <Select.Option
                                key={sourceGroupItem.SourceGroupID}
                                value={sourceGroupItem.SourceGroupID}
                            >
                                {sourceGroupItem.SourceGroupName}
                            </Select.Option>
                        ))}
                    </Select>
                    Dataset:
                    <Select
                        style={{ minWidth: '120px' }}
                        value={selectedDataset?.DatasetID}
                        onChange={(value) => {
                            const sourceDatasetItem = dataset?.find(
                                (sg) => sg.DatasetID === Number(value)
                            )
                            if (sourceDatasetItem) {
                                setSelectedDatasetProxy(sourceDatasetItem)
                            }
                        }}
                    >
                        {dataset?.map((sourceGroupItem: ISourceDataset) => (
                            <Select.Option
                                key={sourceGroupItem.DatasetID}
                                value={sourceGroupItem.DatasetID}
                            >
                                {sourceGroupItem.DatasetName}
                            </Select.Option>
                        ))}
                    </Select>
                    {selectedDataset && (
                        <Button
                            onClick={() => {
                                setDatasetStructureDrawerVisible(true)
                            }}
                        >
                            View Dataset Structure
                        </Button>
                    )}
                </Space>
            </div>
            <div style={{ margin: '15px' }}>
                <SourceFileTable
                    groupId={selectedDataset?.DatasetID}
                    refresh={isModalVisible}
                />
            </div>
            <Modal
                title="Upload Source File"
                visible={isModalVisible}
                onOk={async (e) => {
                    await handleOk(e)
                }}
                confirmLoading={createIsLoading}
                onCancel={(e) => handleCancel(e)}
                destroyOnClose
            >
                <CreateSourceFile
                    setSourceFileName={setSourceFileName}
                    sourceFileName={sourceFileName}
                    setFileProperties={setFileProperties}
                    fileProperties={fileProperties}
                    datasetName={selectedDataset?.DatasetName}
                    datasetId={selectedDataset?.DatasetID}
                    setMetadata={setMetadata}
                    selectedCalculationRules={selectedCalculationRules}
                    setSelectedCalculationRules={setSelectedCalculationRules}
                />
            </Modal>
            {selectedDataset && (
                <ViewDatasetMetadataDrawer
                    metadata={parseJson(selectedDataset.DatasetMetadata)}
                    // @ts-ignore
                    metadataV2={parseJson(selectedDataset.DatasetMetadataV2)}
                    visible={datasetStructureDrawerVisible}
                    setVisible={setDatasetStructureDrawerVisible}
                    title={`${selectedDataset.DatasetName} Structure`}
                />
            )}
        </div>
    )
}

export default withRouter(SourceFile)
