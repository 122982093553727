import { Col, Row } from 'antd'
import React from 'react'

function CalculationRuleConditionalStepCollapseWhenOpened({
    name,
}: {
    name: string
}): JSX.Element {
    return (
        <div>
            <Row>
                <Col>
                    <b>{name}</b>
                    <i> (condition)</i>
                </Col>
            </Row>
        </div>
    )
}
export default CalculationRuleConditionalStepCollapseWhenOpened
