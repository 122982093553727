/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Space, Table, Tag } from 'antd'
import { IRunHistory } from '../../services/run/interfaces/run-history.interface'
import { runService } from '../../services/run/run.service'
import moment from 'moment'
import { getColumnSearchProps } from '../../utils/columnsearch-props'
import { handleAPIError } from '../../utils/catch-error'

function RunnersTable({
    fromDate,
    toDate,
}: {
    fromDate: moment.Moment
    toDate: moment.Moment
}): JSX.Element {
    const [data, setData] = React.useState<IRunHistory[]>([])
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState('')

    React.useEffect(() => {
        setLoading(true)
        runService
            .getAll({ fromDate, toDate })
            .then((res) => {
                setData(res)
                setLoading(false)
            })
            .catch((err) => {
                handleAPIError(err)
                setLoading(false)
            })
    }, [fromDate, toDate])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'RunName',
            key: 'RunId',
            ...getColumnSearchProps('RunName', searchText, setSearchText),
        },
        {
            title: 'Type',
            dataIndex: 'RunType',
            key: 'RunId',
            filters: [
                { text: 'Allocation', value: 'allocation' },
                { text: 'Calculation', value: 'calculation' },
                { text: 'Join', value: 'join' },
                { text: 'Merge', value: 'merge' },
            ],
            onFilter: (
                value: string | number | boolean,
                record: IRunHistory
            ) => {
                return (
                    typeof value === 'string' &&
                    record.RunType?.indexOf?.(value) === 0
                )
            },
            render: (value: string) => {
                return <p style={{ textTransform: 'capitalize' }}>{value}</p>
            },
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'RunId',
            render: (text: string) => {
                if (text === 'running') {
                    return <Tag color="green">Running</Tag>
                }
                if (text === 'finished') {
                    return <Tag color="blue">Finished</Tag>
                }
                if (text === 'failed') {
                    return <Tag color="red">Failed</Tag>
                }
                return (
                    <Tag color="orange" style={{ textTransform: 'capitalize' }}>
                        {text}
                    </Tag>
                )
            },
            filters: [
                { text: 'Running', value: 'running' },
                { text: 'Finished', value: 'finished' },
                { text: 'Failed', value: 'failed' },
                { text: 'Created', value: 'created' },
            ],
            onFilter: (
                value: string | number | boolean,
                record: IRunHistory
            ) => {
                return (
                    typeof value === 'string' &&
                    record.Status?.indexOf?.(value) === 0
                )
            },
        },
        {
            title: 'Created At',
            dataIndex: 'Auditdatetime',
            key: 'RunId',
            render: (text: string) => {
                return moment(text).format('YYYY-MM-DD HH:mm')
            },
            sorter: (a: IRunHistory, b: IRunHistory) => {
                return (
                    moment(a.Auditdatetime).valueOf() -
                    moment(b.Auditdatetime).valueOf()
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'RunId',
            ellipsis: true,
        },
        {
            title: 'Action',
            key: 'action',
            render: (): JSX.Element => (
                <Space size="middle">
                    <a>View</a>
                </Space>
            ),
        },
    ]

    return <Table columns={columns} dataSource={data} loading={loading} />
}

export default RunnersTable
