import { Space, Table } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { IMergeRuleResponse } from '../../services/merge-rule/interfaces/merge-rule-response.interface'
import { mergeRuleService } from '../../services/merge-rule/merge-rule.service'
import { handleAPIError } from '../../utils/catch-error'
import { getColumnSearchProps } from '../../utils/columnsearch-props'
import MergeColumnDrawer from './merge-column-drawer'

function MergeRuleTable(): JSX.Element {
    const [mergeRules, setMergeRules] = React.useState<
        IMergeRuleResponse[] | undefined
    >(undefined)
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState('')
    const [MergeRuleDrawerVisible, setMergeRuleNameDrawerVisible] =
        React.useState(false)
    const [drawerProps, setDrawerProps] = React.useState<{
        title: string
        MergeRuleID: number
        leftDatasetName: string
        rightDatasetName: string
    }>()

    useEffect(() => {
        setLoading(true)
        mergeRuleService
            .get()
            .then((resp) => {
                setMergeRules(resp)
                setLoading(false)
            })
            .catch((err) => {
                handleAPIError(err)
                setLoading(false)
            })
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'MergeRuleID',
            key: 'MergeRuleID',
            sorter: (a: IMergeRuleResponse, b: IMergeRuleResponse) => {
                return (a.MergeRuleID || 1) > (b.MergeRuleID || 1) ? 1 : -1
            },
        },
        {
            title: 'Merge Rule Name',
            dataIndex: 'MergeRuleName',
            key: 'MergeRuleID',
            ...getColumnSearchProps('MergeRuleName', searchText, setSearchText),
        },
        {
            title: 'Data Group Name',
            dataIndex: 'SourceGroupName',
            key: 'MergeRuleID',
        },
        {
            title: 'Left Dataset Name',
            dataIndex: 'leftDatasetName',
            key: 'MergeRuleID',
        },
        {
            title: 'Right Dataset Name',
            dataIndex: 'rightDatasetName',
            key: 'MergeRuleID',
        },
        {
            title: 'Created At',
            dataIndex: 'AuditDateTime',
            key: 'MergeRuleID',
            render: (text: string) => {
                return moment(text).format('YYYY-MM-DD HH:mm')
            },
            sorter: (a: IMergeRuleResponse, b: IMergeRuleResponse) => {
                return (
                    moment(a.AuditDateTime).valueOf() -
                    moment(b.AuditDateTime).valueOf()
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'MergeRuleID',
            ellipsis: true,
        },
        {
            title: 'Action',
            key: 'action',
            render: (record: IMergeRuleResponse): JSX.Element => (
                <Space size="middle">
                    <a
                        role="none"
                        onClick={() => {
                            try {
                                setDrawerProps({
                                    title: `${record.MergeRuleName}`,
                                    MergeRuleID: record.MergeRuleID,
                                    leftDatasetName: record.leftDatasetName,
                                    rightDatasetName: record.rightDatasetName,
                                })
                                setMergeRuleNameDrawerVisible(true)
                            } catch (e: unknown) {
                                handleAPIError(e)
                            }
                        }}
                    >
                        view
                    </a>
                </Space>
            ),
        },
    ]

    return (
        <>
            <Table
                columns={columns}
                dataSource={mergeRules}
                loading={loading}
                size="small"
                style={{ padding: '0 15px' }}
            />
            <MergeColumnDrawer
                visible={MergeRuleDrawerVisible}
                setVisible={setMergeRuleNameDrawerVisible}
                title={drawerProps?.title}
                MergeRuleID={drawerProps?.MergeRuleID}
            />
        </>
    )
}

export default MergeRuleTable
