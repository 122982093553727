import * as React from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Space, Table, Tag } from 'antd'
import { IConfigFile } from '../../services/config-file/interfaces/config-file.interface'
import { getColumnSearchProps } from '../../utils/columnsearch-props'
import moment from 'moment'

function ConfigFileTable({
    configFiles,
    loading,
}: {
    configFiles: IConfigFile[]
    loading: boolean
}): JSX.Element {
    const [searchText, setSearchText] = React.useState('')

    const columns = [
        {
            title: 'Id',
            dataIndex: 'ConfigFileID',
            key: 'ConfigFileID',
            sorter: (a: IConfigFile, b: IConfigFile) => {
                return (a.ConfigFileID || 1) > (b.ConfigFileID || 1) ? 1 : -1
            },
        },
        {
            title: 'Config Name',
            dataIndex: 'ConfigName',
            key: 'ConfigFileID',
            ...getColumnSearchProps('ConfigName', searchText, setSearchText),
        },
        {
            title: 'Dataset Name',
            dataIndex: 'DatasetName',
            key: 'ConfigFileID',
        },
        {
            title: 'Allocation Rule Group Name',
            dataIndex: 'AllocationRuleGroupName',
            key: 'ConfigFileID',
        },
        {
            title: 'Stage',
            dataIndex: 'Stage',
            key: 'ConfigFileID',
        },
        {
            title: 'Stage Status',
            dataIndex: 'StageStatus',
            key: 'ConfigFileID',
            render: (text: string) => {
                if (text === 'running') {
                    return <Tag color="green">Running</Tag>
                }
                if (text === 'finished') {
                    return <Tag color="blue">Finished</Tag>
                }
                if (text === 'failed') {
                    return <Tag color="red">Failed</Tag>
                }
                return <Tag color="orange">{text}</Tag>
            },
        },
        {
            title: 'Created At',
            dataIndex: 'Auditdatetime',
            key: 'RunId',
            render: (text: string) => {
                return moment(text).format('YYYY-MM-DD HH:mm')
            },
            sorter: (a: IConfigFile, b: IConfigFile) => {
                return (
                    moment(a.Auditdatetime).valueOf() -
                    moment(b.Auditdatetime).valueOf()
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: 'UserName',
            key: 'RunId',
            ellipsis: true,
        },
    ]

    return (
        <div>
            <Table
                columns={columns}
                loading={loading}
                dataSource={configFiles}
            />
        </div>
    )
}

export default ConfigFileTable
