/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Grid, Layout } from 'antd'
import React, { useEffect, useState } from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import AvatarDropdown from './AvatarDropDown'
import Notification from './Notification'
import { withRouter } from '../../../utils/withRouter'
import header from '../../../assets/images/header-image.png'
import { useThemeSwitcher } from 'react-css-theme-switcher'

const { Header } = Layout

function HomeHeader({ toggleSider, siderCollapsed }: any): JSX.Element {
    const { useBreakpoint } = Grid

    const screens = useBreakpoint()
    const { currentTheme } = useThemeSwitcher()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [siderHidden, setSiderHidden] = useState(false)

    useEffect(() => {
        if (screens?.xl || screens?.xxl || screens?.lg) {
            setSiderHidden(false)
        } else {
            setSiderHidden(true)
        }
    }, [screens])

    return (
        <div>
            <Header
                style={{
                    height: '85px',
                    padding: 0,
                    background:
                        currentTheme === 'dark'
                            ? 'linear-gradient(180deg, rgba(22,22,22,1) 0%, rgba(80,79,79,1) 50%, rgba(61,63,66,1) 100%)'
                            : 'linear-gradient(180deg, rgba(199,203,207,1) 0%, rgba(255,255,255,1) 50%, rgba(199,203,207,1) 100%)',
                    boxShadow: `2px 2px 10px 2px ${
                        currentTheme === 'dark'
                            ? 'rgba(98, 98, 98, .5)'
                            : 'rgba(168, 170, 172, .5)'
                    }`,
                    overflowY: 'hidden',
                    position: 'fixed',
                    width: '100vw',
                    zIndex: '10',
                }}
            >
                {React.createElement(
                    siderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                        className: 'trigger',
                        onClick: toggleSider,
                        style: {
                            color: currentTheme === 'dark' ? 'white' : 'white',
                            zIndex: 10,
                            top: 35,
                            left: 35,
                            display: 'flex',
                            float: 'right',
                            marginRight: '25px',
                            position: 'absolute',
                            filter:
                                currentTheme === 'dark'
                                    ? undefined
                                    : 'invert(1)',
                        },
                    }
                )}
                <div
                    style={{
                        display: 'table',
                        margin: '0 auto',
                    }}
                >
                    <img
                        src={header}
                        alt="not found"
                        style={{
                            paddingTop: '7.5px',
                            height: '75px',
                        }}
                    />
                </div>
                <div
                    style={{
                        top: 0,
                        right: 0,
                        display: 'flex',
                        float: 'right',
                        marginRight: '25px',
                        position: 'absolute',
                    }}
                >
                    <div
                        style={{
                            margin: '0px 20px',
                            cursor: 'pointer',
                            filter:
                                currentTheme === 'dark'
                                    ? undefined
                                    : 'invert(1)',
                        }}
                    >
                        <Notification />
                    </div>
                    <div>
                        <AvatarDropdown />
                    </div>
                </div>
            </Header>
        </div>
    )
}

export default withRouter(HomeHeader)
