import React from 'react'
import { TopLevelLineage } from '../../services/lineage/itnerfaces/lineage.interface'

export const lineageToReactFlowShape = (
    data: TopLevelLineage[]
): { nodes: any[]; edges: any[] } => {
    const dataLineage = data.reverse()

    const seenParentIds: Record<
        string,
        { count: number; lineages: string[]; isInput: boolean }
    > = {}

    const isInput = (item: TopLevelLineage, i: number): boolean => {
        if (i === 0) {
            return true
        }
        if (item.parentLineageId && seenParentIds[item.parentLineageId]) {
            if (
                seenParentIds[item.parentLineageId].lineages.includes(
                    item.lineageId
                )
            ) {
                return seenParentIds[item.parentLineageId].isInput
            }
        }
        return false
    }

    const getColorCodeForType = (type: string): string => {
        if (type === 'source') {
            return '#0fb828'
        }
        if (type === 'calculation') {
            return '#029499'
        }
        if (type === 'join') {
            return '#c7bd02'
        }
        return 'yellowgreen'
    }

    const nodes: any[] = dataLineage.map((item, i) => {
        let xPosition = 0
        let yPosition = i * 100
        if (item.parentLineageId && seenParentIds[item.parentLineageId]) {
            xPosition = 300 * seenParentIds[item.parentLineageId].count
            seenParentIds[item.parentLineageId].count += 1
            seenParentIds[item.parentLineageId].lineages.push(item.lineageId)
            yPosition = (i - 1) * 100
        } else if (item.parentLineageId) {
            seenParentIds[item.parentLineageId] = {
                count: 1,
                lineages: [item.lineageId],
                isInput: i === 0,
            }
        }

        return {
            id: item.lineageId,
            position: {
                x: xPosition,
                y: yPosition,
            },
            type:
                // eslint-disable-next-line no-nested-ternary
                i === dataLineage.length - 1
                    ? 'output'
                    : isInput(item, i)
                    ? 'input'
                    : undefined,
            data: {
                label: (
                    <div>
                        <div>{item.name}</div>
                        <div style={{ color: getColorCodeForType(item.type) }}>
                            {item.type}
                        </div>
                    </div>
                ),
            },
        }
    })

    const edges: any[] = []
    dataLineage.forEach((item) => {
        if (item.parentLineageId) {
            edges.push({
                id: `${item.lineageId}-${item.parentLineageId}`,
                source: item.lineageId,
                target: item.parentLineageId,
                animated: true,
            })
        }
    })

    return { nodes, edges }
}
