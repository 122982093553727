import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Form,
    Input,
    notification,
    Row,
    Select,
    Space,
    Typography,
} from 'antd'
import { IAllocationRuleGroup } from '../../services/allocation-rule/interfaces/allocation-rule-group.interface'
import { IAllocationRuleFileInfo } from '../../services/allocation-rule/interfaces/allocation-rule-file.interface'
import { ISourceFileInfo } from '../../services/source-group/interfaces/source-file-info.interface'
import { allocationRuleGroupService } from '../../services/allocation-rule/allocation-rule-group.service'
import { sourceFileInfoService } from '../../services/source-group/source-file-info.service'
import { allocationRuleFileService } from '../../services/allocation-rule/allocation-rule-file.service'
import { runService } from '../../services/run/run.service'
import { handleAPIError } from '../../utils/catch-error'

function CreateAllocationRun({
    nextStep,
}: {
    nextStep: () => void
}): JSX.Element {
    const [form] = Form.useForm()

    const [allocationGroups, setAllocationGroups] = useState<
        IAllocationRuleGroup[]
    >([])
    const [selectedAllocationGroup, setSelectedAllocationGroup] = useState<
        IAllocationRuleGroup | undefined
    >(undefined)

    const [allocationRules, setAllocationRules] = useState<
        IAllocationRuleFileInfo[]
    >([])

    const [sourceNames, setSourceNames] = useState<ISourceFileInfo[]>([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (selectedAllocationGroup?.DatasetID) {
            sourceFileInfoService
                .getAllForDataset(selectedAllocationGroup.DatasetID)
                .then(setSourceNames)
                .catch(handleAPIError)
        }
    }, [selectedAllocationGroup])

    useEffect(() => {
        allocationRuleGroupService
            .getAll()
            .then(setAllocationGroups)
            .catch(handleAPIError)
    }, [])

    useEffect(() => {
        if (selectedAllocationGroup?.AllocationRuleGroupID) {
            allocationRuleFileService
                .getAllForGroup(selectedAllocationGroup.AllocationRuleGroupID)
                .then(setAllocationRules)
                .catch(handleAPIError)
        }
    }, [selectedAllocationGroup])

    const onFinish = async (values: any): Promise<void> => {
        setIsLoading(true)
        try {
            await runService.createAllocationRun({
                RunName: values['Run Name'],
                AllocationRuleFileID: values['Allocation Rule File'],
                SourceFileID: values['Source Name'],
            })
            notification.success({
                message: 'Success',
                description: 'Run created successfully',
            })
            form.resetFields()
            setIsLoading(false)
            nextStep()
        } catch (error) {
            handleAPIError(error)
            setIsLoading(false)
        }
    }

    const onReset = (): void => {
        form.resetFields()
    }

    // @ts-ignore
    // @ts-ignore
    return (
        <div
            style={{
                paddingTop: '30px',
                margin: '0 auto',
                display: 'table',
                minWidth: '400px',
            }}
        >
            <Form
                layout="vertical"
                form={form}
                name="create-run"
                onFinish={onFinish}
                style={{ maxWidth: '400px' }}
            >
                <Form.Item
                    name="Run Name"
                    label="Run Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Row gutter={[20, 0]}>
                    <Col span={12}>
                        <Form.Item
                            name="Allocation Group"
                            label="Allocation Group"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select Allocation Group"
                                filterOption={(input, option) =>
                                    option?.children
                                        // @ts-ignore
                                        ?.toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear
                                onChange={(value: number) => {
                                    setSelectedAllocationGroup(
                                        allocationGroups.find(
                                            (item) =>
                                                item.AllocationRuleGroupID ===
                                                value
                                        )
                                    )
                                    form.resetFields([
                                        'Allocation Rule File',
                                        'Source Name',
                                    ])
                                }}
                            >
                                {allocationGroups?.map?.((item) => (
                                    <Select.Option
                                        key={item.AllocationRuleGroupID}
                                        value={item.AllocationRuleGroupID}
                                    >
                                        {item.AllocationRuleGroupName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="Allocation Rule File"
                            label="Allocation Rule File"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select File"
                                filterOption={(input, option) =>
                                    option?.children
                                        // @ts-ignore
                                        ?.toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear
                                disabled={!selectedAllocationGroup}
                            >
                                {allocationRules?.map?.((item) => (
                                    <Select.Option
                                        key={item.AllocationRuleFileID}
                                        value={item.AllocationRuleFileID}
                                    >
                                        {item.AllocationRuleName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {selectedAllocationGroup && (
                    <Typography.Paragraph
                        style={{
                            marginTop: '-16px',
                            fontSize: '13px',
                            marginLeft: '3px',
                        }}
                    >
                        Associated to Data Group:{' '}
                        {selectedAllocationGroup.SourceGroupName} | Dataset:{' '}
                        {selectedAllocationGroup.DatasetName}
                    </Typography.Paragraph>
                )}
                <Form.Item
                    name="Source Name"
                    label="Source Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Select Source name"
                        filterOption={(input, option) =>
                            option?.children
                                // @ts-ignore
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        disabled={!selectedAllocationGroup}
                    >
                        {sourceNames?.map?.((item) => (
                            <Select.Option
                                key={item.SourceName}
                                value={item.SourceFileID}
                            >
                                {item.SourceName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Row justify="center">
                    <Form.Item>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                            >
                                Submit
                            </Button>
                            <Button
                                htmlType="button"
                                onClick={onReset}
                                loading={isLoading}
                            >
                                Reset
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    )
}

export default CreateAllocationRun
