/* eslint-disable no-unused-vars */
import React, { useContext, useMemo } from 'react'
import { Col, Row, Space } from 'antd'
import { CalculationRuleContext } from './new-calculation-rule'
import { CalAggregateOperations } from '../../services/calculation-rule/interfaces/calculation-rule.interface'
import { calculationRuleHelper } from './calculation-rule.helper'

function ViewCalculationRuleAggregateStep({
    stepId,
    columnId,
    name,
}: {
    stepId: number
    columnId: number
    name: string
}): JSX.Element {
    const calculationRuleValue = useContext(CalculationRuleContext)

    const currentStepDetails = useMemo(() => {
        return calculationRuleValue?.columns
            ?.find((column) => column.columnID === columnId)
            ?.steps?.find((step) => step.stepID === stepId)
    }, [calculationRuleValue, columnId, stepId])

    return (
        <Row justify="start" style={{ marginTop: '10px' }}>
            <Col span={4}>
                <b>{name}</b>
                <i> (aggregate)</i>
            </Col>
            <Col span={17}>
                <Row>
                    <Col>
                        <span
                            style={{ paddingRight: '10px', fontWeight: 'bold' }}
                        >
                            =
                        </span>
                    </Col>
                    <Col>
                        <Space>
                            <p>
                                <b>
                                    {/* @ts-ignore */}
                                    {currentStepDetails?.aggregate?.Operator}
                                </b>
                            </p>
                            <p>
                                <i>
                                    {/* @ts-ignore */}
                                    {currentStepDetails?.aggregate?.Operator ===
                                    CalAggregateOperations.Average
                                        ? 'on'
                                        : 'of'}
                                </i>
                            </p>
                            {calculationRuleHelper.getColumnNameFromColumnType(
                                calculationRuleValue,
                                // @ts-ignore
                                currentStepDetails?.aggregate?.Column1Type,
                                // @ts-ignore
                                currentStepDetails?.aggregate?.Column1,
                                columnId
                            )}
                            {currentStepDetails?.aggregate?.groupBy?.length && (
                                <>
                                    <p>
                                        <i>Group By</i>
                                    </p>
                                    {currentStepDetails?.aggregate?.groupBy.map(
                                        (groupBy, index) => {
                                            return (
                                                <>
                                                    {calculationRuleHelper.getColumnNameFromColumnType(
                                                        calculationRuleValue,
                                                        // @ts-ignore
                                                        groupBy.ColumnType,
                                                        // @ts-ignore
                                                        groupBy.ColumnValue,
                                                        columnId,
                                                        (currentStepDetails
                                                            ?.aggregate?.groupBy
                                                            ?.length || 0) -
                                                            1 !==
                                                            index
                                                    )}
                                                </>
                                            )
                                        }
                                    )}
                                </>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ViewCalculationRuleAggregateStep
