import { Space, Table } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { routeMapMini } from '../../constants/routeMap'
import { ITeamUsers } from '../../services/user-management/interfaces/teamusers.interface'
import { IUsers } from '../../services/user-management/interfaces/users.interface'
import { getColumnSearchProps } from '../../utils/columnsearch-props'

function UsersTable({
    users,
    loading,
}: {
    users: IUsers[] | undefined
    loading: boolean
}): JSX.Element {
    const [searchText, setSearchText] = React.useState('')

    const columns = [
        {
            title: 'User ID',
            dataIndex: 'UserID',
            key: 'UserID',
        },
        {
            title: 'Username',
            dataIndex: 'UserName',
            key: 'UserName',
            ...getColumnSearchProps('UserName', searchText, setSearchText),
        },
        {
            title: 'First Name',
            dataIndex: 'FirstName',
            key: 'UserName',
        },
        {
            title: 'Last Name',
            dataIndex: 'LastName',
            key: 'UserName',
        },
        {
            title: 'Reportee Name',
            dataIndex: 'ReporteeName',
            key: 'UserName',
        },
        {
            title: 'User Status',
            dataIndex: 'UserStatus',
            key: 'UserName',
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'TeamID',
            render: (_: string, record: ITeamUsers) => (
                <Space>
                    <Link
                        to={`${routeMapMini.userTeams}?userId=${record.UserID}`}
                    >
                        User Teams
                    </Link>
                </Space>
            ),
        },
    ]

    return (
        <Table
            columns={columns}
            dataSource={users}
            loading={loading}
            style={{ padding: '0 15px 0 7.5px' }}
        />
    )
}
export default UsersTable
