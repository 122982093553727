import {
    IAllocationRunHistoryCount,
    IAllocationRunHistoryRequest,
    ICalculationRunHistoryRequest,
    IJoinRunHistoryRequest,
    IMergeRunHistoryRequest,
    IRunHistory,
    IRunHistoryCount,
    IRunHistoryQuery,
} from './interfaces/run-history.interface'
import { AxiosX } from '../../utils/axios'

export const runService = {
    createAllocationRun: async (
        run: IAllocationRunHistoryRequest
    ): Promise<IRunHistory> => {
        const resp = await AxiosX.post<IRunHistory>(
            '/v1/run/create-allocation-run',
            run
        )
        return resp.data
    },

    createCalculationRun: async (
        run: ICalculationRunHistoryRequest
    ): Promise<IRunHistory> => {
        const resp = await AxiosX.post<IRunHistory>(
            '/v1/run/create-calculation-run',
            run
        )
        return resp.data
    },

    createJoinRun: async (
        run: IJoinRunHistoryRequest
    ): Promise<IRunHistory> => {
        const resp = await AxiosX.post<IRunHistory>(
            '/v1/run/create-join-run',
            run
        )
        return resp.data
    },

    createMergRun: async (
        run: IMergeRunHistoryRequest
    ): Promise<IRunHistory> => {
        const resp = await AxiosX.post<IRunHistory>(
            '/v1/run/create-merge-run',
            run
        )
        return resp.data
    },

    getAll: async (data: IRunHistoryQuery): Promise<IRunHistory[]> => {
        const resp = await AxiosX.get<IRunHistory[]>('/v1/run/all', {
            params: {
                ...data,
                fromDate: data.fromDate.format('YYYY-MM-DD'),
                toDate: data.toDate.format('YYYY-MM-DD'),
            },
        })
        return resp.data
    },

    getAllByEmail: async (): Promise<IRunHistory[]> => {
        const resp = await AxiosX.get<IRunHistory[]>('/v1/run/all/user')
        return resp.data
    },

    getAllRunsCountByUserId: async (
        data: IRunHistoryQuery
    ): Promise<IRunHistoryCount[]> => {
        const resp = await AxiosX.get<IRunHistoryCount[]>(
            '/v1/run/all/user/count',
            {
                params: {
                    fromDate: data.fromDate.format('YYYY-MM-DD'),
                    toDate: data.toDate.format('YYYY-MM-DD'),
                },
            }
        )
        return resp.data
    },

    getAllocationRunsCountByUserId: async (): Promise<
        IAllocationRunHistoryCount[]
    > => {
        const resp = await AxiosX.get<IAllocationRunHistoryCount[]>(
            '/v1/run/all/user/allocation-count'
        )
        return resp.data
    },
}
