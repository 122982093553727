import React, { useEffect } from 'react'
import {
    Button,
    Checkbox,
    Col,
    Input,
    notification,
    Row,
    Select,
    Table,
    Typography,
} from 'antd'
import { ColumnType } from 'antd/es/table'
import { DeleteTwoTone } from '@ant-design/icons'

type IColumnData = {
    id: number
    columnName: string
    alias: string
}

type IProps = {
    leftColumns: string[]
    rightColumns: string[]
    selectedLeftColumns: IColumnData[]
    selectedRightColumns: IColumnData[]
    setSelectedLeftColumns: React.Dispatch<React.SetStateAction<IColumnData[]>>
    setSelectedRightColumns: React.Dispatch<React.SetStateAction<IColumnData[]>>
    leftSourceDatasetName: string
    rightSourceDatasetName: string
}

function JoinSelectors({
    leftColumns,
    rightColumns,
    selectedRightColumns,
    selectedLeftColumns,
    setSelectedLeftColumns,
    setSelectedRightColumns,
    leftSourceDatasetName,
    rightSourceDatasetName,
}: IProps): JSX.Element {
    const [leftCheckedAll, setLeftCheckedAll] = React.useState<boolean>()
    const [rightCheckedAll, setRightCheckedAll] = React.useState<boolean>()

    useEffect(() => {
        setLeftCheckedAll(selectedLeftColumns.length === leftColumns.length)
        setRightCheckedAll(selectedRightColumns.length === rightColumns.length)
    }, [selectedLeftColumns, selectedRightColumns, leftColumns, rightColumns])

    const buildColumns = (
        type: 'left' | 'right'
    ): ColumnType<IColumnData>[] => {
        return [
            {
                title: 'Column',
                dataIndex: 'columnName',
                key: 'id',
                render: (text: string, record) => {
                    return (
                        <Select
                            style={{ minWidth: '180px' }}
                            value={text}
                            allowClear
                            onChange={(e: string) => {
                                if (type === 'left') {
                                    setSelectedLeftColumns(
                                        selectedLeftColumns.map((item) => {
                                            if (item.id === record.id) {
                                                return {
                                                    ...item,
                                                    columnName: e,
                                                }
                                            }
                                            return item
                                        })
                                    )
                                } else {
                                    setSelectedRightColumns(
                                        selectedRightColumns.map((item) => {
                                            if (item.id === record.id) {
                                                return {
                                                    ...item,
                                                    columnName: e,
                                                }
                                            }
                                            return item
                                        })
                                    )
                                }
                            }}
                        >
                            {(type === 'left' ? leftColumns : rightColumns)
                                .filter((item) => {
                                    const selectedItems =
                                        type === 'right'
                                            ? selectedRightColumns
                                            : selectedLeftColumns
                                    return !selectedItems.some(
                                        (selectedItem) =>
                                            selectedItem.columnName === item
                                    )
                                })
                                .map((item) => {
                                    return (
                                        <Select.Option key={item} value={item}>
                                            {item}
                                        </Select.Option>
                                    )
                                })}
                        </Select>
                    )
                },
            },
            {
                title: 'Alias',
                dataIndex: 'alias',
                key: 'id',
                render: (text: string, record) => {
                    return (
                        <Input
                            value={text}
                            onChange={(value) => {
                                if (type === 'left') {
                                    setSelectedLeftColumns(
                                        selectedLeftColumns.map((item) => {
                                            if (item.id === record.id) {
                                                return {
                                                    ...item,
                                                    alias: value.target.value,
                                                }
                                            }
                                            return item
                                        })
                                    )
                                } else {
                                    setSelectedRightColumns(
                                        selectedRightColumns.map((item) => {
                                            if (item.id === record.id) {
                                                return {
                                                    ...item,
                                                    alias: value.target.value,
                                                }
                                            }
                                            return item
                                        })
                                    )
                                }
                            }}
                        />
                    )
                },
            },
            {
                title: 'Act',
                key: 'id',
                render: (_text: string, record) => {
                    return (
                        <DeleteTwoTone
                            twoToneColor="red"
                            onClick={() => {
                                if (type === 'left') {
                                    setSelectedLeftColumns(
                                        selectedLeftColumns.filter(
                                            (item) => item.id !== record.id
                                        )
                                    )
                                } else {
                                    setSelectedRightColumns(
                                        selectedRightColumns.filter(
                                            (item) => item.id !== record.id
                                        )
                                    )
                                }
                            }}
                        />
                    )
                },
            },
        ]
    }

    return (
        <Row>
            <Col span={12} style={{ marginTop: '20px' }}>
                <Typography.Title level={4}>
                    Select {leftSourceDatasetName} Columns
                </Typography.Title>
                <Checkbox
                    checked={leftCheckedAll}
                    onChange={(e) => {
                        setLeftCheckedAll(e.target.checked)
                        if (e.target.checked) {
                            setSelectedLeftColumns(
                                leftColumns.map((item, index) => {
                                    return {
                                        id: index + 1,
                                        alias: item,
                                        columnName: item,
                                    }
                                })
                            )
                        } else {
                            setSelectedLeftColumns([])
                        }
                    }}
                >
                    Select All <b>{leftSourceDatasetName}</b> Columns
                </Checkbox>

                <Table
                    columns={buildColumns('left')}
                    dataSource={selectedLeftColumns}
                    pagination={false}
                    scroll={{ x: true, y: 400 }}
                    style={{ width: '95%' }}
                />
                <Button
                    size="small"
                    type="primary"
                    disabled={
                        !leftColumns.some(
                            (item) =>
                                !selectedLeftColumns.some(
                                    (selectedItem) =>
                                        selectedItem.columnName === item
                                )
                        )
                    }
                    onClick={() => {
                        let id = 1
                        if (selectedLeftColumns.length > 0) {
                            id =
                                selectedLeftColumns[
                                    selectedLeftColumns.length - 1
                                ].id + 1
                        }

                        const unUsedColumn = leftColumns.filter(
                            (item) =>
                                !selectedLeftColumns.some(
                                    (selectedItem) =>
                                        selectedItem.columnName === item
                                )
                        )

                        if (!unUsedColumn.length) {
                            notification.error({
                                message: 'Error',
                                description: 'All columns are selected',
                            })
                            return
                        }

                        setSelectedLeftColumns([
                            ...selectedLeftColumns,
                            {
                                id,
                                columnName: unUsedColumn[0],
                                alias: unUsedColumn[0],
                            },
                        ])
                    }}
                >
                    Add Selection
                </Button>
            </Col>
            <Col span={12} style={{ marginTop: '20px' }}>
                <Typography.Title level={4}>
                    Select {rightSourceDatasetName} Columns
                </Typography.Title>
                <Checkbox
                    checked={rightCheckedAll}
                    onChange={(e) => {
                        setRightCheckedAll(e.target.checked)
                        if (e.target.checked) {
                            setSelectedRightColumns(
                                rightColumns.map((item, index) => {
                                    return {
                                        id: index + 1,
                                        alias: item,
                                        columnName: item,
                                    }
                                })
                            )
                        } else {
                            setSelectedRightColumns([])
                        }
                    }}
                >
                    Select All <b>{rightSourceDatasetName}</b> Columns
                </Checkbox>

                <Table
                    columns={buildColumns('right')}
                    dataSource={selectedRightColumns}
                    pagination={false}
                    scroll={{ x: true, y: 400 }}
                    style={{ width: '95%' }}
                />
                <Button
                    size="small"
                    type="primary"
                    disabled={
                        !rightColumns.some(
                            (item) =>
                                !selectedRightColumns.some(
                                    (selectedItem) =>
                                        selectedItem.columnName === item
                                )
                        )
                    }
                    onClick={() => {
                        let id = 1
                        if (selectedRightColumns.length > 0) {
                            id =
                                selectedRightColumns[
                                    selectedRightColumns.length - 1
                                ].id + 1
                        }

                        const unUsedColumn = rightColumns.filter(
                            (item) =>
                                !selectedRightColumns.some(
                                    (selectedItem) =>
                                        selectedItem.columnName === item
                                )
                        )

                        if (!unUsedColumn.length) {
                            notification.error({
                                message: 'Error',
                                description: 'All columns are selected',
                            })
                            return
                        }

                        setSelectedRightColumns([
                            ...selectedRightColumns,
                            {
                                id,
                                columnName: unUsedColumn[0],
                                alias: unUsedColumn[0],
                            },
                        ])
                    }}
                >
                    Add Selection
                </Button>
            </Col>
        </Row>
    )
}

export default JoinSelectors
