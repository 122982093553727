import {
    Button,
    Form,
    Input,
    notification,
    Row,
    Select,
    Steps,
    Space,
    Col,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { routeMapMini } from '../../constants/routeMap'
import { IUsers } from '../../services/user-management/interfaces/users.interface'
import { teamsService } from '../../services/user-management/teams.service'
import { usersService } from '../../services/user-management/users.service'
import { handleAPIError } from '../../utils/catch-error'
import { useAuth0 } from '@auth0/auth0-react'

function CreateNewTeam(): JSX.Element {
    const [currentStep, setCurrentStep] = useState(0)
    const [users, setUsers] = React.useState<IUsers[] | undefined>(undefined)
    const [configureTeamForm] = Form.useForm()
    const [addUsersForm] = Form.useForm()
    const [UserId, setUserId] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { user } = useAuth0()

    const truncateEmail = (email: string | undefined): string => {
        if (!email) return 'Guest'
        if (email.length > 10) {
            return `${email}`
        }
        return email
    }

    const nextStep = (): void => {
        if (currentStep < 2) {
            setCurrentStep(currentStep + 1)
        }
    }

    const prevStep = (): void => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1)
        }
    }

    const checkTeamNameExist = async (): Promise<void> => {
        const teams = await teamsService.findTeamname(
            configureTeamForm.getFieldValue('Team Name')
        )

        if (teams.length > 0) {
            return Promise.reject(new Error(`This 'Team Name' Already exists`))
        }
        return Promise.resolve()
    }

    const ConfigureTeamFinish = async (): Promise<void> => {
        setIsLoading(true)
        try {
            setUserId(configureTeamForm.getFieldValue('Managed By'))
            setIsLoading(false)
            nextStep()
        } catch (error) {
            handleAPIError(error)
            setIsLoading(false)
        }
    }

    const AddUsersFinish = async (values: any): Promise<void> => {
        setIsLoading(true)
        let addUsersArray: number[] = []

        if (values.UserID === undefined) {
            addUsersArray = []
        } else {
            addUsersArray = values?.UserID?.map((item: any) => item.value)
        }

        try {
            await teamsService.create({
                TeamName: configureTeamForm.getFieldValue('Team Name'),
                ManagedBy: configureTeamForm.getFieldValue('Managed By'),
                AddUsers: addUsersArray,
            })
            notification.success({
                message: 'Success',
                description: 'Team created successfully',
            })
            addUsersForm.resetFields()
            setIsLoading(false)
            nextStep()
        } catch (error) {
            handleAPIError(error)
            setIsLoading(false)
        }
    }

    const ConfigureTeamReset = (): void => {
        configureTeamForm.resetFields()
    }

    const AddUsersReset = (): void => {
        addUsersForm.resetFields()
    }

    useEffect(() => {
        usersService
            .getAll()
            .then((res) => {
                setUsers(res)
            })
            .catch(handleAPIError)
    }, [])

    return (
        <div style={{ margin: '20px' }}>
            <Steps current={currentStep}>
                <Steps.Step title="Configure Team" description="" />
                <Steps.Step title="Add Users" />
                <Steps.Step title="Done" />
            </Steps>
            <div>
                {currentStep === 0 && (
                    <Form
                        form={configureTeamForm}
                        name="teams"
                        autoComplete="off"
                        onFinish={ConfigureTeamFinish}
                        style={{ marginTop: '15px' }}
                        wrapperCol={{ span: 6 }}
                    >
                        <Form.Item
                            label="Team Name"
                            name="Team Name"
                            rules={[
                                {
                                    required: true,
                                    min: 3,
                                },
                                { validator: checkTeamNameExist },
                            ]}
                        >
                            <Input placeholder="Enter Team Name" />
                        </Form.Item>

                        <Form.Item
                            label="Managed By"
                            name="Managed By"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select>
                                {users?.map((userItem: IUsers) => (
                                    <Select.Option
                                        key={userItem.UserID}
                                        value={userItem.UserID}
                                    >
                                        {userItem.UserName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Next
                                </Button>
                                <Button
                                    htmlType="button"
                                    onClick={ConfigureTeamReset}
                                    loading={isLoading}
                                >
                                    Reset
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                )}
                {currentStep === 1 && (
                    <div>
                        <Form
                            form={addUsersForm}
                            name="create-run"
                            onFinish={AddUsersFinish}
                            style={{ marginTop: '15px' }}
                        >
                            <Form.Item
                                name="UserID"
                                label="Add Users"
                                wrapperCol={{ span: 5 }}
                            >
                                <Select
                                    labelInValue
                                    mode="multiple"
                                    placeholder="select Users"
                                >
                                    {users?.map((userItem: IUsers) => {
                                        if (
                                            Number(UserId) !==
                                                Number(userItem.UserID) &&
                                            userItem.UserName !==
                                                truncateEmail(user?.email)
                                        ) {
                                            return (
                                                <Select.Option
                                                    key={userItem.UserID}
                                                    value={userItem.UserID}
                                                >
                                                    {userItem.UserName}
                                                </Select.Option>
                                            )
                                        }
                                        return null
                                    })}
                                </Select>
                            </Form.Item>
                            <Row>
                                <Col span={10}>
                                    <Button onClick={prevStep}>Previous</Button>
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <Space>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={isLoading}
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                htmlType="button"
                                                onClick={AddUsersReset}
                                                loading={isLoading}
                                            >
                                                Reset
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )}
                {currentStep === 2 && (
                    <div>
                        <p style={{ marginTop: '15px' }}>
                            Team is created successfully. Go to{' '}
                            <Link to={routeMapMini.userManagement}>
                                User Management
                            </Link>{' '}
                            to check or create another team{' '}
                            <a href={routeMapMini.createNewTeam}>
                                Another Team
                            </a>
                            .
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}
export default CreateNewTeam
